import { AppModel, AfaqyObject } from '../../../common/classes';
import { TranslateService } from '@ngx-translate/core';

class Location extends AfaqyObject {
  private _type: string;
  private _coordinates: number[];

  protected copyFields = ['type', 'coordinates'];

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get coordinates(): number[] {
    return this._coordinates;
  }

  set coordinates(value: number[]) {
    this._coordinates = value;
  }
}
export class Markers extends AppModel {
  constructor(protected translate: TranslateService) {
    super();
  }

  private _name: string;
  private _user_id: string;
  private _status: number;
  private _desc: string;
  private _icon: string;
  private _iconUrl: string;
  private _location: Location = new Location();
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;

  protected copyFields = [
    'id',
    'name',
    'user_id',
    'status',
    'desc',
    'icon',
    'iconUrl',
    'location',
    'created_at',
    'deleted_at',
    'users_list',
  ];
  protected createFields = [
    'name',
    'user_id',
    'status',
    'desc',
    'icon',
    'location',
    'users_list',
  ];
  protected updateFields = [
    'name',
    'user_id',
    'status',
    'desc',
    'icon',
    'location',
    'users_list',
  ];

  static statusVal = ['not_active', 'active'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get status(): number {
    return this._status;
  }

  set status(value: number) {
    this._status = value;
  }

  get desc(): string {
    return this._desc;
  }

  set desc(value: string) {
    this._desc = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get iconUrl(): string {
    return this._iconUrl;
  }

  set iconUrl(value: string) {
    this._iconUrl = value;
  }

  get location(): Location {
    return this._location;
  }

  set location(value: Location) {
    this._location = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get statusDisplay() {
    return Markers.statusVal[this.status ? 1 : 0];
  }

  get statusType() {
    return this.translate.instant(this.statusDisplay);
  }
}
