import { AfaqyHelper, AfaqyObject, AppModel } from 'app/common/classes';

export interface ColorRangeObject {
  from: any;
  to: any;
  color: string;
}

export class InventoryRoomsModel extends AppModel {
  private _name: string;
  private _created_at: Date;
  private _deleted_at: Date;
  private _warehouse: string;
  private _building: string;
  private _floor: string;
  private _units: string;
  private _safe_intervals: boolean;

  // private _profile: string;
  protected sensorsTypesFields = [
    'temperature_intervals',
    'humidity_intervals',
  ];
  protected copyFields = [
    'id',
    'name',
    'warehouse',
    'building',
    'floor',
    'units',
    'created_at',
    'deleted_at',
    ...this.sensorsTypesFields,
  ];
  protected createFields = [
    'name',
    'warehouse',
    'building',
    'floor',
    'units',
    ...this.sensorsTypesFields,
  ];
  protected updateFields = [
    'name',
    'warehouse',
    'building',
    'floor',
    'units',
    ...this.sensorsTypesFields,
  ];

  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }

  public get warehouse(): string {
    return this._warehouse;
  }
  public set warehouse(value: string) {
    this._warehouse = value;
  }

  public get building(): string {
    return this._building;
  }
  public set building(value: string) {
    this._building = value;
  }

  public get floor(): string {
    return this._floor;
  }
  public set floor(value: string) {
    this._floor = value;
  }

  public get units(): string {
    return this._units;
  }
  public set units(value: string) {
    this._units = value;
  }
  public get created_at(): Date {
    return this._created_at;
  }
  public set created_at(value: Date) {
    this._created_at = value;
  }
  public get deleted_at(): Date {
    return this._deleted_at;
  }
  public set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  public get safe_intervals(): boolean {
    return this._safe_intervals;
  }
  public set safe_intervals(value: boolean) {
    this._safe_intervals = value;
  }
}
