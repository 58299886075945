import { AfaqyObject } from '../../../common/classes';
import { AppsTaxiInvoices } from './app-taxi-invoices';
import { AppsCCTV } from './apps-cctv';
import { AppsFleets } from './apps-fleets';
import { AppsRoutes } from './apps-routes';
import { AppsSenseware } from './apps-senseware';

export class Apps extends AfaqyObject {
  private _cctv: AppsCCTV = new AppsCCTV();
  private _routes: AppsRoutes = new AppsRoutes();
  private _fleets: AppsFleets = new AppsFleets();
  private _senseware: AppsSenseware = new AppsSenseware();
  private _taxi_invoices: AppsTaxiInvoices = new AppsTaxiInvoices();

  protected copyFields = [
    'cctv',
    'routes',
    'fleets',
    'taxi_invoices',
    'senseware',
  ];

  get cctv(): AppsCCTV {
    return this._cctv;
  }

  set cctv(value: AppsCCTV) {
    this._cctv = value;
  }

  get routes(): AppsRoutes {
    return this._routes;
  }

  set routes(value: AppsRoutes) {
    this._routes = value;
  }

  get fleets(): AppsFleets {
    return this._fleets;
  }

  set fleets(value: AppsFleets) {
    this._fleets = value;
  }
  get senseware(): AppsSenseware {
    return this._senseware;
  }

  set senseware(value: AppsSenseware) {
    this._senseware = value;
  }

  get taxi_invoices(): AppsTaxiInvoices {
    return this._taxi_invoices;
  }

  set taxi_invoices(value: AppsTaxiInvoices) {
    this._taxi_invoices = value;
  }
}
