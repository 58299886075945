import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { StatusService } from '../../modules/status/services';

@Injectable()
export class StatusResolver implements Resolve<any> {
  constructor(public service: StatusService) {}

  resolve() {
    return this.service.statusList();
  }
}
