<div [formGroup]="group">
  <div formGroupName="rate">
    <div class="row">
      <div class="col-12">
        <label class="form-control-label font-weight-bold">
          {{ 'units.fc.rate.title' | translate }}
        </label>
      </div>
    </div>
    <!--    <div class="row">
      <div class="col-12">
        <label class="form-control-label">
          {{ 'units.fc.rate.enable' | translate }}
        </label>
      </div>
      <div class="col-12">
        <label
          class="switch"
          for="unit-fc-rate-enable"
          [attr.testId]="cid + 'rateEnable'"
        >
          <input
            value="None"
            id="unit-fc-rate-enable"
            type="checkbox"
            formControlName="enable"
            (click)="toggleRate()"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>-->
    <div>
      <div class="row">
        <div class="col-md-6">
          <div
            class="row form-group"
            [class.has-danger]="
              (group.controls['rate']['controls']['summer_rate'].dirty ||
                group.controls['rate']['controls']['summer_rate'].touched) &&
              group.controls['rate']['controls']['summer_rate'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'units.fc.rate.summer_rate' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <input
                class="form-control form-control-sm"
                formControlName="summer_rate"
                [attr.testId]="cid + 'summer_rate'"
              />
              <control-errors-hint
                [ctl]="group.controls['rate']['controls']['summer_rate']"
              >
              </control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (group.controls['rate']['controls']['winter_from_month'].dirty ||
                group.controls['rate']['controls']['winter_from_month']
                  .touched) &&
              group.controls['rate']['controls']['winter_from_month'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'units.fc.rate.winter_from_date' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="winter_from_month"
                [attr.testId]="cid + 'winter_from_month'"
              >
                <option *ngFor="let m of formOptions.months" [value]="m">
                  {{ 'months.' + m | translate }} ({{ m }})
                </option>
              </select>
              <control-errors-hint
                [ctl]="group.controls['rate']['controls']['winter_from_month']"
              >
              </control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div
            class="row form-group"
            [class.has-danger]="
              (group.controls['rate']['controls']['winter_from_day'].dirty ||
                group.controls['rate']['controls']['winter_from_day']
                  .touched) &&
              group.controls['rate']['controls']['winter_from_day'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"></label>
            </div>
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="winter_from_day"
                [attr.testId]="cid + 'winter_from_day'"
              >
                <option
                  *ngFor="let md of formOptions.monthFromDays"
                  [value]="md"
                >
                  {{ md }}
                </option>
              </select>
              <control-errors-hint
                [ctl]="group.controls['rate']['controls']['winter_from_day']"
              >
              </control-errors-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div
            class="row form-group"
            [class.has-danger]="
              (group.controls['rate']['controls']['winter_rate'].dirty ||
                group.controls['rate']['controls']['winter_rate'].touched) &&
              group.controls['rate']['controls']['winter_rate'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'units.fc.rate.winter_rate' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <input
                class="form-control form-control-sm"
                formControlName="winter_rate"
                [attr.testId]="cid + 'winter_rate'"
              />
              <control-errors-hint
                [ctl]="group.controls['rate']['controls']['winter_rate']"
              >
              </control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (group.controls['rate']['controls']['winter_to_month'].dirty ||
                group.controls['rate']['controls']['winter_to_month']
                  .touched) &&
              group.controls['rate']['controls']['winter_to_month'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'units.fc.rate.winter_to_date' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="winter_to_month"
                [attr.testId]="cid + 'winter_to_month'"
              >
                <option *ngFor="let m of formOptions.months" [value]="m">
                  {{ 'months.' + m | translate }} ({{ m }})
                </option>
              </select>
              <control-errors-hint
                [ctl]="group.controls['rate']['controls']['winter_to_month']"
              >
              </control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div
            class="row form-group"
            [class.has-danger]="
              (group.controls['rate']['controls']['winter_to_day'].dirty ||
                group.controls['rate']['controls']['winter_to_day'].touched) &&
              group.controls['rate']['controls']['winter_to_day'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"></label>
            </div>
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="winter_to_day"
                [attr.testId]="cid + 'winter_to_day'"
              >
                <option *ngFor="let md of formOptions.monthToDays" [value]="md">
                  {{ md }}
                </option>
              </select>
              <control-errors-hint
                [ctl]="group.controls['rate']['controls']['winter_to_day']"
              >
              </control-errors-hint>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row mt-4">
      <div class="col-12">
        <label class="form-control-label font-weight-bold">
          {{ 'units.fc.additional' | translate }}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['time_of_ignoring_signals_after_stationary_on']
              .dirty ||
              group.controls['time_of_ignoring_signals_after_stationary_on']
                .touched) &&
            group.controls['time_of_ignoring_signals_after_stationary_on']
              .errors
          "
        >
          <div class="col-12">
            <label class="form-control-label">
              {{
                'units.fc.time_of_ignoring_signals_after_stationary_on'
                  | translate
              }}:
            </label>
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="time_of_ignoring_signals_after_stationary_on"
              [attr.testId]="
                cid + 'time_of_ignoring_signals_after_stationary_on'
              "
            />
            <control-errors-hint
              [ctl]="
                group.controls['time_of_ignoring_signals_after_stationary_on']
              "
            >
            </control-errors-hint>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['target_fuel_per_month'].dirty ||
              group.controls['target_fuel_per_month'].touched) &&
            group.controls['target_fuel_per_month'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.fc.target_fuel_per_month' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="target_fuel_per_month"
              [attr.testId]="cid + 'target_fuel_per_month'"
            />
            <control-errors-hint
              [ctl]="group.controls['target_fuel_per_month']"
            >
            </control-errors-hint>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<div formGroupName="math">
        <div class="row">
            <div class="col-12">
                <label class="form-control-label">
                    {{'units.fc.math.enable' | translate}}
                </label>
            </div>
            <div class="col-12">
                <label class="switch" for="unit-fc-math-enable" [attr.testId]="cid +'mathEnable'">
                    <input value="None" id="unit-fc-math-enable" type="checkbox" formControlName="enable"
                        (click)="toggleMath()">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>
        <div *ngIf="group.controls['math']['controls']['enable'].value" @showhide>
            <div class="row">
                <div class="col-md-6">
                    <div class="row form-group"
                        [class.has-danger]="(group.controls['math']['controls']['idling'].dirty ||group.controls['math']['controls']['idling'].touched) && group.controls['math']['controls']['idling'].errors">
                        <div class="col-12">
                            <label class="form-control-label">{{"units.fc.math.idling" | translate}}:</label>
                        </div>
                        <div class="col-12 input-group form-group-hint">
                            <input class="form-control form-control-sm " formControlName="idling"
                                [attr.testId]="cid + 'idling'">
                            <control-errors-hint [ctl]="group.controls['math']['controls']['idling']">
                            </control-errors-hint>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row form-group"
                        [class.has-danger]="(group.controls['math']['controls']['urban'].dirty ||group.controls['math']['controls']['urban'].touched) && group.controls['math']['controls']['urban'].errors">
                        <div class="col-12">
                            <label class="form-control-label">{{"units.fc.math.urban" | translate}}:</label>
                        </div>
                        <div class="col-12 input-group form-group-hint">
                            <input class="form-control form-control-sm " formControlName="urban"
                                [attr.testId]="cid + 'urban'">
                            <control-errors-hint [ctl]="group.controls['math']['controls']['urban']">
                            </control-errors-hint>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row form-group"
                        [class.has-danger]="(group.controls['math']['controls']['loaded_coef'].dirty ||group.controls['math']['controls']['loaded_coef'].touched) && group.controls['math']['controls']['loaded_coef'].errors">
                        <div class="col-12">
                            <label class="form-control-label">{{"units.fc.math.loaded_coef" | translate}}:</label>
                        </div>
                        <div class="col-12 input-group form-group-hint">
                            <input class="form-control form-control-sm " formControlName="loaded_coef"
                                [attr.testId]="cid + 'loaded_coef'">
                            <control-errors-hint [ctl]="group.controls['math']['controls']['loaded_coef']">
                            </control-errors-hint>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row form-group"
                        [class.has-danger]="(group.controls['math']['controls']['suburban'].dirty ||group.controls['math']['controls']['suburban'].touched) && group.controls['math']['controls']['suburban'].errors">
                        <div class="col-12">
                            <label class="form-control-label">{{"units.fc.math.suburban" | translate}}:</label>
                        </div>
                        <div class="col-12 input-group form-group-hint">
                            <input class="form-control form-control-sm " formControlName="suburban"
                                [attr.testId]="cid + 'suburban'">
                            <control-errors-hint [ctl]="group.controls['math']['controls']['suburban']">
                            </control-errors-hint>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</div>
