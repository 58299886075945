enum MotionState {
  MovingON = 1,
  MovingOFF = 2,
  MovingFromTime = 3,
  StationaryFromTime = 4,
  StationaryON = 5,
  StationaryOFF = 6,
  NoMessages = 10,
}

enum DataAccuracyLastMessage {
  NoOver = 0,
  FiveMinutes = 1,
  HOUR = 2,
  DAY = 3,
  LONGTIME = 4,
}

enum DataAccuracySatellites {
  NotAvailable = 0,
  NotAvailableForTime = 1,
  Available = 2,
}

enum ConnectionState {
  OFF = 0,
  ON = 1,
}

export class MonitoringStatus {
  static MotionState = MotionState;
  static DataAccuracyLastMessage = DataAccuracyLastMessage;
  static DataAccuracySatellites = DataAccuracySatellites;
  static ConnectionState = ConnectionState;
}
