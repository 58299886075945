<div class="wrapper">
  <header id="header">
    <div class="nav-container">
      <nav class="navbar navbar-expand-lg navbar-light navbar-primary">
        <div
          class="row mx-auto w-100 h-100 d-flex justify-content-start mainNavbar"
        >
          <div class="d-flex w-30">
            <div
              class="col-auto d-flex align-items-center justify-content-between brand-shape"
            >
              <a
                class="d-flex navbar-brand"
                *ngIf="configs.settingsLoaded"
                [routerLink]="['/portal']"
              >
                <img
                  class="img-fluid"
                  height="50"
                  src="{{ configs.logo }}"
                  alt="'logo' | translate"
                />
              </a>
            </div>
          </div>
          <div
            class="col-auto ml-auto d-flex align-items-center brand-shape brand-shape-inverted"
          >
            <a class="d-flex align-items-center" (click)="fullscreen()">
              <i
                class="hvr-pop afaqy-icon-fullscreen icon-18px mr-2 text-theme-color"
              ></i>
            </a>
            <a
              id="user-settings-sidebar-key"
              class="d-flex align-items-center profile-img user-header dropdown-toggle"
              data-toggle="modal"
              data-target="#user-settings-sidebar"
            >
              <img class="img-fluid rounded-circle" [src]="user.imgUrl" />
            </a>
            <a class="d-flex align-items-center" [routerLink]="['/portal']">
              <img
                src="../../../../assets/images/icon-brand.svg"
                alt="Afaqy"
                class="nav-icon"
              />
            </a>
            <strong>{{ user.username }}</strong>
          </div>
        </div>
      </nav>
    </div>
  </header>

  <div
    id="user-settings-sidebar"
    class="modal right fade"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="userMenu">
            <!-- <a *ngIf="loggedInAs['id']" href="?sid={{loggedInAs['id']}}">
                            <h6 class="name-userlist">{{loggedInAs['username']}}</h6>
                        </a> -->
            <!-- <span *ngIf="loggedInAs['id']" class="fa fa-arrow-down"></span> -->
            <!-- <a href="javascript:void(0)" (click)="showProfile = true">
                            <h5 class="profile">{{user.username}}</h5>
                        </a> -->
            <h6 class="heading">
              {{ 'WELCOME' | translate }}
              <a
                preventRightClick
                [routerLink]="['/', { outlets: { popup: ['profile'] } }]"
                [skipLocationChange]="configs['skipLocationChange']"
                >{{ user.username }}</a
              >
            </h6>
            <hr />
            <ul class="list-group">
              <!-- <li *ngIf="loggedInAs['id']" class="list-group-item">
                                <a class="heading" href="?sid={{loggedInAs['id']}}">
                                    <i class="icon icon-18px afaqy-icon-user-preferences text-primary"></i> {{loggedInAs['username']}}</a>
                            </li> -->
              <!--<li class="list-group-item">-->
              <!--<a class="heading" href="#">-->
              <!--<i class="icon icon-18px afaqy-icon-user-preferences text-primary"></i> {{ 'USER_PREFERENCES' | translate }}</a>-->
              <!--</li>-->
              <!--<li class="list-group-item">-->
              <!--<a class="heading" href="#">-->
              <!--<i class="icon icon-18px afaqy-icon-company-settings text-primary"></i> {{ 'COMPANY_SETTINGS' | translate }}</a>-->
              <!--</li>-->
              <!--<li class="list-group-item">-->
              <!--<a class="heading" href="#">-->
              <!--<i class="icon icon-18px afaqy-icon-support text-primary"></i> {{ 'SUPPORT' | translate }}</a>-->
              <!--</li>-->
              <!--<li class="list-group-item">-->
              <!--<a class="heading" href="#">-->
              <!--<i class="icon icon-18px afaqy-icon-help text-primary"></i> {{ 'HELP' | translate }}</a>-->
              <!--</li>-->
              <li class="list-group-item">
                <a class="heading" href="javascript:void(0)" (click)="logout()">
                  <i
                    class="icon icon-18px afaqy-icon-logout text-theme-color"
                  ></i>
                  {{ 'LOGOUT' | translate }}</a
                >
              </li>
            </ul>
          </div>
          <hr />
          <div>
            <h6 class="heading">
              <i class="icon icon-theme-colors icon-18px text-theme-color"></i>
              {{ 'THEME_COLOR' | translate }}
            </h6>
            <div class="custom-radios">
              <div *ngFor="let color of colors; let i = index">
                <input
                  type="radio"
                  id="{{ color.name }}"
                  name="color"
                  value="{{ color.name }}"
                  [checked]="color.active == 1"
                  (change)="choose($event)"
                />
                <label class="hvr-pulse-shrink" for="{{ color.name }}">
                  <span [style.background]="color.code">
                    <i class="mdi mdi-check mdi-24px"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <hr />
            <h6 class="heading">
              <i class="icon icon-language icon-18px text-theme-color"></i>
              {{ 'LANGUAGES' | translate }}
            </h6>
            <div class="settings-sidebox site-fonts">
              <system-langs></system-langs>
            </div>
          </div>
          <!-- <div> -->
          <!-- <hr> -->
          <!-- <h6 class="heading"> -->
          <!-- <i class="icon icon-time-zone icon-18px text-primary"></i> {{ 'TIME_ZONE' | translate }} -->
          <!-- </h6> -->
          <!-- <div class="form-group"> -->
          <!--<select class="form-control" id="language-selector">-->
          <!--<option>Cairo</option>-->
          <!--<option>Riyaddah</option>-->
          <!--</select>-->
          <!--</div>-->
          <!--</div>-->
        </div>
      </div>
    </div>
  </div>

  <section id="main" class="container-fluid">
    <div class="row">
      <section id="content-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="p-0 bg-primary menu" id="cms-navigation">
              <ul class="nav flex-column pt-3">
                <li
                  class="nav-item"
                  *ngFor="let menu_item of menu"
                  [secured]="menu_item.permissions"
                  [class.active]="menu_item.selected"
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    (click)="addNewTab(menu_item.code)"
                  >
                    <i class="{{ menu_item.icon }} pb-2"></i>
                    {{ menu_item.label | translate }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="cms-main-content">
              <main role="main" id="cmsMainContent">
                <simple-notifications
                  [options]="configs.toastyNotifications"
                  [attr.testid]="'notifications-toast'"
                ></simple-notifications>
                <afaqy-alert [attr.testid]="'afaqy-alert'"></afaqy-alert>
                <tabset>
                  <ng-container *ngFor="let tabz of tabs; let index = index">
                    <tab
                      [active]="tabz.active"
                      [heading]="tabz.title"
                      (select)="selectTab(index)"
                      (deselect)="tabz.active = false"
                      [removable]="true"
                      (removed)="removeTabHandler(index)"
                    >
                      <ng-template tabHeading>
                        <span>{{ 'menu.' + tabz.code | translate }}</span>
                      </ng-template>
                      <ng-container [ngSwitch]="tabz.code">
                        <ng-container *ngSwitchCase="'units'">
                          <units-list-cms
                            [randomRefresh]="tabz.random"
                          ></units-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'units-trashed'">
                          <units-trashed-cms [randomRefresh]="tabz.random">
                          </units-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'units-add'">
                          <unit-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></unit-form>
                        </ng-container>

                        <ng-container *ngSwitchCase="'units-edit'">
                          <unit-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'units-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></unit-form>
                        </ng-container>
                        <ng-container *ngSwitchCase="'units-copy'">
                          <unit-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'units-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></unit-form>
                        </ng-container>

                        <ng-container *ngSwitchCase="'unit_groups'">
                          <unit-group-list-cms [randomRefresh]="tabz.random">
                          </unit-group-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'unit_groups-trashed'">
                          <unit-group-trashed-cms [randomRefresh]="tabz.random">
                          </unit-group-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'unit_groups-add'">
                          <unit-group-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></unit-group-form>
                        </ng-container>

                        <ng-container *ngSwitchCase="'unit_groups-edit'">
                          <unit-group-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'unit_groups-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></unit-group-form>
                        </ng-container>
                        <ng-container *ngSwitchCase="'unit_groups-copy'">
                          <unit-group-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'unit_groups-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></unit-group-form>
                        </ng-container>

                        <ng-container *ngSwitchCase="'users'">
                          <users-list-cms
                            [randomRefresh]="tabz.random"
                          ></users-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'users-trashed'">
                          <users-trashed-cms [randomRefresh]="tabz.random">
                          </users-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'users-add'">
                          <user-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></user-form>
                        </ng-container>

                        <ng-container *ngSwitchCase="'users-edit'">
                          <user-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'users-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></user-form>
                        </ng-container>
                        <ng-container *ngSwitchCase="'users-copy'">
                          <user-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'users-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></user-form>
                        </ng-container>

                        <!-- Status -->
                        <ng-container *ngSwitchCase="'status'">
                          <status-list-cms
                            [randomRefresh]="tabz.random"
                          ></status-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'status-trashed'">
                          <status-trashed-cms [randomRefresh]="tabz.random">
                          </status-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'status-add'">
                          <status-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></status-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'status-edit' ||
                            tabz.code === 'status-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <status-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'status-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></status-form>
                        </ng-container>

                        <!-- Event Status -->
                        <ng-container *ngSwitchCase="'event_status'">
                          <event-status-list-cms [randomRefresh]="tabz.random">
                          </event-status-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'event_status-trashed'">
                          <event-status-trashed-cms
                            [randomRefresh]="tabz.random"
                          >
                          </event-status-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'event_status-add'">
                          <event-status-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></event-status-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'event_status-edit' ||
                            tabz.code === 'event_status-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <event-status-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'event_status-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></event-status-form>
                        </ng-container>

                        <!-- Tailers -->
                        <ng-container *ngSwitchCase="'tailers'">
                          <tailers-list-cms
                            [randomRefresh]="tabz.random"
                          ></tailers-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'tailers-trashed'">
                          <tailers-trashed-cms [randomRefresh]="tabz.random">
                          </tailers-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'tailers-add'">
                          <tailer-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></tailer-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'tailers-edit' ||
                            tabz.code === 'tailers-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <tailer-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'tailers-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></tailer-form>
                        </ng-container>

                        <!-- Events -->
                        <ng-container *ngSwitchCase="'events'">
                          <events-list-cms
                            [randomRefresh]="tabz.random"
                          ></events-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'events-trashed'">
                          <events-trashed-cms [randomRefresh]="tabz.random">
                          </events-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'events-add'">
                          <events-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></events-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'events-edit' ||
                            tabz.code === 'events-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <events-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'events-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></events-form>
                        </ng-container>

                        <!-- Drivers -->
                        <ng-container *ngSwitchCase="'drivers'">
                          <drivers-list-cms
                            [randomRefresh]="tabz.random"
                          ></drivers-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'drivers-trashed'">
                          <drivers-trashed-cms [randomRefresh]="tabz.random">
                          </drivers-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'drivers-add'">
                          <driver-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></driver-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'drivers-edit' ||
                            tabz.code === 'drivers-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <driver-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'drivers-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></driver-form>
                        </ng-container>

                        <!-- Driver Groups -->
                        <ng-container *ngSwitchCase="'driver_groups'">
                          <driver-group-list-cms [randomRefresh]="tabz.random">
                          </driver-group-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'driver_groups-trashed'">
                          <driver-group-trashed-cms
                            [randomRefresh]="tabz.random"
                          >
                          </driver-group-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'driver_groups-add'">
                          <driver-group-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></driver-group-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'driver_groups-edit' ||
                            tabz.code === 'driver_groups-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <driver-group-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'driver_group-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></driver-group-form>
                        </ng-container>

                        <!-- Roles -->
                        <ng-container *ngSwitchCase="'roles_permissions'">
                          <roles-list-cms
                            [randomRefresh]="tabz.random"
                          ></roles-list-cms>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'roles_permissions-trashed'"
                        >
                          <roles-trashed-cms [randomRefresh]="tabz.random">
                          </roles-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'roles_permissions-add'">
                          <roles-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></roles-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'roles_permissions-edit' ||
                            tabz.code === 'roles_permissions-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <roles-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'roles_permissions-edit'
                                ? 'edit'
                                : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></roles-form>
                        </ng-container>

                        <!-- Packages -->
                        <ng-container *ngSwitchCase="'package'">
                          <packages-list-cms [randomRefresh]="tabz.random">
                          </packages-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'package-trashed'">
                          <packages-trashed-cms [randomRefresh]="tabz.random">
                          </packages-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'package-add'">
                          <packages-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></packages-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'package-edit' ||
                            tabz.code === 'package-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <packages-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'package-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></packages-form>
                        </ng-container>

                        <!-- Protocol Commands -->
                        <ng-container *ngSwitchCase="'protocol_commands'">
                          <protocol-list-cms [randomRefresh]="tabz.random">
                          </protocol-list-cms>
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="'protocol_commands-trashed'"
                        >
                          <protocol-trashed-cms [randomRefresh]="tabz.random">
                          </protocol-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'protocol_commands-add'">
                          <protocol_command-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></protocol_command-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'protocol_commands-edit' ||
                            tabz.code === 'protocol_commands-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <protocol_command-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'protocol_commands-edit'
                                ? 'edit'
                                : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></protocol_command-form>
                        </ng-container>

                        <!-- Protocols -->
                        <ng-container *ngSwitchCase="'protocols'">
                          <protocols-list-cms [randomRefresh]="tabz.random">
                          </protocols-list-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'protocols-trashed'">
                          <protocols-trashed-cms [randomRefresh]="tabz.random">
                          </protocols-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'protocols-add'">
                          <protocols-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></protocols-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'protocols-edit' ||
                            tabz.code === 'protocols-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <protocols-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'protocols-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></protocols-form>
                        </ng-container>

                        <!-- Reports -->
                        <ng-container *ngSwitchCase="'reports'">
                          <reports-settings-cms [randomRefresh]="tabz.random">
                          </reports-settings-cms>
                        </ng-container>
                        <ng-container *ngSwitchCase="'reports-trashed'">
                          <reports-settings-trashed-cms
                            [randomRefresh]="tabz.random"
                          >
                          </reports-settings-trashed-cms>
                        </ng-container>

                        <ng-container *ngSwitchCase="'reports-add'">
                          <reports-form
                            [insidetab]="true"
                            [action]="'add'"
                            (closeForm)="close(tabz)"
                          ></reports-form>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            tabz.code === 'reports-edit' ||
                            tabz.code === 'reports-copy'
                              ? tabz.code
                              : ''
                          "
                        >
                          <reports-form
                            [insidetab]="true"
                            [resource_id]="tabz.data['resource_id']"
                            [action]="
                              tabz.code == 'reports-edit' ? 'edit' : 'copy'
                            "
                            (closeForm)="close(tabz)"
                          ></reports-form>
                        </ng-container>
                      </ng-container>
                    </tab>
                  </ng-container>
                </tabset>
              </main>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>

  <footer id="footer">
    <div
      class="login_v2_reserved_text text-xs-center bold-fonts"
      [attr.testId]="'login_v2_reserved'"
    >
      <afaqy-spinner [loading]="!configs.settingsLoaded"></afaqy-spinner>
      <p *ngIf="configs.settingsLoaded">{{ configs.copyrights }}</p>
    </div>

    <div class="controlIcons d-flex align-items-center">
      <!-- <button [tooltip]="'Option' | translate" type="button" class="btn btn-light btn-sm" role="button">
                <i class="mdi mdi-message"></i>
                <span class="badge badge-primary">2</span>
            </button>
            <button [tooltip]="'Option' | translate" type="button" class="btn btn-light btn-sm" role="button">
                <i class="mdi mdi-signal"></i>
                <span class="badge badge-primary">8</span>
            </button>
            <button [tooltip]="'Option' | translate" type="button" class="btn btn-light btn-sm" role="button">
                <i class="mdi mdi-wrench"></i>
                <span class="badge badge-primary">24</span>
            </button> -->
      <button
        class="btn btn-sm footerOption"
        [tooltip]="'cms.new_tab' | translate"
        [ngClass]="{ 'btn-secondary': !formNewTab, 'btn-primary': formNewTab }"
        (click)="toggle()"
      >
        <i class="mdi mdi-open-in-new"></i>
      </button>
      <span class="mx-4">
        <i class="mdi mdi-clock"></i>
        {{ userTime | afaqyDate : true : 'HH:mm:ss' }}</span
      >
    </div>
  </footer>
</div>
