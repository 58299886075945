<div id="page-listing-body">
  <wj-flex-grid
    #grid
    *ngIf="cvData?.sourceCollection?.length"
    [autoGenerateColumns]="false"
    [allowAddNew]="false"
    [allowDelete]="false"
    [allowPinning]="'SingleColumn'"
    [itemsSource]="cvData"
    [validateEdits]="false"
    [selectionMode]="'Cell'"
    [isReadOnly]="true"
    [allowSorting]="true"
    [allowPinning]="false"
    class="afaqy-flex-grid"
    (formatItem)="formatItem(grid, $event)"
  >
    <wj-flex-grid-filter
      [filterColumns]="filtersCols"
      [showFilterIcons]="true"
      #filter
      (filterApplied)="onFilterApplied(filter)"
    >
    </wj-flex-grid-filter>

    <ng-template wjFlexGridCellTemplate cellType="TopLeft">
      <input
        *ngIf="is_trashed"
        type="checkbox"
        class="column_check"
        [(ngModel)]="isAllChecked"
        (change)="doToggleCheck($event.target.checked)"
      />
      <span *ngIf="!is_trashed">#</span>
    </ng-template>

    <ng-template
      wjFlexGridCellTemplate
      cellType="RowHeader"
      let-cell="cell"
      let-item="item"
    >
      <span class="row-header">
        <input
          *ngIf="is_trashed"
          type="checkbox"
          [checked]="item.checked"
          (change)="doUpdateIds($event.target.checked, item.id, cell.row.index)"
          class="column_check"
        />
        <span *ngIf="!is_trashed">{{ cell.row.index + 1 }}</span>
      </span>
    </ng-template>

    <wj-flex-grid-column
      *ngFor="let col of activeColumns"
      [allowSorting]="
        col.colValue == 'actions' || col.sorting === false ? false : true
      "
      [header]="col.header | translate"
      [binding]="col.colValue"
      [minWidth]="col.minWidth"
      [width]="col.width"
      [align]="col.align ? col.align : 'left'"
    >
      <ng-template
        wjFlexGridCellTemplate
        cellType="ColumnHeader"
        let-cell="cell"
        let-item="item"
        *ngIf="col.headerHtml"
      >
        <span
          [innerHTML]="col.header"
          afaqy-tooltip
          [tooltipText]="col.headerTooltipText | translate"
        ></span>
      </ng-template>

      <ng-container [ngSwitch]="col?.type">
        <ng-container *ngSwitchCase="'checkbox'">
          <ng-container
            *ngIf="authService.checkPermissions(controller + '-bulkDelete')"
          >
            <ng-template wjFlexGridCellTemplate cellType="ColumnHeader">
              <input
                type="checkbox"
                [(ngModel)]="isAllChecked"
                (change)="doToggleCheck($event.target.checked)"
              />
            </ng-template>

            <ng-template
              wjFlexGridCellTemplate
              cellType="Cell"
              let-cell="cell"
              let-item="item"
            >
              <div class="checkbox-squared text-center">
                <input
                  type="checkbox"
                  [checked]="item.checked"
                  (change)="
                    doUpdateIds($event.target.checked, item.id, cell.row.index)
                  "
                />
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'visible'">
          <ng-template wjFlexGridCellTemplate cellType="ColumnHeader">
            <input
              type="checkbox"
              id="visible-header"
              (click)="toggleVisibility('visible', $event.target.checked)"
              [(ngModel)]="visibleAll"
            />
            <label
              class="icon-eye visibilitySwitch"
              for="visible-header"
              afaqy-tooltip
              [tooltipText]="'visibility' | translate"
            ></label>
          </ng-template>

          <ng-template
            wjFlexGridCellTemplate
            cellType="Cell"
            let-cell="cell"
            let-item="item"
          >
            <div class="checkbox-squared text-center">
              <label class="switch" for="v_{{ item.id }}">
                <input
                  id="v_{{ item.id }}"
                  type="checkbox"
                  [checked]="item.visible"
                  (click)="
                    toggleVisibility('visible', $event.target.checked, item.id)
                  "
                />
                <span class="slider round"></span>
              </label>
            </div>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'booleanflag'">
          <ng-template wjFlexGridCellTemplate cellType="Cell" let-cell="cell">
            <span
              class="pointer mdi"
              (click)="
                pushExtraAction({
                  item: cell.item,
                  key: col.flagkey,
                  action: 'update_flag'
                })
              "
              [ngClass]="
                cell.item[col.colText]
                  ? 'mdi-check green-color'
                  : 'mdi-close red-color'
              "
            ></span>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'boolean'">
          <ng-template wjFlexGridCellTemplate cellType="Cell" let-cell="cell">
            <span>
              {{ cell.item | objectBoolean : col.colValue | translate }}</span
            >
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'date'">
          <ng-template wjFlexGridCellTemplate cellType="Cell" let-cell="cell">
            <span>
              {{
                cell.item[col.colValue] | afaqyDate : true : '' : col.ms
              }}</span
            >
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'icon'">
          <ng-template wjFlexGridCellTemplate cellType="Cell" let-cell="cell">
            <span>
              <img class="list-icon" src="{{ cell.item[col.colValue] }}"
            /></span>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'geofenceIcon'">
          <ng-template wjFlexGridCellTemplate cellType="Cell" let-cell="cell">
            <span class="{{ cell.item[col.colText] }}"></span>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'pointer'">
          <ng-template wjFlexGridCellTemplate cellType="Cell" let-cell="cell">
            <span
              (click)="extraActionsForItem(col.call, cell.item)"
              class="pointer"
              >{{ cell.item[col.colValue] }}</span
            >
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-template
        wjFlexGridCellTemplate
        cellType="Cell"
        cellClass="class"
        let-cell="cell"
        let-item="item"
        *ngIf="col?.colValue === 'actions'"
      >
        <div class="list-actions d-flex flex-row">
          <div
            afaqy-tooltip
            [tooltipText]="'tooltips.extra' | translate"
            *ngIf="showExtra && extraActions.length > 1"
            class="inline pointer relative-pos"
            [secured]="extraParams.permissions"
          >
            <a
              href="javascript:void(0)"
              (click)="showExtraActions($event, cell)"
              ><i
                class="mdi mdi-arrow-down"
                [attr.testid]="moduleName + '-extra'"
              ></i
            ></a>
          </div>
          <a
            *ngIf="showExtra && extraActions.length == 1"
            [secured]="extraParams.permissions"
            (click)="extraActionsForItem(extraActions[0].code, item)"
            href="javascript:void(0)"
            afaqy-tooltip
            [tooltipText]="extraActions[0].header | translate"
            ><i
              [class]="'fa ' + extraActions[0].faclass"
              [attr.testid]="moduleName + '-extra'"
            ></i
          ></a>

          <a
            *ngIf="!is_trashed && !service.disableListItemAction['edit']"
            afaqy-tooltip
            [tooltipText]="'tooltips.edit' | translate"
            secured="{{ controller + '-edit' }}"
            [skipLocationChange]="config['skipLocationChange']"
            [routerLink]="[actions['edit'], item.id]"
            ><i class="mdi mdi-pencil" [attr.testid]="moduleName + '-edit'"></i
          ></a>

          <!--Start View Option-->
          <a
            *ngIf="!is_trashed && editPermission === false"
            afaqy-tooltip
            [tooltipText]="'tooltips.view' | translate"
            secured="{{ controller + '-view' }}"
            [skipLocationChange]="config['skipLocationChange']"
            [routerLink]="['view', item.id]"
            ><i class="mdi mdi-eye" [attr.testid]="moduleName + '-view'"></i
          ></a>
          <!--End View Option-->

          <a
            *ngIf="!is_trashed && !service.disableListItemAction['copy']"
            afaqy-tooltip
            [tooltipText]="'tooltips.copy' | translate"
            secured="{{ controller + '-add,' + controller + '-edit' }}"
            [skipLocationChange]="config['skipLocationChange']"
            [routerLink]="[actions['copy'], item.id]"
          >
            <i
              class="mdi mdi-content-copy"
              [attr.testid]="moduleName + '-copy'"
            ></i>
          </a>

          <a
            *ngIf="!is_trashed && !service.disableListItemAction['delete']"
            afaqy-tooltip
            [tooltipText]="'tooltips.delete' | translate"
            secured="{{ controller + '-delete' }}"
            (click)="doDelete($event, item.id)"
            href="javascript:void(0)"
          >
            <i
              class="mdi mdi-delete"
              [attr.testid]="moduleName + '-delete'"
            ></i>
          </a>

          <a
            *ngIf="is_trashed"
            afaqy-tooltip
            [tooltipText]="'tooltips.restore' | translate"
            secured="{{ controller + '-restore' }}"
            (click)="doRestore($event, [item.id])"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-undo" [attr.testid]="moduleName + '-restore'"></i>
          </a>

          <a
            *ngIf="is_trashed"
            afaqy-tooltip
            [tooltipText]="'tooltips.deleteForever' | translate"
            secured="{{ controller + '-deleteForever' }}"
            (click)="doDeleteForever($event, item.id)"
            href="javascript:void(0)"
          >
            <i
              class="mdi mdi-delete"
              [attr.testid]="moduleName + '-deleteForever'"
            ></i>
          </a>

          <ng-container *ngIf="customActions">
            <app-list-custom-actions [options]="customActions" [row]="item">
            </app-list-custom-actions>
          </ng-container>
        </div>
      </ng-template>
    </wj-flex-grid-column>
  </wj-flex-grid>
  <!-- <p class="text-center text-bold">{{'noData' | translate}}</p> -->

  <div
    #extraActionsContainer
    *ngIf="showExtra && extraActions.length > 1"
    id="{{ controller + '-extra-action' }}"
    class="listing-extra-actions"
    [attr.testid]="'extra-actions-list'"
    [class.hidden]="extraParams.hideExtra"
    [ngStyle]="
      isRTL
        ? { 'top.px': extraParams.elmTop, 'right.px': extraParams.elmRight }
        : { 'top.px': extraParams.elmTop, 'left.px': extraParams.elmLeft }
    "
    style="position: absolute"
    (mouseleave)="hideExtra()"
  >
    <table class="">
      <tr *ngFor="let act of extraActions" [secured]="act.permissions">
        <td
          (click)="executeAction(act.code)"
          [attr.testid]="moduleName + '-' + act.code"
        >
          <a href="javascript:void(0)">
            <i
              [class]="'fa ' + act.faclass"
              afaqy-tooltip
              [tooltipText]="act.header | translate"
            ></i>
          </a>
        </td>
      </tr>
    </table>
  </div>
</div>
