import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AfaqyAlertService {
  private subject = new Subject<any>();
  private result = new Subject<any>();

  constructor() {}

  confirm(
    message: string = 'are-you-sure',
    params = null,
    params2 = null,
    title: string = 'confirmation',
    ok: string = 'ok',
    cancel: string = 'cancel'
  ) {
    this.subject.next({
      type: 'confirm',
      title: title,
      message: message,
      ok: ok,
      cancel: cancel,
      params: params,
      params2: params2,
    });
    return this.result;
  }

  alert(
    message: string = 'is-it-ok',
    title: string = 'alert',
    ok: string = 'ok'
  ) {
    this.subject.next({
      type: 'alert',
      title: title,
      message: message,
      ok: ok,
    });
    return this.result;
  }

  popup(messages: any[] = [], title: string = 'alert', ok: string = 'ok') {
    this.subject.next({
      type: 'alert',
      title: title,
      messagesList: messages,
      ok: ok,
    });
    return this.result;
  }

  Ok() {
    this.result.next(true);
  }

  Cancel() {
    this.result.next(false);
  }

  get message(): Observable<any> {
    return this.subject;
  }

  get response(): Observable<any> {
    return this.result;
  }
}
