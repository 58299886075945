import { Injectable } from '@angular/core';
import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { ProtocolCommand } from '../models';
import { NotificationsService } from 'angular2-notifications';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AfaqyAPIResponse, AfaqyResponse } from '../../../core/classes';

@Injectable()
export class ProtocolCommandService extends RootService {
  private _systemProtocolCommandsList = {};

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  protocolCommandsList(device) {
    if (this._systemProtocolCommandsList[device]) {
      return of(this._systemProtocolCommandsList[device]);
    }
    return this.apiRequest
      .authPost(this.getFunctionURL('protocolCommands'), {
        device: device,
        simplify: 1,
      })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          if (result.status_code == 200) {
            let list = [];
            for (let obj of result.data) {
              let object = new ProtocolCommand();
              object.copyInto(obj);
              list.push(object);
            }
            list.push({
              id: 'custom',
              name: 'Custom',
              channel: 'auto',
              param_option: 'required',
            });
            this._systemProtocolCommandsList[device] = list;
            return list;
          }
          return [];
        })
      );
  }

  routerPrefix(val: string = '') {
    return val ? val : 'protocol_commands';
  }

  get modelInstance() {
    return new ProtocolCommand();
  }

  get channelsValue() {
    return ProtocolCommand.channels;
  }

  gridColumns(trashed = false) {
    let allcols = [
      {
        header: 'name',
        minWidth: 75,
        width: '*',
        colValue: 'name',
        active: false,
        immutable: true,
        default: true,
      },
      {
        header: this.cid + '.protocol_code',
        minWidth: 50,
        width: '*',
        colValue: 'protocol_code',
        active: false,
        immutable: true,
        default: true,
      },
      {
        header: this.cid + '.msg',
        minWidth: 50,
        width: '*',
        colValue: 'msg',
        active: false,
        default: true,
      },
      {
        header: this.cid + '.msg2',
        minWidth: 50,
        width: '*',
        colValue: 'msg2',
        active: false,
        default: true,
      },
      {
        header: this.cid + '.param',
        minWidth: 75,
        width: '*',
        colValue: 'param',
        active: false,
        default: true,
      },
      {
        header: this.cid + '.channel',
        minWidth: 75,
        width: '*',
        colValue: 'channel',
        active: false,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 30,
        width: 90,
        colValue: 'actions',
        active: false,
        default: true,
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
    }
    return allcols;
  }

  get listingIcon() {
    return 'afaqy-icon-user-preferences';
  }
}
