import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from 'app/common/classes';
import { ProtocolsService } from 'app/modules/protocols/services';
import { UnitService } from 'app/modules/units/services';
import { environment } from '../../../../../environments/environment';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'unit-form-general',
  templateUrl: './unit-form-general.component.html',
})
export class UnitFormGeneralComponent implements OnInit, OnChanges, OnDestroy {
  @Input() group: UntypedFormGroup;
  @Input() isAdd: UntypedFormGroup;
  environment = environment;
  alive = true;
  formOptions = {
    protocolsList: [],
  };
  crat: Date;
  cid = 'units-main-';
  device_types = [
    {
      title: 'GPS',
      value: 'gps',
    },
    {
      title: 'Fixed Unit',
      value: 'fixed_unit',
    },
  ];
  showOnFixed = false;
  showOnGateway = false;
  gatewayUnits = [];
  wasl = AppConfig?.wasl;
  skipSIMValidation: boolean;
  constructor(
    public route: ActivatedRoute,
    private service: ProtocolsService,
    private unitService: UnitService
  ) {}

  watchChanges() {
    this.DeviceType.valueChanges.subscribe((value) => {
      this.showOnFixed = value === 'fixed_unit';
    });
    this.Gateway.valueChanges.subscribe((value) => {
      this.showOnGateway = !!value;
      // Mandatory cases for SIM Fields
    });
  }

  ngOnInit() {
    this.watchChanges();
    this.service.systemProtocolsList().subscribe({
      next: (list) => {
        this.formOptions.protocolsList = list;
      },
    });
    this.unitService.resources.subscribe({
      next: () => {
        this.gatewayUnits = [
          { name: '-', value: null },
          ...this.unitService.resourcesList.filter((u) => u.gateway),
        ];
      },
    });
    this.group
      .get('skip_sim_information')
      .valueChanges.pipe(takeWhile(() => this.alive))
      .subscribe((value) => {
        this.simValidation(value);
      });
    if (!this.isAdd) {
      this.group.get('owner').disable();
      this.group.get('creator').disable();
    } else {
      this.group.get('owner').enable();
      this.group.get('creator').enable();
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.group && simpleChanges.group.currentValue) {
      this.showOnFixed = this.DeviceType.value === 'fixed_unit';
      this.showOnGateway = !!this.Gateway.value;
    }
  }

  get is_wasl_connected() {
    return this.group.get('is_wasl_connected').value;
  }

  get DeviceType() {
    return this.group.get('device_type');
  }

  get DeviceGateway() {
    return this.group.get('device_gateway');
  }
  get SkipSIM() {
    return this.group.get('skip_sim_information').value;
  }
  get Gateway() {
    return this.group.get('gateway');
  }
  simValidation(checked) {
    if (checked) {
      this.group.get('sim_number').clearValidators();
      this.group.get('sim_serial').clearValidators();
      this.group.get('sim_number').updateValueAndValidity();
      this.group.get('sim_serial').updateValueAndValidity();
      this.group.get('sim_number').setValue('');
      this.group.get('sim_serial').setValue('');
      this.group.get('sim_number').disable();
      this.group.get('sim_serial').disable();
    } else {
      this.group.get('sim_number').enable();
      this.group.get('sim_serial').enable();
      this.group.get('sim_number').setValidators([
        Validators.required,
        // Validators.minLength(13),
        Validators.maxLength(16),
        Validators.pattern('^[+][0-9]*$'),
      ]);
      this.group
        .get('sim_serial')
        .setValidators([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(25),
          Validators.pattern('^[0-9]+$'),
        ]);
      this.group.get('sim_number').updateValueAndValidity();
      this.group.get('sim_serial').updateValueAndValidity();
      if (!this.group.get('sim_number').value) {
        this.group.get('sim_number').setValue('+966');
      }
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
