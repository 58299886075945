import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';

import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { Events, EventData } from '../models';
import { EventsSettings } from './events-settings';
import {
  AfaqyAPIResponse,
  AfaqyResponse,
} from '../../../core/classes/afaqy-response';
import { UnitService } from '../../units/services/unit.service';
import { AfaqyHelper } from 'app/common';
import { BookLogService } from '../../../core/services/book-log.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class EventsService extends RootService {
  protected _eventsList = {};
  public unreadEventCount$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public unreadOperationCount$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public notificationFullyLoaded = false;
  public events: Subject<any> = new Subject<any>();
  public sliderStatus: Subject<any> = new Subject<any>();
  public eventTypesList: ReplaySubject<any> = new ReplaySubject<any[]>();
  private _maxid: string = null;

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    public unitService: UnitService,
    public bookLog: BookLogService,
    public translateService: TranslateService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.translateService.onLangChange.subscribe({
      next: () => {
        this.setResourcesList(this.resourcesList);
      },
    });
  }

  routerPrefix(val: string = '') {
    return val ? val : 'events';
  }

  applyAfterLoadResources() {
    this.getEventsTypes();
    super.applyAfterLoadResources();
  }
  resetResources(newSession: boolean = false) {
    super.resetResources();
    if (newSession) {
      this.unreadEventCount$.next(0);
      this.eventsList = [];
      this.maxid = null;
    }
  }

  set eventsList(elist: any) {
    let unread = 0;
    let rList = {};
    if (elist.length) {
      for (let obj of elist) {
        rList[obj['id']] = obj;
        if (!obj.isread) {
          unread++;
        }
      }
      this._eventsList = rList;
    } else {
      this._eventsList = {};
    }

    // this.unreadEventCount = unread;
  }
  incrementUnreadEventNotification() {
    const currentValue = this.unreadEventCount$.value;
    this.unreadEventCount$.next(currentValue + 1);
  }
  decrementUnreadEventNotification() {
    const currentValue = this.unreadEventCount$.value;
    this.unreadEventCount$.next(currentValue - 1);
  }

  getEventsTypes() {
    this.apiRequest.postWithToken('events/list_event_types', null).subscribe({
      next: (res) => {
        this.eventTypesList.next(res.data);
        // const filteredList: any[] = this.filterEventTypesList(res.data);
        // this.eventTypesList.next(filteredList);
      },
    });
  }

  // private filterEventTypesList(list: any[]): any[] {
  //     if (!list || !list.length) return [];
  //     return list.filter((type: any) => {
  //         return type['value'] !== "overspeed_google";
  //     })
  // }

  getEventData(id: string) {
    return this._eventsList[id];
  }

  get eventsList() {
    let rList = [];
    if (this._eventsList) {
      for (let pk in this._eventsList) {
        rList.push(this._eventsList[pk]);
      }
    }
    return rList;
  }

  pushNewEvent(data: any) {
    if (!data['ns']['notifySystem']) {
      return;
    }
    let elist = [];
    let obj = new EventData();
    obj.isread = false;
    obj.id = data['edi'];
    obj.event_id = data['ei'];
    obj.desc = data['ed'];
    obj.notify = data['ss'];
    obj.pos = {
      dtt: data['dtt'],
      dts: data['dts'],
      loc: {
        coordinates: [data['ln'], data['lt']],
      },
    };
    obj.unit_id = data['uui'];
    obj.unit_name = this.unitService.getResourceTitle(obj.unit_id);
    // check if the event related to unit
    if (data['uui']) {
      // play sound with parameter filename.mp3
      this.playSound(obj.notify);
    }
    elist.push(obj);
    const currentEvents = [...this.eventsList];
    const newEvents = [...elist];
    this.eventsList = [...newEvents, ...currentEvents];
    // if (this.eventsList.length) this.eventsList.concat(elist);
    // else this.eventsList = elist;
    // this.unreadEventCount++;
    this.incrementUnreadEventNotification();
    this.events.next(true);

    this.bookLog.log({
      resource: obj.unit_name,
      type: 'event',
      message: obj.desc,
      loc: { lat: data['lt'], lng: data['ln'] },
      dir: 2,
      date: obj.pos.dtt / 1000,
    });
  }

  // play sound when unit send an event
  playSound(file: any) {
    // get mute state from user preferences
    const status = this.authService.preferences('events', 'sounds');
    if (status) {
      if (file) {
        AfaqyHelper.playEventSound(file);
      }
    }
  }

  get modelInstance() {
    return new Events(this.translateService);
  }

  get statusValue() {
    return EventsSettings.statusVal;
  }

  get eventTypesValue() {
    return EventsSettings.eventTypes;
  }

  get alertSounds() {
    return EventsSettings.alertSounds;
  }

  get weekDays() {
    return EventsSettings.weekDays;
  }

  get unitStatus() {
    return EventsSettings.unitStatus;
  }

  get gateway() {
    return EventsSettings.gateway;
  }

  get commandType() {
    return EventsSettings.commandType;
  }

  // get sensors() {
  //     return EventsSettings.sensors;
  // }

  get operations() {
    return EventsSettings.operations;
  }

  gridColumns(trashed: boolean = false) {
    let allcols = [
      {
        header: 'multiple_select',
        minWidth: 28,
        width: 30,
        colValue: 'checkbox',
        active: true,
        immutable: false,
        default: true,
        type: 'checkbox',
        sorting: false,
        filters: false,
      },
      {
        header: 'name',
        minWidth: 150,
        width: '*',
        colValue: 'name',
        active: false,
        immutable: true,
        default: true,
      },
      {
        header: this.cid + '.type',
        minWidth: 100,
        width: '*',
        colValue: 'typeDisplay',
        active: false,
        default: true,
        translate: false,
      },
      {
        header: this.cid + '.status',
        width: '*',
        colValue: 'statusType',
        colText: 'status',
        type: 'booleanflag',
        flagkey: 'status',
        active: false,
        default: true,
        translate: true,
      },
      {
        headerTooltipText: `${this.cid}.notify_email`,
        header: `<span class="mdi mdi-email"></span>`,
        headerHtml: true,
        align: 'center',
        width: '*',
        colValue: 'emailType',
        colText: 'email',
        type: 'booleanflag',
        flagkey: 'email',
        active: false,
        default: true,
      },
      {
        headerTooltipText: `${this.cid}.notify_sms`,
        header: `<span class="mdi mdi-message-text"></span>`,
        headerHtml: true,
        align: 'center',
        width: '*',
        colValue: 'smsType',
        colText: 'sms',
        type: 'booleanflag',
        flagkey: 'sms',
        active: false,
        default: true,
      },
      // {
      //     headerTooltipText: `${this.cid}.notify_mobile`,
      //     header: `<span class="mdi mdi-cellphone"></span>`,
      //     'headerHtml': true,
      //     'align': "center",
      //     "width": '*',
      //     colValue: "mobileType",
      //     colText: 'mobile',
      //     type: 'booleanflag',
      //     "flagkey": "mobile",
      //     active: false,
      //     default: true
      // },
      {
        headerTooltipText: `${this.cid}.notify_system`,
        header: `<span class="mdi mdi-monitor"></span>`,
        headerHtml: true,
        align: 'center',
        width: '*',
        colValue: 'systemType',
        colText: 'system',
        type: 'booleanflag',
        flagkey: 'system',
        active: false,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 60,
        width: 100,
        colValue: 'actions',
        active: false,
        default: true,
        extra: [
          {
            header: 'assign_user',
            permissions: this.cid + '-assign',
            code: 'assign',
            faclass: 'fa-link',
          },
          {
            header: 'unassign_user',
            permissions: this.cid + '-unassign',
            code: 'unassign',
            faclass: 'fa-unlink',
          },
        ],
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
      allcols.shift();
    }
    if (!this.authService.checkPermissions(this.cid + '-bulkDelete')) {
      allcols.shift();
    }
    return allcols;
  }

  get listingIcon() {
    return 'afaqy-icon-events';
  }

  ticketEvents() {
    let list = [];
    let i = 0;
    for (var pk in this._eventsList) {
      list.push(this._eventsList[pk]);
      i++;
      if (i > 5) {
        break;
      }
    }
    return list;
  }

  loadLastList(params = <any>{}): Observable<Array<any>> {
    params['filters'] = <any>{};
    params = {
      filters: { isread: false },
      limit: 50,
      offset: 0,
      simplify: false,
      pagination: false,
    };
    if (this._maxid) {
      params.filters['maxid'] = this._maxid;
    }
    return this.apiRequest.authPost(this.getFunctionURL('data'), params).pipe(
      map((result: AfaqyAPIResponse) => {
        let list = [];
        if (params.hasOwnProperty('unread'))
          this.unreadEventCount$.next(parseInt(result.extra.unread));

        if (result.status_code == 200) {
          for (let obj of result.data) {
            let object = new EventData();
            object.copyInto(obj);
            list.push(object);
          }
          if (result.data.length === 0) {
            this.notificationFullyLoaded = true;
          }
          if (list.length) {
            this._maxid = list[list.length - 1]['id'];
          }
        }
        return list;
      }),
      catchError((err) => {
        return this.serverError(err);
      })
    );
  }

  loadLastUnread(params: any = <any>{}) {
    params['pagination'] = false;
    return this.apiRequest.authPost(this.getFunctionURL('data'), params).pipe(
      map((result: AfaqyAPIResponse) => {
        let list = [];
        if (result.status_code == 200) {
          for (let obj of result.data) {
            let object = new EventData();
            object.copyInto(obj);
            list.push(object);
          }
        }
        return list;
      }),
      catchError((err) => {
        return this.serverError(err);
      })
    );
  }

  get maxid(): string {
    return this._maxid;
  }

  set maxid(value: string) {
    this._maxid = value;
  }

  updateEvent(id: string, status: any, push: boolean = true) {
    this._eventsList[id]['isread'] = status;
    let unreadEventCount = 0;
    unreadEventCount += status ? -1 : 1;
    this.unreadEventCount$.next(unreadEventCount);
    if (push) {
      this.events.next(true);
    }
  }

  updateStatus(status: any, ids: string[]) {
    return this.apiRequest
      .authPost(this.getFunctionURL(status), { ids: ids })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          reqres.status(result.status_code);
          if (reqres.status) {
            if (ids.length) {
              for (let i of ids) {
                this.updateEvent(i, status == 'read', true);
              }
            } else {
              for (let i in this._eventsList) {
                this.updateEvent(i, status == 'read', false);
              }
            }
            // console.log('updateStatus', status, ids)
            // this.unreadEventCount += (ids.length * (status == 'read' ? -1 : 1));
            this.events.next(true);
          }
          this.fillResponseDetails(reqres, result);
          return this.doResponse(reqres);
        }),
        catchError((err) => this.serverError(err))
      );
  }

  addEventComment(statusID: string, eventDataID: string, descreption: any) {
    return this.apiRequest
      .authPost(this.getFunctionURL('add', 'event_comment'), {
        description: descreption,
        status_id: statusID,
        event_data_id: eventDataID,
      })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          if (result.status_code == 200) {
            return result.data;
          }
          return [];
        }),
        catchError((err) => this.serverError(err))
      );
  }

  listEventComments(event_data_id: string = '') {
    const prms = { filters: { event_data_id: event_data_id } };
    return this.apiRequest
      .authPost(this.getFunctionURL('lists', 'event_comment'), prms)
      .pipe(
        map((result: AfaqyAPIResponse) => {
          if (result.status_code == 200) {
            return result.data;
          }
          return [];
        }),
        catchError((err) => this.serverError(err))
      );
  }

  deleteEventComment(id: string) {
    return this.apiRequest
      .authPost(this.getFunctionURL('delete', 'event_comment'), { id: id })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          if (result.status_code == 200) {
            return result.data;
          }
          return [];
        }),
        catchError((err) => this.serverError(err))
      );
  }

  // statusList() {
  //     return this.apiRequest.authPost(this.getFunctionURL("event_status"), {simplify: 1}).pipe(
  //         map((result: AfaqyAPIResponse) => {
  //             if (result.status_code == 200) {
  //                 let list = [];
  //                 for (let obj of result.data) {
  //                     let object = new EventStatus();
  //                     object.copyInto(obj);
  //                     list.push(object);
  //                 }
  //                 return list;
  //             }
  //             return [];
  //         })
  //     );
  // }

  loadOperationsData() {
    return this.apiRequest.postWithToken('notifications/lists', {});
  }

  toggleReadStatus(reqBody) {
    return this.apiRequest.postWithToken('notifications/status', reqBody);
  }
}
