<div
  class="input-group-append hint"
  *ngIf="(_ctl.dirty || _ctl.touched) && _ctl.errors"
>
  <span
    class="input-group-text"
    afaqy-tooltip
    [afaqyTooltipHtml]="ctrl_errors"
    containerClass="errors_tooltip"
  >
    <i class="fa fa-exclamation-circle text-light"></i>
  </span>
</div>
<div class="hidden">
  <div #ctrl_errors>
    <control-errors [ctl]="_ctl"></control-errors>
  </div>
</div>
