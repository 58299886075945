import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { Regions } from '../models';

@Injectable()
export class LocationsService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    protected translate: TranslateService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  applyAfterLoadResources() {
    super.applyAfterLoadResources();
  }

  get modelInstance() {
    return new Regions(this.translate);
  }

  get listingIcon() {
    return 'afaqy-icon-zone-geolocation';
  }
}
