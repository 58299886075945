import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AfaqyValidation } from 'app/common/afaqy-validation';
import { Unit, UnitDriverBehavior } from 'app/modules/units/models';
import {
  UnitDriverBehaviorService,
  UnitService,
} from 'app/modules/units/services';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AfaqyHelper, Message } from './../../../../common/classes';
import { AfaqyResponse } from './../../../../core/classes';

@Component({
  selector: 'driver-behavior-form',
  templateUrl: './unit-form-driver-behavior-form.component.html',
  styleUrls: ['./unit-form-driver-behavior-form.component.scss'],
})
export class UnitFormDriverBehaviorFormComponent
  implements OnChanges, OnInit, OnDestroy
{
  alive: boolean = true;
  @Input() object: UnitDriverBehavior;
  @Input() unitID: string;
  @Input() unitObject: Unit;
  @Input() isView;
  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateList: EventEmitter<any> = new EventEmitter<any>();

  iconsList = [];
  title: string = '';
  form: UntypedFormGroup;
  message: Message;
  cid = 'units-unit_driver_behavior-';

  posting = false;
  loading = false;
  forceDeactivate: boolean = false;
  subs = new Subscription();

  sensorList = [];

  constructor(
    public translate: TranslateService,
    protected fb: UntypedFormBuilder,
    public service: UnitDriverBehaviorService,
    public unitService: UnitService
  ) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillFormValues();
    let unit: Unit = this.unitService.getItemFromResources(this.unitID);
    const unitParams: string[] = Object.keys(unit.last_update.prms);
    unitParams.push('parm253');
  }

  setIcon(icon) {
    this.form.controls['icon'].setValue(icon);
    this.form.markAsDirty();
  }

  get form_fields(): any {
    return {
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(100),
        ],
      ],
      sensor_id: ['', [Validators.required]],
      penalty: [
        '',
        [
          Validators.required,
          AfaqyValidation.numberIntValidatorIfexist,
          Validators.min(0),
          Validators.max(1000),
        ],
      ],
      minValue: [
        '',
        [
          Validators.required,
          AfaqyValidation.numberValidatorIfexist,
          Validators.min(-1000),
          Validators.max(1000),
        ],
      ],
      maxValue: [
        '',
        [
          AfaqyValidation.numberValidatorIfexist,
          Validators.min(-1000),
          Validators.max(1000),
        ],
      ],
      minSpeed: [
        '',
        [
          AfaqyValidation.numberIntValidatorIfexist,
          Validators.min(0),
          Validators.max(250),
        ],
      ],
      maxSpeed: [
        '',
        [
          AfaqyValidation.numberIntValidatorIfexist,
          Validators.min(0),
          Validators.max(250),
        ],
      ],
      minDuration: [
        '',
        [AfaqyValidation.numberIntValidatorIfexist, Validators.min(0)],
      ],
      maxDuration: [
        '',
        [AfaqyValidation.numberIntValidatorIfexist, Validators.min(0)],
      ],
    };
  }

  ngOnInit() {
    this.message = new Message();
    this.fillFormValues();

    this.loadSensorList();
  }

  ngOnDestroy() {
    this.alive = false;
    this.subs.unsubscribe();
  }

  createForm() {
    this.form = this.fb.group(this.form_fields);
    this.forceDeactivate = false;
  }

  reformatValue(event, controlName) {
    if (!isNaN(event.target.value) && !isNaN(parseFloat(event.target.value))) {
      const floatVal = parseFloat(event.target.value);
      event.target.value = floatVal;
      this.form.controls[controlName].setValue(floatVal);
    }
  }

  checkHigh(type, event, control) {
    if (event) {
      this.reformatValue(event, control);
    }
    let isLower = false;
    let controlName = 'maxValue';
    let error = 'invalid_max_value';
    if (type == 'value') {
      const lval = parseFloat(this.form.controls['minValue'].value);
      const hval = parseFloat(this.form.controls['maxValue'].value);
      controlName = 'maxValue';
      isLower = hval < lval;
      error = 'invalid_max_value';
    } else if (type == 'spd') {
      if (
        AfaqyValidation.numberIntValidatorIfexist(
          this.form.controls['maxSpeed']
        ) != null
      ) {
        const obj = {};
        obj['invalidNumber'] = true;
        this.form.controls['maxSpeed'].setErrors(obj);
        return false;
      }
      const lval = parseFloat(this.form.controls['minSpeed'].value);
      const hval = parseFloat(this.form.controls['maxSpeed'].value);
      controlName = 'maxSpeed';
      isLower = hval <= lval;
      error = 'invalid_max_speed';
    } else if (type == 'du') {
      if (
        AfaqyValidation.numberIntValidatorIfexist(
          this.form.controls['maxDuration']
        ) != null
      ) {
        const obj = {};
        obj['invalidNumber'] = true;
        this.form.controls['maxDuration'].setErrors(obj);
        return false;
      }
      const lval = parseFloat(this.form.controls['minDuration'].value);
      const hval = parseFloat(this.form.controls['maxDuration'].value);
      controlName = 'maxDuration';
      isLower = hval <= lval;
      error = 'invalid_max_duration';
    } else return null;

    if (isLower) {
      const obj = {};
      obj[error] = true;
      this.form.controls[controlName].setErrors(obj);
    }
  }

  loadSensorList() {
    for (let sensor of this.unitObject.sensors) {
      this.sensorList.push({ id: sensor['id'], title: sensor['name'] });
    }
  }

  isEditUnit() {
    return this.unitID;
  }

  canDeactivate() {
    if (this.forceDeactivate || this.form.pristine) {
      return true;
    }
    return this.service.confirm();
  }

  fillFormValues() {
    let fobj = {};
    if (this.object) {
      for (let field in this.form_fields) {
        fobj[field] = this.object[field];
      }
    }
    this.form.reset(fobj);
  }

  revert() {
    this.fillFormValues();
  }

  reset() {
    this.fillFormValues();
  }

  modalClose($event) {
    this.closeForm.next($event);
  }

  prepareSave(): UnitDriverBehavior {
    const formModel = this.form.value;
    const saveObj = this.service.modelInstance;
    saveObj.id = this.object.id;
    for (let field in this.form_fields) {
      saveObj[field] = formModel[field] as string;
    }
    saveObj.unit_id = this.unitID;
    return saveObj;
  }

  afterFail(err) {
    this.posting = false;
    let error = new AfaqyResponse();
    error.copyInto(JSON.parse(err));
    let errorsList = error.errors;
    AfaqyHelper.setFGErrors(this.form, errorsList);
    this.message.type = 'danger';
    this.message.message = error.message || 'please-try-again';
    AfaqyHelper.calcModalHeight(true);
  }

  afterSuccess(msg = {}) {
    AfaqyHelper.calcModalHeight(false);
    this.posting = false;
    this.forceDeactivate = true;
    this.modalClose({ msg: msg, type: 'success', success: true });
  }

  saveToDB() {
    this.object = this.prepareSave();
    this.posting = true;
    this.message.clear();
    if (this.object.id) {
      this.service
        .update(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: () => {
            this.updateList.next({ object: this.object });
            this.afterSuccess('notifications.unit_driver_behavior.updated');
          },
          error: (error) => {
            this.afterFail(error);
          },
        });
    } else {
      this.service
        .create(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (response: AfaqyResponse) => {
            this.object.id = response.data.id;
            this.updateList.next({ object: this.object });
            this.afterSuccess('notifications.unit_driver_behavior.added');
          },
          error: (error) => {
            this.afterFail(error);
          },
        });
    }
  }

  onSubmit() {
    this.checkHigh('value', null, null);
    this.checkHigh('spd', null, null);
    this.checkHigh('du', null, null);

    if (!this.form.valid) {
      AfaqyHelper.touchAll(this.form);
      return;
    }
    if (this.isEditUnit()) {
      this.saveToDB();
    } else {
      this.object = this.prepareSave();
      this.updateList.next({ object: this.object });
    }
  }
}
