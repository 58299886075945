<!-- <ng-container *ngTemplateOutlet="(authService?.loadedSettings | async) ? LoggedIn : ServerIssue">

</ng-container> -->
<!-- <ng-template #LoggedIn> -->
<afaqy-loading [transparent]="false" [loading]="systemLoading"></afaqy-loading>
<ng-container *ngIf="!systemLoading">
  <div id="wrapperContent" class="wrapper" (click)="checkOpenedLayers($event)">
    <!-- <header id="header" [class.locked]="!isActiveSession"> -->
    <header id="header">
      <div class="nav-container">
        <nav class="navbar navbar-expand-lg navbar-light navbar-primary">
          <div class="loadingbar-container">
            <ngx-loading-bar
              height="4px"
              fixed="false"
              color="#1a8922"
            ></ngx-loading-bar>
          </div>
          <div
            class="row mx-auto w-100 h-100 d-flex justify-content-start mainNavbar"
          >
            <div
              class="d-flex brand-container"
              [class.MenuOpen]="MenuOpen"
              [class.NotificationsOpen]="NotificationsOpen"
              [class.UserSidebarOpen]="UserSidebarOpen"
            >
              <div
                #navbarNotch
                id="headerNotch"
                class="col-auto d-flex align-items-center justify-content-between brand-shape"
              >
                <a
                  class="d-flex navbar-brand"
                  *ngIf="configs.settingsLoaded"
                  [routerLink]="['/']"
                >
                  <img
                    class="img-fluid"
                    src="{{ configs.logo }}"
                    alt="'logo' | translate"
                  />
                </a>
                <button
                  class="navbar-toggler clickOutsideExcept"
                  type="button"
                  (click)="toggleMenu()"
                  [attr.testid]="'side_menu_toggle'"
                >
                  <span class="bg-primary clickOutsideExcept"></span>
                  <span class="bg-primary clickOutsideExcept"></span>
                  <span class="bg-primary clickOutsideExcept"></span>
                </button>
              </div>
              <div class="alpha-20 height-30">
                <div
                  class="current-tab d-flex h-100 align-items-center"
                  [attr.testid]="'current-module'"
                >
                  <div
                    class="overflow-hidden d-flex align-items-center flex-row current-tab-style"
                  >
                    <i class="{{ pageDetails.pageIcon }} icon-18px mr-2"></i>
                    <span class="mr-2 d-inline no-wrap">{{
                      'headings.' + pageDetails.pageTitle | translate
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-auto d-flex h-100 align-items-center notificationsSlider"
              [ngClass]="{ 'sr-only': isShowSlider() }"
            >
              <div id="notifications-ticker">
                <ul class="list-unstyled">
                  <li
                    class="text-white"
                    *ngFor="let e of eventsList"
                    (click)="expandNotification(e)"
                  >
                    {{ e.unit_name }} {{ e.desc }}
                  </li>
                </ul>
              </div>
              <div class="ticker-controls">
                <a class="up" href="#">
                  <i class="afaqy-icon-caret-top"></i>
                </a>
                <a class="down" href="#">
                  <i class="afaqy-icon-caret-bottom"></i>
                </a>
              </div>
            </div>

            <notification-list
              [NotificationsOpen]="NotificationsOpen"
              (NotificationsState)="toggleNotificationshelper($event)"
              #notificationsList="AfaqyNotificationList"
              (eventDetails)="receiveEventDetails($event)"
              (notificationImportToFill)="loadImportToFillModal($event)"
            >
            </notification-list>

            <!-- UserSidebarOpen refactor later to seperate component  -->
            <div
              id="user-settings-sidebar"
              class="modal right fade show"
              tabindex="-1"
              role="dialog"
              [@showHide]
              *ngIf="UserSidebarOpen"
            >
              <div
                class="modal-dialog"
                role="document"
                clickOutside
                (clickOutside)="toggleUserSidebarhelper(false)"
              >
                <div class="modal-content">
                  <perfect-scrollbar class="modal-body">
                    <div class="userMenu">
                      <h6 class="heading">
                        {{ 'WELCOME' | translate }}
                        <a
                          preventRightClick
                          [routerLink]="[
                            '/',
                            { outlets: { popup: ['profile'] } }
                          ]"
                          [skipLocationChange]="true"
                          (click)="closeModal()"
                          >{{ user?.username }}</a
                        >
                      </h6>
                      <hr />
                      <ul class="list-group">
                        <li class="list-group-item" *ngIf="configs.help_url">
                          <a
                            class="heading"
                            target="_new"
                            href="{{ configs.help_url }}"
                          >
                            <i
                              class="mdi mdi-help-circle-outline text-theme-color"
                            ></i>
                            {{ 'users.help_url' | translate }}
                          </a>
                        </li>
                        <li class="list-group-item" *ngIf="configs.support_url">
                          <a
                            class="heading"
                            target="_new"
                            href="{{ configs.support_url }}"
                          >
                            <i class="mdi mdi-lifebuoy text-theme-color"></i>
                            {{ 'users.support_url' | translate }}
                          </a>
                        </li>
                        <li
                          class="list-group-item"
                          *ngIf="configs.support_email"
                        >
                          <a
                            class="heading"
                            target="_new"
                            href="mailto:{{ configs.support_email }}"
                          >
                            <i class="mdi mdi-at text-theme-color"></i>
                            {{ 'users.support_email' | translate }}
                          </a>
                        </li>
                        <li class="list-group-item">
                          <a
                            class="heading"
                            preventRightClick
                            [routerLink]="[
                              '/',
                              { outlets: { popup: ['profile'] } }
                            ]"
                            [skipLocationChange]="true"
                            (click)="closeModal()"
                          >
                            <i class="mdi mdi-cog-outline text-theme-color"></i>
                            {{ 'edit_profile' | translate }}</a
                          >
                        </li>
                        <li class="list-group-item" secured="package-usage">
                          <a
                            class="heading"
                            preventRightClick
                            [routerLink]="[
                              '/',
                              { outlets: { popup: ['package-usage'] } }
                            ]"
                            [skipLocationChange]="configs['skipLocationChange']"
                            (click)="closeModal()"
                          >
                            <mat-icon
                              class="state-icon package-usage-icon"
                              [svgIcon]="'icon-package-usage'"
                              [attr.size]="'20px'"
                              (click)="closeModal()"
                            >
                            </mat-icon>
                            {{ 'package_usage' | translate }}</a
                          >
                        </li>
                        <li
                          *ngIf="authService?.loggedAsUser?.id"
                          class="list-group-item"
                        >
                          <a
                            class="heading"
                            (click)="navigateToPreviousLoggedUser()"
                          >
                            <i
                              class="mdi mdi-account-convert text-theme-color"
                            ></i>
                            {{ authService?.loggedAsUser?.username }}</a
                          >
                        </li>
                        <li class="list-group-item" *ngIf="toggleLogoutBtn">
                          <a
                            class="heading"
                            href="javascript:void(0)"
                            (click)="logout()"
                          >
                            <i class="mdi mdi-logout text-theme-color"></i>
                            {{ 'LOGOUT' | translate }}</a
                          >
                        </li>
                      </ul>
                    </div>
                    <hr />
                    <div>
                      <h6 class="heading">
                        <i
                          class="icon icon-theme-colors icon-18px text-theme-color"
                        ></i>
                        {{ 'THEME_COLOR' | translate }}
                      </h6>
                      <div class="custom-radios">
                        <div *ngFor="let color of colors; let i = index">
                          <input
                            type="radio"
                            id="{{ color.name }}"
                            name="color"
                            value="{{ color.name }}"
                            [checked]="color.active == 1"
                            (change)="choose($event)"
                          />
                          <label
                            class="hvr-pulse-shrink"
                            for="{{ color.name }}"
                          >
                            <span [ngStyle]="{ background: color.code }">
                              <i class="mdi mdi-check mdi-24px"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <hr />
                      <h6 class="heading">
                        <i
                          class="icon icon-language icon-18px text-theme-color"
                        ></i>
                        {{ 'LANGUAGES' | translate }}
                      </h6>
                      <div class="settings-sidebox site-fonts">
                        <system-langs></system-langs>
                      </div>
                    </div>
                    <ng-container *ngIf="!authService?.loggedAsUser?.id">
                      <!-- <form [formGroup]="toggleForm"> -->
                      <div
                        class="d-flex justify-content-space-between pt-4 mt-10 two-way-authentication"
                      >
                        <div class="d-flex flex-column">
                          <div class="authentication-div d-flex">
                            <mat-icon
                              class="avl-icon"
                              [svgIcon]="'icon-question-mark'"
                              [attr.size]="'24px'"
                            ></mat-icon>
                            <p>{{ 'verify-account.2FA' | translate }}</p>
                          </div>
                          <div
                            class="authentication-desc"
                            *ngIf="!isUserVerified"
                          >
                            <p>
                              {{ 'verify-account.2FA-description' | translate }}
                            </p>
                          </div>
                        </div>
                        <div class="checkbox-squared">
                          <label class="switch">
                            <input
                              type="checkbox"
                              [(ngModel)]="toggle_is_2fa"
                              (ngModelChange)="twoFactorToggleChange($event)"
                              [disabled]="!isUserVerified"
                            />
                            <span
                              class="slider round"
                              [ngStyle]="{
                                cursor: !isUserVerified
                                  ? 'not-allowed'
                                  : 'pointer'
                              }"
                            ></span>
                          </label>
                        </div>
                      </div>
                      <hr *ngIf="!isUserVerified" />
                      <!-- </form> -->

                      <div
                        class="account-verification-text d-flex"
                        *ngIf="!isUserVerified"
                      >
                        <div class="d-flex align-items-center">
                          <mat-icon
                            class="avl-icon"
                            [svgIcon]="'icon-warning-red'"
                            [attr.size]="'24px'"
                          ></mat-icon>
                        </div>
                        <div class="d-flex flex-column verify-text">
                          <p>
                            {{
                              'verify-account.account-not-verify' | translate
                            }}
                          </p>
                          <button
                            class="verify-link-account"
                            [routerLink]="[
                              '/',
                              { outlets: { popup: ['profile'] } }
                            ]"
                            [skipLocationChange]="true"
                          >
                            {{ 'verify-account.account-verify' | translate }}
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
            <div
              class="custom-backdrop"
              [@fadeIn]
              *ngIf="UserSidebarOpen"
            ></div>

            <div
              #navbarComponents
              class="col-auto d-flex justify-content-end brand-shape brand-shape-inverted"
              [class.MenuOpen]="MenuOpen"
              [class.NotificationsOpen]="NotificationsOpen"
              [class.UserSidebarOpen]="UserSidebarOpen"
            >
              <ul class="list-inline nav-right-buttons">
                <ng-container *ngIf="showSocketStatusIcons">
                  <li class="list-inline-item justify-status-icon">
                    <span
                      class="socket-connection-status unit"
                      afaqy-tooltip
                      [tooltipText]="
                        'Unit Socket ' +
                        socketService?.unitsSocketConnectionState
                      "
                    >
                      <i
                        *ngIf="
                          socketService?.unitsSocketConnectionState === 'CLOSED'
                        "
                        class="mdi mdi-wifi-off red-color"
                      ></i>
                      <i
                        *ngIf="
                          socketService?.unitsSocketConnectionState === 'OPEN'
                        "
                        class="mdi mdi-wifi green-color"
                      ></i>
                      <i
                        *ngIf="
                          socketService?.unitsSocketConnectionState !==
                            'CLOSED' &&
                          socketService?.unitsSocketConnectionState !== 'OPEN'
                        "
                        class="mdi mdi-18px mdi-spin mdi-loading"
                      ></i>
                    </span>
                    <span class="px-1">|</span>
                    <span
                      class="socket-connection-status notifications"
                      afaqy-tooltip
                      [tooltipText]="
                        'Notifications Socket ' +
                        socketService?.notificationsSocketConnectionState
                      "
                    >
                      <i
                        *ngIf="
                          socketService?.notificationsSocketConnectionState ===
                          'CLOSED'
                        "
                        class="mdi mdi-wifi-off red-color"
                      ></i>
                      <i
                        *ngIf="
                          socketService?.notificationsSocketConnectionState ===
                          'OPEN'
                        "
                        class="mdi mdi-wifi green-color"
                      ></i>
                      <i
                        *ngIf="
                          socketService?.notificationsSocketConnectionState !==
                            'CLOSED' &&
                          socketService?.notificationsSocketConnectionState !==
                            'OPEN'
                        "
                        class="mdi mdi-18px mdi-spin mdi-loading"
                      ></i>
                    </span>
                  </li>
                </ng-container>

                <li class="list-inline-item">
                  <user-apps
                    *ngIf="authService?.user?.account_apps"
                  ></user-apps>
                </li>
                <li class="list-inline-item">
                  <button
                    id="notifications-sidebar-key"
                    [attr.testid]="'notifications-sidebar-key'"
                    class="btn btn-link position-relative d-flex align-items-center notifications clickOutsideExcept"
                    afaqy-tooltip
                    [tooltipText]="'navbar.notifications' | translate"
                    (click)="
                      toggleNotifications();
                      notificationsList.refresh();
                      toggleUserSidebarhelper(false)
                    "
                    [class.active]="NotificationsOpen"
                  >
                    <i
                      class="afaqy-icon-notifications icon-18px mr-2 text-theme-color clickOutsideExcept"
                    ></i>
                    <span
                      *ngIf="
                        eventService?.unreadEventCount$.value > 0 ||
                        eventService?.unreadOperationCount$.value > 0
                      "
                      class="badge badge-pill badge-warning clickOutsideExcept"
                      >{{
                        (eventService?.unreadEventCount$ | async) +
                          (eventService?.unreadOperationCount$ | async)
                      }}</span
                    >
                  </button>
                </li>
                <li class="list-inline-item">
                  <a
                    class="d-flex align-items-center toggleFullScreen"
                    toggleFullscreen
                    afaqy-tooltip
                    [tooltipText]="'navbar.fullscreen' | translate"
                  >
                    <i
                      class="afaqy-icon-fullscreen icon-18px mr-2 text-theme-color"
                    ></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <button
                    id="user-settings-sidebar-key"
                    class="d-flex align-items-center profile-img user-header clickOutsideExcept"
                    [class.active]="UserSidebarOpen"
                    afaqy-tooltip
                    [tooltipText]="'navbar.manage_account' | translate"
                    (click)="
                      toggleUserSidebar(); toggleNotificationshelper(false)
                    "
                  >
                    <!-- (click)="toggleSidebars('#user-settings-sidebar', '#user-settings-sidebar-key')"> -->

                    <img
                      class="img-fluid rounded-circle clickOutsideExcept"
                      [src]="user?.imgUrl"
                    />
                  </button>
                </li>
                <li class="list-inline-item">
                  <button
                    type="button"
                    class="btn btn-link btn-sm d-flex align-items-center LoginAs dropdown-toggle clickOutsideExcept"
                    [ngClass]="{ active: showLoginAs }"
                    afaqy-tooltip
                    [tooltipText]="'navbar.login_as' | translate"
                    (click)="
                      loginAsSubUser();
                      toggleNotificationshelper(false);
                      toggleUserSidebarhelper(false)
                    "
                    [attr.testid]="'username-name'"
                  >
                    {{ user?.username }}
                  </button>
                  <!-- <div class="btn-group h-100 LoginAs">
                                    </div> -->
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <!-- Start Login As Menu -->
      <div
        class="dragged-section p-0"
        [ngDraggableOld]="true"
        *ngIf="showLoginAs"
        clickOutside
        (clickOutside)="showLoginAs = !showLoginAs"
      >
        <div class="bg-primary p-2 px-2 cursor-pointer">
          <strong class="text-light">{{
            'search.login_as' | translate
          }}</strong>
        </div>
        <div class="input-group input-group-sm mb-2 my-2 px-2">
          <input
            id="loginAsSubUserInput"
            name="loginAsSubUser"
            autocomplete="off"
            type="text"
            class="form-control"
            [placeholder]="'search.filter' | translate"
            [(ngModel)]="searchText"
          />
        </div>
        <p-virtualScroller
          [value]="subUsersMap | filter : searchText : 'username'"
          scrollHeight="300px"
          [rows]="11"
          [itemSize]="25"
          [lazy]="true"
        >
          <ng-template pTemplate="item" let-user>
            <div class="d-flex flex-row justify-content-between m-2">
              <span
                class="loginAsName m-0 text-black cursor-pointer"
                (click)="loginInSameWindow(user.id)"
                afaqy-tooltip
                [tooltipText]="'search.login_same_window' | translate"
                >{{ user?.username }}</span
              >
              <span
                class="loginAsIcon m-0 text-theme-color cursor-pointer"
                (click)="LoginInNewWindow(user.id)"
                afaqy-tooltip
                [tooltipText]="'search.login_new_window' | translate"
                ><i class="mdi mdi-window-restore"></i
              ></span>
            </div>
          </ng-template>
        </p-virtualScroller>
      </div>
      <!-- End Login As Menu -->
      <!-- Catch Map Status from the MenuComponent -->
      <afaqy-menu
        [fullMap]="fullmap"
        [MenuOpen]="MenuOpen"
        (MenuState)="toggleMenuhelper($event)"
        (dataMapStatus)="dataMapStatusHandler($event)"
        #menu="AfaqyMenu"
      ></afaqy-menu>
    </header>

    <!-- <section id="main" class="container-fluid" [class.locked]="!isActiveSession"> -->

    <section id="main" class="container-fluid">
      <div class="row">
        <section id="content-wrapper">
          <div class="content">
            <div id="page-container">
              <as-split
                unit="percent"
                [class.w-101]="!splitterMode"
                [gutterSize]="$any(splitter)?.gutterSize"
                [disabled]="dragDisabled"
                (dragEnd)="dragEnd($event)"
              >
                <!-- <div id="splitLeft"> -->
                <as-split-area
                  [size]="splitter['data']"
                  minSize="35"
                  [visible]="
                    !smallWidth || (smallWidth && contentsVisibilty.listing)
                  "
                >
                  <div id="left-content">
                    <router-outlet>
                      <!--<afaqy-loading [loading]="pageloading"></afaqy-loading>-->
                    </router-outlet>
                  </div>
                </as-split-area>
                <!-- </div> -->
                <as-split-area
                  id="map"
                  [size]="splitter['map']"
                  [visible]="
                    !smallWidth || (smallWidth && contentsVisibilty.map)
                  "
                >
                  <as-split
                    unit="percent"
                    direction="vertical"
                    [disabled]="mapDragDisabled"
                    [gutterSize]="mapGutterSize"
                    (dragEnd)="dragMapResizeEnd($event)"
                  >
                    <as-split-area
                      [size]="configs.mapSplitter.map"
                      minSize="30"
                    >
                      <div id="map-content">
                        <afaqy-map
                          [config]="mapConfiguration"
                          [mapId]="'map_main'"
                          *ngIf="authService?.userSessionReady | async"
                        ></afaqy-map>
                        <router-outlet name="details"></router-outlet>
                      </div>
                    </as-split-area>
                    <as-split-area
                      [size]="configs.mapSplitter.data"
                      id="belowMapArea"
                      [visible]="isBelowMap"
                    >
                      <ng-template
                        *ngIf="authService?.userSessionReady | async"
                        #MapSectionContainer
                      ></ng-template>
                    </as-split-area>
                  </as-split>
                  <units-follow-list></units-follow-list>
                </as-split-area>
                <!-- TODO refactor code to match one syntax -->
                <ng-container *ngIf="showCopyInfoSocketIcon">
                  <div
                    class="socket-updates-status"
                    *ngIf="unitService?.copyInfoData?.operation_status"
                  >
                    <div>
                      <ng-container
                        [ngSwitch]="unitService?.copyInfoData?.operation_status"
                      >
                        <div class="success-info">
                          <button>
                            <span
                              *ngIf="unitService?.copyInfoData"
                              class="units-counter"
                            >
                              {{ unitService?.copyInfoData?.success }} of
                              {{ unitService?.copyInfoData?.total }}
                              <span
                                *ngIf="
                                  unitService?.copyInfoData
                                    ?.operation_status === 'in_progress' ||
                                  unitService?.copyInfoData
                                    ?.operation_status === 'completed'
                                "
                              >
                                {{ 'units-updated' | translate }}
                              </span>
                            </span>
                            <!-- <afaqy-loading [loading]="true"></afaqy-loading> -->
                            <mat-spinner
                              [diameter]="25"
                              *ngIf="
                                unitService.showCopyInfoSpinner ||
                                unitService?.copyInfoData?.operation_status ===
                                  'in_progress'
                              "
                            ></mat-spinner>
                            <mat-icon
                              *ngSwitchCase="'completed'"
                              class="state-icon"
                              [svgIcon]="'icon-unit-copy-success-info'"
                              [attr.size]="'20px'"
                              (click)="navigateToCopyInfoModel()"
                            >
                            </mat-icon>
                            <ng-container
                              *ngSwitchCase="'completed_with_failures'"
                            >
                              <span>
                                {{ 'units-not-completed' | translate }}</span
                              >
                              <mat-icon
                                class="state-icon"
                                [svgIcon]="'icon-unit-copy-fail-info'"
                                [attr.size]="'20px'"
                                (click)="navigateToCopyInfoModel()"
                              >
                              </mat-icon>
                            </ng-container>
                          </button>
                        </div>
                      </ng-container>
                    </div>

                    <div class="copyInfoStatus">
                      <ng-template #copyInfoStatus></ng-template>
                    </div>
                  </div>
                </ng-container>

                <div
                  class="socket-updates-status"
                  *ngIf="showImportToFill && importToFillData?.operation_status"
                >
                  <div>
                    <ng-container>
                      <div class="success-info">
                        <ng-container
                          [ngSwitch]="importToFillData?.operation_status"
                        >
                          <div *ngSwitchCase="'in_progress'">
                            <button>
                              <span class="units-counter">
                                {{ importToFillData?.success }}
                                {{ 'importToFill.ofWord' | translate }}
                                {{ importToFillData?.total }}
                                {{
                                  'importToFill.unitsHasBeenUpdated' | translate
                                }}
                              </span>
                              <mat-spinner
                                [diameter]="25"
                                *ngIf="
                                  importToFillData?.operation_status ===
                                  'in_progress'
                                "
                              >
                              </mat-spinner>
                            </button>
                          </div>

                          <div *ngSwitchCase="'completed_with_failures'">
                            <button>
                              <span class="units-counter">
                                {{ importToFillData?.fails }}
                                {{ 'importToFill.ofWord' | translate }}
                                {{ importToFillData?.total }}
                                {{
                                  'importToFill.unitsNotCompleted' | translate
                                }}
                              </span>
                              <mat-icon
                                *ngIf="
                                  importToFillData?.operation_status ===
                                  'completed_with_failures'
                                "
                                class="state-icon"
                                [svgIcon]="'icon-unit-copy-fail-info'"
                                [attr.size]="'20px'"
                                (click)="
                                  loadImportToFillModal(importToFillData)
                                "
                              >
                              </mat-icon>
                            </button>
                          </div>

                          <div *ngSwitchCase="'completed'">
                            <button>
                              <span class="units-counter">
                                {{ importToFillData?.success }}
                                {{ 'importToFill.ofWord' | translate }}
                                {{ importToFillData?.total }}
                                {{
                                  'importToFill.unitsHasBeenUpdated' | translate
                                }}
                              </span>
                              <mat-icon
                                *ngIf="
                                  importToFillData?.operation_status ===
                                  'completed'
                                "
                                class="state-icon"
                                [svgIcon]="'icon-unit-copy-success-info'"
                                [attr.size]="'20px'"
                                (click)="
                                  loadImportToFillModal(importToFillData)
                                "
                              >
                              </mat-icon>
                            </button>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="copyInfoStatus">
                  <ng-template #importToFill></ng-template>
                </div>
              </as-split>
            </div>
            <afaqy-alert [attr.testid]="'afaqy-alert'"></afaqy-alert>
            <simple-notifications
              [options]="configs.toastyNotifications"
              [attr.testid]="'notifications-toast'"
            >
            </simple-notifications>
            <router-outlet name="modal"></router-outlet>
          </div>
        </section>
      </div>

      <!-- <afaqy-modal
          id="lockScreen"
          *ngIf="!isActiveSession"
          [title]="'login.title'"
          #modal="afaqyModal"
          [staticHeight]="false"
          [draggable]="false"
          sizeClass="modal-dialog-centered"
        >
          <div class="modalheader"></div>
          <div class="modalbody">
            <div class="lockscreen-page">
              <flash-message [message]="message"></flash-message>
              <div class="main-lockscreen-contain">
                <div class="lockscreen-circul text-xs-center">
                  <img [src]="user?.imgUrl" />
                </div>
                <div class="lockscreen-form">
                  <h5 class="text-center bg-primary text-light py-3 m-0">
                    {{user?.username}}
                  </h5>
                  <div class="lockscreen_input">
                    <input
                      type="password"
                      placeholder="{{'login.EnterPassword' | translate}}"
                      class="form-control form-control-sm lockscreen-form-border"
                      (keydown.enter)="unlock()"
                      [(ngModel)]="unlockPassword"
                      [ngModelOptions]="{standalone: true}"
                    />
                    <span class="lockscreen-right-icon">
                      <i class="icon_key"></i>
                    </span>
                  </div>
                  <afaqy-spinner [loading]="loading"></afaqy-spinner>
                </div>
              </div>
            </div>
          </div>
          <div class="modalfooter">
            <button type="button" class="btn btn-primary" (click)="unlock()">
              <i class="mdi mdi-lock-open"></i> {{ 'unlock' | translate}}
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="logout()"
            >
              <i class="mdi mdi-logout"></i> {{ 'logout' | translate }}
            </button>
          </div>
        </afaqy-modal> -->
    </section>
    <!-- <footer id="footer" [class.locked]="!isActiveSession"> -->

    <footer id="footer">
      <div class="row w-100 p-0 m-0">
        <div class="col text-left">
          <div
            class="login_v2_reserved_text bold-fonts"
            [attr.testid]="'login_v2_reserved'"
          >
            <afaqy-spinner [loading]="!configs.settingsLoaded"></afaqy-spinner>

            <button
              *ngIf="!smallWidth && splitter['data'] != 100"
              id="openDataSection"
              type="button"
              class="btn btn-sm"
              role="button"
              (click)="toggleMapStatus()"
              [ngClass]="{ 'btn-primary': fullmap }"
            >
              <i class="mdi mdi-arrange-bring-forward mx-2"></i>
              <span *ngIf="fullmap">{{ 'monitoring.show' | translate }}</span>
              <span *ngIf="!fullmap">{{ 'monitoring.hide' | translate }}</span>
            </button>

            <button
              *ngIf="smallWidth && splitterMode"
              type="button"
              class="btn btn-outline-primary btn-sm"
              role="button"
              (click)="toggleVisibility()"
            >
              <span *ngIf="contentsVisibilty.map"
                ><i class="mdi mdi-view-list mr-4"></i
                >{{ 'monitoring.showdata' | translate }}</span
              >
              <span *ngIf="!contentsVisibilty.map"
                ><i class="mdi mdi-earth mr-4"></i
                >{{ 'monitoring.showmap' | translate }}</span
              >
            </button>

            <span
              class="unit_count"
              afaqy-tooltip
              [tooltipText]="'footer.units_count' | translate"
            >
              <span class="badge"
                >{{ unitService.stats.monitored }}/{{
                  unitService.stats.count
                }}</span
              >
            </span>
            &nbsp;

            <span
              class="off_unit_count"
              afaqy-tooltip
              [tooltipText]="'footer.off_units' | translate"
              (click)="filterStoppedUnits()"
            >
              <!--<span class="icon-device-stop text-danger"></span>-->
              <mat-icon
                class="state-icon"
                [svgIcon]="'icon-motion-state-unit-stationary'"
                [attr.size]="'20px'"
              >
              </mat-icon>
              <span class="badge">{{ unitService.stats.stopped }}</span>
            </span>
            &nbsp;

            <span
              class="on_unit_count"
              afaqy-tooltip
              [tooltipText]="'footer.on_units' | translate"
              (click)="filterMovingUnits()"
            >
              <!--<span class="icon-device-move text-success"></span>-->
              <mat-icon
                class="state-icon"
                [svgIcon]="'icon-motion-state-unit-moving'"
                [attr.size]="'20px'"
              >
              </mat-icon>
              <span class="badge">{{ unitService.stats.moving }}</span>
            </span>
          </div>
        </div>
        <div class="col text-center d-none d-md-block">
          <div class="copyright">
            <p>
              {{ configs.copyrightYear }} - {{ currentYear }} ©
              <a target="_blank" [href]="configs.copyrightsURL">{{
                configs.title
              }}</a>
            </p>
          </div>
        </div>
        <div class="col text-right d-none d-sm-block">
          <span
            class="icon booklog pointer"
            [class.active]="bookService.status"
            (click)="toggleBookLog()"
            afaqy-tooltip
            [tooltipText]="'booklog.log' | translate"
          ></span>
          <span class="mx-4">
            <i class="mdi mdi-clock-outline"></i>
            {{ userTime | afaqyDate : true : 'HH:mm:ss' }}
          </span>
        </div>
      </div>
    </footer>
  </div>

  <div id="event-popup">
    <unit-details
      [ngClass]="{ 'sr-only': hideEventDetails }"
      *ngIf="expandedEvent"
      [unitId]="expandedEvent.unit_id"
      [updatable]="false"
      [isEventPopUP]="expandedEvent"
      [isTooltip]="false"
    ></unit-details>
    <a
      (click)="hideNotification()"
      id="popup-closer"
      class="ol-popup-closer"
    ></a>
  </div>

  <!--<div id="event-popup" class="ol-popup" [ngClass]="{'sr-only':hideEventDetails}">-->
  <!--<a (click)="hideNotification()" id="popup-closer" class="ol-popup-closer"></a>-->
  <!--<div id="popup-content">-->
  <!--<ul class="event-details" *ngIf="expandedEvent">-->
  <!---->
  <!--<li>-->
  <!--<strong>{{'notifications.object' | translate}}</strong>-->
  <!--<strong>{{expandedEvent.unit_name}}</strong>-->
  <!--</li>-->
  <!--<li><strong>{{'notifications.event' | translate}}</strong> {{ expandedEvent.desc }}</li>-->
  <!--<li><strong>{{'notifications.position' | translate}}</strong> <span class="green-color">-->
  <!--{{expandedEvent.pos.loc.coordinates[0]}} , {{expandedEvent.pos.loc.coordinates[1]}}</span></li>-->
  <!--<li><strong>{{'notifications.time' | translate}}</strong> {{expandedEvent.pos.dtt | afaqyDate}}</li>-->
  <!--</ul>-->
  <!--</div>-->
  <!--</div>-->

  <div id="copyrightInfo" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="offset-3 col-6 d-flex align-items-center">
              <img
                [src]="configs.logo"
                [alt]="configs.title"
                class="nav-icon w-100"
              />
              <span class="mx-4 text-muted">{{ configs.version }}</span>
            </div>
            <div class="col-12 mt-4 text-center">
              <p>
                {{ configs.copyrightYear }} - {{ currentYear }} ©
                <a target="_blank" [href]="configs.copyrightsURL">{{
                  configs.title
                }}</a>
                {{ 'footer.copyrights' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </ng-template> -->
  <!-- <ng-template #ServerIssue>
    <div class="server-issue-container">
      <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="2"  fill="#EEEEEE" animationDuration="60s"></p-progressSpinner>
    </div>
  </ng-template> -->
</ng-container>
