import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'search-fields',
  exportAs: 'AfaqySearch',
  templateUrl: './search-fields.component.html',
  styleUrls: ['./search-fields.component.css'],
})
export class SearchFieldsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  @Input()
  fields = [];

  searchField = 'all';

  @Output() fieldChange: EventEmitter<any> = new EventEmitter<any>();

  search($event) {
    this.fieldChange.next(this.searchField);
  }
}
