import { AppModel } from '../../../common/classes';

export class ProtocolCommand extends AppModel {
  private _protocol_code: string;
  private _name: string;
  private _msg: string;
  private _msg2: string;
  private _msg3: string;
  private _msg4: string;
  private _param: string;
  private _param_option: string;
  private _channel: string;
  private _user_id: string;
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;

  protected copyFields = [
    'id',
    'protocol_code',
    'name',
    'msg',
    'msg2',
    'msg3',
    'msg4',
    'param_option',
    'param',
    'channel',
    'user_id',
    'deleted_at',
  ];
  protected createFields = [
    'protocol_code',
    'name',
    'msg',
    'msg2',
    'msg3',
    'msg4',
    'param_option',
    'param',
    'channel',
  ];
  protected updateFields = [
    'protocol_code',
    'name',
    'msg',
    'msg2',
    'msg3',
    'msg4',
    'param_option',
    'param',
    'channel',
  ];

  static channels = [
    { k: 'auto', v: 'Auto' },
    { k: 'sms', v: 'SMS' },
    { k: 'gprs', v: 'GPRS' },
    { k: 'both', v: 'Both' },
  ];

  get protocol_code(): string {
    return this._protocol_code;
  }

  set protocol_code(value: string) {
    this._protocol_code = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get msg(): string {
    return this._msg;
  }

  set msg(value: string) {
    this._msg = value;
  }

  get msg2(): string {
    return this._msg2;
  }

  set msg2(value: string) {
    this._msg2 = value;
  }

  get msg3(): string {
    return this._msg3;
  }

  set msg3(value: string) {
    this._msg3 = value;
  }

  get msg4(): string {
    return this._msg4;
  }

  set msg4(value: string) {
    this._msg4 = value;
  }

  get param(): string {
    return this._param;
  }

  set param(value: string) {
    this._param = value;
  }

  get channel(): string {
    return this._channel;
  }

  set channel(value: string) {
    this._channel = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get param_option(): string {
    return this._param_option ? this._param_option : 'hidden';
  }

  set param_option(value: string) {
    this._param_option = value;
  }
}
