import { Injectable } from '@angular/core';

export class Logger {
  public static debug: boolean = false;

  constructor() {}

  static log(...txt: any[]) {
    if (this.debug) {
      // console.log(txt);
    }
  }

  static error(...txt: any[]) {
    if (this.debug) console.error(txt);
  }

  static warn(...txt: any[]) {
    if (this.debug) console.warn(txt);
  }

  static info(...txt: any[]) {
    if (this.debug) console.info(txt);
  }
}
