import { Injectable } from '@angular/core';
import { MapLayerService } from '../../map/map_layer.service';
import { MapService } from '../../map/map.service';
import { ZonesService } from './zones.service';
import { Zones } from '../models/zones';
import { MapModel } from '../../map/mapModel';
import { TranslateService } from '@ngx-translate/core';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { asArray } from 'ol/color';
import { getPointResolution, METERS_PER_UNIT } from 'ol/proj';

@Injectable()
export class ZonesMapLayerService extends MapLayerService {
  listened = false;
  mapModel: MapModel;
  mapId = 'map_main';

  constructor(
    mapService: MapService,
    protected zonesService: ZonesService,
    translate: TranslateService
  ) {
    super(mapService, zonesService, translate);
    this.init();
    zonesService.resources.subscribe({
      next: (res) => {
        switch (res.action) {
          case 'list':
          case 'addMulti':
            const zones: Zones[] = res.data;
            this.generateFeatures(zones, true);
            break;
          case 'update':
          case 'add':
            if (!res.relations) {
              let zone: Zones = res.data;
              let feature = this.createFeature(zone);
              this.features[zone.id] = feature;
              if (zone['visible']) {
                this.updateFeature(zone.id, this.features[zone.id]);
              }
            }
            break;
          case 'remove':
            for (let id of res.data) {
              this.removeFeature(id);
              delete this.features[id];
            }
            break;
          case 'visible':
            if (
              !(this.features instanceof Object) ||
              !Object.keys(this.features).length
            )
              this.generateFeatures();
            this.refresh();
            break;
          case 'refresh':
            this.removeAllFeatures();
            this.features = {};
            break;
        }
      },
    });
  }

  layerId: string = 'zones';
  features = {};
  init() {
    this.getMapModel();
  }
  getMapModel() {
    if (this.mapModel) {
      this.listen();
      return;
    }
    this.mapService.action((mapModel: MapModel) => {
      this.mapModel = mapModel;
    });
  }
  recalculateMapRadius(map, radius) {
    let view = map.getView();
    let projection = view.getProjection();
    let resolutionAtEquator = view.getResolution();
    let center = view.getCenter();
    let pointResolution = getPointResolution(
      projection,
      resolutionAtEquator,
      center
    );
    let resolutionFactor = resolutionAtEquator / pointResolution;
    radius = (radius / METERS_PER_UNIT.m) * resolutionFactor;

    return radius;
  }

  listen() {
    if (this.listened) {
      return;
    }
    this.mapService.listen(this.mapId).subscribe((event) => {
      if (typeof event == 'function') {
        return true;
      }
      if (event.type == 'cluster') {
        // this.showTails(event.tail_ids);
      } else if (event.type == 'uncluster') {
        // this.showAllTails();
      }
    });
    this.listened = true;
  }

  createFeature(zone: Zones) {
    let feature: any = {};
    let style = this.createStyle(zone);
    this.mapService.action((mapModel: MapModel) => {
      switch (zone.type) {
        case 'circle':
          feature = mapModel.createCircle(
            zone.vertices[0],
            this.recalculateMapRadius(mapModel.map, zone.radius),
            style
          );
          break;
        case 'polygon':
          feature = mapModel.createPolygon(zone.vertices, style);
          break;
        case 'line':
          feature = mapModel.createLine(zone.vertices, style);
          break;
      }
      feature.setId(zone.id);
      feature.set('label', zone.name);
      feature.set('maxResolutionForLabel', 1400);
    });
    return feature;
  }

  private createStyle(zone) {
    let textStyle = new Text({
      placement: 'point',
      font: 'bold 16px Arial',
      overflow: true, // Ref: https://stackoverflow.com/questions/55067624/text-disappear-when-goes-outside-marked-area-open-layer
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    });
    return (feature, resolution) => {
      let text = '';
      if (feature) {
        text = feature.get('label');
        if (resolution > 1400) {
          text = '';
        }
        textStyle.setText(text);
      }
      let checkColor = this.checkHexColorValidity(zone.color)
        ? zone.color
        : '#5C92B5';
      let color = asArray(checkColor);
      color[3] = 0.3;
      let style = new Style({
        text: textStyle,
        fill: new Fill({
          color: color,
          fillOpacity: 0.3,
        } as any),
        stroke: new Stroke({
          color: color,
          width: zone.type == 'line' ? zone.radius / resolution : 0,
        }),
      });
      return [style];
    };
  }

  checkHexColorValidity(color: string): boolean {
    // Regular expression to match hexadecimal color code
    const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

    // Test if the color matches the regex
    return hexRegex.test(color);
  }
}
