export class shipmentLists {
  static productCategories = [
    {
      id: 'frozen_meat',
      name: 'frozen-meat',
    },
    {
      id: 'chicks',
      name: 'chicks',
    },
    {
      id: 'feed_bulk',
      name: 'feed-bulk',
    },
    {
      id: 'feed_bags',
      name: 'feed-bags',
    },
    {
      id: 'birds',
      name: 'birds',
    },
    {
      id: 'eggs',
      name: 'eggs',
    },
  ];
  static truckTypes = [
    {
      id: 'reefer1_40_footer',
      name: 'reefer',
    },
    {
      id: 'bulker_40_footer',
      name: 'bulker',
    },
    {
      id: 'flat_bed',
      name: 'flat-bed',
    },
    {
      id: 'diana',
      name: 'diana',
    },
    {
      id: 'reefer2_medium',
      name: 'medium',
    },
    {
      id: 'reefer3_dianna',
      name: 'dianna',
    },
    {
      id: 'reefer4_other',
      name: 'other',
    },
  ];
  static shipmentTypes = [
    {
      id: 'long_haul',
      name: 'long-haul',
    },
    {
      id: 'client_delivery',
      name: 'client-delivery',
    },
  ];
  static shipmentPaymentTypes = [
    {
      id: 'monthly_paid',
      name: 'monthly-paid',
    },
    {
      id: 'trip_paid',
      name: 'trip-paid',
    },
  ];
}
