<div class="cms-listing-body" (mousemove)="checkMove($event)">
  <div class="data-grid">
    <wj-flex-grid
      class="listing-grid"
      #grid
      [itemsSource]="cvData"
      [selectionMode]="'Cell'"
      [isReadOnly]="true"
      [allowSorting]="true"
    >
      <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'">
        <input
          *ngIf="is_trashed"
          type="checkbox"
          class="column_check"
          (click)="dotoggleCheck()"
        />
        <span *ngIf="!is_trashed">#</span>
      </ng-template>

      <ng-template
        wjFlexGridCellTemplate
        [cellType]="'RowHeader'"
        let-cell="cell"
        let-item="item"
      >
        <span class="row-header">
          <input
            *ngIf="is_trashed"
            type="checkbox"
            [checked]="is_checked"
            (change)="doUpdateIds($event, item?.id)"
            class="column_check"
          />
          <span *ngIf="!is_trashed">{{ cell?.row?.index + 1 }}</span>
        </span>
      </ng-template>

      <wj-flex-grid-column
        *ngFor="let col of activeColumns"
        [allowSorting]="
          col?.colValue == 'actions' || col?.sorting === false ? false : true
        "
        [header]="col?.header | translate"
        [binding]="col?.colValue"
        [minWidth]="col?.minWidth"
        [width]="col?.width"
      >
        <ng-template
          wjFlexGridCellTemplate
          [cellType]="'ColumnHeader'"
          let-cell="cell"
          let-item="item"
          *ngIf="col?.headerHtml"
        >
          <span [innerHTML]="col?.header"></span>
        </ng-template>

        <ng-template
          wjFlexGridCellTemplate
          [cellType]="'Cell'"
          let-cell="cell"
          *ngIf="col?.translate"
        >
          <span> {{ cell?.item[col?.colValue] | translate }}</span>
        </ng-template>
        <ng-template
          wjFlexGridCellTemplate
          [cellType]="'Cell'"
          let-cell="cell"
          *ngIf="col?.type == 'boolean'"
        >
          <span>
            {{ cell?.item | objectBoolean : col?.colValue | translate }}</span
          >
        </ng-template>
        <ng-template
          wjFlexGridCellTemplate
          [cellType]="'Cell'"
          let-cell="cell"
          *ngIf="col.type == 'booleanflag'"
        >
          <span
            class="pointer mdi"
            (click)="
              updateFlag({
                item: cell?.item,
                key: col?.flagkey,
                action: 'update_flag'
              })
            "
            [ngClass]="
              cell?.item[col?.colText]
                ? 'mdi-check green-color'
                : 'mdi-close red-color'
            "
          ></span>
        </ng-template>
        <ng-template
          wjFlexGridCellTemplate
          [cellType]="'Cell'"
          let-cell="cell"
          *ngIf="col.type == 'date'"
        >
          <span>
            {{
              cell?.item[col?.colValue] | afaqyDate : true : '' : col?.ms
            }}</span
          >
        </ng-template>
        <ng-template
          wjFlexGridCellTemplate
          [cellType]="'Cell'"
          let-cell="cell"
          *ngIf="col.type == 'icon'"
        >
          <span>
            <img class="list-icon" src="{{ cell.item[col?.colValue] }}" />
          </span>
        </ng-template>
        <ng-template
          wjFlexGridCellTemplate
          [cellType]="'Cell'"
          let-cell="cell"
          *ngIf="col.type == 'geofenceIcon'"
        >
          <span class="{{ cell?.item[col?.colText] }}"></span>
        </ng-template>
        <ng-template
          wjFlexGridCellTemplate
          [cellType]="'Cell'"
          cellClass="class"
          let-cell="cell"
          let-item="item"
          *ngIf="col.colValue == 'actions'"
        >
          <div class="list-actions d-flex flex-row justify-content-between">
            <div
              afaqy-tooltip
              [tooltipText]="'tooltips.extra' | translate"
              *ngIf="showExtra && extraActions?.length > 1"
              class="inline pointer relative-pos"
              [secured]="extraParams.permissions"
            >
              <a
                href="javascript:void(0)"
                (click)="showExtraActions($event, cell)"
              >
                <i
                  class="mdi mdi-arrow-down"
                  [attr.testid]="'extra-actions'"
                ></i>
              </a>
            </div>
            <a
              *ngIf="showExtra && extraActions?.length == 1"
              [secured]="extraParams?.permissions"
              (click)="executeAction(extraActions[0].code, item)"
              href="javascript:void(0)"
              title="{{ extraActions[0].header | translate }}"
            >
              <i
                [class]="'fa ' + extraActions[0]?.faclass"
                [attr.testid]="'extra-actions'"
              ></i>
            </a>
            <a
              *ngIf="!is_trashed"
              afaqy-tooltip
              [tooltipText]="'tooltips.edit' | translate"
              secured="{{ controller + '-edit' }}"
              (click)="executeAction('edit', item)"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-pencil" [attr.testid]="'edit-item'"></i>
            </a>
            <a
              *ngIf="!is_trashed"
              afaqy-tooltip
              [tooltipText]="'tooltips.copy' | translate"
              secured="{{ controller + '-add,' + controller + '-edit' }}"
              (click)="executeAction('copy', item)"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-content-copy" [attr.testid]="'copy-item'"></i>
            </a>
            <a
              *ngIf="!is_trashed"
              afaqy-tooltip
              [tooltipText]="'tooltips.delete' | translate"
              secured="{{ controller + '-delete' }}"
              (click)="doDelete($event, item?.id)"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-delete" [attr.testid]="'delete-item'"></i>
            </a>
            <a
              *ngIf="is_trashed"
              afaqy-tooltip
              [tooltipText]="'tooltips.restore' | translate"
              secured="{{ controller + '-restore' }}"
              (click)="doRestore($event, [item?.id])"
              href="javascript:void(0)"
              title="{{ 'restore' | translate }}"
            >
              <i class="mdi mdi-undo" [attr.testid]="'restore-item'"></i>
            </a>
          </div>
        </ng-template>
      </wj-flex-grid-column>
    </wj-flex-grid>
    <div
      #extraActionsContainer
      [attr.testid]="'extra-actions'"
      *ngIf="showExtra && extraActions?.length > 1"
      id="{{ controller + '-extra-action' }}"
      class="listing-extra-actions"
      [attr.testid]="'extra-actions-list'"
      [class.hidden]="extraParams?.hideExtra"
      [ngStyle]="
        isRTL
          ? { 'top.px': extraParams.elmTop, 'right.px': extraParams?.elmRight }
          : { 'top.px': extraParams?.elmTop, 'left.px': extraParams?.elmLeft }
      "
      style="position: absolute"
    >
      <table class="">
        <tr *ngFor="let act of extraActions" [secured]="act?.permissions">
          <td (click)="applyExtraAction(act.code)">
            <a href="javascript:void(0)">
              <i
                [class]="'fa ' + act?.faclass"
                title="{{ act?.header | translate }}"
                [attr.testid]="moduleName + '-' + act?.code"
              ></i>
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="paginationPositioning">
    <div class="row pagination-details">
      <!-- <div class="col-md-4"> -->
      <perpage
        [perpage]="pagination?.limit"
        (changePagerLimit)="changePagerLimit($event)"
      ></perpage>
      <!-- </div> -->
      <!-- <div class="col-md-8"> -->
      <afaqy-paginate
        [pagination]="pagination"
        (getPageResources)="loadPageData($event)"
      ></afaqy-paginate>
      <!-- </div> -->
    </div>
  </div>
</div>
