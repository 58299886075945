<div [formGroup]="group" class="shipment-form">
  <div class="shipment-info">
    <h5>{{ 'units.shipment-info' | translate }}</h5>
    <div class="row">
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['product_category'].dirty ||
              group.controls['product_category'].touched) &&
            group.controls['product_category'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.product_category' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <div class="input-group">
              <afaqy-custom-select
                id="product_category"
                [dataList]="product_category"
                [predefinedList]="true"
                [titleField]="'name'"
                [ctl]="group.controls['product_category']"
                formControlName="product_category"
                [attr.testId]="cid + 'product_category'"
                [multiple]="false"
                [clear]="true"
              >
              </afaqy-custom-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['truck_type'].dirty ||
              group.controls['truck_type'].touched) &&
            group.controls['truck_type'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.truck_type' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <div class="input-group">
              <afaqy-custom-select
                id="truck_type"
                [dataList]="truck_type"
                [predefinedList]="true"
                [titleField]="'name'"
                [ctl]="group.controls['truck_type']"
                formControlName="truck_type"
                [attr.testId]="cid + 'truck_type'"
                [multiple]="false"
                [clear]="true"
              >
              </afaqy-custom-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['shipment_type'].dirty ||
              group.controls['shipment_type'].touched) &&
            group.controls['shipment_type'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.shipment_type' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <div class="input-group">
              <afaqy-custom-select
                id="shipment_type"
                [dataList]="shipment_type"
                [predefinedList]="true"
                [titleField]="'name'"
                [ctl]="group.controls['shipment_type']"
                formControlName="shipment_type"
                [attr.testId]="cid + 'shipment_type'"
                [multiple]="false"
                [clear]="true"
              >
              </afaqy-custom-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['shipment_payment_type'].dirty ||
              group.controls['shipment_payment_type'].touched) &&
            group.controls['shipment_payment_type'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.shipment_payment_type' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <div class="input-group">
              <afaqy-custom-select
                id="shipment_payment_type"
                [dataList]="shipment_payment_type"
                [predefinedList]="true"
                [titleField]="'name'"
                [ctl]="group.controls['shipment_payment_type']"
                formControlName="shipment_payment_type"
                [attr.testId]="cid + 'shipment_payment_type'"
                [multiple]="false"
                [clear]="true"
              >
              </afaqy-custom-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['transporter_type'].dirty ||
              group.controls['transporter_type'].touched) &&
            group.controls['transporter_type'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.transporter_type' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="transporter_type"
              [attr.testId]="cid + 'transporter_type'"
            />
            <control-errors-hint
              [ctl]="group.controls['transporter_type']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['operating_area'].dirty ||
              group.controls['operating_area'].touched) &&
            group.controls['operating_area'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.operating_area' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="operating_area"
              [attr.testId]="cid + 'operating_area'"
            />
            <control-errors-hint
              [ctl]="group.controls['operating_area']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="shipment-target">
    <h5>{{ 'units.shipment-target' | translate }}</h5>
    <div class="row">
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['weight_kilograms_per_day'].dirty ||
              group.controls['weight_kilograms_per_day'].touched) &&
            group.controls['weight_kilograms_per_day'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.weight_kilograms_per_day' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="weight_kilograms_per_day"
              [attr.testId]="cid + 'weight_kilograms_per_day'"
            />
            <control-errors-hint
              [ctl]="group.controls['weight_kilograms_per_day']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['kms_per_day'].dirty ||
              group.controls['kms_per_day'].touched) &&
            group.controls['kms_per_day'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.kms_per_day' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="kms_per_day"
              [attr.testId]="cid + 'kms_per_day'"
            />
            <control-errors-hint
              [ctl]="group.controls['kms_per_day']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['shipment_trip_drive_hrs_day'].dirty ||
              group.controls['shipment_trip_drive_hrs_day'].touched) &&
            group.controls['shipment_trip_drive_hrs_day'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.shipment_trip_drive_hrs_day' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="shipment_trip_drive_hrs_day"
              [attr.testId]="cid + 'shipment_trip_drive_hrs_day'"
            />
            <control-errors-hint
              [ctl]="group.controls['shipment_trip_drive_hrs_day']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['allowable_delay_drive_hrs_day'].dirty ||
              group.controls['allowable_delay_drive_hrs_day'].touched) &&
            group.controls['allowable_delay_drive_hrs_day'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.allowable_delay_drive_hrs_day' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="allowable_delay_drive_hrs_day"
              [attr.testId]="cid + 'allowable_delay_drive_hrs_day'"
            />
            <control-errors-hint
              [ctl]="group.controls['allowable_delay_drive_hrs_day']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['expected_shipment_trip_day'].dirty ||
              group.controls['expected_shipment_trip_day'].touched) &&
            group.controls['expected_shipment_trip_day'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.expected_shipment_trip_day' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="expected_shipment_trip_day"
              [attr.testId]="cid + 'expected_shipment_trip_day'"
            />
            <control-errors-hint
              [ctl]="group.controls['expected_shipment_trip_day']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['car_pay_per_day'].dirty ||
              group.controls['car_pay_per_day'].touched) &&
            group.controls['car_pay_per_day'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.car_pay_per_day' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="car_pay_per_day"
              [attr.testId]="cid + 'car_pay_per_day'"
            />
            <control-errors-hint
              [ctl]="group.controls['car_pay_per_day']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['exceeding_temperature_limit_per_day'].dirty ||
              group.controls['exceeding_temperature_limit_per_day'].touched) &&
            group.controls['exceeding_temperature_limit_per_day'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{
                'units.exceeding_temperature_limit_per_day' | translate
              }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="exceeding_temperature_limit_per_day"
              [attr.testId]="cid + 'exceeding_temperature_limit_per_day'"
            />
            <control-errors-hint
              [ctl]="group.controls['exceeding_temperature_limit_per_day']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['temperature_upper_limit'].dirty ||
              group.controls['temperature_upper_limit'].touched) &&
            group.controls['temperature_upper_limit'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.temperature_upper_limit' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="temperature_upper_limit"
              [attr.testId]="cid + 'temperature_upper_limit'"
            />
            <control-errors-hint
              [ctl]="group.controls['temperature_upper_limit']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['exceeding_humidity_limit_per_day'].dirty ||
              group.controls['exceeding_humidity_limit_per_day'].touched) &&
            group.controls['exceeding_humidity_limit_per_day'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{
                'units.exceeding_humidity_limit_per_day' | translate
              }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="exceeding_humidity_limit_per_day"
              [attr.testId]="cid + 'exceeding_humidity_limit_per_day'"
            />
            <control-errors-hint
              [ctl]="group.controls['exceeding_humidity_limit_per_day']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['humidity_upper_limit'].dirty ||
              group.controls['humidity_upper_limit'].touched) &&
            group.controls['humidity_upper_limit'].errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.humidity_upper_limit' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              type="number"
              class="form-control form-control-sm"
              formControlName="humidity_upper_limit"
              [attr.testId]="cid + 'humidity_upper_limit'"
            />
            <control-errors-hint
              [ctl]="group.controls['humidity_upper_limit']"
            ></control-errors-hint>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
