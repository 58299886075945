<div class="input-group form-group-hint">
  <div class="searchBoxCtrl search-ctrl-label w-100">
    <input
      type="text"
      class="form-control form-control-sm"
      [attr.testId]="'units-filter'"
      [(ngModel)]="searchText"
      (keyup)="refreshFilters()"
    />
  </div>
  <div class="input-group-append hint">
    <span class="input-group-text">
      <i
        class="fa fa-exclamation-circle"
        afaqy-tooltip
        [tooltipText]="'search.multiple_search' | translate"
      ></i>
    </span>
  </div>
</div>
<wj-flex-grid
  class="report-resource-list searchArea"
  #grid
  [itemsSource]="optionsList"
  [headersVisibility]="'Column'"
  [selectionMode]="'None'"
  [isReadOnly]="true"
  [allowSorting]="true"
  [allowResizing]="'None'"
>
  <wj-flex-grid-column binding="id" [isReadOnly]="true" [width]="60">
    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
      <input
        type="checkbox"
        [id]="'units_list_' + item.id"
        [attr.testId]="item.name"
        [checked]="selectedValue[item.id]"
        (click)="toggleItem(item.id, $event)"
      />
    </ng-template>
    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
      <i
        class="mdi mdi-minus-box-outline"
        (click)="expandAll()"
        [attr.testId]="'expandAll'"
      ></i>
      <i
        class="mdi mdi-plus-box-outline"
        (click)="collapsAll()"
        [attr.testId]="'collapsAll'"
      ></i>
      <input
        type="checkbox"
        [attr.testId]="'name'"
        [checked]="selectedGroups['all']"
        class="inline"
        (click)="AllSelection($event.target.checked)"
      />
    </ng-template>
  </wj-flex-grid-column>
  <!-- GROUP NAME -->
  <wj-flex-grid-column
    [allowSorting]="false"
    header=""
    [binding]="'group_id'"
    [isReadOnly]="true"
    [minWidth]="1"
    [width]="1"
  >
    <ng-template
      wjFlexGridCellTemplate
      [cellType]="'GroupHeader'"
      let-cell="cell"
    >
      <div>
        <i
          class="inline fa"
          (click)="cell.row.isCollapsed = !cell.row.isCollapsed"
          [class.fa-plus-square]="cell.row.isCollapsed"
          [class.fa-minus-square]="!cell.row.isCollapsed"
        ></i>
        <input
          type="checkbox"
          [attr.testId]="cell.item.items[0].group_name"
          [checked]="selectedGroups[cell.item.name]"
          class="inline"
          (click)="UnitGroupSelection($event.target.checked, cell.item.name)"
        />
        <div
          (click)="cell.row.isCollapsed = !cell.row.isCollapsed"
          class="pointer inline"
        >
          <span class="objectName text-bold">{{
            cell.item.items[0].group_name
          }}</span>
          <span>({{ cell.item.items.length }})</span>
        </div>
      </div>
    </ng-template>
    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">&nbsp;</ng-template>
  </wj-flex-grid-column>
  <!-- END GROUP NAME -->

  <!-- NAME -->
  <wj-flex-grid-column
    [header]="'name' | translate"
    binding="name"
    [minWidth]="150"
    [width]="'*'"
  >
    <ng-template
      wjFlexGridCellTemplate
      [cellType]="'Cell'"
      let-cell="cell"
      let-item="item"
    >
      <label [for]="'units_list_' + item.id">{{ item.name }}</label>
    </ng-template>
  </wj-flex-grid-column>
  <!-- END NAME -->
</wj-flex-grid>
