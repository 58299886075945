export class EventsSettings {
  static statusVal = ['not_active', 'active', 'hidden'];
  static eventTypes = [
    { value: 'tow', display: 'tow', allowed_device_types: ['gps'] },
    { value: 'pwrcut', display: 'pwrcut', allowed_device_types: ['gps'] },
    { value: 'gpsantcut', display: 'gpsantcut', allowed_device_types: ['gps'] },
    { value: 'lowdc', display: 'lowdc', allowed_device_types: ['gps'] },
    { value: 'lowbat', display: 'lowbat', allowed_device_types: ['gps'] },
    { value: 'noconn', display: 'noconn', allowed_device_types: ['gps'] },
    { value: 'overspeed', display: 'overspeed', allowed_device_types: ['gps'] },
    {
      value: 'underspeed',
      display: 'underspeed',
      allowed_device_types: ['gps'],
    },
    {
      value: 'overspeed_google',
      display: 'overspeed_google',
      allowed_device_types: ['gps'],
    },
    { value: 'saccel', display: 'saccel', allowed_device_types: ['gps'] },
    { value: 'sbrake', display: 'sbrake', allowed_device_types: ['gps'] },
    {
      value: 'sudden_driftng',
      display: 'sudden_driftng',
      allowed_device_types: ['gps'],
    },
    { value: 'sensor', display: 'sensor', allowed_device_types: ['gps'] },
    {
      value: 'fuel_drain',
      display: 'fuel_drain',
      allowed_device_types: ['gps'],
    },
    {
      value: 'fuel_filling',
      display: 'fuel_filling',
      allowed_device_types: ['gps'],
    },
    { value: 'service', display: 'service', allowed_device_types: ['gps'] },
    { value: 'zone_in', display: 'zone_in', allowed_device_types: ['gps'] },
    { value: 'zone_out', display: 'zone_out', allowed_device_types: ['gps'] },
    { value: 'stop', display: 'stop', allowed_device_types: ['gps'] },
    { value: 'idle_time', display: 'idle_time', allowed_device_types: ['gps'] },
    {
      value: 'customer_in',
      display: 'customer_in',
      allowed_device_types: ['gps'],
    },
    { value: 'job_order', display: 'job_order', allowed_device_types: ['gps'] },
  ];
  static alertSounds = [
    { value: 'alarm1.mp3', display: 'Alarm1.mp3' },
    { value: 'alarm2.mp3', display: 'Alarm2.mp3' },
    { value: 'alarm3.mp3', display: 'Alarm3.mp3' },
    { value: 'alarm4.mp3', display: 'Alarm4.mp3' },
    { value: 'alarm5.mp3', display: 'Alarm5.mp3' },
    { value: 'alarm6.mp3', display: 'Alarm6.mp3' },
    { value: 'alarm7.mp3', display: 'Alarm7.mp3' },
    { value: 'alarm8.mp3', display: 'Alarm8.mp3' },
    { value: 'beep1.mp3', display: 'Beep1.mp3' },
    { value: 'beep2.mp3', display: 'Beep2.mp3' },
    { value: 'beep3.mp3', display: 'Beep3.mp3' },
    { value: 'beep4.mp3', display: 'Beep4.mp3' },
    { value: 'beep5.mp3', display: 'Beep5.mp3' },
  ];
  static weekDays = [
    { value: '1', display: 'sat', code: 'sa' },
    { value: '2', display: 'sun', code: 'su' },
    { value: '3', display: 'mon', code: 'mo' },
    { value: '4', display: 'tue', code: 'tu' },
    { value: '5', display: 'wed', code: 'we' },
    { value: '7', display: 'thu', code: 'th' },
    { value: '7', display: 'fri', code: 'fr' },
  ];
  static gateway = [
    { value: 'AUTO', display: 'Auto' },
    { value: 'GPRS', display: 'GPRS' },
    { value: 'SMS', display: 'SMS' },
  ];
  static commandType = [
    { value: 'ASCII', display: 'ASCII' },
    { value: 'HEX', display: 'HEX' },
  ];
  static unitStatus = [
    { value: 'st1', display: 'Status 1' },
    { value: 'st2', display: 'Status 2' },
  ];
  // static sensors = [
  //     {value: 'di1', display: 'di1'},
  //     {value: 'di2', display: 'di2'},
  //     {value: 'do179', display: 'do179'},
  //     {value: 'ePwrV', display: 'ePwrV'},
  //     {value: 'event', display: 'event'},
  //     {value: 'gsm', display: 'gsm'},
  //     {value: 'parm239', display: 'parm239'},
  //     {value: 'parm240', display: 'parm240'},
  //     {value: 'parm253', display: 'parm253'},
  //     {value: 'priority', display: 'priority'},
  //     {value: 'protocol', display: 'protocol'},
  //     {value: 'sat', display: 'sat'},
  //     {value: 'spd', display: 'spd'},
  //     {value: 'vOdometer', display: 'vOdometer'}
  // ];
  static operations = ['<', '=', '>'];
  // Event conditions for each event type from the dropdown
  static event_conds = {
    hide: ['overspeed_google', 'saccel', 'sbrake', 'service', 'job_order'],
    show: {
      lowdc: ['sensor_conditions', 'time_minutes'],
      lowbat: ['sensor_conditions', 'time_minutes'],
      tow: ['sensor', 'time_minutes'],
      stop: ['time_minutes'],
      idle_time: ['time_minutes'],
      pwrcut: ['sensor', 'time_minutes'],
      gpsantcut: ['sensor', 'time_minutes'],
      noconn: ['no_conn_period_min'],
      overspeed: ['time_minutes', 'speed_limit'],
      underspeed: ['time_minutes', 'speed_limit'],
      sensor: ['sensor_conditions', 'time_minutes'],
      zone_in: ['zone_groups', 'zones', 'time_minutes', 'min_speed'],
      zone_out: ['zone_groups', 'zones', 'time_minutes', 'min_speed'],
      customer_in: ['customer_types', 'customers', 'time_minutes'],
      sudden_driftng: ['speed_diff', 'angle_diff'],
      fuel_drain: ['volume', 'time_minutes'],
      fuel_filling: ['volume', 'time_minutes'],
    },
  };
}
