import { AppModel } from 'app/common/classes';

export class WasteContainersModel extends AppModel {
  private _name: string;

  private _created_at: Date;
  private _deleted_at: Date;
  private _type: string;
  private _detection: string = 'zone';
  private _zone_id: string;
  private _radius: string;
  private _location: string;
  private _rfid: string;
  private _unit_id: string;
  private _last_pickup: string;
  private _active: boolean = true;
  private _picked: boolean;
  private _picking_du: string;
  private _district_id: string;

  protected copyFields = [
    'id',
    'name',
    'type',
    'detection',
    'zone_id',
    'plans',
    'radius',
    'location',
    'rfid',
    'municipality',
    'neighborhood',
    'region_id',
    'governorate_id',
    'municipality_id',
    'district_id',
    'district',
    'neighborhood_name',
    'neighborhood_id',
    'active',
    'picked',
    'picking_du',
    'created_at',
    'deleted_at',
    'last_pickup',
  ];
  protected createFields = [
    'name',
    'type',
    'detection',
    'zone_id',
    'plans',
    'radius',
    'location',
    'rfid',
    'region_id',
    'municipality',
    ,
    'neighborhood',
    'governorate_id',
    'municipality_id',
    'district_id',
    'district',
    'neighborhood_name',
    'neighborhood_id',
    'active',
    'picked',
    'picking_du',
    'last_pickup',
  ];
  protected updateFields = [
    'name',
    'type',
    'detection',
    'zone_id',
    'plans',
    'radius',
    'location',
    'rfid',
    'region_id',
    'municipality',
    ,
    'neighborhood',
    'governorate_id',
    'municipality_id',
    'district_id',
    'district',
    'neighborhood_name',
    'neighborhood_id',
    'active',
    'picked',
    'picking_du',
    'last_pickup',
  ];

  public get last_pickup(): string {
    return this._last_pickup;
  }
  public set last_pickup(value: string) {
    this._last_pickup = value;
  }
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }
  public get detection(): string {
    return this._detection;
  }
  public set detection(value: string) {
    this._detection = value;
  }
  public get zone_id(): string {
    return this._zone_id;
  }
  public set zone_id(value: string) {
    this._zone_id = value;
  }
  public get radius(): string {
    return this._radius;
  }
  public set radius(value: string) {
    this._radius = value;
  }
  public get picking_du(): string {
    return this._picking_du;
  }
  public set picking_du(value: string) {
    this._picking_du = value;
  }
  public get location(): string {
    return this._location;
  }
  public set location(value: string) {
    this._location = value;
  }
  public get rfid(): string {
    return this._rfid;
  }
  public set rfid(value: string) {
    this._rfid = value;
  }
  public get unit_id(): string {
    return this._unit_id;
  }
  public set unit_id(value: string) {
    this._unit_id = value;
  }

  public get district_id(): string {
    return this._district_id;
  }
  public set district_id(value: string) {
    this._district_id = value;
  }
  public get active(): boolean {
    return this._active;
  }
  public get picked(): boolean {
    return this._picked;
  }
  public set picked(value: boolean) {
    this._picked = value;
  }

  public set active(value: boolean) {
    this._active = value;
  }
  public get created_at(): Date {
    return this._created_at;
  }
  public set created_at(value: Date) {
    this._created_at = value;
  }
  public get deleted_at(): Date {
    return this._deleted_at;
  }
  public set deleted_at(value: Date) {
    this._deleted_at = value;
  }
}
