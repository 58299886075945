import { AfaqyObject } from '../../../common/classes';
export class UnitFuelConsumptionMath extends AfaqyObject {
  protected copyFields = [
    'enable',
    'idling',
    'urban',
    'suburban',
    'loaded_coef',
  ];

  private _enable: boolean = false;
  private _idling: Number = 2;
  private _urban: Number = 10;
  private _suburban: Number = 7;
  private _loaded_coef: Number = 1.3;

  get enable(): boolean {
    return this._enable;
  }

  set enable(value: boolean) {
    this._enable = value;
  }

  get idling(): Number {
    return this._idling;
  }

  set idling(value: Number) {
    this._idling = value;
  }

  get urban(): Number {
    return this._urban;
  }

  set urban(value: Number) {
    this._urban = value;
  }

  get suburban(): Number {
    return this._suburban;
  }

  set suburban(value: Number) {
    this._suburban = value;
  }

  get loaded_coef(): Number {
    return this._loaded_coef;
  }

  set loaded_coef(value: Number) {
    this._loaded_coef = value;
  }
}
