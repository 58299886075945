import {
  Component,
  Input,
  ComponentFactoryResolver,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';

import {
  AssignationService,
  AssignableResourcesService,
  AuthService,
} from '../../../core/services';
import { CoreListComponent } from '../../../common/core-list-compenent';
import { Message, AppConfig } from '../../../common/classes';
import { UserService } from '../../../modules/users/services';

@Component({
  selector: 'user-assignations',
  templateUrl: './user-assignations.component.html',
  styleUrls: ['./user-assignations.component.scss'],
})
export class UserAssignationsComponent
  extends CoreListComponent
  implements OnInit
{
  autoUpdatetitle = false;
  posting = false;
  op: string;
  currentUrl: string;
  is_assignation = true;
  is_checked: boolean;
  assignedUsers = [];
  columns = [
    {
      header: 'username',
      minWidth: 250,
      width: '*',
      colValue: 'username',
      active: false,
      immutable: true,
      default: true,
    },
    // {
    //     header: "email",
    //     "minWidth": 75,
    //     "width": '*',
    //     colValue: "email",
    //     active: false,
    //     default: true
    // },
    {
      header: 'actions',
      minWidth: 60,
      width: 70,
      colValue: 'actions',
      active: false,
      default: true,
    },
  ];
  message: Message;
  config = AppConfig;

  @Input() set type(value) {
    this.op = value;
  }

  routerPrefix;

  @Input() set prefix(value) {
    this.routerPrefix = value;
  }

  _resource_id;
  @Input() set resource_id(value) {
    this._resource_id = value;
  }

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    translate: TranslateService,
    private userService: UserService,
    route: ActivatedRoute,
    service: AssignationService,
    private assignableResourcesService: AssignableResourcesService,
    router: Router,
    authService: AuthService
  ) {
    super(
      componentFactoryResolver,
      translate,
      route,
      service,
      router,
      authService
    );
    this.cid = 'users';
    if (!AppConfig.isCMS) {
      this.currentUrl = this.router.url;
      this.op = this.route.snapshot.url[0]['path'];
    } else {
      this.columns[0]['colValue'] = 'name';
      this.columns.splice(1, 1);
    }
    this.message = new Message();
  }

  ngOnInit() {
    this.service.op = this.op;
    this.prepareUsersList();
  }

  prepareUsersList() {
    if (AppConfig.isCMS) {
      this.assignableResourcesService
        .getById(this._resource_id, this.routerPrefix)
        .subscribe({
          next: (response) => {
            this.assignedUsers = response['data']['users_list'];
          },
        });
    } else if (
      this.route.snapshot.data['assignedUsers']['data']['users_list']
    ) {
      this.service.assignedUsers =
        this.route.snapshot.data['assignedUsers']['data']['users_list'];
      this.assignedUsers =
        this.route.snapshot.data['assignedUsers']['data']['users_list'];
    }
  }

  getPrefix() {
    if (AppConfig.isCMS) {
      return this.routerPrefix;
    }
    return this.route.snapshot.data['routerPrefix'];
  }

  getResourceID() {
    if (AppConfig.isCMS) {
      return this._resource_id;
    }
    return this.route.snapshot.params['id'];
  }

  assignation(ids, op) {
    let subscription = this.service.confirm('confirm-' + this.op).subscribe({
      next: (response) => {
        if (response) {
          this.loading = true;
          let prefix = this.getPrefix();
          this.service
            .assignation(ids, op, [this.getResourceID()], prefix)
            .pipe(takeWhile(() => this.alive))
            .subscribe({
              next: () => {
                this.notify(
                  this.getAssignationMsg(ids, op),
                  'headings.' + prefix
                );
                this.cancel();
                this.multiple_ids = [];
              },
              error: (error) => {
                this.notify(
                  this.getErrorAssignationMsg(error),
                  'headings.' + prefix,
                  'error'
                );
                this.cancel();
              },
              complete: () => {
                this.loading = false;
              },
            });
        }
        subscription.unsubscribe();
      },
    });
    return false;
  }

  getAssignationMsg(id, op) {
    if (id.length > 1) {
      return 'all_' + op;
    } else {
      return 'notifications.resource.has_been_' + op + 'ed';
    }
  }

  cancel() {
    if (AppConfig.isCMS) {
      this.closeForm.next();
    } else {
      let urlArr = this.currentUrl.split('/');
      urlArr.splice(-2, 2);
      this.router.navigateByUrl(urlArr.join('/'), {
        skipLocationChange: AppConfig.skipLocationChange,
      });
    }
  }
}
