import { AppModel } from '../../../common/classes';
import { ZoneGroup } from './';
import { TranslateService } from '@ngx-translate/core';

export class Zones extends AppModel {
  constructor(protected translate: TranslateService) {
    super();
  }

  private _name: string;
  private _user_id: string;
  private _color: string;
  private _type: string = 'polygon';
  private _status: number;
  private _personal: number;
  private _radius: number;
  private _area: number;
  private _vertices: any[];
  private _zone_group_id: string;
  private _zone_group: ZoneGroup;
  private _is_customer: boolean;
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;
  private _unit_count: string;
  private _code: string;
  protected copyFields = [
    'id',
    'name',
    'user_id',
    'color',
    'type',
    'status',
    'personal',
    'radius',
    'area',
    'vertices',
    'zone_group_id',
    'is_customer',
    'created_at',
    'deleted_at',
    'users_list',
    'code',
  ];
  protected createFields = [
    'name',
    'user_id',
    'color',
    'type',
    'status',
    'personal',
    'radius',
    'area',
    'vertices',
    'zone_group_id',
    'is_customer',
    'users_list',
    'code',
  ];
  protected updateFields = [
    'name',
    'user_id',
    'color',
    'type',
    'status',
    'personal',
    'radius',
    'area',
    'vertices',
    'zone_group_id',
    'is_customer',
    'users_list',
    'code',
  ];

  static statusVal = ['not_active', 'active'];
  static isPersonal = ['no', 'yes'];
  static zoneTypes = ['line', 'circle', 'polygon'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get status(): number {
    return this._status;
  }

  set status(value: number) {
    this._status = value;
  }

  get personal(): number {
    return this._personal;
  }

  set personal(value: number) {
    this._personal = value;
  }

  get radius(): number {
    return this._radius;
  }

  set radius(value: number) {
    this._radius = value;
  }

  get area(): number {
    return this._area;
  }

  set area(value: number) {
    this._area = value;
  }

  get vertices(): any[] {
    return this._vertices;
  }

  set vertices(value: any[]) {
    this._vertices = value;
  }

  get zone_group_id(): string {
    return this._zone_group_id;
  }

  set zone_group_id(value: string) {
    this._zone_group_id = value;
  }

  get is_customer(): boolean {
    return this._is_customer;
  }

  set is_customer(value: boolean) {
    this._is_customer = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get zone_group(): ZoneGroup {
    return this._zone_group;
  }

  set zone_group(value: ZoneGroup) {
    this._zone_group = value;
  }

  get unitsCount(): string {
    return this._unit_count;
  }

  set unitsCount(value: string) {
    this._unit_count = value;
  }

  get zoneGroupName() {
    if (this.zone_group) return this.zone_group.name;
    return '';
  }

  get zoneTypeIcon() {
    let cls = '';
    switch (this.type) {
      case 'polygon':
        cls = 'icon-zone-polygone';
        break;
      case 'line':
        cls = 'icon-zone-line';
        break;
      default:
        cls = 'icon-zone-circle';
        break;
    }
    return cls;
  }

  get zoneType() {
    return this.translate.instant(`zones.${this.type}`);
  }

  get statusDisplay() {
    return Zones.statusVal[this.status ? 1 : 0];
  }

  get statusType() {
    return this.translate.instant(this.statusDisplay);
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }
}
