import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { AuthService } from '../../../core/services';

@Component({
  selector: 'perpage',
  exportAs: 'perPage',
  templateUrl: './perpage.component.html',
  styleUrls: ['./perpage.component.css'],
})
export class PerpageComponent implements OnInit {
  @Output() changePagerLimit: EventEmitter<any> = new EventEmitter<any>();
  @Input() perpage: number;

  constructor(private authService: AuthService) {
    //private pagerService: AfaqyPerPageService
  }

  ngOnInit() {}

  pushChanges() {
    this.changePagerLimit.next(this.perpage);
    //this.pagerService.change(this.perpage);
  }
}
