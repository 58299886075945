import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { map } from 'rxjs/operators';
import { AfaqyAPIResponse } from '../../../core/classes/afaqy-response';
import { ChatService } from '../../chat/services/chat.service';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { UnitCommand } from '../models';

@Injectable()
export class UnitCommandService extends RootService {
  protected channels = [];

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    public chat: ChatService,
    protected translate: TranslateService
  ) {
    super(authService, apiRequest, alert, notificationsService);

    this.channels = [
      { k: 'auto', v: this.translate.instant('commands.auto') },
      { k: 'sms', v: this.translate.instant('commands.sms') },
      { k: 'gprs', v: this.translate.instant('commands.gprs') },
      { k: 'both', v: this.translate.instant('commands.both') },
    ];
  }

  get limitForPageList() {
    return 0;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'unit_commands';
  }

  get modelInstance() {
    return new UnitCommand();
  }

  get channelsValue() {
    return this.channels;
  }

  prepareFormParams(params) {
    return params;
  }

  applyBeforeCreate(params) {
    return this.prepareFormParams(params);
  }

  applyBeforeUpdate(params) {
    return this.prepareFormParams(params);
  }

  execute(id) {
    return this.apiRequest.authPost('unit_commands/execute', { id: id }).pipe(
      map((result: AfaqyAPIResponse) => {
        this.chat.logChat(result['data']);
        return result;
      })
    );
  }
}
