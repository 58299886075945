import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { asArray } from 'ol/color';
import { getPointResolution, METERS_PER_UNIT } from 'ol/proj';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { MapService } from '../../map/map.service';
import { MapModel } from '../../map/mapModel';
import { MapLayerService } from '../../map/map_layer.service';
import { Customers } from '../models/customers';
import { CustomersService } from './customers.service';

@Injectable()
export class CustomersMapLayerService extends MapLayerService {
  constructor(
    mapService: MapService,
    private customersService: CustomersService,
    translate: TranslateService
  ) {
    super(mapService, customersService, translate);
    customersService.resources.subscribe({
      next: (res) => {
        switch (res.action) {
          case 'list':
          case 'addMulti':
            const customers: Customers[] = res.data;
            this.generateFeatures(customers);
            break;
          case 'update':
          case 'add':
            if (!res.relations) {
              let customer: Customers = res.data;
              let feature = this.createFeature(customer);
              this.features[customer.id] = feature;
              if (customer['visible']) {
                this.updateFeature(customer.id, this.features[customer.id]);
              }
            }
            break;
          case 'remove':
            for (let id of res.data) {
              this.removeFeature(id);
              delete this.features[id];
            }
            break;
          case 'visible':
            if (
              !(this.features instanceof Object) ||
              !Object.keys(this.features).length
            )
              this.generateFeatures();
            this.refresh();
            break;
          case 'refresh':
            this.removeAllFeatures();
            this.features = {};
            break;
        }
      },
    });
  }

  layerId: string = 'customers';
  features = {};

  createFeature(customer: Customers) {
    let feature: any = {};
    let style = this.createStyle(customer);
    this.mapService.action((mapModel: MapModel) => {
      switch (customer.type) {
        case 'circle':
          feature = mapModel.createCircle(
            customer.vertices[0],
            this.recalculateMapRadius(mapModel.map, customer.radius),
            style
          );
          break;
        case 'polygon':
          feature = mapModel.createPolygon(customer.vertices, style);
          break;
        case 'line':
          feature = mapModel.createLine(customer.vertices, style);
          break;
      }
      feature.setId(customer.id);
      feature.set('label', customer.name);
      feature.set('maxResolutionForLabel', 1400);
    });
    return feature;
  }

  recalculateMapRadius(map, radius) {
    let view = map.getView();
    let projection = view.getProjection();
    let resolutionAtEquator = view.getResolution();
    let center = view.getCenter();
    let pointResolution = getPointResolution(
      projection,
      resolutionAtEquator,
      center
    );
    let resolutionFactor = resolutionAtEquator / pointResolution;
    radius = (radius / METERS_PER_UNIT.m) * resolutionFactor;

    return radius;
  }

  private createStyle(customer) {
    let textStyle = new Text({
      placement: 'point',
      font: 'bold 16px Arial',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    });
    return (feature, resolution) => {
      let text = '';
      if (feature) {
        text = feature.get('label');
        if (resolution > 1400) {
          text = '';
        }
        textStyle.setText(text);
      }
      let color = asArray(customer.color);
      color[3] = 0.3;
      let style = new Style({
        text: textStyle,
        fill: new Fill({
          color: color,
          fillOpacity: 0.3,
        } as any),
        stroke: new Stroke({
          color: color,
          width: customer.type == 'line' ? customer.radius : 0,
        }),
      });

      return [style];
    };
  }
}
