<div class="event-comments">
  <afaqy-modal
    (modalClose)="closeModal()"
    sizeClass="modal-lg"
    #modal="afaqyModal"
    [title]="title"
  >
    <div class="modalbody">
      <div class="row d-flex mb-3">
        <div secured="event_comment-add" class="col-12 col-lg-6">
          <h5 class="text-left">
            {{ 'notifications.comments.add_new_comment' | translate }}
          </h5>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row form-group">
              <div class="col-12">
                <label class="form-control-label">{{
                  'description' | translate
                }}</label>
              </div>
              <div class="col-12">
                <textarea
                  formControlName="description"
                  class="form-control"
                  name="description"
                  rows="2"
                  [class.has-danger]="
                    (form.controls['description'].dirty ||
                      form.controls['description'].touched) &&
                    form.controls['description'].errors
                  "
                ></textarea>
                <control-errors
                  [ctl]="form.controls['description']"
                ></control-errors>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-12">
                <label class="form-control-label">{{
                  'Status' | translate
                }}</label>
              </div>
              <div class="col-12">
                <select
                  formControlName="status"
                  class="form-control form-control-sm"
                  name="status"
                  [class.has-danger]="
                    (form.controls['status'].dirty ||
                      form.controls['status'].touched) &&
                    form.controls['status'].errors
                  "
                >
                  <option
                    *ngFor="let status of formOptions.statusList"
                    [value]="status.id"
                  >
                    {{ status.title }}
                  </option>
                </select>
                <control-errors
                  [ctl]="form.controls['status']"
                ></control-errors>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-12 d-flex flex-row-reverse">
                <button
                  class="btn btn-outline-success"
                  type="submit"
                  [disabled]="!form.valid"
                >
                  <i class="mdi mdi-plus" aria-hidden="true"></i>
                  {{ 'notifications.comments.add_new_comment' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-lg-6">
          <h5 class="text-left">
            {{ 'notifications.comments.event_details' | translate }}
          </h5>

          <table class="event-comment-details">
            <tr>
              <th>{{ 'notifications.comments.event_title' | translate }}</th>
              <th>:</th>
              <td>{{ event?.desc }}</td>
            </tr>
            <tr>
              <th>{{ 'notifications.comments.event_unit' | translate }}</th>
              <th>:</th>
              <td>{{ event?.unit_name }}</td>
            </tr>
            <tr>
              <th>{{ 'notifications.comments.event_date' | translate }}</th>
              <th>:</th>
              <!-- <td>{{ event?.pos.dts | afaqyDate:true:'':true }}</td> -->
              <td>{{ event?.pos.dts | date : 'dd/MM/yyyy' }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row d-flex mb-3">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <h5 class="text-left">
                {{ 'notifications.comments.comments_list' | translate }}
              </h5>
            </div>
          </div>
          <!-- List Comments  -->
          <div class="row">
            <div class="col-12">
              <table class="table table-bordered table-striped">
                <thead>
                  <th style="width: 45%">{{ 'description' | translate }}</th>
                  <th style="width: 20%">
                    {{ 'notifications.comments.event_status' | translate }}
                  </th>
                  <th style="width: 25%">{{ 'date' | translate }}</th>
                  <th style="width: 10%">{{ 'actions' | translate }}</th>
                </thead>
                <tbody>
                  <ng-container *ngFor="let info of data">
                    <tr>
                      <td class="comments">{{ info.description }}</td>
                      <td class="comments">
                        {{ info.events_comment_status.name }}
                      </td>
                      <td class="comments">
                        {{ info.date | afaqyDate : true : '' : true }}
                      </td>
                      <td class="comments text-center">
                        <a
                          class="pointer"
                          (click)="delete(info._id)"
                          afaqy-tooltip
                          [tooltipText]="'delete' | translate"
                        >
                          <i class="mdi mdi-delete"></i>
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <!-- List Comments  -->
        </div>
      </div>
    </div>
  </afaqy-modal>
</div>
