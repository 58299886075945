import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { UnitServiceItem } from '../models';

@Injectable()
export class UnitServicesService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  get limitForPageList() {
    return 0;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'unit_services';
  }

  get modelInstance() {
    return new UnitServiceItem();
  }

  prepareFormParams(params) {
    return params;
  }

  applyBeforeCreate(params) {
    return this.prepareFormParams(params);
  }

  applyBeforeUpdate(params) {
    return this.prepareFormParams(params);
  }
}
