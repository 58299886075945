import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { AppConfig } from '../../../common/classes';
import { RootService } from '../../../core/services/rootService';

@Component({
  selector: 'afaqy-custom-select',
  templateUrl: './afaqy-custom-select.component.html',
  styleUrls: ['./afaqy-custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AfaqyCustomSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfaqyCustomSelectComponent),
      multi: true,
    },
  ],
})
export class AfaqyCustomSelectComponent
  implements OnInit, OnDestroy, OnChanges, ControlValueAccessor
{
  alive: boolean = true;
  writing: boolean = false;
  objectsService: RootService;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  propagateChange: any = () => {};
  validateFn: any = () => {};
  @Input() set service(service: RootService) {
    this.objectsService = service;
    if (!AppConfig.isCMS) {
      this.objectsService.startAutoload();
    }
    this.ngOnInit();
  }
  @Input() dataList = [];
  @Input() predefinedList = false;
  @Input() multiple = false;
  @Input() isOpen: any;
  /* takes true and false and null */
  @Input() titleField = 'title';
  @Input() clear = false;
  @Input() selectFirst = false;
  @Input() isDisabled = false;
  @Input() ctl: FormControl = new FormControl();
  @Input() resetNewForm: boolean = false;
  itemsList: any[] = [];
  allItemsList: any[] = [];
  @Input() itemsIDsList: string[] = [];
  isItemsListUpdatable: boolean = false;
  @Input() selectedList: any;
  @Input() hideSelectAll: boolean = false;
  @Input() hasCustomFilter: boolean = false;
  @Input() placeholderText: string = '';
  constructor(
    private config: NgSelectConfig,
    private translate: TranslateService
  ) {
    this.setNotFoundText();
    this.translate.onLangChange.subscribe(() => {
      this.setNotFoundText();
    });
    this.ctl.valueChanges.subscribe((value) => {});
  }

  private setNotFoundText() {
    this.config.notFoundText = this.translate.instant(
      'custom_select.no_items_found'
    );
  }

  public selectAll() {
    this.selectedList = this.itemsList?.map((item) => item.id);
    this.pushValue(true);
  }

  public unselectAll() {
    this.selectedList = [];
    this.pushValue(true);
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    this.updateSourceCollection();
    if (!AppConfig.isCMS && this.objectsService) {
      this.objectsService.resources
        .pipe(takeWhile(() => this.alive))
        .subscribe((event) => {
          if (event['action'] == 'list') {
            this.updateSourceCollection();
          }
        });
    }
  }

  updateSourceCollection() {
    if (this.predefinedList) {
      if (!this.isItemsListUpdatable) {
        this.allItemsList = this.itemsList = this.dataList;
        this.applyCollectionStatus();
      }
    } else if (this.objectsService) {
      if (AppConfig.isCMS) {
        this.objectsService
          .getOptionsList(this.objectsService.routerPrefix(), '', false)
          .subscribe((response) => {
            if (!this.isItemsListUpdatable) {
              this.allItemsList = this.itemsList = response;
              this.applyCollectionStatus();
            }
          });
      } else {
        if (!this.isItemsListUpdatable) {
          this.allItemsList = this.itemsList =
            this.objectsService.listForSelect;
          this.applyCollectionStatus();
        }
      }
    }
  }

  applyCollectionStatus(force = false) {
    if (
      this.selectFirst &&
      (force || !this.selectedList) &&
      this.itemsList.length
    ) {
      this.selectedList = this.itemsList[0].id;
      setTimeout(() => {
        this.pushValue(force);
      }, 100);
    }
  }

  setMoreItems(items) {
    if (items.length > 2)
      return items
        .filter((d, i) => i > 1)
        ?.map((d) => d[this.titleField])
        .toString();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.itemsList = [];
    if (changes.resetNewForm && !changes.resetNewForm.firstChange) {
      this.ctl.setValue('');
    }
    if (changes.itemsIDsList) {
      if (this.allItemsList.length > 0) {
        this.isItemsListUpdatable = true;
      }
      const tempSelected: any = this.selectedList;
      this.selectedList = [];
      this.itemsList = [];
      if (
        changes.itemsIDsList.currentValue &&
        changes.itemsIDsList.currentValue.length > 0
      ) {
        this.itemsList = this.allItemsList.filter((item) =>
          changes.itemsIDsList.currentValue.includes(item.id)
        );
        if (this.itemsIDsList.includes(tempSelected))
          this.selectedList = tempSelected;
      }
    }
    if (!AppConfig.isCMS) {
      this.updateSourceCollection();
    }
  }
  onChange(e) {}

  pushValue(force = false) {
    if (!force && this.selectedList == null) {
      return;
    }
    if (!force && this.writing) {
      return;
    }
    let value = this.selectedList;
    if (value == null) {
      value = '';
    }
    this.propagateChange(value);
    this.valueChanged.next(value);
  }

  onClear() {
    this.pushValue(true);
  }

  onBlur() {
    this.ctl.markAsTouched();
  }

  writeValue(value: any) {
    if (typeof value !== 'undefined' && value != null) {
      this.writing = true;
      if (value == '') {
        value = null;
      }
      this.selectedList = value;
      this.writing = false;
      this.applyCollectionStatus();
    } else {
      this.writing = true;
      this.selectedList = null;
      this.writing = false;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  validate(c: FormControl) {
    return this.validateFn(c);
  }
  customFilter = (term: string, item: any) => {
    return (
      item.name.toLowerCase().indexOf(term) > -1 ||
      item.name.indexOf(term) > -1 ||
      item.imei.includes(term)
    );
  };
}
