import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import * as wjcCore from '@grapecity/wijmo';

import { Unit, UnitDriverBehavior } from 'app/modules/units/models';
import {
  UnitDriverBehaviorService,
  UnitService,
} from 'app/modules/units/services';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { AfaqyResponse } from '../../../../core/classes';
import { AfaqyAPIResponse } from '../../../../core/classes/afaqy-response';
import { ApiRequestService } from '../../../../core/services/api-request-service';
import { AuthService } from '../../../../core/services/auth.service';
import { Logger, Message } from './../../../../common/classes';

@Component({
  selector: 'unit-form-driver-behavior',
  templateUrl: './unit-form-driver-behavior.component.html',
  styleUrls: ['./unit-form-driver-behavior.component.scss'],
})
export class UnitFormDriverBehaviorComponent
  implements OnChanges, OnInit, OnDestroy
{
  showExport: boolean = false;
  alive: boolean = true;
  @Input() group: UntypedFormGroup;
  @Input() unitObject: Unit;
  @Input() refresh: any;
  @Input() resourceID: any;
  cid = 'units-unit_driver_behavior-';
  loading = false;
  filtersCols = ['name'];
  controller = 'unit_driver_behavior';
  itemsList: wjcCore.CollectionView;

  showForm = false;
  updatingIndex = -1;
  selectedDriverBehavior: UnitDriverBehavior;
  message: Message;
  isView: boolean = false;
  editPermission: boolean = false;

  selectedItem: any;
  behaviorId: number;
  showClone: boolean;

  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    public service: UnitDriverBehaviorService,
    private unitService: UnitService,
    protected apiRequest: ApiRequestService
  ) {
    this.itemsList = new wjcCore.CollectionView([]);
    this.message = new Message();
  }

  import(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.loading = true;
      let file: File = fileList[0];
      let params = {
        updloadfiles: { file: { file: file, name: file.name } },
        unit_id: this.unitObject.id,
      };
      this.service
        .import(params)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (response: AfaqyResponse) => {
            let errorsList = response.data.errors;
            let messagesList = [];
            messagesList.push({
              message: 'success_count',
              params: { count: response.data.success_count },
            });
            messagesList.push({
              message: 'error_count',
              params: { count: response.data.error_count },
            });
            for (let row in errorsList) {
              let rowIndex = parseInt(row.replace('row-', '')) + 1;
              let rowErrors = [];
              for (let key in errorsList[row]) {
                let keyErrors = errorsList[row][key];
                for (var i = 0; i < keyErrors.length; i++) {
                  rowErrors.push({
                    message: 'serverValidations.' + keyErrors[i],
                    params: { field: key },
                  });
                }
              }
              messagesList.push({
                message: 'record',
                params: { index: rowIndex },
                childs: rowErrors,
              });
            }
            this.service.popup(messagesList);
            if (response.data.success_count) {
              this.service.autoLoadResources();
            }
            if (response.data.error_count == 0) {
              let url = this.service.getFunctionURL('lists');
              let params = {
                filters: { unit_id: this.unitObject.id },
              };
              this.doGetLists(url, params).subscribe({
                next: (response: AfaqyResponse) => {
                  let unit = this.unitService.getUnitDetails(
                    this.unitObject.id
                  );
                  unit.driver_behavior = response.list;
                  this.unitObject = unit;
                  this.loadItems();
                  this.loading = false;
                },
              });
            }
          },
          error: (error) => {
            let err = new AfaqyResponse();
            err.copyInto(JSON.parse(error));
            let errorsList = err.errors;
            let messagesList = [];
            for (let key in errorsList) {
              messagesList.push({
                message: 'serverValidations.' + errorsList[key],
                params: { field: key },
              });
            }
            /* If root service sends error of 504 */
            if (err.status_code == '504') {
              messagesList.push({
                message: 'serverValidations.failure_response',
                params: { field: '' },
              });
            }
            /* If root service sends error of 504 */
            this.service.popup(messagesList);
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          },
        });
    }
  }

  doExport(type) {
    this.showExport = false;
    this.loading = true;
    this.service
      .doExport(this.service.getFunctionURL('export'), {
        type: type,
        unit_id: this.unitObject.id,
      })
      .subscribe({
        next: () => {
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          let err = new AfaqyResponse();
          err.copyInto(JSON.parse(error));
          let errorsList = err.errors;
          let messagesList = [];
          for (let key in errorsList) {
            for (let index in errorsList[key]) {
              messagesList.push({
                message: 'serverValidations.' + errorsList[key][index],
                params: { field: key },
              });
            }
          }
          this.service.popup(messagesList);
          this.loading = false;
        },
      });
  }

  doGetLists(url, params) {
    params['simplify'] = 1;
    params['projection'] = [];
    return this.apiRequest.authPost(url, params).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        if (result.status_code == 200) {
          if (result.pagination) {
            reqres.paginate(result.pagination);
          }
          reqres.list = this.service.prepareList(result.data);
          this.service.applyAfterList(reqres.list);
        }
        this.service.fillResponseDetails(reqres, result);
        if (reqres.success) {
          return reqres;
        } else {
          throw new Error(JSON.stringify(reqres));
        }
      }),
      catchError((err) => {
        // Logger.error(err);
        return err;
      })
    );
  }

  ngOnInit() {
    this.loadItems();
    if (this.authService.checkPermissions(this.controller + '-edit', '')) {
      this.editPermission = true;
    }
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  ngOnChanges() {
    this.itemsList.refresh();
  }

  loadItems() {
    let items = this.loadSensorForAllBehavious(this.unitObject.driver_behavior);
    this.itemsList.sourceCollection = items;
  }

  loadSensorForAllBehavious(items) {
    for (let i in items) {
      let sensor = this.unitObject.sensors.find((obj) => {
        return obj.id == items[i]['sensor_id'];
      });

      if (sensor) {
        items[i]['sensor_name'] = sensor['name'];
      }
    }
    return items;
  }

  updateDriverBehavior(event) {
    if (this.updatingIndex != -1) {
      this.itemsList.sourceCollection[this.updatingIndex] = event.object;
      this.itemsList.refresh();
    } else {
      this.itemsList.sourceCollection = this.itemsList.sourceCollection.concat([
        event.object,
      ]);
    }
    this.unitObject.driver_behavior = this.itemsList.sourceCollection;
    this.showForm = false;
    this.selectedDriverBehavior = new UnitDriverBehavior();

    this.loadItems();
  }

  openForm() {
    this.showForm = true;
  }

  closeForm(event) {
    this.showForm = false;
    if (event && event['success'] && event['msg']) {
      this.message.dismissible = true;
      this.message.timeout = 3000;
      this.message.type = event['type'];
      this.message.message = event['msg'];
    }
  }

  refreshList() {
    this.loadItems();
  }

  addForm() {
    this.isView = false;
    this.updatingIndex = -1;
    this.selectedDriverBehavior = new UnitDriverBehavior();
    this.openForm();
  }

  edit(index, item) {
    this.isView = false;
    this.updatingIndex = index;
    this.selectedDriverBehavior = item;
    this.openForm();
  }

  view(index, item) {
    this.isView = true;
    this.updatingIndex = index;
    this.selectedDriverBehavior = item;
    this.openForm();
  }

  copy(item) {
    this.isView = false;
    this.updatingIndex = -1;
    this.selectedDriverBehavior = item.clone();
    this.selectedDriverBehavior.id = '';
    this.openForm();
  }

  updateDriverBehaviorList(item) {
    let driverBehavior = item;
    this.unitObject.driver_behavior = this.unitObject.driver_behavior.filter(
      (sens) => sens.id != driverBehavior.id
    );
    this.unitService.setResourceObject(this.unitObject);
  }

  delete(item) {
    if (this.unitObject.id) {
      const id = item['id'];
      let subscription = this.service.confirm('confirm-delete').subscribe({
        next: (response) => {
          if (response) {
            this.loading = true;
            this.service.delete(id).subscribe({
              next: (response) => {
                this.message.fill(
                  'notifications.unit_driver_behavior.deleted',
                  '',
                  'success'
                );
                this.itemsList.remove(item);
                this.updateDriverBehaviorList(item);
              },
              error: (error) => {
                this.message.fill('notifications.tryagain', '', 'error');
              },
              complete: () => {
                this.loading = false;
              },
            });
          }
          subscription.unsubscribe();
        },
      });
      return false;
    } else {
      this.itemsList.remove(item);
    }
  }

  clone(index, item) {
    this.updatingIndex = index;
    this.selectedItem = item;
    this.behaviorId = item.id;
    this.isView = false;
    this.openClone();
  }

  openClone() {
    this.showClone = true;
  }

  closeClone(event) {
    this.showClone = false;
    if (event && event['success'] && event['msg']) {
      this.message.dismissible = true;
      this.message.timeout = 3000;
      this.message.type = event['type'];
      this.message.message = event['msg'];
    }
  }
}
