/**
 * Created by negm on 11/29/17.
 */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AfaqyHelper } from 'app/common';
import { AppModel } from '../../common/classes/app-model';
import { MapService } from './map.service';
import { MapModel } from './mapModel';

@Injectable({ providedIn: 'root' })
export abstract class MapLayerService {
  constructor(
    protected mapService: MapService,
    protected service: any,
    protected translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe({
      next: () => {
        // setTimeout(() => {
        // this.refresh();
        // }, 1000);
      },
    });
    this.filterFeatures();
  }

  abstract layerId: any;
  abstract features: any;

  abstract createFeature(item: AppModel): any;

  refresh() {
    const visibleFeatures = [];
    const items: any[] = this.service.getActiveResourcesList();
    for (let item of items) {
      if (item.visible && this.features[item.id] instanceof Array) {
        visibleFeatures.push(...this.features[item.id]);
      } else if (item.visible) {
        visibleFeatures.push(this.features[item.id]);
      }
    }

    this.removeAllFeatures();
    if (visibleFeatures.length > 0) this.addFeatures(visibleFeatures);
  }

  getFeature(id: any) {
    return this.features[id];
  }

  generateFeatures(data?: AppModel[], isComplex: boolean = false): void {
    const items: any[] = data ? data : this.service.getActiveResourcesList();
    const features = [];
    for (const item of items) {
      const feature = this.createFeature(item);
      this.features[item.id] = feature;
      if (item['visible']) {
        features.push(this.features[item.id]);
      }
    }

    this.addFeatures(features);
  }
  addComplexFeatures(features, map_key = 'map_main') {
    // console.log(features, 'line 67');
  }
  addFeatures(features, map_key = 'map_main') {
    this.mapService?.action((mapModel: MapModel) => {
      if (features && features.length) {
        features = features.filter((f) => f);
      }
      mapModel?.addFeatures(features, this.layerId);
    }, map_key);
  }

  removeFeature(id, map_key = 'map_main') {
    this.mapService.action((mapModel: MapModel) => {
      mapModel.removeFeature(id, this.layerId);
    }, map_key);
  }

  removeAllFeatures(map_key = 'map_main') {
    this.mapService.action((mapModel: MapModel) => {
      mapModel.removeAllFeatures(this.layerId);
    }, map_key);
  }

  updateFeature(id, feature, map_key = 'map_main') {
    this.mapService.action((mapModel: MapModel) => {
      mapModel?.removeFeature(id, this.layerId);
      mapModel.addFeatures(feature, this.layerId);
    }, map_key);
  }

  updateFeatures(ids, features, map_key = 'map_main') {
    this.mapService.action((mapModel: MapModel) => {
      mapModel?.removeFeatures(ids, this.layerId);
      mapModel.addFeatures(features, this.layerId);
    }, map_key);
  }

  filterFeatures(): void {
    this.service.filterResourcesApplied.subscribe({
      next: () => {
        const ids: string[] = AfaqyHelper.getListIds(
          this.service.filteredResourcesList
        );

        const filteredItems: any = {};
        this.service.filteredResourcesList.map((item: any) => {
          filteredItems[item.id] = item;
        });

        const filteredFeatures = Object.keys(this.features)
          .filter((key: string) => ids.includes(key))
          .reduce((obj: {}, key: string) => {
            obj[key] = this.features[key];
            return obj;
          }, {});

        this.removeAllFeatures();
        for (let key in filteredFeatures) {
          if (filteredItems[key].visible)
            this.addFeatures(filteredFeatures[key]);
        }
      },
    });
  }
}
