import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  styleUrls: ['./redirect.component.scss'],
  templateUrl: 'redirect.component.html',
})
export class RedirectComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor() {}
  timer = 5;
  ngOnInit(): void {
    if (window.location.origin.includes('afaqy.sa')) {
      let downloadTimer = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(downloadTimer);
          let domain = window.location.origin;
          domain = domain.replace('sa', 'pro');
          window.location.href = domain;
        } else {
          this.timer -= 1;
        }
      }, 1000);
    } else {
      window.location.href = '/auth/login';
    }
  }
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
}
