import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { AfaqyHelper } from 'app/common';
import { takeWhile } from 'rxjs/operators';
import { ColorRangeObject } from './color-range';
import { ColorRangesBarService } from './color-ranges-bar/color-ranges-bar.service';
import { ColorRangesFormService } from './color-ranges-form/color-ranges-form.service';

@Component({
  selector: 'color-ranges',
  templateUrl: './color-ranges.component.html',
  styleUrls: ['./color-ranges.component.scss'],
})
export class ColorRangesComponent implements OnInit, OnChanges, OnDestroy {
  alive: boolean = true;
  @Input() colorRangesControls: UntypedFormArray;
  @Input() defaultColorRanges: ColorRangeObject[] = [];
  @Input() min: number; // Minimum value
  @Input() max: number; // Maximum value
  private isEdit: boolean;
  private defaultMin: number;
  private defaultMax: number;

  constructor(
    public colorRangesBarService: ColorRangesBarService,
    public colorRangesFormService: ColorRangesFormService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.onSubmit();
    this.onReset();
  }

  ngOnChanges(changes: SimpleChanges) {
    // If `min` value has been changed as a first change.
    if (changes.min && changes.min.isFirstChange()) {
      this.defaultMin = changes.min.currentValue;
    }

    // If `max` value has been changed as a first change.
    if (changes.max && changes.max.isFirstChange()) {
      this.defaultMax = changes.max.currentValue;
    }

    // If `defaultColorRanges` value has been changed.
    if (changes.defaultColorRanges) this.onReceiveDefaultColorRanges();

    // If `min` or `max` value has been changed as a non-first change.
    if (
      (changes.min && !changes.min.isFirstChange()) ||
      (changes.max && !changes.max.isFirstChange())
    ) {
      this.updateDefaultColorRanges();
    }
  }

  setFormVisibility(): void {
    this.colorRangesFormService.openSensorForm();
  }

  resetColorRange(): void {
    if (this.isEdit && this.sameDefaultMinMax()) this.setDefaultColorRanges();
    else this.updateDefaultColorRanges();
    this.submitColorRanges();
  }

  /** Check if the current `min` and `max` is the same as the `defaultMin` and `defaultMax` values and return a boolean value. */
  private sameDefaultMinMax(): boolean {
    const isDefaultMinNumber: boolean = AfaqyHelper.isNumber(this.defaultMin);
    const isDefaultMaxNumber: boolean = AfaqyHelper.isNumber(this.defaultMax);
    const isCurrentMinNumber: boolean = AfaqyHelper.isNumber(this.min);
    const isCurrentMaxNumber: boolean = AfaqyHelper.isNumber(this.max);

    if (
      (!isDefaultMinNumber &&
        !isDefaultMaxNumber &&
        !isCurrentMinNumber &&
        !isCurrentMaxNumber) ||
      (isDefaultMinNumber &&
        !isDefaultMaxNumber &&
        isCurrentMinNumber &&
        !isCurrentMaxNumber &&
        this.min === this.defaultMin) ||
      (!isDefaultMinNumber &&
        isDefaultMaxNumber &&
        !isCurrentMinNumber &&
        isCurrentMaxNumber &&
        this.max === this.defaultMax) ||
      (isDefaultMinNumber &&
        isDefaultMaxNumber &&
        isCurrentMinNumber &&
        isCurrentMaxNumber &&
        this.min === this.defaultMin &&
        this.max === this.defaultMax)
    ) {
      return true;
    }
    return false;
  }

  /** Send `defaultColorRanges` to colorRanges BehaviorSubject. */
  private setDefaultColorRanges(): void {
    this.colorRangesBarService.updateColorRanges(this.defaultColorRanges);
  }

  /** Send `default color ranges` with current `min` and `max` values to colorRanges BehaviorSubject and call `submitColorRanges` fn. */
  private updateDefaultColorRanges(): void {
    const defaultColorRanges: ColorRangeObject[] =
      this.colorRangesBarService.getDefaultColorRanges(this.min, this.max);
    this.colorRangesBarService.updateColorRanges(defaultColorRanges);
    this.submitColorRanges();
  }

  /**
   * If the received `defaultColorRanges` input has no value, `override` its value.
   * Else, calls `onEditColorRangesCase` fn.
   * Send the `defaultColorRanges` value to colorRanges BehaviorSubject.
   * Calls `submitColorRanges` function.
   */
  private onReceiveDefaultColorRanges(): void {
    if (!this.defaultColorRanges || this.defaultColorRanges.length === 0)
      this.overrideDefaultColorRanges();
    else this.onEditColorRangesCase();

    this.colorRangesBarService.updateColorRanges(this.defaultColorRanges);
    this.submitColorRanges();
  }

  /** Override `defaultColorRanges` with default `min` and `max` values. */
  private overrideDefaultColorRanges(): void {
    this.defaultColorRanges = this.colorRangesBarService.getDefaultColorRanges(
      this.defaultMin,
      this.defaultMax
    );
  }

  /** Sets a true value to `isEdit` variable and calls `overrideLastDefaultColorRange` fn. */
  private onEditColorRangesCase(): void {
    this.isEdit = true;
    this.overrideLastDefaultColorRange();
  }

  /** Override `last default color range` with `defaultMax` value, if has no value or small than the `defaultMax`. */
  private overrideLastDefaultColorRange(): void {
    if (!this.defaultColorRanges || !this.defaultColorRanges.length) return;
    const lastDefaultColorRange: ColorRangeObject =
      this.defaultColorRanges[this.defaultColorRanges.length - 1];
    if (!lastDefaultColorRange.to || lastDefaultColorRange.to < this.defaultMax)
      lastDefaultColorRange.to = this.defaultMax;
  }

  /** Subscribe to isFormSubmitted and when it emit a value, calling submitColorRanges fn. */
  private onSubmit(): void {
    this.colorRangesFormService.isFormSubmitted
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: () => {
          this.submitColorRanges();
        },
      });
  }

  /** Set colors ranges form array value depends on colorRangesObjects. */
  private submitColorRanges(): void {
    if (
      !this.colorRangesBarService.colorRangesObjects ||
      !this.colorRangesBarService.colorRangesObjects.length
    ) {
      return;
    }

    let colorRangeFormGroup: UntypedFormGroup;
    this.colorRangesControls.clear();

    this.colorRangesBarService.colorRangesObjects.map((obj: any) => {
      colorRangeFormGroup = this.fb.group({ from: [], to: [], color: [] });
      this.colorRangesControls.push(colorRangeFormGroup);
      for (let key in colorRangeFormGroup.controls) {
        colorRangeFormGroup.controls[key].setValue(obj[key]);
      }
    });
  }

  /** Subscribe to doReset and reset sensor colors when the observable emits a value. */
  private onReset(): void {
    this.colorRangesBarService.doReset
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: () => {
          this.resetColorRange();
        },
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
