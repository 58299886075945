import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import * as wjcCore from '@grapecity/wijmo';

import { AuthService } from '../../../../core/services';
import { Message } from './../../../../common/classes';

import { Unit, UnitServiceItem } from 'app/modules/units/models';
import { UnitService, UnitServicesService } from 'app/modules/units/services';

@Component({
  selector: 'unit-form-services',
  templateUrl: './unit-form-services.component.html',
  styleUrls: ['./unit-form-services.component.scss'],
})
export class UnitFormServicesComponent implements OnChanges, OnInit, OnDestroy {
  alive: boolean = true;
  @Input() group: UntypedFormGroup;
  @Input() unitObject: Unit;
  @Input() refresh: any;
  @Input() resourceID: any;
  @Input() isViewFromParent;
  cid = 'units-service-';
  loading = false;
  filtersCols = ['name'];
  controller = 'unit_services';
  itemsList: wjcCore.CollectionView;

  showForm = false;
  showClone = false;
  updatingIndex = -1;
  selectedService: UnitServiceItem;
  message: Message;
  serviceId: string;
  isView: boolean = false;
  editPermission: boolean = false;
  @Input() isAdd?: boolean; // used to disable the 'add new service' button in case of adding a new unit

  constructor(
    public route: ActivatedRoute,
    public service: UnitServicesService,
    public authService: AuthService,
    private unitService: UnitService,
    protected translate: TranslateService
  ) {
    this.itemsList = new wjcCore.CollectionView([]);
    this.message = new Message();
  }

  ngOnInit() {
    this.loadItems();
    if (this.authService.checkPermissions(this.controller + '-edit', '')) {
      this.editPermission = true;
    }
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  ngOnChanges() {
    this.itemsList.refresh();
  }
  notify(msg, title) {
    const transMsg = this.translate.instant(msg);
    const transTitle = this.translate.instant(title);
    this.service.pushNotification(transMsg, transTitle);
  }

  loadItems() {
    const servicesList = this.unitObject.services;
    servicesList.map((service) => {
      const serviceStatus = this.unitService.calcServiceStatus(
        service,
        this.unitObject.counters.odometer,
        this.unitObject.counters.engine_hours
      );
      service.status = serviceStatus['text'];
      return service;
    });
    this.itemsList.sourceCollection = servicesList;
  }

  updateServices(event) {
    if (this.updatingIndex != -1) {
      this.itemsList.sourceCollection[this.updatingIndex] = event.object;
      this.itemsList.refresh();
    } else {
      this.itemsList.sourceCollection = this.itemsList.sourceCollection.concat([
        event.object,
      ]);
    }
    this.unitObject.services = this.itemsList.sourceCollection;
    this.showForm = false;
    this.showClone = false;
    this.selectedService = new UnitServiceItem();

    this.loadItems();
  }

  openForm() {
    this.showForm = true;
  }

  closeForm(event) {
    this.showForm = false;
    if (event && event['success'] && event['msg']) {
      this.message.dismissible = true;
      this.message.timeout = 3000;
      this.message.type = event['type'];
      this.message.message = event['msg'];
    }
  }

  refreshList() {
    this.loadItems();
  }

  addForm() {
    this.isView = false;
    this.updatingIndex = -1;
    this.selectedService = new UnitServiceItem();
    this.openForm();
  }

  edit(index, item) {
    this.isView = false;
    this.updatingIndex = index;
    this.selectedService = item;
    this.openForm();
  }

  view(index, item) {
    this.isView = true;
    this.updatingIndex = index;
    this.selectedService = item;
    this.openForm();
  }

  copy(item) {
    this.isView = false;
    this.updatingIndex = -1;
    this.selectedService = item.clone();
    this.selectedService.id = '';
    this.openForm();
  }

  updateServicesList(item) {
    let service = item;
    this.unitObject.services = this.unitObject.services.filter(
      (sens) => sens.id != service.id
    );
    this.unitService.setResourceObject(this.unitObject);
  }

  deleteItem(item) {
    if (this.unitObject.id) {
      const id = item['id'];
      let subscription = this.service.confirm('confirm-delete').subscribe({
        next: (response) => {
          if (response) {
            this.loading = true;
            this.service.delete(id).subscribe({
              next: () => {
                /*this.message.fill('notifications.unit_services.deleted', "", "success");*/

                this.itemsList.remove(item);
                this.updateServicesList(item);
                this.notify(
                  'notifications.unit_services.deleted',
                  'units.services'
                );
              },
              error: () => {
                this.notify('notifications.tryagain', 'units.services');
                /*this.message.fill('notifications.tryagain', "", 'error');*/
              },
              complete: () => {
                this.loading = false;
              },
            });
          }
          subscription.unsubscribe();
        },
      });
      return false;
    } else {
      this.itemsList.remove(item);
    }
  }

  openClone() {
    this.showClone = true;
  }

  closeClone(event) {
    this.showClone = false;
    if (event && event['success'] && event['msg']) {
      this.message.dismissible = true;
      this.message.timeout = 3000;
      this.message.type = event['type'];
      this.message.message = event['msg'];
    }
  }

  clone(index, item) {
    this.updatingIndex = index;
    this.selectedService = item;
    this.serviceId = item.id;
    this.isView = false;
    this.openClone();
  }
}
