import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { AfaqyHelper } from 'app/common';
import { takeWhile } from 'rxjs/operators';
import { ColorRangeObject } from '../color-range';
import { ColorRangesFormService } from '../color-ranges-form/color-ranges-form.service';
import { ColorRangesBarService } from './color-ranges-bar.service';

@Component({
  selector: 'color-ranges-bar',
  templateUrl: './color-ranges-bar.component.html',
  styleUrls: ['./color-ranges-bar.component.scss'],
})
export class ColorRangesBarComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  alive: boolean = true;
  intervals: ColorRangeObject[];
  @Input() min: number; // Minimum value
  @Input() max: number; // Maximum value
  @ViewChildren('intervalFrom') intervalFromElements: QueryList<ElementRef>;

  constructor(
    private colorRangesBarService: ColorRangesBarService,
    private colorRangesFormService: ColorRangesFormService
  ) {}

  ngOnInit(): void {
    this.setIntervals();
  }

  ngOnChanges(changes: SimpleChanges) {
    // If `min` value has been changed, set its value to `colorRangesBarService.min`.
    if (changes.min) this.colorRangesBarService.min = changes.min.currentValue;

    // If `max` value has been changed, set its value to `colorRangesBarService.max`.
    if (changes.max) this.colorRangesBarService.max = changes.max.currentValue;
  }

  ngAfterViewInit(): void {
    this.setSpanMargin();
  }

  isNumber(value: any): boolean {
    return AfaqyHelper.isNumber(value);
  }

  /** Check if the `color range` has the `same valid number values` and `not equal` the `min` and the `max` values, return a boolean value. */
  hasValidSameColorRange(interval: ColorRangeObject): boolean {
    if (
      this.hasSameValues(interval.from, interval.to) &&
      (!this.hasSameValues(interval.from, this.min) ||
        !this.hasSameValues(interval.to, this.max))
    )
      return true;
    return false;
  }

  /** Check if the two parameters have the same valid number values, return a boolean value. */
  private hasSameValues(firstValue: any, secondValue: any): boolean {
    if (parseFloat(firstValue) !== parseFloat(secondValue)) return false;
    return true;
  }

  /**
   * Close sensor color form if it's opened and open it with the param value.
   * @param value UnitSensorColor.
   */
  editInterval(value?: any): void {
    if (this.colorRangesFormService.isOpen) {
      this.colorRangesFormService.closeSensorForm();
    }
    this.colorRangesFormService.openSensorForm(value);
  }

  /** Subscribe to intervalColors and set its value to intervals variables. */
  private setIntervals(): void {
    this.colorRangesBarService.colorRanges
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (value: ColorRangeObject[]) => {
          if (!value) return;
          this.intervals = [...value];
          setTimeout(() => {
            this.setSpanMargin();
          });
        },
      });
  }

  /** Set left-margin for every analog interval 'from span' element depends on its width. */
  private setSpanMargin(): void {
    if (!this.intervalFromElements) {
      return;
    }

    let span: HTMLElement;
    this.intervalFromElements.forEach((el: ElementRef) => {
      span = <HTMLElement>el.nativeElement;
      span.style.marginLeft = `-${span.clientWidth / 2}px`;
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
