import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AfaqyPerPageService {
  set limit(value: number) {
    this._limit = value;
  }

  get limit(): number {
    return this._limit;
  }

  private _limit: number;

  private perpage = new Subject<any>();

  constructor() {}

  change(number) {
    this.limit = number;
    this.perpage.next(number);
  }

  getLimit(): Observable<any> {
    return this.perpage.asObservable();
  }
}
