import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Unit } from 'app/modules/units/models';
import { ApiRequestService } from '../../../../core/services';
import { UnitService } from '../../../../modules/units/services';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'unit-driver-behavior-clone-form',
  templateUrl: './unit-form-driver-behavior-clone-form.component.html',
  styleUrls: ['./unit-form-driver-behavior-clone-form.component.scss'],
})
export class UnitFormDriverBehaviorCloneFormComponent {
  public myGroup: UntypedFormGroup;
  posting: boolean;
  public unitService: UnitService;
  @Input() behaviorId: string;
  unitsList = []
  alive = true
  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    unitService: UnitService,
    private ApiRequestService: ApiRequestService
  ) {
    this.unitService = unitService;
    this.myGroup = new UntypedFormGroup({
      unitsList: new UntypedFormControl(),
    });
    this.unitService.finishedLoading
    .pipe(takeWhile(() => this.alive))
    .subscribe((finishedLoading) => {
      if (finishedLoading) {
        this.unitsList = this.unitService.resourcesList.filter(
          (u) => u.device_type === 'gps'
        );
      }
    });
  }

  get fields(): UntypedFormArray {
    return this.myGroup.get('unitsList') as UntypedFormArray;
  }

  get form_fields(): any {
    return {
      unitsList: [Validators.required],
    };
  }

  modalClose($event: any) {
    this.closeForm.next($event);
  }

  onSubmit() {
    const payload = {
      units: this.fields.value,
      behavior_id: this.behaviorId,
    };

    this.ApiRequestService.authPost(
      'unit_driver_behavior/clone_behavior',
      payload
    ).subscribe({
      next: (res) => {
        if (res['status_code'] === 200) {
          this.modalClose({
            success: true,
            type: 'success',
            msg: 'driver_behavior.behavior_is_cloned',
          });
        } else {
          this.unitService.alert('driver_behavior.behavior_is_duplicated');
        }
      },
    });
  }

  mapItems = function (item: Unit) {};
}
