import { AfaqyObject } from '../../../common/classes';
export class UnitTripDetector extends AfaqyObject {
  private _move: string = 'acc+gps';
  private _min_speed: number = 6;
  private _min_stop: number = 60;
  private _min_park: number = 300;
  private _min_sat: number = 4;
  private _max_distance: number = 1000;
  private _min_trip_time: number = 60;
  private _min_trip_distance: number = 100;

  protected copyFields = [
    'move',
    'min_speed',
    'min_stop',
    'min_park',
    'min_sat',
    'max_distance',
    'min_trip_time',
    'min_trip_distance',
  ];

  get min_speed(): number {
    return this._min_speed;
  }

  set min_speed(value: number) {
    this._min_speed = value;
  }

  get min_stop(): number {
    return this._min_stop;
  }

  set min_stop(value: number) {
    this._min_stop = value;
  }

  get min_park(): number {
    return this._min_park;
  }

  set min_park(value: number) {
    this._min_park = value;
  }

  get min_sat(): number {
    return this._min_sat;
  }

  set min_sat(value: number) {
    this._min_sat = value;
  }

  get max_distance(): number {
    return this._max_distance;
  }

  set max_distance(value: number) {
    this._max_distance = value;
  }

  get min_trip_time(): number {
    return this._min_trip_time;
  }

  set min_trip_time(value: number) {
    this._min_trip_time = value;
  }

  get move(): string {
    return this._move;
  }

  set move(value: string) {
    if (!value) {
      value = 'acc+gps';
    }
    this._move = value;
  }

  get min_trip_distance(): number {
    return this._min_trip_distance;
  }

  set min_trip_distance(value: number) {
    this._min_trip_distance = value;
  }
}
