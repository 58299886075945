import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, fromEvent } from 'rxjs';
import { filter, take, takeWhile, tap } from 'rxjs/operators';
import { AfaqyHelper, AppConfig, Logger } from '../../../common/classes';
import { ApiRequestService, AuthService } from '../../services';
import { NotificationsService } from 'angular2-notifications';

@Component({
  moduleId: module.id,
  selector: 'app-root',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  loading = true;
  private alive: boolean = true;
  curLangCode: string;

  /* responsive boolean for a toggleable responsive features */
  responsive: boolean = true;

  svgIcons = [
    'humidity',
    'temperature',
    'main_truck',
    'backup_truck',
    'eye',
    'back-icon',
    '240 L',
    '2 Yard',
    '4 Yard',
    '6 Yard',
    '20 Yard',
    '30 Yard',
    'collapse-icon',
    'arrow-left',
  ];

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private service: ApiRequestService,
    private router: Router,
    private title: Title,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private notificationsService: NotificationsService
  ) {
    this.updateLogger(AppConfig['debug']);
    // this.loadSettings();
  }

  loadSettings() {
    if (AppConfig.settingsLoaded) {
      this.curLangCode = this.translate.currentLang;
      this.updateTitle();
      this.updateTheme();
    }
  }

  loadSVGIcons() {
    this.svgIcons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `../../../../assets/icons/${icon}.svg`
        )
      );
    });
  }

  ngOnInit() {
    this.loadSVGIcons();
    window['Logger'] = {
      debug: (status) => this.updateLogger(status),
    };
    window['Helper'] = AfaqyHelper;
    this.router.events.pipe(takeWhile(() => this.alive)).subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.updateTitle();
        }
      },
    });
    this.translate.use(AppConfig.defaultLang); // Set default language on app initialization
    this.translate.onLangChange.pipe(takeWhile(() => this.alive)).subscribe({
      next: (item) => {
        this.updateTitle();
        this.updateTheme(item.lang);
        if (this.authService.loadedSession.getValue()) {
          this.authService.updatePreferences('site', 'lang', item.lang);
        }
        this.translate.use(item.lang.replace('-ltr', '').replace('-rtl', ''));
      },
    });
    this.authService.passwordExpired
      .pipe(takeWhile(() => this.alive))
      .pipe(filter((data) => data?.expired))
      .pipe(
        filter(
          (data) =>
            !this.router.url.includes('auth/expiry') &&
            !this.router.url.includes('auth/login')
        )
      )
      .subscribe((data) => {
        // console.log(expired);
        this.notificationsService.error(
          this.translate.instant('common.password_expired')
        ); // Replace with your warning display logic
        this.router.navigate(['/auth/expiry/' + data?.username]).then(() => {
          // console.log('navigated');
        });
      });
  }

  updateLogger(status: boolean) {
    // Logger.debug = status;
  }

  updateColor() {
    AfaqyHelper.updateThemeBodyClass(this.authService.colorDetails);
  }

  updateTheme(lang?: string) {
    const langCode = lang || this.translate.currentLang;
    const body = document.getElementsByTagName('body')[0];
    // set css direction Class
    let lngType = 'cssltr';
    if (AppConfig.cssrtl.langs.includes(langCode)) {
      // lngType = "cssrtl";
    }
    body.classList.remove('cssrtl', 'cssltr');
    body.classList.add(lngType);
    for (const cssId in AppConfig[lngType].styles) {
      AfaqyHelper.loadCssFile(cssId, AppConfig[lngType].styles[cssId]);
    }

    // set lang class
    body.classList.remove('lng-' + this.curLangCode);
    body.classList.add('lng-' + langCode);
    this.curLangCode = langCode;

    //update html & toastyNotifications direction
    const html = document.getElementsByTagName('html')[0];
    html.setAttribute('dir', lngType.replace('css', ''));
    const t = JSON.parse(JSON.stringify(AppConfig.toastyNotifications));
    if (lngType === 'cssrtl') {
      t.position = ['top', 'left'];
      t.rtl = true;
      html.setAttribute('dir', 'rtl');
    } else {
      t.position = ['top', 'right'];
      t.rtl = false;
      html.setAttribute('dir', 'ltr');
    }
    AppConfig.toastyNotifications = t;

    /* adding responsive class for a toggleable responsive feature */
    if ((this.responsive = true)) {
      body.classList.add('responsive');
    }
  }

  updateTitle(src = '') {
    if (AppConfig.title) {
      let titleArray = this.recursivelyGenerateTitle(
        this.router.routerState.snapshot.root
      );
      titleArray.unshift(AppConfig.title + ' ' + AppConfig.version);
      this.title.setTitle(titleArray.join(' - '));
    }
  }

  recursivelyGenerateTitle(snapshot: ActivatedRouteSnapshot) {
    var titleParts = <string[]>[];
    if (snapshot) {
      if (snapshot.firstChild) {
        titleParts = titleParts.concat(
          this.recursivelyGenerateTitle(snapshot.firstChild)
        );
      }
      if (snapshot.data['title']) {
        if (
          this.router.url.split('?')[0] == '/monitoring' ||
          this.router.url.split('?')[0] == '/'
        ) {
          snapshot.data['title'] = 'headings.monitoring';
        }
        this.translate
          .get(snapshot.data['title'])
          .pipe(takeWhile(() => this.alive))
          .subscribe({
            next: (res: string) => {
              titleParts.push(res);
            },
          });
      }
    }
    return titleParts;
  }

  public ngOnDestroy() {
    this.alive = false;
  }
}
