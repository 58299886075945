import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { InventoryRoomsService } from 'app/modules/inventory-rooms/services/inventory-rooms.service';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { Warehouses } from '../models/warehouses';

@Injectable({ providedIn: 'root' })
export class WarehousesService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    protected translate: TranslateService,
    protected inventoryRoomService: InventoryRoomsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.inventoryRoomService.resources.subscribe((updates) => {
      this.updateObjectsRelations({ data: this.resourcesList, action: 'list' });
    });

    this.resources.subscribe((updates) => {
      if (this.resourcesList.length) this.updateGroupedResources(updates);
    });

    this.inventoryRoomService.resources.subscribe((updates) => {
      this.updateGroupedResources(updates);
    });
  }

  setResourceObject(obj) {
    const vids = this.authService.preferences('warehouses', 'visible');
    if (vids?.includes(obj?.id)) {
      obj['visible'] = 1;
    } else {
      obj['visible'] = 0;
    }
    super.setResourceObject(obj);
  }

  get zoneTypes() {
    return Warehouses.zoneTypes;
  }

  get isPersonal() {
    return Warehouses.isPersonal;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'warehouses';
  }

  get modelInstance() {
    return new Warehouses(this.translate);
  }

  get listingIcon() {
    return 'mdi mdi-store';
  }
  updateObjectsRelations(updates: any) {
    if (updates['action'] === 'list' || updates['action'] === 'addMulti') {
      for (const data of updates['data']) {
        const result = this.countAssets(data);
        // this.setResourceObject(result);
        this.resources.next({ update: 'list', data: result });
      }
    } else if (updates['action'] === 'update' || updates['action'] === 'add') {
      const data = updates['data'];
      const result = Array.isArray(data)
        ? this.countAssets(data)
        : this.countAssets([data]);
      this.setResourcesList(result);
    }
  }

  private countAssets(data: any) {
    let buildings = 0,
      floors = 0,
      rooms = 0;
    if (
      data['buildings'] &&
      Array.isArray(data['buildings']) &&
      data['buildings'].length
    ) {
      data['buildings'].map((building) => {
        buildings++;
        if (building.floors) {
          building.floors.map((floor) => {
            floors++;
            this.inventoryRoomService.resourcesList.map((room) => {
              if (room.floor === floor.id) {
                rooms++;
              }
            });
          });
        }
      });
    }

    data['buildings_no'] = buildings;
    data['floors_no'] = floors;
    data['rooms_no'] = rooms;

    return data;
  }
  updateGroupedResources(updates: any) {
    // if(updates.action == "list"){
    let rList = [];
    this.inventoryRoomService.resourcesList.map((item) => {
      item.groupsList = [item.warehouse];
      if (item.groupsList.length) {
        item.groupsList.forEach((groupid) => {
          let newitem = item.clone();
          newitem['group_id'] = groupid;
          newitem['group_name'] = this.getItemFromResources(groupid).name;
          rList.push(newitem);
        });
      } else {
        let newitem = item.clone();
        newitem['group_id'] = 0;
        newitem['group_name'] = 'Un-grouped';
        rList.push(newitem);
      }
    });
    this.inventoryRoomService.groupedResources.next(rList);

    // }
  }
}
