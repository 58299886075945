<div class="bg-cover">
  <!--    <ng-lottie [options]="lottieConfig" (animationCreated)="handleAnimation($event)"></ng-lottie>-->
</div>
<div id="particles-js"></div>
<div class="loginContainer" *ngIf="loadedSettings">
  <ngx-loading-bar color="#1b325f"></ngx-loading-bar>
  <div class="container">
    <div class="tile">
      <div class="row">
        <div class="col-12">
          <div
            class="brandContainer  {{ configs.loginClass }}"
            [style.background]="configs.login_logo_bgColor"
          >
            <img
              class="img-fluid brand-image"
              src="{{ configs.loginlogo }}"
              alt="{{ configs.title }}"
            />
          </div>
        </div>
      </div>

      <div class="tile-body">
        <div class="row">
          <div class="col-12">
            <flash-message [message]="message"></flash-message>
          </div>
        </div>
        <form
          id="form"
          action="#"
          name="myForm"
          id="form-validation"
          method="post"
          (ngSubmit)="f.form.valid && reset()"
          #f="ngForm"
        >
          <div
            class="row mt-5"
            [ngClass]="{
              danger:
                (user_name.errors?.required || code_invalid) &&
                (code.dirty || code.touched)
            }"
          >
            <div class="col-12">
              <label class="form-input">
                <i class="mdi mdi-email-lock"></i>
                <input
                  type="text"
                  id="code"
                  name="code"
                  [(ngModel)]="model.code"
                  #code="ngModel"
                  required
                />
                <span class="underline"></span>
                <span class="label">{{ 'login.code' | translate }} </span>
              </label>
            </div>
          </div>
          <div
            class="row"
            [ngClass]="{
              danger:
                (user_name.errors?.required || user_name_invalid) &&
                (user_name.dirty || user_name.touched)
            }"
          >
            <div class="col-12">
              <label class="form-input">
                <i class="mdi mdi-account"></i>
                <input
                  type="text"
                  id="username"
                  name="username"
                  [(ngModel)]="model.user_name"
                  #user_name="ngModel"
                  required
                />
                <span class="underline"></span>
                <span class="label">{{ 'login.user_name' | translate }}</span>
              </label>
            </div>
          </div>
          <div
            class="row"
            [ngClass]="{
              danger:
                (password.errors?.required || password_invalid) &&
                (password.dirty || password.touched)
            }"
          >
            <div class="col-12">
              <label class="form-input">
                <i class="mdi mdi-account"></i>
                <input
                  type="text"
                  id="password"
                  name="password"
                  [(ngModel)]="model.password"
                  #password="ngModel"
                  required
                />
                <span class="underline"></span>
                <span class="label">{{ 'login.password' | translate }}</span>
              </label>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <button
                [disabled]="loading || !f.form.valid"
                type="submit"
                class="btn btn-block btn-success float-right"
              >
                {{ 'login.reset_button' | translate }}
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 text-right">
              <a href="/login">{{ 'login.backtologin' | translate }}</a>
            </div>
          </div>
          <div class="fixed-lang">
            <system-langs></system-langs>
          </div>
        </form>

        <div
          *ngIf="configs.androidAppURL || configs.iosAppURL"
          class="appSection p-relative row pt-5 my-5 flex-column align-items-center"
        >
          <h6 class="mb-2">
            <strong>{{ 'login.app_title' | translate }}</strong>
          </h6>
          <p>
            <strong>{{ 'login.app_message' | translate }}</strong>
          </p>
          <div class="justify-content-center d-flex flex-nowrap">
            <a
              target="_blank"
              *ngIf="configs.androidAppURL"
              class="hvr-float-shadow"
              href="{{ configs.androidAppURL }}"
              ><img
                class="mx-3"
                src="./assets/images/google.png"
                width="120"
                height="40"
                alt="Download App"
            /></a>
            <a
              target="_blank"
              *ngIf="configs.iosAppURL"
              class="hvr-float-shadow"
              href="{{ configs.iosAppURL }}"
              ><img
                class="mx-3"
                src="./assets/images/app_store.png"
                width="120"
                height="40"
                alt="Download App"
            /></a>
          </div>
        </div>
      </div>
      <div class="w-100 text-center text-white copyrights">
        {{ configs.copyrights }}
      </div>
    </div>
  </div>
</div>
