import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import { AppConfig } from '../../../common/classes';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { UnitGroup } from '../models';

@Injectable({ providedIn: 'root' })
export class UnitGroupService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    // authService.loadedSession.subscribe((flag) => {
    //   if (flag) {
    //     if (!AppConfig.isCMS) {
    //       this.startAutoload();
    //     }
    //   }
    // });
  }

  get limitForPageList() {
    return 0;
  }

  updateObjectsRelations(updates) {
    if (updates['action'] == 'list' || updates['action'] == 'addMulti') {
      let updatesList = [];
      for (const group of updates['data']) {
        group.parent = this.getItemFromResources(group.parent_id);
        updatesList.push(group);
      }
      this.setResourcesList(updatesList);
    } else if (updates['action'] == 'update' || updates['action'] == 'add') {
      updates['data'].parent = this.getItemFromResources(
        updates['data'].parent_id
      );
      this.setResourcesList(updates['data']);
    }
  }

  setIds(params) {
    params['ids'] = params['unitsList'];
    delete params['unitsList'];
    if (params['ids'].length == 0) {
      delete params['ids'];
    }
    return params;
  }

  applyBeforeUpdate(params) {
    return this.setIds(params);
  }

  applyBeforeCreate(params) {
    return this.setIds(params);
  }

  getList() {
    let glist = [];
    for (const g of this.resourcesList) {
      // if (true || !g.parent_id) {
      glist.push({ id: g.id, name: g.name });
      // }
    }
    return glist;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'unit_groups';
  }

  get modelInstance() {
    return new UnitGroup();
  }

  gridColumns(trashed = false) {
    let allcols = [
      {
        header: 'multiple_select',
        minWidth: 28,
        width: 30,
        colValue: 'checkbox',
        active: true,
        immutable: false,
        default: true,
        type: 'checkbox',
        sorting: false,
        filters: false,
      },
      {
        header: 'name',
        minWidth: 100,
        width: '*',
        colValue: 'name',
        active: true,
        immutable: true,
        default: true,
      },
      {
        header: this.cid + '.units',
        minWidth: 100,
        width: '*',
        colValue: 'gic',
        active: false,
        default: true,
      },
      {
        header: 'unit_groups.parent',
        minWidth: 75,
        width: '*',
        colValue: 'parentName',
        active: false,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 60,
        width: 100,
        colValue: 'actions',
        active: false,
        default: true,
        extra: [
          {
            header: 'assign_user',
            permissions: this.cid + '-assign',
            code: 'assign',
            faclass: 'fa-link',
          },
          {
            header: 'unassign_user',
            permissions: this.cid + '-unassign',
            code: 'unassign',
            faclass: 'fa-unlink',
          },
        ],
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
      allcols.shift();
    }
    if (!trashed) {
      if (!this.authService.checkPermissions(this.cid + '-bulkDelete')) {
        allcols.shift();
      }
    }
    return allcols;
  }

  get listingIcon() {
    return 'mdi mdi-group';
  }
}
