import {
  OnInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  forwardRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { CellFormatter } from '../../../common/classes/cell-formatter';

@Component({
  selector: 'afaqy-time',
  templateUrl: './afaqy-time.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AfaqyTimeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfaqyTimeComponent),
      multi: true,
    },
  ],
})
export class AfaqyTimeComponent implements OnInit, ControlValueAccessor {
  propagateChange: any = () => {};
  validateFn: any = () => {};
  value = '';
  times = [];

  constructor() {
    for (let h = 0; h < 24; h++) {
      let hours = CellFormatter.time2digits(h);
      for (let m = 0; m < 60; m += 5) {
        let minutes = CellFormatter.time2digits(m);
        this.times.push(hours + ':' + minutes);
      }
    }
  }

  ngOnInit() {}

  pushValue() {
    this.propagateChange(this.value);
  }

  writeValue(value: any) {
    if (!value) {
      return;
    }
    this.value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
}
