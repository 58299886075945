<afaqy-modal
  [title]="'units.sensor'"
  (modalClose)="modalClose($event)"
  #modal="afaqyModal"
  sizeClass="modal-lg"
  [staticHeight]="false"
>
  <div class="modalbody">
    <form [formGroup]="myGroup">
      <afaqy-select-control
        formControlName="unitsList"
        [service]="unitService"
        [useDropDownFilter]="true"
        [unitID]="unitID"
        [cid]="cid"
        [SearchWithKeys]="[
          'name',
          'creator',
          'owner',
          'imei',
          'device_protocol',
          'sim_card_number',
          'sim_card_serial',
          'device_serial',
          'protocol',
          'model',
          'vin',
          'plate_number',
          'driver',
          'trailer'
        ]"
      ></afaqy-select-control>
    </form>
  </div>
  <div class="modalfooter">
    <button
      type="button"
      class="btn btn-success btn-custom float-right mx-1"
      (click)="onSubmit()"
      [disabled]="posting"
    >
      <i class="mdi mdi-check"></i> {{ 'sensors.clone' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline-danger btn-custom float-left mx-1"
      (click)="modalClose($event)"
    >
      <i class="mdi mdi-close"></i> {{ 'Cancel' | translate }}
    </button>
  </div>
</afaqy-modal>
