import { AppModel } from '../../../common/classes';

export class DriverGroup extends AppModel {
  private _name: string;
  private _driversList: string[] = [];
  private _deleted_at: Date;

  protected copyFields = [
    'id',
    'name',
    'driversList',
    'deleted_at',
    'users_list',
  ];
  protected createFields = ['name', 'user_id', 'driversList', 'users_list'];
  protected updateFields = ['name', 'user_id', 'driversList', 'users_list'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get driversList(): string[] {
    if (this._driversList == null) {
      return [];
    }
    return this._driversList;
  }

  set driversList(value: string[]) {
    if (value) {
      this._driversList = value;
    }
  }

  get gic() {
    return this.driversList.length;
  }
}
