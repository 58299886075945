<gridlist-for-select
  title="headings.{{ op }}"
  [assignedUsers]="assignedUsers"
  [posting]="posting"
  [service]="service"
  [columns]="columns"
  [is_assignation]="is_assignation"
  [is_checked]="is_checked"
  [op]="op"
  [multiple_ids]="multiple_ids"
  (Cancel)="cancel()"
  (toggleCheck)="toggleCheck($event)"
  (updateIds)="updateIds($event)"
  (refresh)="refresh()"
  (assign)="doAssign($event)"
  (assignation)="doAssignation(op)"
  [message]="message"
>
</gridlist-for-select>
