import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from 'app/core/services';
import { ManualRoute } from 'app/modules/manual-route/models';
import { Subject } from 'rxjs';
interface ActiveView {
  list: boolean;
  form: boolean;
}

@Injectable()
export class RoutesService extends RootService {
  public showModal: boolean;
  public activeView: ActiveView;
  public submitForm: Subject<any> = new Subject<any>();
  public manualRoutes: Subject<ManualRoute[]> = new Subject<ManualRoute[]>();
  public selectedManualRoutes: Subject<ManualRoute[]> = new Subject<
    ManualRoute[]
  >();
  public selectedRoute: ManualRoute;
  public routes: ManualRoute[] = [];

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.setRoutes();
  }

  routerPrefix(val: string = '') {
    return val ? val : 'routes_manual';
  }

  openRoutesModal() {
    this.showModal = true;
  }

  closeRoutesModal() {
    this.showModal = false;
  }

  activeListView() {
    this.activeView = { list: true, form: false };
  }

  activeFormView() {
    this.activeView = { list: false, form: true };
  }

  setRoutes() {
    this.manualRoutes.subscribe({
      next: (data: Array<ManualRoute>) => {
        if (!data || !data.length) return;
        let selected =
          this.authService.preferences('timeline-monitor', 'selected') || [];
        this.routes = data.map((r) => {
          r.selected = selected.includes(r.id);
          return r;
        });
        this.selectedManualRoutes.next([
          ...this.routes.filter((i) => i.selected),
        ]);
      },
    });
  }

  assignUnit(obj) {
    let params: {} = obj;
    return this.doCreate(this.getFunctionURL('assign_unit'), params);
  }
}
