<div class="servicesCustomPadding">
  <unit-service-form
    *ngIf="showForm"
    [isView]="isView"
    [unitID]="unitObject.id"
    [object]="selectedService"
    (closeForm)="closeForm($event)"
    (updateList)="updateServices($event)"
  ></unit-service-form>

  <unit-service-clone-form
    *ngIf="showClone"
    [serviceId]="serviceId"
    (closeForm)="closeClone($event)"
    [unitID]="unitObject.id"
  ></unit-service-clone-form>

  <div class="table-responsive height100">
    <div class="clearfix">
      <div class="float-right filter no-padding form-inline">
        <afaqy-spinner [loading]="loading"></afaqy-spinner>
        <button
          *ngIf="authService?.loadedSession | async"
          class="btn btn-sm mb-3 btn-outline-primary"
          container="body"
          secured="{{ controller + '-add' }}"
          (click)="addForm()"
          [attr.testId]="cid + 'add_new_service'"
          type="button"
          [disabled]="isAdd"
        >
          <i class="mdi mdi-plus"></i>
          {{ 'units.unit_services.add_new_service' | translate }}
        </button>
      </div>
    </div>
    <div>
      <div class="grid-container">
        <flash-message
          langKey=""
          [message]="message"
          style="display: none"
        ></flash-message>
        <wj-flex-grid
          [itemsSource]="itemsList"
          [selectionMode]="'None'"
          [isReadOnly]="true"
          [allowSorting]="true"
        >
          <wj-flex-grid-filter
            [filterColumns]="filtersCols"
          ></wj-flex-grid-filter>
          <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'"
            >#</ng-template
          >
          <ng-template
            wjFlexGridCellTemplate
            [cellType]="'RowHeader'"
            let-cell="cell"
            ><span class="row-header">{{
              cell.row.index + 1
            }}</span></ng-template
          >
          <wj-flex-grid-column
            [allowSorting]="true"
            [header]="'name' | translate"
            binding="name"
            [isReadOnly]="true"
            [width]="'*'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [allowSorting]="true"
            [header]="'units.status' | translate"
            binding="status"
            [isReadOnly]="true"
            [width]="'*'"
          >
            <ng-template
              wjFlexGridCellTemplate
              [cellType]="'Cell'"
              let-item="item"
            >
              <div [innerHTML]="item.status"></div>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'actions' | translate"
            binding=""
            [isReadOnly]="true"
            [width]="100"
          >
            <ng-template
              wjFlexGridCellTemplate
              [cellType]="'Cell'"
              cellClass="class"
              let-item="item"
              let-cell="cell"
            >
              <div class="float-right list-actions">
                <!-- clone button needs permission check -->
                <a
                  secured="{{ controller + '-cloneService' }}"
                  (click)="clone(cell.row.index, item)"
                  afaqy-tooltip
                  [tooltipText]="'services.clone' | translate"
                >
                  <i
                    class="mdi mdi-arrange-send-backward"
                    [attr.testid]="'clone'"
                  ></i>
                </a>

                <a
                  secured="{{ controller + '-edit' }}"
                  afaqy-tooltip
                  [tooltipText]="'services.edit' | translate"
                  (click)="edit(cell.row.index, item)"
                  ><i class="mdi mdi-pencil" [attr.testid]="'edit'"></i
                ></a>
                <!-- Start View Option -->
                <a
                  *ngIf="editPermission === false"
                  secured="{{ controller + '-view' }}"
                  afaqy-tooltip
                  [tooltipText]="'services.view' | translate"
                  (click)="view(cell.row.index, item)"
                  ><i class="mdi mdi-eye" [attr.testid]="'view'"></i
                ></a>
                <!-- End View Option -->
                <a
                  secured="{{ controller + '-add,' + controller + '-edit' }}"
                  afaqy-tooltip
                  [tooltipText]="'services.duplicate' | translate"
                  (click)="copy(item)"
                  ><i class="mdi mdi-content-copy" [attr.testid]="'copy'"></i
                ></a>

                <a
                  secured="{{ controller + '-delete' }}"
                  afaqy-tooltip
                  [tooltipText]="'services.trash' | translate"
                  (click)="deleteItem(item)"
                  ><i class="mdi mdi-delete" [attr.testid]="'delete'"></i
                ></a>
              </div>
            </ng-template>
          </wj-flex-grid-column>
        </wj-flex-grid>
      </div>
    </div>
  </div>
</div>
