import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { NotificationsSocketConfig } from './notificationsStomp.config.service';

@Injectable({
  providedIn: 'root',
})
export class RxNotificationsStompService extends RxStomp {
  constructor(public notificationsSocketConfig: NotificationsSocketConfig) {
    super();
  }
}
