import { Component, Input } from '@angular/core';
import { Unit } from '../../../modules/units/models/unit';
import { UnitService } from '../../../modules/units/services/unit.service';

@Component({
  selector: 'unit-data-accuracy',
  templateUrl: './unit-data-accuracy.component.html',
})
export class UnitDataAccuracyComponent {
  unit: Unit;
  _unitId;
  dataAccuracy: string[];
  cid = 'monitoring';
  satsText = '';
  satsCount: number;
  lastMessageText = '';
  timePeriod;

  @Input()
  set unitId(uid) {
    this.unit = this.unitService.getUnitDetails(uid);
    this._unitId = uid;

    this.satsText =
      this.unitService.resourceTrackingDetails[uid]['satsTooltipText'];
    this.satsCount =
      typeof this.unit.last_update.prms === 'undefined'
        ? 0
        : this.unit.last_update.prms['sat'];

    this.lastMessageText =
      this.unitService.resourceTrackingDetails[uid]['lastMessageTooltipText'];
  }

  get unitId() {
    return this._unitId;
  }

  constructor(protected unitService: UnitService) {
    this.unit = new Unit();
  }
}
