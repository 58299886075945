<div class="thirdLayer">
  <afaqy-modal
    [title]="'units.service'"
    (modalClose)="modalClose($event)"
    #modal="afaqyModal"
    [draggable]="false"
    sizeClass="modal-lg modal-dialog-centered service-modal"
    [staticHeight]="false"
  >
    <div class="modalbody">
      <div [formGroup]="form">
        <div class="row">
          <div class="afaqy-mask" *ngIf="isView"></div>
          <div class="col-12 col-lg-8">
            <div
              class="row form-group"
              [class.has-danger]="
                (form.controls['name'].dirty ||
                  form.controls['name'].touched) &&
                form.controls['name'].errors
              "
            >
              <div class="col-12">
                <label class="form-control-label"
                  >{{ 'name' | translate }}:</label
                >
              </div>
              <div class="col-12 input-group form-group-hint">
                <input
                  class="form-control form-control-sm"
                  formControlName="name"
                  [attr.testId]="cid + 'name'"
                />
                <control-errors-hint
                  [ctl]="form.controls['name']"
                ></control-errors-hint>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="row form-group">
              <div class="col-12">
                <label class="form-control-label pointer"
                  >{{ 'units.unit_services.update_last' | translate }}
                </label>
              </div>
              <div class="col-12">
                <label
                  class="switch"
                  for="units.unit_services.update_last"
                  [attr.testId]="cid + 'update_last'"
                >
                  <input
                    id="units.unit_services.update_last"
                    type="checkbox"
                    formControlName="update_last"
                    (click)="updateLast()"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <p></p>
        <div class="row">
          <div
            class="d-block float-right mb-0 py-3 mx-5 alert alert-danger"
            role="alert"
            *ngIf="intervalErrorMsg"
          >
            <p class="mb-0">{{ intervalErrorMsg }}</p>
          </div>
        </div>
        <p></p>
        <div class="row">
          <div class="col-md-12 table-responsive">
            <table
              class="table table-sm table-bordered table-stripped table-hover"
            >
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>{{ 'units.unit_services.interval' | translate }}</th>
                  <th>{{ 'units.unit_services.last_service' | translate }}</th>
                  <th>&nbsp;</th>
                  <th>{{ 'units.unit_services.event_left' | translate }}</th>
                  <th>{{ 'units.unit_services.current' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr formGroupName="odometer">
                  <td>
                    {{ 'units.unit_services.odemeter' | translate }}
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['odometer']['controls']['status']
                          .dirty ||
                          form.controls['odometer']['controls']['status']
                            .touched) &&
                        form.controls['odometer']['controls']['status'].errors
                      "
                    >
                      <div class="col-12">
                        <label
                          class="switch"
                          for="units.unit_services.odemeter.interval"
                          [attr.testId]="cid + 'odometerstatus'"
                        >
                          <input
                            id="units.unit_services.odemeter.interval"
                            type="checkbox"
                            formControlName="status"
                            (change)="
                              updateServiceTypeField($event, 'odometer')
                            "
                          />
                          <span class="slider round"></span>
                        </label>
                        <control-errors
                          [ctl]="
                            form.controls['odometer']['controls']['status']
                          "
                        ></control-errors>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['odometer']['controls']['interval']
                          .dirty ||
                          form.controls['odometer']['controls']['interval']
                            .touched) &&
                        form.controls['odometer']['controls']['interval'].errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="interval"
                          [attr.testId]="cid + 'odometerinterval'"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.km' | translate
                          }}</span>
                        </div>
                        <control-errors-hint
                          [ctl]="
                            form.controls['odometer']['controls']['interval']
                          "
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['odometer']['controls']['last'].dirty ||
                          form.controls['odometer']['controls']['last']
                            .touched) &&
                        form.controls['odometer']['controls']['last'].errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="last"
                          [attr.testId]="cid + 'odometerlast'"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.km' | translate
                          }}</span>
                        </div>
                        <control-errors-hint
                          [ctl]="form.controls['odometer']['controls']['last']"
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['odometer']['controls']['left'].dirty ||
                          form.controls['odometer']['controls']['left']
                            .touched) &&
                        form.controls['odometer']['controls']['left'].errors
                      "
                    >
                      <div class="col-12">
                        <label
                          class="switch"
                          for="units.unit_services.odemeter.left"
                          [attr.testId]="cid + 'odometerleft'"
                        >
                          <input
                            id="units.unit_services.odemeter.left"
                            type="checkbox"
                            formControlName="left"
                            (change)="
                              updateServiceLeftStatusField($event, 'odometer')
                            "
                          />
                          <span class="slider round"></span>
                        </label>
                        <control-errors-hint
                          [ctl]="form.controls['odometer']['controls']['left']"
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['odometer']['controls']['left_num']
                          .dirty ||
                          form.controls['odometer']['controls']['left_num']
                            .touched) &&
                        form.controls['odometer']['controls']['left_num'].errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="left_num"
                          [attr.testId]="cid + 'odometerleft_num'"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.km' | translate
                          }}</span>
                        </div>
                        <control-errors-hint
                          [ctl]="
                            form.controls['odometer']['controls']['left_num']
                          "
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="row form-group">
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          [attr.testId]="cid + 'odometercounters'"
                          value="{{ unitObject?.counters.odometer }}"
                          disabled
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.km' | translate
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="engine_hours">
                  <td>
                    {{ 'units.unit_services.engine_hours' | translate }}
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['engine_hours']['controls']['status']
                          .dirty ||
                          form.controls['engine_hours']['controls']['status']
                            .touched) &&
                        form.controls['engine_hours']['controls']['status']
                          .errors
                      "
                    >
                      <div class="col-12">
                        <label
                          class="switch"
                          for="units.unit_services.engine_hours.interval"
                          [attr.testId]="cid + 'engine_hours_status'"
                        >
                          <input
                            id="units.unit_services.engine_hours.interval"
                            type="checkbox"
                            formControlName="status"
                            (change)="
                              updateServiceTypeField($event, 'engine_hours')
                            "
                          />
                          <span class="slider round"></span>
                        </label>
                        <control-errors-hint
                          [ctl]="
                            form.controls['engine_hours']['controls']['status']
                          "
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['engine_hours']['controls']['interval']
                          .dirty ||
                          form.controls['engine_hours']['controls']['interval']
                            .touched) &&
                        form.controls['engine_hours']['controls']['interval']
                          .errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="interval"
                          [attr.testId]="cid + 'engine_hours_interval'"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.hour' | translate
                          }}</span>
                        </div>
                        <control-errors-hint
                          [ctl]="
                            form.controls['engine_hours']['controls'][
                              'interval'
                            ]
                          "
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['engine_hours']['controls']['last']
                          .dirty ||
                          form.controls['engine_hours']['controls']['last']
                            .touched) &&
                        form.controls['engine_hours']['controls']['last'].errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="last"
                          [attr.testId]="cid + 'engine_hours_last'"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.hour' | translate
                          }}</span>
                        </div>
                        <control-errors-hint
                          [ctl]="
                            form.controls['engine_hours']['controls']['last']
                          "
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['engine_hours']['controls']['left']
                          .dirty ||
                          form.controls['engine_hours']['controls']['left']
                            .touched) &&
                        form.controls['engine_hours']['controls']['left'].errors
                      "
                    >
                      <div class="col-12">
                        <label
                          class="switch"
                          for="units.unit_services.engine_hours.left"
                          [attr.testId]="cid + 'engine_hours_left'"
                        >
                          <input
                            id="units.unit_services.engine_hours.left"
                            type="checkbox"
                            formControlName="left"
                            (change)="
                              updateServiceLeftStatusField(
                                $event,
                                'engine_hours'
                              )
                            "
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <control-errors-hint
                        [ctl]="
                          form.controls['engine_hours']['controls']['left']
                        "
                      ></control-errors-hint>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['engine_hours']['controls']['left_num']
                          .dirty ||
                          form.controls['engine_hours']['controls']['left_num']
                            .touched) &&
                        form.controls['engine_hours']['controls']['left_num']
                          .errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="left_num"
                          [attr.testId]="cid + 'engine_hours_left_num'"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.hour' | translate
                          }}</span>
                        </div>
                        <control-errors-hint
                          [ctl]="
                            form.controls['engine_hours']['controls'][
                              'left_num'
                            ]
                          "
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="row form-group">
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          [attr.testId]="cid + 'engine_hours_counters'"
                          value="{{
                            unitObject?.counters.engine_hours / 3600
                              | number : '1.2-2'
                          }}"
                          disabled
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.hour' | translate
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="days">
                  <td>
                    {{ 'units.unit_services.days' | translate }}
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['days']['controls']['status'].dirty ||
                          form.controls['days']['controls']['status']
                            .touched) &&
                        form.controls['days']['controls']['status'].errors
                      "
                    >
                      <div class="col-12">
                        <label
                          class="switch"
                          for="units.unit_services.days.interval"
                          [attr.testId]="cid + 'days_status'"
                        >
                          <input
                            id="units.unit_services.days.interval"
                            type="checkbox"
                            formControlName="status"
                            (change)="updateServiceTypeField($event, 'days')"
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <control-errors-hint
                        [ctl]="form.controls['days']['controls']['status']"
                      ></control-errors-hint>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['days']['controls']['interval'].dirty ||
                          form.controls['days']['controls']['interval']
                            .touched) &&
                        form.controls['days']['controls']['interval'].errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="interval"
                          [attr.testId]="cid + 'days_interval'"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.day' | translate
                          }}</span>
                        </div>
                        <control-errors-hint
                          [ctl]="form.controls['days']['controls']['interval']"
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['days']['controls']['last'].dirty ||
                          form.controls['days']['controls']['last'].touched) &&
                        form.controls['days']['controls']['last'].errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint service-date-group"
                      >
                        <!--<input class="form-control form-control-sm" type="date" formControlName="last"/>-->
                        <wj-input-date
                          [attr.testId]="cid + 'days_days'"
                          [isDisabled]="
                            form.controls['days']['controls']['last'].disabled
                          "
                          (valueChanged)="valueChanged()"
                          [(value)]="dates.days"
                          [isRequired]="false"
                          [format]="'yyyy-MM-dd'"
                          [ngClass]="[
                            form.controls['days']['controls']['last'].errors
                              ? 'service-dirty'
                              : 'service-normal'
                          ]"
                        ></wj-input-date>
                        <control-errors-hint
                          [ctl]="form.controls['days']['controls']['last']"
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['days']['controls']['left'].dirty ||
                          form.controls['days']['controls']['left'].touched) &&
                        form.controls['days']['controls']['left'].errors
                      "
                    >
                      <div class="col-12">
                        <label
                          class="switch"
                          for="units.unit_services.days.left"
                          [attr.testId]="cid + 'days_left'"
                        >
                          <input
                            id="units.unit_services.days.left"
                            type="checkbox"
                            formControlName="left"
                            (change)="
                              updateServiceLeftStatusField($event, 'days')
                            "
                          />
                          <span class="slider round"></span>
                        </label>
                        <control-errors-hint
                          [ctl]="form.controls['days']['controls']['left']"
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['days']['controls']['left_num'].dirty ||
                          form.controls['days']['controls']['left_num']
                            .touched) &&
                        form.controls['days']['controls']['left_num'].errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="left_num"
                          [attr.testId]="cid + 'days_left_num'"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.day' | translate
                          }}</span>
                        </div>
                        <control-errors-hint
                          [ctl]="form.controls['days']['controls']['left_num']"
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="date">
                  <td>
                    {{ 'units.unit_services.date' | translate }}
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['date']['controls']['status'].dirty ||
                          form.controls['date']['controls']['status']
                            .touched) &&
                        form.controls['date']['controls']['status'].errors
                      "
                    >
                      <div class="col-12">
                        <label
                          class="switch"
                          for="units.unit_services.date.interval"
                          [attr.testId]="cid + 'date_status'"
                        >
                          <input
                            id="units.unit_services.date.interval"
                            type="checkbox"
                            formControlName="status"
                            (change)="updateServiceTypeField($event, 'date')"
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <control-errors-hint
                        [ctl]="form.controls['date']['controls']['status']"
                      ></control-errors-hint>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['date']['controls']['date'].dirty ||
                          form.controls['date']['controls']['date'].touched) &&
                        form.controls['date']['controls']['date'].errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint service-date-group"
                      >
                        <!--<input class="form-control form-control-sm" type="date" formControlName="date"/>-->
                        <wj-input-date
                          [attr.testId]="cid + 'date_date'"
                          [isDisabled]="
                            form.controls['date']['controls']['date'].disabled
                          "
                          (valueChanged)="valueChanged()"
                          [(value)]="dates.date"
                          [isRequired]="false"
                          [format]="'yyyy-MM-dd'"
                          [ngClass]="[
                            form.controls['date']['controls']['date'].errors
                              ? 'service-dirty'
                              : 'service-normal'
                          ]"
                        ></wj-input-date>

                        <control-errors-hint
                          [ctl]="form.controls['date']['controls']['date']"
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['date']['controls']['left'].dirty ||
                          form.controls['date']['controls']['left'].touched) &&
                        form.controls['date']['controls']['left'].errors
                      "
                    >
                      <div class="col-12">
                        <label
                          class="switch"
                          for="units.unit_services.date.left"
                          [attr.testId]="cid + 'date_left'"
                        >
                          <input
                            id="units.unit_services.date.left"
                            type="checkbox"
                            formControlName="left"
                            (change)="
                              updateServiceLeftStatusField($event, 'date')
                            "
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <control-errors-hint
                        [ctl]="form.controls['date']['controls']['left']"
                      ></control-errors-hint>
                    </div>
                  </td>
                  <td>
                    <div
                      class="row form-group"
                      [class.has-danger]="
                        (form.controls['date']['controls']['left_num'].dirty ||
                          form.controls['date']['controls']['left_num']
                            .touched) &&
                        form.controls['date']['controls']['left_num'].errors
                      "
                    >
                      <div
                        class="col-12 input-group input-group-sm form-group-hint"
                      >
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="left_num"
                          [attr.testId]="cid + 'date_left_num'"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">{{
                            'units.unit_services.day' | translate
                          }}</span>
                        </div>
                        <control-errors-hint
                          [ctl]="form.controls['date']['controls']['left_num']"
                        ></control-errors-hint>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modalfooter">
      <form-actions
        [form]="form"
        [isView]="isView"
        [posting]="posting"
        (submit)="onSubmit()"
        (cancel)="modalClose($event)"
        (reset)="reset()"
      ></form-actions>
    </div>
  </afaqy-modal>
</div>
