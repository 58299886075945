import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public splitterMode: BehaviorSubject<boolean> = new BehaviorSubject(true);
  showMarkerDetails: BehaviorSubject<any> = new BehaviorSubject({});
  hideMarkerDetails: BehaviorSubject<any> = new BehaviorSubject(false);

  hoverInside = false;
  constructor() {}
}
