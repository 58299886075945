import {
  Input,
  Component,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Message } from '../../../common/classes';

@Component({
  selector: 'flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.css'],
  providers: [],
})
export class FlashMessageComponent implements OnInit, OnChanges {
  @Input() message: Message;
  @Input() langKey: string = 'messages.';
  @Input() validationMessages: BehaviorSubject<any>;
  @Input() cid: string;
  messageList: any[] = [];
  constructor(public translate: TranslateService) {}

  ngOnInit() {
    if (this.validationMessages) {
      this.validationMessages.subscribe({
        next: (changes: string) => {
          this.messageList = [];
          if (changes === 'exist') {
            for (const [key, value] of Object.entries(
              this.message.validationMessages
            )) {
              let newMsg = {
                key: this.translate.instant(key),
                value: value,
              };
              const keyChilds = key.split('.');
              if (keyChilds.length >= 2) {
                newMsg.key =
                  this.translate.instant(this.cid + '.' + keyChilds[0]) +
                  ' - ' +
                  this.translate.instant(this.cid + '.' + keyChilds[1]);
                if (keyChilds[2] && !isNaN(Number(keyChilds[2]))) {
                  newMsg.key += ' - ' + (Number(keyChilds[2]) + 1);
                }
              }
              this.messageList.push({ msg: newMsg });
            }
          }
        },
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  onClosed(event: any) {
    this.message.clear();
    event.isClosed = false;
  }
}
