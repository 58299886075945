<div class="main-container">
  <div class="content-container">
    <div class="content-image">
      <img src="assets/redirect-assets/panel-settings.png" />
    </div>

    <div class="content-text">
      <img src="assets/redirect-assets/hello.svg" />
      <div class="slogan-text">
        <h1>We moved to afaqy.pro</h1>
        <p>
          you will be redirected in
          <span class="counter">
            {{ timer }}
          </span>
          seconds
        </p>
      </div>
    </div>
  </div>
  <div class="footer">
    <img src="assets/redirect-assets/en.svg" alt="" />
  </div>
</div>
