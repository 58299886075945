<div class="float-right filter">
  <div class="form-inline float-right">
    <afaqy-spinner [loading]="loading"></afaqy-spinner>
    <div
      *ngIf="
        multiple_ids.length &&
        actionsOptions.trash &&
        !is_trashed &&
        !appConfig.isCMS
      "
      class="totalItem"
    >
      <p
        [style.direction]="translateService.currentLang == 'ar' ? 'rtl' : 'ltr'"
      >
        <b>{{ multiple_ids?.length }}</b>
        {{ ('selected_of' | translate) + ' ' }}
        <b> {{ count }} </b>
        {{ 'multipleDelete.' + controller | translate }}
      </p>
    </div>

    <!--        <a-->
    <!--            *ngIf="-->
    <!--                !appConfig.isCMS-->
    <!--            "-->
    <!--            class="btn btn-xs link"-->
    <!--            href="javascript:void(0)"-->
    <!--            afaqy-tooltip-->
    <!--            [tooltipText]="'tooltips.share_location' | translate"-->
    <!--            (click)="shareLocation.next()"-->
    <!--            [attr.testId]="controller + '-trash'"-->
    <!--            ><i class="mdi mdi-share-variant"></i-->
    <!--        ></a>-->

    <a
      *ngIf="
        multiple_ids.length &&
        actionsOptions.trash &&
        !is_trashed &&
        !appConfig.isCMS
      "
      class="btn btn-xs link"
      href="javascript:void(0)"
      afaqy-tooltip
      [tooltipText]="'tooltips.delete_all' | translate"
      (click)="multipleDelete.next()"
      [attr.testId]="controller + 'bulk-delete'"
      ><i class="mdi mdi-delete"></i
    ></a>

    <a
      *ngIf="!loading && actionsOptions.reload"
      class="btn btn-xs link"
      afaqy-tooltip
      [tooltipText]="'tooltips.refresh' | translate"
      (click)="refresh.next()"
      [attr.testId]="controller + '-refresh'"
    >
      <i class="mdi mdi-refresh mdi-18px"></i>
    </a>

    <ng-container *ngIf="is_trashed">
      <a
        href="javascript:void(0)"
        title="{{ 'restore_all' | translate }}"
        (click)="restoreAll.next()"
        [attr.testId]="controller + '-restoreAll'"
        ><i class="fa fa-undo"></i
      ></a>
    </ng-container>

    <ng-container *ngIf="!is_trashed">
      <ng-container *ngIf="!appConfig?.isCMS">
        <a
          *ngIf="actionsOptions.add"
          class="btn btn-xs link"
          afaqy-tooltip
          [tooltipText]="'tooltips.add' | translate"
          secured="{{ controller + '-add' }}"
          [routerLink]="['add']"
          [attr.testId]="controller + '-add'"
          ><i class="mdi mdi-plus mdi-18px"></i
        ></a>
        <a
          *ngIf="actionsOptions?.trash"
          class="btn btn-xs link"
          afaqy-tooltip
          [tooltipText]="'tooltips.list_trashed' | translate"
          secured="{{ controller + '-trashed' }}"
          [routerLink]="['trashed']"
          [attr.testId]="controller + '-trash'"
          ><mat-icon [svgIcon]="'icon-archive-list'"></mat-icon
        ></a>
      </ng-container>
      <ng-container *ngIf="appConfig?.isCMS">
        <a
          *ngIf="actionsOptions.add"
          class="btn btn-xs link"
          afaqy-tooltip
          [tooltipText]="'tooltips.add' | translate"
          secured="{{ controller + '-add' }}"
          href="javascript:void(0)"
          (click)="applyAction.next({ code: 'add' })"
          ><i class="mdi mdi-plus mdi-18px"></i>&nbsp;</a
        >
        <a
          *ngIf="actionsOptions.trash"
          class="btn btn-xs link"
          afaqy-tooltip
          [tooltipText]="'tooltips.list_trashed' | translate"
          secured="{{ controller + '-trashed' }}"
          href="javascript:void(0)"
          (click)="applyAction.next({ code: 'trashed' })"
          ><i class="mdi mdi-delete"></i>&nbsp;</a
        >
      </ng-container>

      <!-- Export -->
      <div class="actions-container">
        <a
          *ngIf="actionsOptions?.export"
          class="btn btn-xs link"
          afaqy-tooltip
          [tooltipText]="'tooltips.export' | translate"
          secured="{{ controller + '-export' }}"
        >
          <i
            (click)="toggleExportActions()"
            [attr.testId]="controller + '-export'"
            class="mdi mdi-download mdi-18px clickOutsideExcept"
          >
          </i>
        </a>
        <div
          class="export-actions"
          clickOutside
          (clickOutside)="closeExportActions()"
          [class.hidden]="!showExport"
          [attr.testid]="'export-actions'"
        >
          <table class="center" *ngFor="let exportOption of exportOptions">
            <tr>
              <td [attr.testid]="'export-xlsx'">
                <span (click)="doExport(exportOption.id)">{{
                  exportOption.name
                }}</span>
              </td>
            </tr>
            <!-- <tr>
              <td [attr.testid]="'export-xls'">
                <span (click)="doExport('xls')">XLS</span>
              </td>
            </tr>
            <tr>
              <td [attr.testid]="'export-csv'">
                <span (click)="doExport('csv')">CSV</span>
              </td>
            </tr> -->
          </table>
        </div>
      </div>

      <!-- Download Import Template -->
      <div class="actions-container">
        <a
          *ngIf="actionsOptions.downloadTemplate"
          class="btn btn-xs link"
          afaqy-tooltip
          [tooltipText]="'tooltips.downloadTemplate' | translate"
          secured="{{ controller + '-downloadSample' }}"
        >
          <i
            (click)="toggleDownloadTemplateActions()"
            [attr.testId]="controller + '-downloadTemplate'"
            class="fas fa-file-download mdi-18px clickOutsideExcept"
          >
          </i>
        </a>
        <div
          class="download-template-actions"
          clickOutside
          (clickOutside)="closeDownloadTemplateActions()"
          [class.hidden]="!showDownloadTemplate"
          [attr.testid]="'downloadTemplate-actions'"
        >
          <table class="center">
            <tr *ngIf="downloadTemplateOptions?.xlsx">
              <td [attr.testid]="'downloadTemplate-xlsx'">
                <span (click)="doDownloadTemplate('xlsx')">XLSX</span>
              </td>
            </tr>
            <tr *ngIf="downloadTemplateOptions?.xls">
              <td [attr.testid]="'downloadTemplate-xls'">
                <span (click)="doDownloadTemplate('xls')">XLS</span>
              </td>
            </tr>
            <tr *ngIf="downloadTemplateOptions?.csv">
              <td [attr.testid]="'downloadTemplate-csv'">
                <span (click)="doDownloadTemplate('csv')">CSV</span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- Import -->
      <a
        *ngIf="actionsOptions.import"
        class="btn btn-xs link"
        afaqy-tooltip
        [tooltipText]="'tooltips.import' | translate"
        secured="{{ controller + '-import' }}"
        (click)="importfile.value = ''; importfile.click()"
        [attr.testId]="controller + '-import'"
      >
        <input
          class="hidden"
          type="file"
          (change)="import.next($event)"
          #importfile
        />
        <i class="mdi mdi-upload mdi-18px"></i>
      </a>
      <a
        *ngIf="actionsOptions.importToAdd"
        class="btn btn-xs link"
        afaqy-tooltip
        [tooltipText]="'tooltips.importToAdd' | translate"
        [secured]="controller + '-import'"
        (click)="checkOperationProcess(controller)"
        [attr.testId]="controller + '-import'"
      >
        <!-- <input class="hidden" type="file" (change)="import.next($event)" #importfile /> -->
        <i class="mdi mdi-upload mdi-18px"></i>
      </a>
      <a
        *ngIf="actionsOptions.importToFill"
        class="btn btn-xs link"
        afaqy-tooltip
        [tooltipText]="'tooltips.importToFill' | translate"
        [secured]="controller + '-import'"
        (click)="checkOperationProcess(controller)"
        [attr.testId]="controller + '-import'"
      >
        <!-- <input class="hidden" type="file" (change)="import.next($event)" #importfile /> -->
        <i class="mdi mdi-upload mdi-18px"></i>
      </a>

      <!--Header Options-->
      <a
        class="btn btn-xs link clickOutsideExcept"
        afaqy-tooltip
        [tooltipText]="'tooltips.grid-optimizer-options' | translate"
        (click)="toggleOptions.next()"
        *ngIf="headerOptions"
        [attr.testId]="controller + '-headerOptions'"
      >
        <i class="clickOutsideExcept mdi mdi-dots-vertical mdi-18px"></i>
      </a>
    </ng-container>
  </div>
</div>

<ng-template let-data #myInfoDialog>
  <!--<h2 mat-dialog-title>Information</h2>-->
  <div mat-dialog-content>
    {{ data }}
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'close' | translate }}</button>
  </div>
</ng-template>
