<!-- <afaqy-loading [loading]="loading"></afaqy-loading> -->
<afaqy-modal
  *ngIf="!loading"
  [insidetab]="insidetab"
  [title]="title"
  (modalClose)="modalClose($event)"
  #modal="afaqyModal"
  sizeClass="modal-lg"
  [draggable]="true"
  [attr.testid]="'units-dialog'"
>
  <div class="modalbody">
    <div class="unit-form">
      <form [formGroup]="form" novalidate>
        <div [ngClass]="{ 'afaqy-mask': posting }"></div>
        <div class="nav-tab-pills-image" #tabs>
          <tabset class="height-calc">
            <tab>
              <div class="afaqy-mask" *ngIf="isView"></div>
              <ng-template tabHeading>
                <span
                  [attr.testid]="'units-tabs-main'"
                  [class.invalid-form]="showValidation && isMainTabInvalid()"
                >
                  {{ cid + '.main' | translate }}
                </span>
              </ng-template>
              <unit-form-general
                [isAdd]="_isAdd"
                [group]="form"
              ></unit-form-general>
            </tab>
            <tab>
              <div class="afaqy-mask" *ngIf="isView"></div>
              <ng-template tabHeading>
                <span
                  [attr.testid]="'units-tabs-profile'"
                  [class.invalid-form]="
                    showValidation &&
                    (!form['controls']['profile'].valid ||
                      !form['controls']['job_order'].valid ||
                      !form['controls']['tailer_id'].valid ||
                      !form['controls']['driver_id'].valid)
                  "
                >
                  {{ cid + '.profile' | translate }}
                </span>
              </ng-template>
              <unit-form-profile
                #UnitFormProfile
                [cctvApp]="cctvApp"
                [group]="form"
                [files]="files"
                [filesPaths]="filesPaths"
                [selectedFileNames]="selectedFileNames"
                [editObject]="editObject"
                (deleteIcon)="deleteIcon($event)"
                (changeIcon)="changeIcon($event)"
                (selectIcon)="selectIcon($event)"
              ></unit-form-profile>
            </tab>
            <tab (select)="refreshRandom()" [attr.testid]="'units-tabs-groups'">
              <ng-template tabHeading>
                <span
                  [class.invalid-form]="
                    showValidation && form.controls['groupsList'].invalid
                  "
                >
                  {{ cid + '.group' | translate }}
                </span>
              </ng-template>
              <!--<unit-form-group [refresh]="random" [group]="form"></unit-form-group>-->
              <div class="unit-groups">
                <afaqy-select-control
                  [isView]="isView"
                  formControlName="groupsList"
                  [attr.testId]="'units-groups-groupsList'"
                  [refresh]="random"
                  [service]="groupService"
                ></afaqy-select-control>
              </div>
            </tab>
            <tab
              *ngIf="form['controls']['device_type']?.value !== 'fixed_unit'"
            >
              <div class="afaqy-mask" *ngIf="isView"></div>
              <ng-template tabHeading>
                <span
                  [attr.testid]="'units-tabs-fuel_consumption'"
                  [class.invalid-form]="
                    showValidation && !form['controls']['fc'].valid
                  "
                >
                  {{ cid + '.fuel-consumption' | translate }}
                </span>
              </ng-template>
              <unit-form-fuel-consumption
                [group]="form.controls.fc"
              ></unit-form-fuel-consumption>
            </tab>
            <tab
              *ngIf="form['controls']['device_type']?.value !== 'fixed_unit'"
            >
              <div class="afaqy-mask" *ngIf="isView"></div>
              <ng-template tabHeading>
                <span
                  [attr.testid]="'units-tabs-accuracy'"
                  [class.invalid-form]="
                    showValidation && !form['controls']['td'].valid
                  "
                >
                  {{ cid + '.accuracy' | translate }}
                </span>
              </ng-template>
              <unit-form-trip-detection
                [group]="form.controls.td"
              ></unit-form-trip-detection>
            </tab>
            <tab
              *ngIf="form['controls']['device_type']?.value !== 'fixed_unit'"
            >
              <div class="afaqy-mask" *ngIf="isView"></div>
              <ng-template tabHeading>
                <span
                  [attr.testid]="'units-tabs-accuracy'"
                  [class.invalid-form]="
                    showValidation && !form['controls']['shipment_info'].valid
                  "
                >
                  {{ cid + '.shipment' | translate }}
                </span>
              </ng-template>
              <unit-form-shipment
                [group]="form.controls.shipment_info"
              ></unit-form-shipment>
            </tab>
            <tab
              *ngIf="!isAdd"
              [attr.testid]="'units-tabs-services'"
              [heading]="cid + '.services' | translate"
              (select)="refreshRandom()"
            >
              <div class="secondLayer">
                <unit-form-services
                  [unitObject]="object"
                  [resourceID]="resourceID"
                  [refresh]="random"
                  [group]="form"
                  [isAdd]="isAdd"
                ></unit-form-services>
              </div>
            </tab>
            <tab
              [attr.testid]="'units-tabs-sensors'"
              [heading]="cid + '.sensors' | translate"
              (select)="refreshRandom()"
            >
              <div class="secondLayer">
                <unit-form-sensors
                  [unitObject]="object"
                  [resourceID]="resourceID"
                  [refresh]="random"
                  [group]="form"
                  [isAdd]="isAdd"
                ></unit-form-sensors>
              </div>
            </tab>
            <tab
              *ngIf="
                !isAdd &&
                form['controls']['device_type']?.value !== 'fixed_unit'
              "
              [attr.testid]="'units-tabs-commands'"
              [heading]="cid + '.commands' | translate"
              (select)="refreshRandom()"
            >
              <div class="secondLayer">
                <unit-form-commands
                  [unitObject]="object"
                  [device]="form.controls['device'].value"
                  [resourceID]="resourceID"
                  [refresh]="random"
                  [group]="form"
                  [isAdd]="isAdd"
                ></unit-form-commands>
              </div>
            </tab>
            <tab
              *ngIf="
                !isAdd &&
                form['controls']['device_type']?.value !== 'fixed_unit'
              "
              [attr.testid]="'units-tabs-driver_behavior'"
              [heading]="cid + '.driver_behavior' | translate"
              (select)="refreshRandom()"
            >
              <div class="secondLayer">
                <unit-form-driver-behavior
                  [unitObject]="object"
                  [resourceID]="resourceID"
                  [refresh]="random"
                  [group]="form"
                ></unit-form-driver-behavior>
              </div>
            </tab>
            <tab
              *ngIf="
                form['controls']['device_type']?.value !== 'fixed_unit' &&
                customFieldsPermission
              "
            >
              <ng-template tabHeading>
                <span
                  [attr.testid]="'units-tabs-custom'"
                  [class.invalid-form]="
                    showValidation && form.controls['custom_fields'].invalid
                  "
                >
                  {{ cid + '.custom' | translate }}
                </span>
              </ng-template>
              <unit-form-custom
                #customTab
                [isView]="isView"
                [form]="form"
                [isAdd]="isAdd"
                [selectedIds]="customIds"
                [isViewLoaded]="isViewLoaded"
                [customFieldsView]="object?.custom_fields"
              >
              </unit-form-custom>
            </tab>
            <tab
              [attr.testid]="'units-tabs-access'"
              class="unitFormAccessTab"
              *ngIf="hasAssignPermissions"
              [heading]="'access' | translate"
              (select)="refreshRandom()"
            >
              <form-assignations
                [isView]="isView"
                (updateSelection)="updateUsersSelection($event)"
                [nestedIdObjectKey]="'uid'"
                [refresh]="random"
                [selected]="object.users_list"
              ></form-assignations>
            </tab>
          </tabset>
        </div>
      </form>
    </div>
    <div class="divider15"></div>
  </div>
  <div
    class="modalfooter"
    [ngClass]="
      !isAdd && authService.checkPermissions('units-copyUnitInfo')
        ? 'd-flex justify-content-space-between'
        : ''
    "
  >
    <div
      class="copy-info"
      *ngIf="!isAdd && authService.checkPermissions('units-copyUnitInfo')"
    >
      <button
        type="button"
        class="btn btn-sm mx-1"
        (click)="checkOperationProcess()"
        [disabled]="service?.copyInfoData?.operation_status === 'in_progress'"
      >
        <mat-icon
          class="avl-icon vertical-align-middle"
          [svgIcon]="'icon-copy-info'"
          [attr.size]="'24px'"
        ></mat-icon>
        {{ cid + '.copy_info' | translate }}
      </button>
    </div>
    <mat-spinner [diameter]="25" *ngIf="checkOperationValidation"></mat-spinner>
    <span *ngIf="hasRunningOperation" class="running-operation-msg">
      {{ 'copyInfoHasRunningOperation' | translate }}
    </span>
    <div class="form-actions">
      <form-actions
        [form]="form"
        [isView]="isView"
        [posting]="posting"
        (submit)="onSubmit()"
        (cancel)="modalClose($event)"
        (reset)="reset()"
      ></form-actions>
      <div class="d-block float-right mb-0 py-0 mx-5 flash-message-reset">
        <flash-message
          [message]="message"
          [cid]="cid"
          [validationMessages]="validationMessages"
        ></flash-message>
      </div>
    </div>
  </div>
</afaqy-modal>

<div class="stepper-model">
  <ng-template #stepperModel></ng-template>
</div>
