import { AfaqyObject } from '../../../common/classes';
export class UserContactInfo extends AfaqyObject {
  private _name: string;
  private _company: string;
  private _company_ar: string;
  private _address: string;
  private _post_code: string;
  private _country: string;
  private _city: string;
  private _phones: string[] = [];
  private _emails: string[] = [];
  private _commercial_number: string;
  private _tax_number: string;
  protected copyFields = [
    'name',
    'company',
    'company_ar',
    'address',
    'post_code',
    'country',
    'city',
    'phones',
    'emails',
    'commercial_number',
    'tax_number',
  ];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get company(): string {
    return this._company;
  }

  set company(value: string) {
    this._company = value;
  }

  get company_ar(): string {
    return this._company_ar;
  }

  set company_ar(value: string) {
    this._company_ar = value;
  }

  get address(): string {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }

  get post_code(): string {
    return this._post_code;
  }

  set post_code(value: string) {
    this._post_code = value;
  }

  get country(): string {
    return this._country;
  }

  set country(value: string) {
    this._country = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get phones(): string[] {
    return this._phones;
  }

  set phones(value: string[]) {
    let phones = [];
    if (value) {
      for (let k in value) {
        if (typeof value[k] === 'object') {
          phones.push(value[k]['value']);
        } else {
          phones.push(value[k]);
        }
      }
    }
    this._phones = phones;
  }

  get emails(): string[] {
    return this._emails;
  }

  set emails(value: string[]) {
    let emails = [];
    if (value) {
      for (let k in value) {
        if (typeof value[k] === 'object') {
          emails.push(value[k]['value']);
        } else {
          emails.push(value[k]);
        }
      }
    }
    this._emails = emails;
  }

  get commercial_number(): string {
    return this._commercial_number;
  }

  set commercial_number(value: string) {
    this._commercial_number = value;
  }

  get tax_number(): string {
    return this._tax_number;
  }

  set tax_number(value: string) {
    this._tax_number = value;
  }
}
