import { AppModel } from '../../../common/classes';
import { AfaqyObject } from '../../../common/classes/afaqy-object';
import { TranslateService } from '@ngx-translate/core';

class Floor extends AfaqyObject {
  private _name: string = '';

  protected copyFields = ['id', 'name'];
  protected createFields = ['name'];
  protected updateFields = ['name'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}

class Building extends AfaqyObject {
  private _name: string = '';
  private _floors: Floor[] = [];

  protected copyFields = ['id', 'name', 'floors'];
  protected createFields = ['name', 'floors'];
  protected updateFields = ['name', 'floors'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get floors(): any[] {
    return this._floors;
  }

  set floors(value: any[]) {
    this._floors = value;
  }
}
class Warehouse extends AfaqyObject {
  private _phone: string = '';
  private _address: string = '';

  protected copyFields = ['id', 'phone', 'address'];
  protected createFields = ['phone', 'address'];
  protected updateFields = ['phone', 'address'];

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  get address(): string {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }
}

export class Warehouses extends AppModel {
  static statusVal = ['not_active', 'active'];
  static isPersonal = ['no', 'yes'];
  static zoneTypes = ['circle', 'polygon'];
  static notifications = [
    { value: '10', display: '10%' },
    { value: '20', display: '20%' },
    { value: '30', display: '30%' },
    { value: '40', display: '40%' },
    { value: '50', display: '50%' },
    { value: '60', display: '60%' },
    { value: '70', display: '70%' },
    { value: '80', display: '80%' },
  ];
  static priority = [
    { value: '1', display: '1 High' },
    { value: '2', display: '2' },
    { value: '3', display: '3' },
    { value: '4', display: '4' },
    { value: '5', display: '5' },
    { value: '6', display: '6' },
    { value: '7', display: '7' },
    { value: '8', display: '8' },
    { value: '9', display: '9' },
    { value: '10', display: '10 Low' },
  ];
  static consuming = [
    { value: '0', display: 'Normal' },
    { value: '1', display: 'Fast' },
  ];
  private _name: string;
  private _user_id: string;
  private _color: string;
  private _type: string = 'polygon';
  private _radius: number;
  private _area: number;
  private _vertices: any[];
  private _is_warehouse: boolean;
  private _warehouse: Warehouse = new Warehouse();
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;
  private _units_list: string[] = [];
  private _inactive_units_list: string[] = [];
  private _buildings: Building[] = [];
  private _buildings_no;
  private _floors_no;
  private _rooms_no;
  private _safe_intervals: boolean;

  // tslint:disable-next-line: max-line-length
  protected copyFields = [
    'id',
    'name',
    'user_id',
    'color',
    'type',
    'status',
    'personal',
    'radius',
    'area',
    'vertices',
    'is_warehouse',
    'created_at',
    'warehouse',
    'deleted_at',
    'users_list',
    'units_list',
    'inactive_units_list',
    'buildings',
    'buildings_no',
    'floors_no',
    'rooms_no',
  ];
  // tslint:disable-next-line: max-line-length
  protected createFields = [
    'name',
    'user_id',
    'color',
    'type',
    'status',
    'personal',
    'radius',
    'area',
    'vertices',
    'is_warehouse',
    'warehouse',
    'users_list',
    'units_list',
    'inactive_units_list',
    'buildings',
    'buildings_no',
    'floors_no',
    'rooms_no',
  ];
  // tslint:disable-next-line: max-line-length
  protected updateFields = [
    'name',
    'user_id',
    'color',
    'type',
    'status',
    'personal',
    'radius',
    'area',
    'vertices',
    'is_warehouse',
    'warehouse',
    'users_list',
    'units_list',
    'inactive_units_list',
    'buildings',
    'buildings_no',
    'floors_no',
    'rooms_no',
  ];

  constructor(protected translate: TranslateService) {
    super();
  }
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get radius(): number {
    return this._radius;
  }

  set radius(value: number) {
    this._radius = value;
  }

  get area(): number {
    return this._area;
  }

  set area(value: number) {
    this._area = value;
  }

  get vertices(): any[] {
    return this._vertices;
  }

  set vertices(value: any[]) {
    this._vertices = value;
  }

  get is_warehouse(): boolean {
    return this._is_warehouse;
  }

  set is_warehouse(value: boolean) {
    this._is_warehouse = value;
  }

  get warehouse(): Warehouse {
    return this._warehouse;
  }

  set warehouse(value: Warehouse) {
    this._warehouse = value;
  }

  get users_list(): string[] {
    return this._users_list;
  }

  set users_list(value: string[]) {
    this._users_list = value;
  }

  get units_list(): string[] {
    return this._units_list;
  }

  set units_list(value: string[]) {
    this._units_list = value;
  }

  get activeUnitsCount(): number {
    return this.units_list.length;
  }

  get inactive_units_list(): string[] {
    return this._inactive_units_list;
  }

  set inactive_units_list(value: string[]) {
    this._inactive_units_list = value;
  }

  get inaciveUnitsCount(): number {
    return this.inactive_units_list.length;
  }

  get buildings(): Building[] {
    return this._buildings;
  }

  set buildings(value: Building[]) {
    this._buildings = value;
  }

  get buildings_no() {
    return this._buildings_no;
  }
  set buildings_no(value) {
    this._buildings_no = value;
  }
  get floors_no() {
    return this._floors_no;
  }
  set floors_no(value) {
    this._floors_no = value;
  }
  get rooms_no() {
    return this._rooms_no;
  }
  set rooms_no(value) {
    this._rooms_no = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get zoneTypeIcon() {
    let cls = '';
    switch (this.type) {
      case 'polygon':
        cls = 'icon-zone-polygone';
        break;
      case 'line':
        cls = 'icon-zone-line';
        break;
      default:
        cls = 'icon-zone-circle';
        break;
    }
    return cls;
  }

  get zoneType() {
    return this.translate.instant(`warehouses.${this.type}`);
  }

  public get safe_intervals(): boolean {
    return this._safe_intervals;
  }
  public set safe_intervals(value: boolean) {
    this._safe_intervals = value;
  }
}

export class WarehousesGroups extends AppModel {
  private _parent_id: string;
  private _name: string;
  private _parent: WarehousesGroups;
  private warehousesList: string[] = [];
  private _deleted_at: Date;

  protected copyFields = [
    'id',
    'name',
    'parent_id',
    'ids',
    'warehousesList',
    'deleted_at',
    'users_list',
  ];
  protected createFields = [
    'name',
    'parent_id',
    'user_id',
    'ids',
    'warehousesList',
    'users_list',
  ];
  protected updateFields = [
    'name',
    'parent_id',
    'user_id',
    'ids',
    'warehousesList',
    'users_list',
  ];

  get parent_id(): string {
    return this._parent_id;
  }

  set parent_id(value: string) {
    this._parent_id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get parent(): WarehousesGroups {
    return this._parent;
  }

  set parent(value: WarehousesGroups) {
    this._parent = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get ids(): string[] {
    if (this.warehousesList == null) {
      return [];
    }
    return this.warehousesList;
  }

  set ids(value: string[]) {
    if (value) {
      this.warehousesList = value;
    }
  }

  get parentName() {
    if (this.parent) {
      return this.parent.name;
    }
    return '';
  }

  get gic() {
    return this.warehousesList.length;
  }
}
