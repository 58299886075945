import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { contentHeaders } from '../../common/headers';
import { AppConfig } from '../../common/classes/app-config';
import { AfaqyAPIResponse } from '../classes';
import { AuthService } from './auth.service';

@Injectable()
export class ApiRequestService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  getURL(url, addtoken = false) {
    let fullURL = AppConfig.baseURL + url;
    if (addtoken && this.auth.authToken) {
      fullURL += '?token=' + this.auth.authToken;
    }
    return fullURL;
  }

  loadJson(url) {
    return this.http.get(url);
  }

  post(url, params) {
    return this.http.post<AfaqyAPIResponse>(
      this.getURL(url),
      { data: JSON.stringify(params) },
      { headers: contentHeaders }
    );
  }

  postWithToken(url, params, token = false) {
    return this.http.post<AfaqyAPIResponse>(
      this.getURL(url, true),
      { data: JSON.stringify(params) },
      { headers: contentHeaders }
    );
  }

  authPost(url, params = {}, options = {}) {
    let formData;
    if (params['updloadfiles'] && Object.keys(params['updloadfiles']).length) {
      formData = new FormData();
      let updloadfiles = params['updloadfiles'];
      for (let key in updloadfiles) {
        if (key === 'action_type') {
          formData.append(key, updloadfiles[key][key]);
        } else {
          formData.append(key, updloadfiles[key].file, updloadfiles[key].name);
        }
      }
      delete params['updloadfiles'];
      formData.append('data', JSON.stringify(params));
    } else {
      formData = { data: JSON.stringify(params) };
    }
    options['headers'] = contentHeaders;

    this.auth.refreshExpireTime();

    return this.http.post<AfaqyAPIResponse>(
      this.getURL(url, true),
      formData,
      options
    );
  }

  authGet(url, params = {}, options = {}) {
    options['headers'] = contentHeaders;
    options['params'] = params;
    this.auth.refreshExpireTime();
    return this.http.get<AfaqyAPIResponse>(this.getURL(url, true), options);
  }

  postExport(url, params = {}, options = {}) {
    return this.http.post(
      this.getURL(url, true),
      { data: JSON.stringify(params) },
      options
    );
  }

  getRequest(url, params = {}, options = {}) {
    options['params'] = params;
    return this.http.get(this.getURL(url, true), options);
  }
}
