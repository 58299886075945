<div>
  <afaqy-modal
    (modalClose)="modalClose($event)"
    sizeClass="modal-lg"
    #modal="afaqyModal"
    [title]="title"
  >
    <div class="modalheader"></div>

    <div class="modalbody">
      <flash-message [message]="message"></flash-message>
      <div [ngClass]="{ 'afaqy-mask': posting }"></div>
      <div class="card-title">
        <h8>{{ 'packageUsage.package_name' | translate }}</h8>
        <p>
          <strong>{{ packageUsageData?.package_name }}</strong>
        </p>
      </div>
      <div class="cards">
        <div class="card-info" *ngFor="let item of packageUsageData?.modules">
          <div class="card-name">
            <p class="module-name">
              <strong>{{ 'packageUsage.' + item?.n | translate }}</strong>
            </p>
            <p>
              {{
                item?.st === 2
                  ? ('packageUsage.unlimited' | translate)
                  : item?.reset == 'none'
                  ? ('packageUsage.life-time' | translate)
                  : ('packageUsage.renewal' | translate) +
                    ' ' +
                    ('packageUsage.' + item?.reset | translate)
              }}
            </p>
          </div>
          <div class="card-content">
            <div class="card-unlimited" *ngIf="item?.st === 2">
              <mat-icon [svgIcon]="'icon-package-unlimited'"> </mat-icon>
            </div>
            <span class="module-range" *ngIf="item?.st !== 2">{{
              item?.count + ' / ' + item?.limit
            }}</span>
            <div class="progress" *ngIf="item?.st !== 2">
              <progressbar
                id="progressBar"
                [ngClass]="{
                  'green-bar': item?.st == 1 && item?.percentage <= 50,
                  'yellow-bar':
                    item?.st == 1 && item?.percentage > 50 && item?.percentage <= 75,
                  'red-bar':
                    item?.st == 1 && item?.percentage > 75,
                }"
                [value]="item.percentage"
              >
              </progressbar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modalfooter">
      <button
        [attr.testId]="'cancel'"
        type="button"
        class="btn btn-cancel btn-custom float-left mx-2"
        (click)="modalClose($event)"
      >
        <i class="cancel"></i>
        {{ 'packageUsage.close' | translate }}
      </button>
    </div>
  </afaqy-modal>
</div>
