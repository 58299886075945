<afaqy-modal
  (modalClose)="closeModal()"
  [hasCloseIcon]="false"
  [modalCentered]="true"
  [draggable]="false"
>
  <div class="modalheader">
    <img
      id="isuzu-logo"
      src="../../../../assets/images/isuzu_connect.png"
      alt=""
    />
    <system-langs></system-langs>
  </div>
  <div
    class="modalbody"
    [style.text-align]="currentLang === 'ar' ? 'right' : 'left'"
    [style.direction]="currentLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <h4>{{ 'isuzu.data_sharing_contract' | translate }}</h4>
    <span class="contract-sentences">{{ 'isuzu.sentence1' | translate }}</span>
    <span class="contract-sentences">{{ 'isuzu.sentence2' | translate }}</span>
    <span class="contract-sentences">{{ 'isuzu.sentence3' | translate }}</span>
    <span class="contract-sentences">{{ 'isuzu.sentence4' | translate }}</span>
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">{{ 'isuzu.section_no_1' | translate }}</th>
          <th scope="col">{{ 'isuzu.monitoring_section' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'isuzu.row_no_1' | translate }}</td>
          <td>{{ 'isuzu.monitoring_section_sentence1' | translate }}</td>
        </tr>
        <tr>
          <td>{{ 'isuzu.row_no_2' | translate }}</td>
          <td>{{ 'isuzu.monitoring_section_sentence2' | translate }}</td>
        </tr>
        <tr>
          <td>{{ 'isuzu.row_no_3' | translate }}</td>
          <td>{{ 'isuzu.monitoring_section_sentence3' | translate }}</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">{{ 'isuzu.section_no_2' | translate }}</th>
          <th scope="col">{{ 'isuzu.maintenance_section' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'isuzu.row_no_1' | translate }}</td>
          <td>{{ 'isuzu.maintenance_section_sentence1' | translate }}</td>
        </tr>
        <tr>
          <td>{{ 'isuzu.row_no_2' | translate }}</td>
          <td>{{ 'isuzu.maintenance_section_sentence2' | translate }}</td>
        </tr>
        <tr>
          <td>{{ 'isuzu.row_no_3' | translate }}</td>
          <td>{{ 'isuzu.maintenance_section_sentence3' | translate }}</td>
        </tr>
        <tr>
          <td>{{ 'isuzu.row_no_4' | translate }}</td>
          <td>{{ 'isuzu.maintenance_section_sentence4' | translate }}</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">{{ 'isuzu.section_no_3' | translate }}</th>
          <th scope="col">{{ 'isuzu.report_section' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'isuzu.row_no_1' | translate }}</td>
          <td>{{ 'isuzu.report_section_sentence1' | translate }}</td>
        </tr>
        <tr>
          <td>{{ 'isuzu.row_no_2' | translate }}</td>
          <td>{{ 'isuzu.report_section_sentence2' | translate }}</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">{{ 'isuzu.section_no_4' | translate }}</th>
          <th scope="col">
            {{ 'isuzu.updated_and_additional_functions' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'isuzu.row_no_1' | translate }}</td>
          <td>
            {{ 'isuzu.updated_and_additional_functions_sentence1' | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modalfooter">
    <div class="form-check">
      <input
        type="checkbox"
        (change)="changeAgreementState($event.target.checked)"
      />
      <label>{{ 'isuzu.agreement' | translate }}</label>
    </div>
    <button class="btn btn-sm" [disabled]="!isAgreed" (click)="closeModal()">
      {{ 'isuzu.connect' | translate }}
    </button>
  </div>
</afaqy-modal>
