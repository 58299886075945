import { AppModel, AfaqyObject } from '../../../common/classes';

export class EventData extends AppModel {
  private _user_id: string;
  private _event_id: string;
  private _desc: string;
  private _notify: any;
  private _unit_id: string;
  private _unit_name: string;
  private _pos: any;
  private _isread: boolean;

  protected copyFields = [
    'id',
    'user_id',
    'event_id',
    'desc',
    'unit_id',
    'unit_name',
    'pos',
    'isread',
  ];

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get event_id(): string {
    return this._event_id;
  }

  set event_id(value: string) {
    this._event_id = value;
  }

  get desc(): string {
    return this._desc;
  }

  set desc(value: string) {
    this._desc = value;
  }

  get notify(): any {
    return this._notify;
  }

  set notify(value: any) {
    this._notify = value;
  }

  get unit_id(): string {
    return this._unit_id;
  }

  set unit_id(value: string) {
    this._unit_id = value;
  }

  get unit_name(): string {
    return this._unit_name;
  }

  set unit_name(value: string) {
    this._unit_name = value;
  }

  get pos(): any {
    return this._pos;
  }

  set pos(value: any) {
    this._pos = value;
  }

  get isread(): boolean {
    return this._isread === undefined ? false : this._isread;
  }

  set isread(value: any) {
    this._isread = value == '' ? false : value;
  }
}
