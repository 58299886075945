import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AfaqyHelper } from '../../../common/classes/afaqy-helper';
import { AfaqyAPIResponse, AfaqyResponse } from '../../../core/classes';
import { AfaqyAlertService } from '../../../core/services/alert.service';
import { ApiRequestService } from '../../../core/services/api-request-service';
import { AuthService } from '../../../core/services/auth.service';
import { RootService } from '../../../core/services/rootService';
import { User } from './../models';

@Injectable({ providedIn: 'root' })
export class UserService extends RootService {
  systemPermissionsList: any;
  usersPermissionsList: any = {};
  syetmServersList: any = [];
  usersTreeList: any;
  imgUrl: any;
  constructor(
    public authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);

    this.authService.loadedSession.subscribe({
      next: (flag) => {
        if (flag) this.usersTreeList = null;
        //this.usersPermissionsList = null;
      },
    });
  }

  setResourceObject(obj: any) {
    if (obj.last_login_date) {
      obj.last_login_date = AfaqyHelper.timeStampToTimeZoneDateString(
        obj.last_login_date * 1000,
        this.authService.user.timezone,
        'DD-MM-YYYY HH:mm:ss'
      );
    }
    super.setResourceObject(obj);
  }

  refactorViewResponse(res: any) {
    if (res.data.role_name) {
      res.data.role_name.n = res.data.role_name.name;
      delete res.data.role_name.name;
    }
    return super.refactorViewResponse(res);
  }

  doGetById(url: string, id: string, simplify = 0) {
    return super.doGetById(url, id, simplify);
  }

  applyAfterListTrashed(objects: User[]) {
    for (const obj of objects) {
      if (obj.last_login_date) {
        obj.last_login_date = AfaqyHelper.timeStampToTimeZoneDateString(
          obj.last_login_date * 1000,
          this.authService.user.timezone,
          'DD-MM-YYYY HH:mm:ss'
        );
      }
      this.setParentUser(obj);
    }
    return objects;
  }

  updateObjectsRelations() {
    for (const key in this._resourcesList) {
      this.setParentUser(this._resourcesList[key]);
    }
    this.pushChanges();
  }

  private setParentUser(user: User) {
    if (!user) return;
    const parent_id: string = user.parent_id;
    if (parent_id) {
      if (parent_id == this.authService.userID)
        user.parent = this.authService.user;
      else user.parent = this.getItemFromResources(user.parent_id);
    }
  }

  routerPrefix(val: string = '') {
    return val ? val : 'user';
  }

  get modelInstance() {
    return new User();
  }

  get allowedIcons() {
    return ['icon', 'arrow', 'iconarrow'];
  }

  get addressFormats() {
    return ['zone_name', 'google_address', 'lat_lng', 'places'];
  }

  get tempUnits() {
    return ['celsius', 'fahrenheit'];
  }

  get distanceUnits() {
    return ['km', 'mile', 'nautical_mile'];
  }
  get currency() {
    return [
      'sar',
      'omr',
      'aed',
      'egp',
      'kwd',
      'usd',
      'eur',
      'gbp',
      'jpy',
      'aud',
      'cad',
      'chf',
      'cny',
      'inr',
      'mxn',
      'brl',
      'zar',
      'krw',
    ];
  }

  get capacityUnits() {
    return [
      'litre',
      'gallon',
      'cubic_meter',
      'cubic_centimeter',
      'cubic_yard',
      'cubic_foot',
      'cubic_inch',
    ];
  }

  get weightUnits() {
    return ['gm', 'kg', 'ton', 'pound', 'ounce'];
  }

  get lengthUnits() {
    return ['meter', 'centimeter', 'yard', 'foot', 'inch'];
  }

  get countUnits() {
    return ['item', 'package'];
  }

  get additionalInfo() {
    let user = new User();
    return user.user_settings.unit_additional_info.copyFields;
  }

  get mapSources() {
    return ['osm', 'gRoadMap', 'gSatellite', 'gHybrid'];
  }
  get listProjectionFields() {
    return ['basic', 'contact_info'];
  }

  applyAfterLoadResources() {
    // console.log("User applyAfterLoadResources", this.resourcesList);
    super.applyAfterLoadResources();
  }

  getSystemPermissionsList() {
    if (this.systemPermissionsList) {
      return of(this.systemPermissionsList);
    }
    return this.apiRequest.authPost('role/list_system_permissions', {}).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        if (result.status_code == 200) {
          this.systemPermissionsList = result.data;
          return this.systemPermissionsList;
        }
        return {};
      })
    );
  }
  getPackageUsage() {
    return this.doGetLists('package/usage', {
      simplify: 1,
    }).pipe(
      map((data: AfaqyResponse) => {
        return data.objectList;
      })
    );
  }
  applyBeforeUpdate(params: any) {
    if (!params['parent_id']) {
      delete params['assignedItems'];
    }
    return params;
  }

  getUserPermissionsList(userID: string) {
    //console.log(userID);
    if (this.usersPermissionsList && this.usersPermissionsList[userID]) {
      return of(this.usersPermissionsList[userID]);
    }
    return this.apiRequest
      .authPost(this.getFunctionURL('permissions'), { id: userID })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          if (result.status_code == 200) {
            this.usersPermissionsList[userID] = result.data.permissions;
            //console.log(result.data.permissions);
            return this.usersPermissionsList[userID];
          }
          return [];
        })
      );
  }

  subUsersList(userID: string, incldeid: boolean = true) {
    return this.apiRequest
      .authPost(this.getFunctionURL('subsList'), {
        id: userID,
        subs: true,
      })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          if (result.status_code == 200) {
            let pList = result.data.map((item) => {
              return { id: item['_id'], name: item['username'] };
            });
            if (!incldeid) {
              pList = pList.filter((item) => item.id != userID);
            }
            return pList;
          }
          return [];
        })
      );
  }

  prepareSubusres(items: any[]) {
    if (items && items.length) {
      items = items.map((item) => {
        return {
          id: item['_id'],
          username: item['username'],
          email: item['email'],
          parent_id: item['parent_id'],
          subusers: this.prepareSubusres(
            item['subusers'] ? item['subusers'] : []
          ),
        };
      });
    }
    return items;
  }

  usersListAsTree(params: any = {}, refresh: boolean = false) {
    if (!refresh && this.usersTreeList) {
      return of(this.usersTreeList);
    }
    return this.apiRequest.authPost(this.getFunctionURL('tree'), params).pipe(
      map((result: AfaqyAPIResponse) => {
        let ulist = [];
        if (result.status_code == 200) {
          ulist = this.prepareSubusres(result.data);
        }
        this.usersTreeList = ulist;
        return ulist;
      })
    );
  }

  serversList() {
    if (this.syetmServersList.length) {
      return of(this.syetmServersList);
    }
    return this.apiRequest.authPost('user/servers', {}).pipe(
      map((result: AfaqyAPIResponse) => {
        if (result.status_code == 200) {
          let list = [];
          for (let serverID in result.data) {
            list.push({
              id: serverID,
              title: result.data[serverID],
            });
          }
          this.syetmServersList = list;
          return list;
        }
        return [];
      })
    );
  }

  clearDataList() {
    this.usersTreeList = null;
  }

  settings(userID: string) {
    return this.apiRequest.authPost('user/settings', { id: userID }).pipe(
      map((result: AfaqyAPIResponse) => {
        if (result.status_code == 200) {
          return result.data;
        }
        return {};
      })
    );
  }

  gridColumns(trashed: boolean = false) {
    let allcols: any[] = [
      {
        header: 'username',
        minWidth: 100,
        width: '*',
        colValue: 'username',
        active: true,
        immutable: true,
        default: true,
      },
      {
        header: 'role_name',
        minWidth: 75,
        width: '*',
        colValue: 'role_name.n',
        active: false,
        default: true,
      },
      {
        header: 'manager',
        minWidth: 75,
        width: '*',
        colValue: 'parent.username',
        active: false,
        default: true,
      },
      // { header: "units_count", "minWidth": 75, "width": '*', colValue: "units_count", active: false, default: true },
      // { header: "units_groups_count", "minWidth": 75, "width": '*', colValue: "units_groups_count", active: false, default: true },
      {
        header: 'email',
        minWidth: 75,
        width: '*',
        colValue: 'email',
        active: false,
        default: true,
      },
      {
        header: 'last_login_date',
        minWidth: 75,
        width: '*',
        colValue: 'last_login_date',
        active: false,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 60,
        width: 150,
        colValue: 'actions',
        active: false,
        default: true,
        extra: [
          {
            header: 'login_as',
            permissions: '',
            code: 'login_as',
            faclass: 'fa-external-link',
          },
          {
            header: 'loginAsNewWindow',
            permissions: '',
            code: 'loginAsNewWindow',
            faclass: 'mdi mdi-window-restore',
          },
        ],
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
    }
    return AfaqyHelper.cloneObject(allcols);
  }

  get listingIcon() {
    return 'afaqy-icon-users';
  }
}
