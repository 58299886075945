import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/core/services';
import { fromEvent, Subscription } from 'rxjs';
import { take, takeWhile, filter } from 'rxjs/operators';
import { AfaqyHelper, AppConfig } from '../../../common';
import { SessionStatusEnum } from '../../enums';

@Component({
  selector: 'app-validate-form',
  styleUrls: ['./validate-token.component.scss'],
  templateUrl: './validate-token.component.html',
})
export class ValidateTokenComponent implements OnInit, OnDestroy {
  alive: boolean = true;
  isLoading = false;
  configs = AppConfig;
  eventSubscription: Subscription;
  backgroundEvent: Subscription;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: false,
    pauseOnHover: false,
  };

  slides = [
    {
      heading: 'Everything',
      text: 'We Calculate',
      image: 'assets/images/login-side-images/Illustration1.png',
    },
    {
      heading: 'Live Monitoring',
      text: 'Tracking Units with a',
      image: 'assets/images/login-side-images/Illustration3.png',
    },
    {
      heading: 'Accessible',
      text: 'All Accurate and',
      image: 'assets/images/login-side-images/Illustration2.png',
    },
  ];
  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService
  ) {
    this.getAccessTokenFromAvlLogin();
  }

  ngOnInit(): void {
    this.authService.loadedSession
      .pipe(
        takeWhile(() => this.alive),
        filter((loaded) => loaded),
        take(1)
      )
      .subscribe({
        next: (loaded) => {
          // console.log('loadedSession', loaded)
          this.authService.redirectTo('/', false);
        },
      });

    this.backgroundEvent = fromEvent(document, 'visibilitychange')
      .pipe(
        takeWhile(() => this.alive),
        take(1)
      )
      .subscribe({
        next: (val) => {
          // console.log('fromEvent visibilitychange', val);
          // console.log('document.hidden', document.hidden)
          if (document.hidden) {
            if (this.authService.loadedSession.getValue()) {
              this.authService.redirectTo('/', false);
            }
          }
        },
      });

    this.eventSubscription = fromEvent(document, 'load')
      .pipe(
        takeWhile(() => this.alive),
        take(1)
      )
      .subscribe({
        next: (val) => {
          // console.log('fromEvent load', val);
          // console.log('document.hidden', document.hidden)
          if (document.hidden) {
            if (this.authService.loadedSession.getValue()) {
              this.authService.redirectTo('/', false);
            }
          }
        },
      });

    /**
     * Observe on session status while loginAs function, in order to handle redirect behaviour
     */
    this.authService._sessionStatusObserver
      .pipe(
        takeWhile(() => this.alive),
        filter((status) => status !== SessionStatusEnum.LOADING),
        take(1)
      )
      .subscribe({
        next: (status) => {
          // console.log('status', status)
          if (status === SessionStatusEnum.ACTIVE) {
            this.authService.redirectTo('/', false);
          } else if (status === SessionStatusEnum.INVALID) {
            // TODO handle invalid case, !!!TEMPORARY!!! navigate to login
            return this.authService.redirectTo('/auth/login');
          } else {
            return this.authService.redirectTo('/auth/login');
          }
        },
      });
  }

  getAccessTokenFromAvlLogin() {
    // AfaqyHelper.clearStorage();
    AfaqyHelper.clearBrowserHistory();
    if (this.activatedRoute.snapshot.params.token) {
      this.getSessionDataWithNewToken(
        this.activatedRoute.snapshot.params.token
      );
    }
  }

  getSessionDataWithNewToken(token: any) {
    this.authService.setAuthScope('validate');
    this.authService.authToken = token;
    this.authService.loadSessionData('/', 'validate');
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
