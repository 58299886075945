import { AfaqyObject, AppModel } from '../../../common/classes';

export class UnitCommand extends AppModel {
  private _unit_id: string;
  private _param: string;
  private _name: string;
  private _protocol_command_id: string;
  private _channel: string;
  private _custom: boolean;
  private _type: string;

  protected copyFields = [
    'id',
    'param',
    'name',
    'protocol_command_id',
    'unit_id',
    'channel',
    'custom',
    'type',
  ];
  protected createFields = [
    'param',
    'name',
    'protocol_command_id',
    'unit_id',
    'channel',
    'custom',
  ];
  protected updateFields = [
    'param',
    'name',
    'protocol_command_id',
    'unit_id',
    'channel',
    'custom',
  ];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get unit_id(): string {
    return this._unit_id;
  }

  set unit_id(value: string) {
    this._unit_id = value;
  }

  get protocol_command_id(): string {
    return this._protocol_command_id;
  }

  set protocol_command_id(value: string) {
    this._protocol_command_id = value;
  }

  get channel(): string {
    return this._channel;
  }

  set channel(value: string) {
    this._channel = value;
  }

  get param(): string {
    return this._param;
  }

  set param(value: string) {
    this._param = value;
  }

  get custom(): boolean {
    return this._custom;
  }

  set custom(value: boolean) {
    this._custom = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }
}
