import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, combineLatest } from 'rxjs';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../core/services';
import { CustomField } from './models';

@Injectable({ providedIn: 'root' })
export class CustomFieldsService extends RootService {
  keyTypesList = [
    { id: 'general', nameEn: 'General', nameAr: 'عام' },
    { id: 'date', nameEn: 'Date', nameAr: 'تاريخ' },
    { id: 'email', nameEn: 'Email', nameAr: 'بريد الكتروني' },
    { id: 'phone', nameEn: 'Phone', nameAr: 'هاتف' },
  ];
  resourceTypesList = [{ id: 'unit', nameEn: 'Unit', nameAr: 'وحدة' }];
  customFieldsKeyChange: BehaviorSubject<any[]> = new BehaviorSubject(null);
  customFieldsBeforeDelete: CustomField;

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    private translate: TranslateService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  statusList() {
    return this.getOptionsList('custom_fields');
  }

  routerPrefix(val: string = '') {
    return val ? val : 'custom_fields';
  }

  get modelInstance() {
    return new CustomField();
  }

  gridColumns(trashed = false) {
    let allcols = [
      {
        header: this.cid + '.resource_type',
        minWidth: 75,
        width: '*',
        colValue:
          this.translate.currentLang === 'en'
            ? 'resource_typeEn'
            : 'resource_typeAr',
        active: true,
        immutable: true,
        default: true,
      },
      {
        header: this.cid + '.key',
        minWidth: 50,
        width: '*',
        colValue: 'key',
        active: true,
        immutable: true,
        default: true,
      },
      {
        header: this.cid + '.type',
        minWidth: 50,
        width: '*',
        colValue: this.translate.currentLang === 'en' ? 'typeEn' : 'typeAr',
        active: true,
        immutable: true,
        default: true,
      },
      {
        header: this.cid + '.default_value',
        minWidth: 50,
        width: '*',
        colValue: 'default_value',
        active: false,
        immutable: false,
        default: true,
      },
      {
        header: this.cid + '.measurement_unit',
        minWidth: 50,
        width: '*',
        colValue: 'measurement_unit',
        active: false,
        immutable: false,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 60,
        width: 100,
        colValue: 'actions',
        active: true,
        default: true,
        extra: [
          {
            header: 'assign_user',
            permissions: this.cid + '-assign',
            code: 'assign',
            faclass: 'fa-link',
          },
          {
            header: 'unassign_user',
            permissions: this.cid + '-unassign',
            code: 'unassign',
            faclass: 'fa-unlink',
          },
        ],
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
    }
    return allcols;
  }

  get listingIcon() {
    return 'mdi mdi-pencil';
  }
  refactorListResponse(res) {
    res?.list?.map((item) => {
      return this.translateCustomField(item);
    });
    return res;
  }

  applyAfterListTrashed(list: any[]) {
    return list?.map((item) => {
      return this.translateCustomField(item);
    });
  }

  getCustomFieldKeys(key?: string) {
    let keys = [];
    if (this.resourcesList.length) {
      const items: CustomField[] = this.resourcesList;
      keys = items
        .filter((x) => x?.unitIds?.length)
        .map((customField: any) => customField.key);
      return keys;
    }
  }
  setResourceObject(obj: any) {
    this._resourcesList[obj[this.IdentifierKey]] = obj;
    // console.log('resourceList: ', this._resourcesList);
  }
  applyBeforeDelete(id: any): void {
    this.customFieldsBeforeDelete = this.resourcesList.find((x) => x.id == id);
  }

  updateObjectsRelations(updates: any) {
    if (updates['action'] == 'addMulti') {
      this.updateResourceField(
        this.customFieldsBeforeDelete.id,
        'unitIds',
        this.customFieldsBeforeDelete.unitIds
      );
    }
  }

  refactorViewResponse(res) {
    this.translateCustomField(res.data);
    /**
     * ignore apply_edits_on_units flag if boolean to not flag it as true
     */
    res.data.apply_edits_on_units = false;

    let id = res?.data?.id;
    if (id) {
      const itemFromList = this.getItemFromResources(id);
      if (itemFromList) {
        // console.log('itemFromList', itemFromList);
        /**
         * setting unitIds to previous set from list after view or edit
         */
        res.data.unitIds = itemFromList.unitIds;
        // console.log('response', res)
      }
    }

    return super.refactorViewResponse(res);
  }

  findTranslateKey(list: Array<any>, keyName, resultKey) {
    const item = list.find((x) => x.id == keyName);
    return item[resultKey];
  }
  translateCustomField(response) {
    if (response.type) {
      response.typeEn = this.findTranslateKey(
        this.keyTypesList,
        response.type,
        'nameEn'
      );
      response.typeAr = this.findTranslateKey(
        this.keyTypesList,
        response.type,
        'nameAr'
      );
    }
    if (response.resource_type) {
      response.resource_typeEn = this.findTranslateKey(
        this.resourceTypesList,
        response.resource_type,
        'nameEn'
      );
      response.resource_typeAr = this.findTranslateKey(
        this.resourceTypesList,
        response.resource_type,
        'nameAr'
      );
    }
    return response;
  }
}
