export class SensorType {
  public static formOptions = {
    types: [
      'acc',
      'di',
      'ai',
      'temperature',
      'humidity',
      'door',
      'door2',
      'CU',
      'rfid',
      'rpm',
      'uhfid',
      'weight',
      'liquid',
      'fuel',
      'fuel_per',
      'custom',
      'eng_hour',
      'milage',
      'fc',
      'tailer_bind',
      'driver_bind',
      'in_battery_volt',
      'ex_battery_volt',
      'passengers',
      'sos',
      'sos_passenger',
      'accident',
      'gprs',
      'sms',
      'pto1',
      'pto2',
      'vehicle_speed_sensor',
      'harsh_acceleration_sensor',
      'harsh_break',
      'harsh_cornering',
      'panic_button_sensor',
      'seat_belt',
      'fl_ext',
      'fl_can',
      'fc_can',
      'total_fuel_can',
      'overspeed',
      'excessive_overspeed',
      'tampering',
      'engine_temperature',
      'fuel_level',
      'arm_movement',
      'arm_angle',
      'speedlimit',
    ],
    result_types: [
      'logic',
      'logicgte',
      'string',
      'value',
      'percentage',
      'codeValue',
      'hexString',
    ],
    formulas: ['+', '-', '/', '*'],
    params: ['parm253'],
  };
}

export class zoneCondition {
  public static formOptions = {
    zone_condition: ['zone_in', 'zone_out'],
  };
}
