import { AfaqyObject } from './afaqy-object';
import { AfaqyHelper } from './afaqy-helper';

export class AppModel {
  protected _id: string;
  protected _users_list: any[] = [];
  protected createFields = [];
  protected updateFields = [];
  protected copyFields = ['id', 'users_list'];
  public updloadfiles;
  public titleField = '';

  constructor() {}

  public clone() {
    var cloneObj = new (<any>this).constructor();
    for (const attribut of this.copyFields) {
      if (this[attribut] instanceof AfaqyObject && this[attribut]) {
        cloneObj[attribut].copyInto(this[attribut]);
      } else {
        cloneObj[attribut] = this[attribut];
      }
    }
    return cloneObj;
  }

  public toJson() {
    let json = {};
    let obj = this;
    for (const key of this.copyFields) {
      json[key] = obj[key];
    }
    return json;
  }

  public copyInto(obj: any, simplify = true) {
    for (let key of this.copyFields) {
      const mappedKey = simplify ? AfaqyHelper.getMappedKey(key) : key;
      if (this[key] instanceof AfaqyObject) {
        //&& obj[mappedKey]
        this[key].copyInto(
          obj[mappedKey] ? obj[mappedKey] : obj[key] ? obj[key] : {},
          simplify
        );
      } else if (key == 'id') {
        this[key] = obj['_id'] ? obj['_id'] : obj['id'];
      } else {
        this[key] =
          obj[mappedKey] || obj[mappedKey] == false
            ? obj[mappedKey]
            : obj[key]
            ? obj[key]
            : '';
      }
    }
  }

  public copyArrayInto(obj, key, instance) {
    const mappedKey = AfaqyHelper.getMappedKey(key);
    for (const x in obj[mappedKey]) {
      instance.copyInto(obj[mappedKey][x]);
      let objects = this[key].push(instance);
      this[key] = objects;
    }
  }

  public preparePostData(field) {
    let data = {};
    for (let key of this[field]) {
      if (
        (this[key] instanceof AppModel || this[key] instanceof AfaqyObject) &&
        this[key]
      ) {
        data[key] = this[key].preparePostData(field);
      } else if (
        this[key] instanceof Array &&
        (this[key][0] instanceof AppModel ||
          this[key][0] instanceof AfaqyObject)
      ) {
        let list = [];
        this[key].forEach((inst) => {
          list.push(inst.preparePostData(field));
        });
        data[key] = list;
      } else {
        data[key] = this[key];
      }
    }
    if (this.updloadfiles) {
      data['updloadfiles'] = this.updloadfiles;
    }
    return data;
  }

  public getCreatePostData() {
    return this.preparePostData('createFields');
  }

  public getUpdatePostData() {
    return this.preparePostData('updateFields');
  }

  titleForList() {
    if (this.titleField !== '') {
      return this[this.titleField];
    }
    if (this['title']) {
      return this['title'];
    }
    if (this['name']) {
      return this['name'];
    }
    return '';
  }

  forList() {
    return { id: this.id, title: this.titleForList() };
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get users_list(): any[] {
    return this._users_list;
  }

  set users_list(value: any[]) {
    this._users_list = value && value.length ? value : [];
  }
}
