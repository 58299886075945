import {
  RootService,
  AuthService,
  ApiRequestService,
  AfaqyAlertService,
} from 'app/core/services';
import { NotificationsService } from 'angular2-notifications';
import { PlanDetails } from '../models';
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AfaqyResponse } from 'app/core/classes';
import { Subject } from 'rxjs';

@Injectable()
export class PlanDetailsService extends RootService {
  planDetails: Subject<any> = new Subject<any>();

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    private router: Router
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  updateResources(response: AfaqyResponse) {
    super.updateResources(response);
    this.planDetails.next(response);
  }

  setResourcesList(list: any) {
    this._resourcesList = list;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'routes_manual/get_plan_details';
  }

  applyBeforeList() {
    let splittedUrl = this.router.url.split('/');
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          splittedUrl = event.url.split('/');
        }
      },
    });
    const splittedId = splittedUrl[splittedUrl.length - 1].split('?')[0];
    return { id: splittedId };
  }

  getFunctionURL() {
    return this.routerPrefix();
  }

  get modelInstance() {
    return new PlanDetails();
  }

  gridColumns(trashed = false) {
    let allcols: any[] = [
      {
        header: 'routes_manual.point_name',
        minWidth: 75,
        width: '*',
        colValue: 'stop-name',
        active: true,
        immutable: true,
        default: true,
        sorting: false,
      },
      {
        header: 'routes_manual.point_address',
        minWidth: 75,
        width: '*',
        colValue: 'stop-address',
        active: true,
        immutable: false,
        default: true,
        sorting: false,
      },
    ];

    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
    }

    return allcols;
  }

  get listingIcon() {
    return 'fas fa-route';
  }
}
