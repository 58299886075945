import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { UnitsSocketConfig } from './unitStomp.config.service';

@Injectable({
  providedIn: 'root',
})
export class RxUnitsStompService extends RxStomp {
  constructor(public unitsSocketConfig: UnitsSocketConfig) {
    super();
  }
}
