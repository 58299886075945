<div class="unitProfile" [formGroup]="group">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4" secured="tailers-setUnitTailer">
      <div class="row form-group">
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.tailer_id' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  id="autoTailer"
                  type="checkbox"
                  [attr.testId]="cid + 'auto_tailer'"
                  formControlName="auto_tailer"
                />
                <label for="autoTailer"></label>
                <span class="pointer" (click)="toggleAutoTailer()">{{
                  'auto' | translate
                }}</span>
              </div>
            </div>

            <afaqy-custom-select
              class="input-group-item"
              [service]="tailerService"
              [attr.testId]="cid + 'tailer_id'"
              formControlName="tailer_id"
              [ctl]="group.controls['tailer_id']"
              [clear]="true"
            ></afaqy-custom-select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4" secured="drivers-setUnitDriver">
      <div class="row form-group">
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.driver_id' | translate }}:</label
          >
        </div>
        <div
          class="col-12 input-group form-group-hint"
          tooltip="{{ getDriverInputTooltip() }}"
        >
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  id="autoDriver"
                  type="checkbox"
                  [attr.testId]="cid + 'auto_driver'"
                  formControlName="auto_driver"
                />
                <label for="autoDriver"></label>
                <span class="pointer" (click)="toggleAutoDriver()">{{
                  'auto' | translate
                }}</span>
              </div>
            </div>

            <afaqy-custom-select
              class="input-group-item"
              [isDisabled]="isUseShifts()"
              [service]="driverService"
              [attr.testId]="cid + 'driver_id'"
              formControlName="driver_id"
              [ctl]="group.controls['driver_id']"
              [clear]="true"
            ></afaqy-custom-select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['business_types'].dirty ||
            group.controls['business_types'].touched) &&
          group.controls['business_types'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label">{{
            'units.business_types' | translate
          }}</label>
        </div>
        <div class="col-12">
          <wj-multi-select
            class="form-control form-control-sm"
            [itemsSource]="businessTypes"
            displayMemberPath="title"
            selectedValuePath="id"
            checkedMemberPath="checked"
            #st
            (checkedItemsChanged)="businessTypeChange(st.checkedItems)"
            [attr.testId]="'business_types'"
            (isDroppedDownChanged)="closeBusinesDropdown($event)"
          ></wj-multi-select>
        </div>
        <div class="col-12">
          <control-errors
            [ctl]="group.controls['business_types']"
          ></control-errors>
        </div>
      </div>
    </div>
  </div>
  <div formGroupName="profile">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].vehicle_type.dirty ||
              group.controls['profile']['controls'].vehicle_type.touched) &&
            group.controls['profile']['controls'].vehicle_type.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.vehicle_type' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'vehicle_type'"
              formControlName="vehicle_type"
            />
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].vehicle_type"
            >
            </control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].measurement_type.dirty ||
              group.controls['profile']['controls'].measurement_type.touched) &&
            group.controls['profile']['controls'].measurement_type.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.measurement_type' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <select
              class="form-control form-control-sm"
              [attr.testId]="cid + 'measurement_type'"
              formControlName="measurement_type"
            >
              <option *ngFor="let mType of measurementTypes" [value]="mType">
                {{ 'units.measurement_type.' + mType | translate }}
              </option>
            </select>
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].measurement_type"
            >
            </control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].max_capacity.dirty ||
              group.controls['profile']['controls'].max_capacity.touched) &&
            group.controls['profile']['controls'].max_capacity.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.max_capacity' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <div class="input-group">
              <input
                class="form-control form-control-sm"
                [attr.testId]="cid + 'max_capacity'"
                formControlName="max_capacity"
              />
              <control-errors-hint
                [ctl]="group.controls['profile']['controls'].max_capacity"
              >
              </control-errors-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].vin.dirty ||
              group.controls['profile']['controls'].vin.touched) &&
            group.controls['profile']['controls'].vin.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.vin' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'vin'"
              formControlName="vin"
            />
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].vin"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].plate_number.dirty ||
              group.controls['profile']['controls'].plate_number.touched) &&
            group.controls['profile']['controls'].plate_number.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.plate_number' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'plate_number'"
              formControlName="plate_number"
            />
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].plate_number"
            >
            </control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].model.dirty ||
              group.controls['profile']['controls'].model.touched) &&
            group.controls['profile']['controls'].model.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.model' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'model'"
              formControlName="model"
            />
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].model"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].year.dirty ||
              group.controls['profile']['controls'].year.touched) &&
            group.controls['profile']['controls'].year.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.year' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'year'"
              formControlName="year"
            />
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].year"
            ></control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].seats.dirty ||
              group.controls['profile']['controls'].seats.touched) &&
            group.controls['profile']['controls'].seats.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.seats' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'seats'"
              formControlName="seats"
            />
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].seats"
            ></control-errors-hint>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].fuel_type.dirty ||
              group.controls['profile']['controls'].fuel_type.touched) &&
            group.controls['profile']['controls'].fuel_type.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.fuel_type' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <select
              class="form-control form-control-sm"
              [attr.testId]="cid + 'fuel_type'"
              formControlName="fuel_type"
            >
              <option *ngFor="let p of ['91', '95', 'diesel']" [value]="p">
                {{ 'units.fuel_type.' + p | translate }}
              </option>
            </select>
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].fuel_type"
            >
            </control-errors-hint>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].start_operation.dirty ||
              group.controls['profile']['controls'].start_operation.touched) &&
            group.controls['profile']['controls'].start_operation.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.start_operation' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'start_operation'"
              formControlName="start_operation"
            />
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].start_operation"
            >
            </control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].contract_milage.dirty ||
              group.controls['profile']['controls'].contract_milage.touched) &&
            group.controls['profile']['controls'].contract_milage.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.contract_milage' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'contract_milage'"
              formControlName="contract_milage"
            />
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].contract_milage"
            >
            </control-errors-hint>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="row form-group"
          [class.has-danger]="
            (group.controls['profile']['controls'].opening_milage.dirty ||
              group.controls['profile']['controls'].opening_milage.touched) &&
            group.controls['profile']['controls'].opening_milage.errors
          "
        >
          <div class="col-12">
            <label class="form-control-label"
              >{{ 'units.profileatrrs.opening_milage' | translate }}:</label
            >
          </div>
          <div class="col-12 input-group form-group-hint">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'opening_milage'"
              formControlName="opening_milage"
            />
            <control-errors-hint
              [ctl]="group.controls['profile']['controls'].opening_milage"
            >
            </control-errors-hint>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" formGroupName="job_order">
    <div class="col-12 col-md-6 col-lg-4" secured="units-set_status">
      <div class="row form-group">
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.status_id' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <div class="input-group">
            <afaqy-custom-select
              [service]="statusService"
              [ctl]="group.controls['job_order']['controls']['status_id']"
              [attr.testId]="cid + 'status_id'"
              formControlName="status_id"
              [clear]="true"
            >
            </afaqy-custom-select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['job_order']['controls'].number.dirty ||
            group.controls['job_order']['controls'].number.touched) &&
          group.controls['job_order']['controls'].number.errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.profileatrrs.job_order' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <input
            class="form-control form-control-sm"
            [attr.testId]="cid + 'number'"
            formControlName="number"
          />
          <control-errors-hint
            [ctl]="group.controls['job_order']['controls'].number"
          ></control-errors-hint>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-lg-4">
      <div class="row form-group">
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.icon' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <div class="upload-img flex-row relative-pos">
            <button
              afaqy-tooltip
              [attr.testId]="cid + 'library'"
              [tooltipText]="'unit_messages.library' | translate"
              class="btn btn-sm-custom-height btn-success"
              (click)="toggleIconLibs()"
              type="button"
            >
              {{ 'library' | translate }}
            </button>
            <button
              afaqy-tooltip
              [attr.testId]="cid + 'uploadImageTrigger'"
              [tooltipText]="'unit_messages.computer' | translate"
              class="btn btn-primary btn-sm-custom-height uploadImgTrigger btn-file px-2 mx-2"
              (click)="uploadImageTrigging()"
              type="button"
            >
              <i class="mdi mdi-upload icon_cloud-upload_alt"></i>
              <input
                #imageInput
                id="uploadImageTrigger"
                style="display: none"
                type="file"
                name="file"
                (change)="iconChange($event)"
                accept="image/*"
              />
            </button>
            <a
              *ngIf="filesPaths.icon && filesPaths.icon['upload']"
              href="javascript:void(0)"
              (click)="iconDelete()"
              class="preview-clear"
              >x</a
            >
            <img
              *ngIf="filesPaths.icon['url']"
              width="26"
              height="26"
              [src]="filesPaths.icon['url']"
              style="display: inline"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['job_order']['controls'].desc.dirty ||
            group.controls['job_order']['controls'].desc.touched) &&
          group.controls['job_order']['controls'].desc.errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.profileatrrs.status_description' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <textarea
            rows="3"
            class="form-control form-control-sm"
            [attr.testId]="cid + 'desc'"
            formControlName="desc"
          ></textarea>
          <control-errors-hint
            [ctl]="group.controls['job_order']['controls'].desc"
          ></control-errors-hint>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="cctvApp" formGroupName="cctv" secured="cctv-unit">
    <div class="col-12 mt-5">
      <h6 _ngcontent-c36="" class="text-theme-color">
        {{ 'units.cctv.title' | translate }}:
      </h6>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['cctv']['controls']['dvr_id'].dirty ||
            group.controls['cctv']['controls']['dvr_id'].touched) &&
          group.controls['cctv']['controls']['dvr_id'].errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.cctv_dvr_id' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <div class="input-group">
            <input
              class="form-control form-control-sm"
              [attr.testId]="cid + 'dvr_id'"
              formControlName="dvr_id"
            />
          </div>
          <control-errors-hint
            [ctl]="group.controls['cctv']['controls']['dvr_id']"
          ></control-errors-hint>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="row form-group"
        [class.has-danger]="
          (group.controls['cctv']['controls'].channels.dirty ||
            group.controls['cctv']['controls'].channels.touched) &&
          group.controls['cctv']['controls'].channels.errors
        "
      >
        <div class="col-12">
          <label class="form-control-label"
            >{{ 'units.cctv_channels' | translate }}:</label
          >
        </div>
        <div class="col-12 input-group form-group-hint">
          <select
            class="form-control form-control-sm"
            [attr.testId]="cid + 'channels'"
            formControlName="channels"
          >
            <option value="1">1</option>
            <option value="4">4</option>
            <option value="9">9</option>
          </select>
          <control-errors-hint
            [ctl]="group.controls['cctv']['controls'].channels"
          ></control-errors-hint>
        </div>
      </div>
    </div>
  </div>
</div>
<afaqy-modal
  *ngIf="showIconLibrary"
  title="select_library_icon"
  (modalClose)="hideIconLibs()"
  #modal="afaqyModal"
  [draggable]="false"
  sizeClass="modal-dialog-centered show-icon-library"
>
  <div class="modalbody">
    <div class="tab-content libraryIcons pb-5">
      <button
        *ngFor="let icon of formOptions.iconsList"
        type="button"
        class="btn btn-light icon"
        [ngClass]="{ 'active-icon': group.controls['icon'].value === icon.key }"
        (click)="updateIcon(icon)"
      >
        <img [src]="icon.url" />
      </button>
      <div class="divider5"></div>
    </div>
  </div>
  <!-- <div class="modalfooter">
        <button type="button" class="btn btn-outline-danger btn-custom float-left mx-2" (click)="hideIconLibs()">
            <i class="mdi mdi-close"></i> {{ 'Cancel' | translate }}</button>
    </div> -->
</afaqy-modal>
