import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { Customers } from '../models';
import { CustomerTypesService } from './customer_types.service';

@Injectable()
export class CustomersService extends RootService {
  constructor(
    public customerTypesService: CustomerTypesService,
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    protected translate: TranslateService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.customerTypesService.resources.subscribe({
      next: (updates) => {
        this.updateCustomerTypeRelations(updates);
      },
    });
  }

  applyAfterLoadResources() {
    //this.customerTypesService.autoLoadResources();
    super.applyAfterLoadResources();
  }

  setResourceObject(obj) {
    let vids = this.authService.preferences('customers', 'visible');
    if (vids?.includes(obj?.id)) {
      obj['visible'] = 1;
    } else {
      obj['visible'] = 0;
    }
    super.setResourceObject(obj);
  }

  updateCustomerTypeRelations(updates) {
    if (updates['action'] == 'list') {
      for (const ct of updates['data']) {
        for (const key in this._resourcesList) {
          if (ct.id == this._resourcesList[key].customer.type_id) {
            this._resourcesList[key].customer.customer_type = ct;
          }
        }
      }
    } else if (updates['action'] == 'remove') {
      for (const key in this._resourcesList) {
        if (
          this._resourcesList[key].customer.customer_type == updates['data']
        ) {
          this._resourcesList[key].customer.customer_type =
            this.customerTypesService.modelInstance;
        }
      }
    } else if (updates['action'] == 'update') {
      for (const key in this._resourcesList) {
        if (
          this._resourcesList[key].customer.customer_type ==
          updates['data']['id']
        ) {
          this._resourcesList[key].customer.customer_type = updates['data'];
        }
      }
    }
    this.pushChanges();
  }

  updateObjectsRelations(updates) {
    if (updates['action'] == 'list' || updates['action'] === 'addMulti') {
      for (const customerObj of updates['data']) {
        customerObj.customer.customer_type =
          this.customerTypesService.getItemFromResources(
            customerObj.customer.type_id
          );
        this.setResourceObject(customerObj);
      }
    } else if (updates['action'] == 'update' || updates['action'] == 'add') {
      this.customerTypesService.getItemFromResources(
        updates['data']?.customer?.type_id
      );
      if (Array.isArray(updates['data']))
        this.setResourcesList(updates['data']);
      else this.setResourcesList([updates['data']]);
    }
  }

  customersList() {
    return this.getOptionsList('customers');
    // return this.apiRequest.authPost("customers/lists", {limit: 0, projection: ['id', 'name', 'customer.type_id']}).map((result: AfaqyAPIResponse) => {
    //     if (result.status_code == 200) {
    //         let list = [];
    //         for (let obj of result.data) {
    //             let object = new Customers();
    //             object.copyInto(obj);
    //             list.push(object);
    //         }
    //         return list;
    //     }
    //     return [];
    // });
  }

  get zoneTypes() {
    return Customers.zoneTypes;
  }

  get customerStatus() {
    return Customers.statusVal;
  }

  get customerNotification() {
    return Customers.notifications;
  }

  get customerPriority() {
    return Customers.priority;
  }

  get customerConsuming() {
    return Customers.consuming;
  }

  get isPersonal() {
    return Customers.isPersonal;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'customers';
  }

  get modelInstance() {
    return new Customers(this.translate);
  }

  get listingIcon() {
    return 'afaqy-icon-customers';
  }
}
