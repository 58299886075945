import { AppModel } from 'app/common';

export class Amanat extends AppModel {
  private _username: string = '';
  private _imei: string;
  private _unit_list: string;
  private _unit_name: string;
  private _plate_number: string;
  private _created_at: Date;
  private _activity: string;
  private _amant: string;


  protected copyFields = [
    'id',
    'username',
    'user_id',
    'unit_list',
    'unit_name',
    'imei',
    'plate_number',
    'activity',
    'created_at',
    'amant'
  ];
  protected createFields = [
    'username',
    'user_id',
    'unit_list',
    'unit_name',
    'imei',
    'plate_number',
    'activity',
    'created_at',
    'amant'
  ];
  protected updateFields = [
    'username',
    'user_id',
    'unit_list',
    'unit_name',
    'imei',
    'plate_number',
    'activity',
    'created_at',
    'amant'
  ];

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  public get imei(): string {
    return this._imei;
  }
  public set imei(value: string) {
    this._imei = value;
  }

  public get unit_list(): string {
    return this._unit_list;
  }
  public set unit_list(value: string) {
    this._unit_list = value;
  }

  public get unit_name(): string {
    return this._unit_name;
  }
  public set unit_name(value: string) {
    this._unit_name = value;
  }

  public get plate_number(): string {
    return this._plate_number;
  }
  public set plate_number(value: string) {
    this._plate_number = value;
  }

  public get activity(): string {
    return this._activity;
  }
  public set activity(value: string) {
    this._activity = value;
  }

  public get created_at(): Date {
    return this._created_at;
  }
  public set created_at(value: Date) {
    this._created_at = value;
  }

  public get amant(): string {
    return this._amant;
  }
  public set amant(value: string) {
    this._amant = value;
  }
}

export const AMANAT_ACTIVITY_LIST = [
  { id: 'garbage', title: 'Garbage' },
  { id: 'cleaning truck', title: 'Cleaning Truck' },
];
