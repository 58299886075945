<div class="otp-wrapper">
  <div class="verify-icon">
    <mat-icon
      class="avl-icon"
      [svgIcon]="'icon-account-verification-mark'"
      [attr.size]="'110px'"
    >
    </mat-icon>
    <p>
      {{ 'otp-verify-account.otp-description' | translate }}
      <span>{{ otpModel.method[otpModel.type].value }}</span>
      {{ 'otp-verify-account.otp-confirmation' | translate }}
    </p>
  </div>
  <div>
    <div class="otp-container">
      <ngx-otp-input
        #otpInput
        class="custom-otp-input {{
          otpStatus === 'error' ? 'error-border' : ''
        }}"
        [config]="otpInputConfig"
        (fill)="otpValue($event)"
        [config.status]="otpStatus"
        (otpChange)="otpChangeValue($event)"
      ></ngx-otp-input>
    </div>

    <div class="disclamier-message">
      <p>{{ otpModel.method[this.otpModel.type].disclaimerMessage }}</p>
    </div>
    <div class="resend-div">
      <p [style.direction]="translate.currentLang == 'ar' ? 'rtl' : 'ltr'">
        <button
          class="resend-button"
          [disabled]="
            otpModel.method[otpModel.type].resend_expires_In > 0 ||
            otpModel.method[otpModel.type].verify_expires_In > 0 ||
            otpModel.method[otpModel.type].blocked
          "
          (click)="resendCode()"
        >
          {{ 'otp-verify-account.resend-code' | translate }}
        </button>
        <span
          *ngIf="
            otpModel.method[otpModel.type].resend_expires_In ||
            otpModel.method[otpModel.type].verify_expires_In
          "
          >{{ 'otp-verify-account.in' | translate }}
          <span class="red">{{
            remainingResendTime?.value || remainingVerifyTime?.value
              ? remainingResendTime?.value > remainingVerifyTime?.value
                ? remainingResendTime?.text
                : remainingVerifyTime?.text
              : ''
          }}</span></span
        >
      </p>
    </div>
  </div>
</div>
