import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
}
if (environment.sentryConfig?.url) {
  Sentry.init({
    dsn: environment.sentryConfig.url,
    environment: environment?.sentryConfig.envName,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  ngZoneEventCoalescing: true,
  ngZoneRunCoalescing: true,
});
