import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CustomerTypesService } from '../../modules/customers/services';

@Injectable()
export class CustomerTypesResolver implements Resolve<any> {
  constructor(public service: CustomerTypesService) {}

  resolve() {
    return this.service.customerTypesList();
  }
}
