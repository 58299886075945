import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';

import { ProtocolCommandService } from 'app/modules/protocol_command/services';
import { Unit, UnitCommand } from 'app/modules/units/models';
import { UnitCommandService, UnitService } from 'app/modules/units/services';
import { AuthService } from '../../../../core/services';
import { Message } from './../../../../common/classes';

@Component({
  selector: 'unit-form-commands',
  templateUrl: './unit-form-commands.component.html',
  styleUrls: ['./unit-form-commands.component.scss'],
})
export class UnitFormCommandsComponent implements OnChanges, OnInit, OnDestroy {
  alive: boolean = true;
  @Input() group: UntypedFormGroup;
  @Input() unitObject: Unit;
  @Input() device: string;
  @Input() refresh: any;
  @Input() resourceID: any;
  @Input() isAdd?: boolean; // used to disable the 'add new service' button in case of adding a new unit
  loading = false;
  filtersCols = ['name'];
  controller = 'unit_commands';
  itemsList: wjcCore.CollectionView;
  commandsList: any[];
  cid = 'units-unit_commands-';
  showForm = false;
  showClone = false;
  updatingIndex = -1;
  selectedItem: UnitCommand;
  message: Message;
  commandId: string;
  isView: boolean = false;
  editPermission: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public service: UnitCommandService,
    public unitService: UnitService,
    private protocolCommandService: ProtocolCommandService
  ) {
    this.itemsList = new wjcCore.CollectionView([]);
    this.message = new Message();
    this.protocolCommandService.startAutoload();
  }

  ngOnInit() {
    this.loadItems();
    this.protocolCommandService.resources.subscribe({
      next: () => {
        this.loadItems();
      },
    });
    if (this.authService.checkPermissions(this.controller + '-edit', '')) {
      this.editPermission = true;
    }
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  ngOnChanges() {
    this.itemsList.refresh();
  }

  loadItems() {
    this.itemsList.sourceCollection = this.unitObject.commands.map(
      (command) => {
        if (command.protocol_command_id) {
          command.type = this.protocolCommandService.getResourceTitle(
            command.protocol_command_id
          );
        } else {
          command.type = 'custom';
        }

        return command;
      }
    );
  }

  openForm() {
    this.showForm = true;
  }

  updateCommands(event) {
    if (this.updatingIndex != -1) {
      this.itemsList.sourceCollection[this.updatingIndex] = event.object;
      this.itemsList.refresh();
    } else {
      this.itemsList.sourceCollection = this.itemsList.sourceCollection.concat([
        event.object,
      ]);
    }
    this.unitObject.commands = this.itemsList.sourceCollection;
    this.showForm = false;
    this.showClone = false;
    this.selectedItem = new UnitCommand();
    this.loadItems();
  }

  closeForm(event) {
    this.showForm = false;
    if (event && event['success'] && event['msg']) {
      this.message.dismissible = true;
      this.message.timeout = 3000;
      this.message.type = event['type'];
      this.message.message = event['msg'];
    }
  }

  refreshList() {
    this.loadItems();
  }

  addForm() {
    this.isView = false;
    this.updatingIndex = -1;
    this.selectedItem = new UnitCommand();
    this.openForm();
  }

  edit(index, item) {
    this.isView = false;
    this.updatingIndex = index;
    this.selectedItem = item;
    this.openForm();
  }

  view(index, item) {
    this.isView = true;
    this.updatingIndex = index;
    this.selectedItem = item;
    this.openForm();
  }

  copy(item) {
    this.isView = false;
    this.updatingIndex = -1;
    this.selectedItem = item.clone();
    this.selectedItem.id = '';
    this.openForm();
  }

  delete(item) {
    this.isView = false;
    if (this.unitObject.id) {
      const id = item['id'];
      let subscription = this.service.confirm('confirm-delete').subscribe({
        next: (response) => {
          if (response) {
            this.loading = true;
            this.service.delete(id).subscribe({
              next: () => {
                this.message.fill(
                  'notifications.unit_commands.deleted',
                  '',
                  'success'
                );
                this.itemsList.remove(item);
              },
              error: () => {
                this.message.fill('notifications.tryagain', '', 'error');
              },
              complete: () => {
                this.loading = false;
              },
            });
          }
          subscription.unsubscribe();
        },
      });
      return false;
    } else {
      this.itemsList.remove(item);
    }
  }

  openClone() {
    this.showClone = true;
  }

  closeClone(event) {
    this.showClone = false;
    if (event && event['success'] && event['msg']) {
      this.message.dismissible = true;
      this.message.timeout = 3000;
      this.message.type = event['type'];
      this.message.message = event['msg'];
    }
  }

  clone(index, item) {
    this.updatingIndex = index;
    this.selectedItem = item;
    this.commandId = item.id;
    this.isView = false;
    this.openClone();
  }
}
