export class copyToExportOptions {
  main: string[] = ['device', 'device_type', 'device_password'];
  profile: string[] = [
    'vehicle_type',
    'measurement_type',
    'max_capacity',
    'model',
    'fuel_type',
    'year',
    'seats',
    'auto_tailer',
    'auto_driver',
  ];
  fc: boolean = false;
  accuracy: boolean = false;
  shipment: boolean = false;
  sensors: string[] = [''];
  commands: boolean = false;
  services: boolean = false;
  groups: boolean = false;
  users_list: boolean = false;
  driver_behaviour: boolean = false;
  custom_fields: string[] = [''];
}
