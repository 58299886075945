import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'afaqy-loading',
  templateUrl: './afaqy-loading.component.html',
  styleUrls: ['./afaqy-loading.component.css'],
})
export class AfaqyLoadingComponent implements OnInit {
  @Input() transparent = true;
  @Input()
  set loading(value: boolean) {
    this._loading = value;
  }

  get loading(): boolean {
    return this._loading;
  }

  private _loading: boolean = false;

  constructor() {}

  ngOnInit() {}
}
