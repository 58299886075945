import { AppModel } from '../../../common/classes';

export class Chat extends AppModel {
  private _resource_id: string;
  private _resource_type: string;
  private _content: string;
  private _gateway: string;
  private _cmd_type: string;
  private _number: string;
  private _user_id: string;
  private _type: number;
  private _status: number;
  private _date: number;
  private _message_date: Date;
  private _message_deliver_date: Date;

  protected copyFields = [
    'id',
    'resource_id',
    'resource_type',
    'content',
    'gateway',
    'cmd_type',
    'number',
    'user_id',
    'type',
    'status',
    'date',
    'message_date',
    'message_deliver_date',
  ];
  protected createFields = [
    'resource_id',
    'resource_type',
    'content',
    'gateway',
    'cmd_type',
  ];
  protected updateFields = [
    'resource_id',
    'resource_type',
    'content',
    'gateway',
    'cmd_type',
  ];
  static cmdTypes = [
    { value: 'hex', display: 'HEX' },
    { value: 'ascii', display: 'ASCII' },
  ];
  static gateways = [
    { value: 'sms', display: 'SMS' },
    { value: 'gprs', display: 'GPRS' },
  ];

  get resource_id(): string {
    return this._resource_id;
  }

  set resource_id(value: string) {
    this._resource_id = value;
  }

  get resource_type(): string {
    return this._resource_type;
  }

  set resource_type(value: string) {
    this._resource_type = value;
  }

  get content(): string {
    return this._content;
  }

  set content(value: string) {
    this._content = value;
  }

  get gateway(): string {
    return this._gateway;
  }

  set gateway(value: string) {
    this._gateway = value;
  }

  get cmd_type(): string {
    return this._cmd_type;
  }

  set cmd_type(value: string) {
    this._cmd_type = value;
  }

  get number(): string {
    return this._number;
  }

  set number(value: string) {
    this._number = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get type(): number {
    return this._type;
  }

  set type(value: number) {
    this._type = value;
  }

  get status(): number {
    return this._status;
  }

  set status(value: number) {
    this._status = value;
  }

  get message_date(): Date {
    return this._message_date;
  }

  set message_date(value: Date) {
    this._message_date = value;
  }

  get message_deliver_date(): Date {
    return this._message_deliver_date;
  }

  set message_deliver_date(value: Date) {
    this._message_deliver_date = value;
  }

  get date(): number {
    return this._date;
  }

  set date(value: number) {
    this._date = value;
  }
}
