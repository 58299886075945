import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../common/classes/index';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventsService } from '../../../modules/events/services';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services';
import { EventStatusService } from '../../../modules/event-status/services';
import { EventData } from 'app/modules/events/models';

@Component({
  selector: 'event-comments',
  templateUrl: './event-comments.component.html',
  styleUrls: ['./event-comments.component.scss'],
})
export class EventCommentsComponent implements OnInit {
  data;
  form: UntypedFormGroup;
  eventDataID;
  config = AppConfig;
  event: EventData;

  constructor(
    private service: EventsService,
    private router: Router,
    protected fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private eventStatusService: EventStatusService
  ) {
    this.createForm();
  }

  formOptions = {
    statusList: [],
  };

  ngOnInit() {
    this.getEventDataID();
    this.event = this.service.getEventData(this.eventDataID);
    this.loadStatus();
    this.listComments();
  }

  getEventDataID() {
    this.activatedRoute.queryParams.subscribe({
      next: (params: Params) => {
        if (params['eventDataId']) {
          this.eventDataID = params['eventDataId'];
        }
      },
    });
  }

  loadStatus() {
    this.eventStatusService.getOptionsList('event_status').subscribe({
      next: (data) => {
        this.formOptions.statusList = data;
      },
    });
  }

  createForm() {
    this.form = this.fb.group(this.form_fields);
  }

  get form_fields(): any {
    return {
      description: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(400),
        ],
      ],
      status: ['', [Validators.required]],
    };
  }

  onSubmit() {
    this.addComment(this.form.value);
  }

  get title(): string {
    return 'notifications.comments.notification_comments';
  }

  addComment(data) {
    this.service
      .addEventComment(data.status, this.eventDataID, data.description)
      .subscribe({
        next: (res) => {
          if (res.id) {
            this.listComments();
          }
        },
      });
  }

  listComments() {
    this.service.listEventComments(this.eventDataID).subscribe({
      next: (res) => {
        this.data = res;
      },
    });
  }

  closeModal() {
    var l = document.getElementById('notifications-sidebar-key');
    for (var i = 0; i < 5; i++) {
      l.click();
    }
    this.router.navigate([{ outlets: { popup: null } }], {
      skipLocationChange: AppConfig.skipLocationChange,
    });
  }

  delete(id) {
    let subscription = this.service.confirm('confirm-delete').subscribe({
      next: (response) => {
        if (response) {
          this.service.deleteEventComment(id).subscribe({
            next: () => {
              this.listComments();
            },
          });
        }
        subscription.unsubscribe();
      },
    });
    return false;
  }
}
