import { Injectable } from '@angular/core';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../core/services';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Amanat } from './models/amanat';
import { AfaqyAPIResponse, AfaqyResponse } from 'app/core/classes';
import { Logger } from 'codelyzer/util/logger';
import { map, catchError } from 'rxjs/operators';
import { AppModel } from 'app/common';

@Injectable()
export class AmanatService extends RootService {
  amanatResources = [];
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    protected translate: TranslateService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.disableListItemAction['copy'] = true;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'amanat/vehicles';
  }

  get modelInstance() {
    return new Amanat();
  }

  get listingIcon() {
    return 'afaqy-icon-users';
  }

  create(obj: AppModel, user: boolean = true) {
    let params: {} = obj.getCreatePostData();
    params = this.applyBeforeCreate(params);
    return this.doCreate(this.getFunctionURL('add'), params);
  }

  getAmanatLookUpList() {
    this.apiRequest.authPost('amanat/vehicles/lookup').subscribe({
      next: (response) => {
        this.amanatResources = response.data.map((amana) => {
          amana.id = amana._id.$oid;
          amana.name = amana.name.en;
          return amana;
        });
      },
    });
  }
}
