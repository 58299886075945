import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { AfaqyValidation } from 'app/common/afaqy-validation';
import { takeWhile } from 'rxjs/operators';
import { UnitSensorColor } from './../../../../../../modules/units/models/unit-sensor';
import { IntervalColorsService } from './../interval-colors/interval-colors.service';
import { SensorColorsFormService } from './sensor-colors-form.service';

@Component({
  selector: 'sensor-colors-form',
  templateUrl: './sensor-colors-form.component.html',
  styleUrls: ['./sensor-colors-form.component.scss'],
})
export class SensorColorsFormComponent implements OnInit, AfterViewInit {
  alive: boolean = true;
  form: UntypedFormGroup;

  constructor(
    public intervalColorsService: IntervalColorsService,
    private fb: UntypedFormBuilder,
    private sensorColorsFormService: SensorColorsFormService
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.updateForm();
  }

  get formFields(): any {
    return {
      from: [null, AfaqyValidation.numberValidatorIfexist],
      to: [null, AfaqyValidation.numberValidatorIfexist],
      type: [null],
      color: ['#000000', AfaqyValidation.colorValidator],
    };
  }

  private createForm(): void {
    this.form = this.fb.group(this.formFields);
  }

  /** Subscribe on formData and set its value to color form. */
  private updateForm(): void {
    this.sensorColorsFormService.formData
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (data: UnitSensorColor) => {
          const formData: any = this.prepareFormData(data);
          this.form.reset(formData);
          this.form.updateValueAndValidity();
        },
      });
  }

  private prepareFormData(data: any): any {
    if (!data) {
      return { from: null, to: null, type: null, color: '#000000' };
    }

    const formData: any = { from: null, to: null, type: null };

    for (const key in formData) {
      if (
        (key == 'type' && this.intervalColorsService.resultType) ||
        !isNaN(data[key])
      ) {
        formData[key] = data[key];
      }
    }

    data['color']
      ? (formData['color'] = data['color'])
      : (formData['color'] = '#000000');

    return formData;
  }

  onSubmit(): void {
    if (this.form.invalid) return;
    this.intervalColorsService.addColor(this.form.value);
    this.sensorColorsFormService.closeSensorForm();
    this.sensorColorsFormService.isFormSubmitted.next(true);
  }

  onCancel(): void {
    this.sensorColorsFormService.closeSensorForm();
  }

  onChangeColor(value: string): void {
    if (!value) return;
    this.colorCtl.setValue(value);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  get fromCtl(): UntypedFormControl {
    return this.form.controls['from'] as UntypedFormControl;
  }
  get toCtl(): UntypedFormControl {
    return this.form.controls['to'] as UntypedFormControl;
  }
  get colorCtl(): UntypedFormControl {
    return this.form.controls['color'] as UntypedFormControl;
  }
}
