<login-agreement *ngIf="loginService.isIsuzuAgreementNeeded"></login-agreement>
<div class="bg-cover">
  <!--    <ng-lottie [options]="lottieConfig" (animationCreated)="handleAnimation($event)"></ng-lottie>-->
</div>
<div id="particles-js"></div>
<div class="loginContainer" *ngIf="loadedSettings">
  <ngx-loading-bar color="#1b325f"></ngx-loading-bar>
  <div class="container">
    <div class="inner-container">
      <div class="tile">
        <div class="tile-inner-container">
          <div class="row">
            <div class="col-12">
              <div
                class="brandContainer {{ configs.loginClass }}"
                [style.background]="configs.login_logo_bgColor"
              >
                <img
                  class="img-fluid brand-image"
                  src="{{ configs.loginlogo }}"
                  alt="{{ configs.title }}"
                />
              </div>
            </div>
          </div>

          <div class="tile-body" id="tile-body">
            <div>
              <h2 class="tile-header">{{ 'login.login' | translate }}</h2>
              <p>{{ 'login.welcome_back' | translate }}</p>
            </div>
            <form
              *ngIf="form"
              method="post"
              (ngSubmit)="form.valid && login()"
              [formGroup]="form"
            >
              <div class="row" [@showHide] *ngIf="message.message">
                <div class="col-12">
                  <div class="alert alert-danger">
                    <i class="mdi mdi-alert"></i>
                    {{
                      message?.message.includes('password')
                        ? (message?.message | translate)
                        : ('login.invalid_credentials' | translate)
                    }}
                  </div>
                </div>
              </div>
              <div
                class="row"
                [ngClass]="{
                  danger:
                    form.get('username').errors?.required &&
                    form.get('username').invalid &&
                    (form.get('username').dirty || form.get('username').touched)
                }"
              >
                <div class="col-12">
                  <label class="form-input">
                    <!--                                    <i class="mdi mdi-account"></i>-->
                    <span class="label">{{
                      'login.user_name' | translate
                    }}</span>
                    <input
                      id="email"
                      #usernameInput
                      type="text"
                      formControlName="username"
                      placeholder="{{ 'login.enterYourUserName' | translate }}"
                      autocomplete="username"
                    />
                    <!--                                    <div class="underline"></div>-->
                  </label>
                </div>
              </div>
              <div
                class="row"
                [ngClass]="{
                  danger:
                    form.get('password').errors?.required &&
                    form.get('password').invalid &&
                    (form.get('password').dirty || form.get('password').touched)
                }"
              >
                <div class="col-12 mt-5">
                  <label class="form-input">
                    <!--                                    <i class="mdi mdi-lock"></i>-->
                    <span class="label">{{
                      'login.password' | translate
                    }}</span>
                    <input
                      id="password"
                      [type]="!showPassword ? 'password' : 'text'"
                      name="password"
                      formControlName="password"
                      placeholder="{{ 'login.enterYourPassword' | translate }}"
                      autocomplete="current-password"
                    />
                    <a (click)="togglePassword()">
                      <mat-icon
                        class="avl-icon show-password-icon"
                        [svgIcon]="'icon-show-password'"
                        [attr.size]="'14px'"
                      ></mat-icon>
                    </a>
                    <!--                                    <div class="underline"></div>-->
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-right forgot-text">
                  <a [routerLink]="['/auth/forgot']">{{
                    'login.Forgot_password' | translate
                  }}</a>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <re-captcha
                    *ngIf="captchaV2?.enabled && !validAutomationSecretCode"
                    (resolved)="captchaResolved($event)"
                    (errored)="captchaErrored($event)"
                    [siteKey]="captchaV2?.siteKey"
                  ></re-captcha>
                </div>
                <div class="col-12">
                  <button
                    [disabled]="
                      loading ||
                      (form.touched && form.invalid) ||
                      (!form.touched &&
                        !isUsernameAutofilled &&
                        !captchaV2?.enabled) ||
                      (captchaV2?.enabled &&
                        form.get('captcha')?.invalid &&
                        !form.touched)
                    "
                    type="submit"
                    class="btn btn-block btn-success float-right btn-login"
                  >
                    {{ 'login.login_button' | translate }}
                  </button>
                </div>
              </div>

              <div class="fixed-lang d-flex justify-content-end">
                <system-langs
                  *ngIf="!loginService.isIsuzuAgreementNeeded"
                ></system-langs>
              </div>
            </form>
            <div class="app-container">
              <div
                *ngIf="configs.androidAppURL || configs.iosAppURL"
                class="appSection p-relative row my-5 flex-column align-items-center"
              >
                <p class="text-center">
                  {{ 'login.app_title' | translate }}
                  <br />
                  {{ 'login.app_message' | translate }}
                </p>
                <div class="justify-content-center d-flex flex-nowrap">
                  <a
                    target="_blank"
                    *ngIf="configs.androidAppURL"
                    href="{{ configs.androidAppURL }}"
                  >
                    <img
                      class="mx-5"
                      src="./assets/images/google.png"
                      width="168 "
                      height="66"
                      alt="Download App"
                    />
                  </a>
                  <a
                    target="_blank"
                    *ngIf="configs.iosAppURL"
                    href="{{ configs.iosAppURL }}"
                  >
                    <img
                      class="mx-4"
                      src="./assets/images/apple.png"
                      width="168 "
                      height="66"
                      alt="Download App"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 text-center text-white copyrights">
            {{ configs.copyrights }}
          </div>
        </div>
      </div>
      <div class="side-img">
        <div class="slide-container">
          <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
          >
            <div
              ngxSlickItem
              *ngFor="let slide of slides"
              class="slide-inner-conatiner"
            >
              <div class="slide">
                <div class="text">
                  {{ slide?.text }}
                  <div class="heading">
                    {{ slide?.heading }}
                  </div>
                </div>
                <div class="image">
                  <img
                    *ngIf="slide?.image"
                    src="{{ slide?.image }}"
                    alt="Illustration"
                  />
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
