import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import * as moment from 'moment';
import { AfaqyHelper } from '.';
import { Component } from '@angular/core';

@Component({
  selector: 'afaqy-validation',
  template: ``,
})
export class AfaqyValidation {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let messageConfigs = {
      required: { message: 'required' },
      exist: { message: 'exist' },
      invalidEmailAddress: { message: 'invalid_email' },
      invalidTextNumeric: { message: 'invalid_text_numeric' },
      invalidNumber: { message: 'invalid_number' },
      invalidPassword: { message: 'invalid_password' },
      passwordSchemaWrong: { message: 'password_schema_msg' },
      must_be_numbers: { message: 'must_be_numbers' },
      minimum_message: { message: 'minimum_validation_message' },
      maximum_message: { message: 'maximum_validation_message' },
      min_phone_number: { message: 'min_phone_number' },
      max_phone_number: { message: 'max_phone_number' },
      must_start_with_plus: { message: 'must_start_with_plus' },
      must_start_with_plus966: { message: 'must_start_with_plus966' },
      minlength: {
        message: 'minlength',
        params: { value: `${validatorValue.requiredLength}` },
      },
      maxlength: {
        message: 'maxlength',
        params: { value: `${validatorValue.requiredLength}` },
      },
      minNumber: {
        message: 'minNumber',
        params: { value: `${validatorValue.requiredNumber}` },
      },
      maxNumber: {
        message: 'maxNumber',
        params: { value: `${validatorValue.requiredNumber}` },
      },
      invalid_min_length: { message: 'invalid_min_length' },
      invalid_format: { message: 'invalid_format' },
      invalidNid: { message: 'invalid_nid' },
      invalidTime: { message: 'invalid_time' },
      locationRequired: { message: 'location_required' },
      endTimeMustBeAfterStartTime: {
        message: 'end_time_must_be_after_start_time',
      },
      invalidTimeBetween: { message: 'invalid_time_between' },
      invalid_contacts_pattern: { message: 'invalid_contacts_pattern' },
      invalidEnTextNumeric: { message: 'accept_english_numbers' },
      mustBeArabicLetters: { message: 'must_be_arabic_letters' },
      invalidDate: { message: 'invalidDate' },
      dateGreaterThanToday: { message: 'dateGreaterThanToday' },
      invalidAlphabeticCharacters: { message: 'invalid_alphabetic_characters' },
      invalidLatFormat: { message: 'invalid_lat_format' },
      invalidLongFormat: { message: 'invalid_long_format' },
      invalidColor: { message: 'invalidColor' },
      containers_errors: { message: 'containers_errors' },
    };
    let message = messageConfigs[validatorName]
      ? messageConfigs[validatorName]
      : { message: validatorName };
    if (!message.params) {
      message.params = { value: '' };
    }
    return message;
  }

  static emailValidator(control: any) {
    // RFC 2822 compliant regex
    if (
      !control.value ||
      control.value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  /** Accepts only English, Arabic characters and integer numbers. */
  static textNumericValidator(control: any) {
    const cValue = '' + control.value;
    if (!cValue.match(/[^a-zA-Z\u0621-\u064A0-9 ]/)) {
      return null;
    }
    return { invalidTextNumeric: true };
  }

  static numberValidator(control: any) {
    let cValue = '' + control.value;
    if (cValue.match(/^0|-?[1-9]\d*\.?\d*$/) && !isNaN(control.value)) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  }

  static numberValidatorIfexist(control: any) {
    let cValue = '' + control.value;
    if (
      cValue == 'null' ||
      cValue == '' ||
      (cValue.match(/^0|-?[1-9]\d*\.?\d*$/) && !isNaN(control.value))
    ) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  }

  static temperatureNumberValidator(control: any) {
    let cValue = '' + control.value;
    let regex = /^([-])?[\d]{1,2}([\.|\,]\d{1})?$/;
    if (cValue == 'null' || cValue == '' || regex.test(control.value)) {
      return null;
    } else {
      return { invalidTempFormat: true };
    }
  }

  static customPatternValidator(pattern: any, invalidKey: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let cValue = '' + control.value;
      if (cValue == 'null' || cValue == '' || pattern.test(control.value)) {
        return null;
      } else {
        return { [invalidKey]: true };
      }
    };
  }

  static numberIntValidatorIfexist(control: any) {
    let cValue = '' + control.value;
    if (
      cValue == 'null' ||
      cValue == '' ||
      (cValue.match(/^[0-9]+$/) && !isNaN(control.value))
    ) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  }

  emailValidator(control) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/i;

    if (control.value && !emailPattern.test(control.value)) {
      return { email: 'Invalid Email' };
    }

    return null;
  }

  static latLngValidator(control) {
    if (control.value?.length === 2) {
      if (control.value[0] < -180 || control.value[0] > 180) {
        return { valid_location: true };
      }
      if (control.value[1] < -90 || control.value[1] > 90) {
        return { valid_location: true };
      }
    }
    if (!Array.isArray(control.value)) {
      return { valid_location: true };
    }
  }

  static time24ValidatorIfexist(control: UntypedFormControl) {
    const cValue = '' + control.value;
    if (
      cValue == '' ||
      cValue == 'null' ||
      cValue.match(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/)
    ) {
      return null;
    } else {
      return { invalid_time: true };
    }
  }

  static Dates_Y_M_ValidatorIfexist(control: any) {
    let cValue = '' + control.value;
    if (
      cValue == '' ||
      cValue == 'null' ||
      cValue.match(/^(19[5-9][0-9]|20[0-4][0-9]|2050)[-](0?[1-9]|1[0-2])$/)
    ) {
      return null;
    } else {
      return { 'invalidFormatYYYY-mm': true };
    }
  }

  // Password must be at least 6 characters, no more than 25 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit.
  static passwordValidator(control: any) {
    if (control.value) {
      if (control.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,25}$/)) {
        return null;
      } else {
        return { passwordSchemaWrong: true };
      }
    } else {
      return null;
    }
  }

  static phoneValidator(control: UntypedFormControl) {
    var NUMBER_REGEXP = /^\s*(\-|\+)?(\d+|(\d*(\d*)))([eE]+[-]?\d+)?\s*$/;
    if (control.value) {
      if (control.value != '' && !NUMBER_REGEXP.test(control.value)) {
        return { must_be_numbers: true };
      }
      if (control.value != '' && control.value.length < 13) {
        return { minimum_message: true };
      }
      if (control.value != '' && control.value.length > 16) {
        return { maximum_message: true };
      }
      if (control.value.charAt(0) !== '+') {
        return { must_start_with_plus: true };
      }
      return null;
    } else {
      return null;
    }
  }

  static phoneWithPlusValidator(control: UntypedFormControl) {
    var NUMBER_REGEXP = /^\s*(\-|\+)?(\d+|(\d*(\d*)))([eE]+[-]?\d+)?\s*$/;
    if (control.value) {
      if (control.value != '' && !NUMBER_REGEXP.test(control.value)) {
        return { must_be_numbers: true };
      }
      if (control.value.charAt(0) !== '+') {
        return { must_start_with_plus: true };
      }
      if (control.value != '' && control.value.length < 10) {
        return { min_phone_number: true };
      }
      if (control.value != '' && control.value.length > 15) {
        return { max_phone_number: true };
      }
      return null;
    } else {
      return null;
    }
  }

  static nidValidatorIfexist(control: UntypedFormControl): any {
    const nidValue: string = control.value;
    if (!nidValue) {
      return;
    }
    if (AfaqyValidation.numberIntValidatorIfexist(control) === null) {
      if (nidValue.length > 14 || nidValue.length < 10) {
        return { invalidNid: true };
      }
    }
  }

  static locationValidator(control: UntypedFormControl): any {
    if (!control.value) {
      return { locationRequired: true };
    } else {
      return null;
    }
  }

  static endTimeValidator(
    sTimeControl: UntypedFormControl,
    eTimeControl: UntypedFormControl,
    errorMsgKey: string = 'endTimeMustBeAfterStartTime'
  ) {
    if (
      !sTimeControl ||
      !eTimeControl ||
      this.time24ValidatorIfexist(sTimeControl) ||
      this.time24ValidatorIfexist(eTimeControl)
    ) {
      return;
    }
    sTimeControl.valueChanges.subscribe({
      next: () => {
        validator(sTimeControl, eTimeControl);
      },
    });
    eTimeControl.valueChanges.subscribe({
      next: () => {
        validator(sTimeControl, eTimeControl);
      },
    });

    function validator(
      sTimeControl: UntypedFormControl,
      eTimeControl: UntypedFormControl
    ) {
      if (!isFTimeBeforeSTime(sTimeControl.value, eTimeControl.value)) {
        eTimeControl.setErrors({ [errorMsgKey]: true });
      } else {
        eTimeControl.setErrors(null);
      }
    }

    function isFTimeBeforeSTime(fTime24: string, sTime24: string): boolean {
      if (!fTime24 || !sTime24) {
        return false;
      }
      const fDate: Date = new Date();
      const sDate: Date = new Date();
      fDate.setHours(+fTime24.split(':')[0], +fTime24.split(':')[1]);
      sDate.setHours(+sTime24.split(':')[0], +sTime24.split(':')[1]);
      if (fDate.getTime() < sDate.getTime()) {
        return true;
      } else {
        return false;
      }
    }
  }

  static endDateValidator(
    sDateControl: UntypedFormControl,
    eDateControl: UntypedFormControl,
    errorMsgKey: string = 'endTimeMustBeAfterStartTime'
  ) {
    if (
      !sDateControl ||
      !eDateControl ||
      this.time24ValidatorIfexist(sDateControl) ||
      this.time24ValidatorIfexist(eDateControl)
    ) {
      return;
    }
    sDateControl.valueChanges.subscribe({
      next: () => {
        validator(sDateControl, eDateControl);
      },
    });
    eDateControl.valueChanges.subscribe({
      next: () => {
        validator(sDateControl, eDateControl);
      },
    });

    function validator(
      sTimeControl: UntypedFormControl,
      eTimeControl: UntypedFormControl
    ) {
      if (!isFTimeBeforeSTime(sTimeControl.value, eTimeControl.value)) {
        eTimeControl.setErrors({ [errorMsgKey]: true });
      } else {
        eTimeControl.setErrors(null);
      }
    }

    function isFTimeBeforeSTime(fTime24: string, sTime24: string): boolean {
      if (!fTime24 || !sTime24) {
        return false;
      }
      const fDate: Date = new Date(fTime24);
      const sDate: Date = new Date(sTime24);
      if (fDate < sDate) {
        return true;
      } else {
        return false;
      }
    }
  }

  static timeBetweenValidator(
    sTimeControl: UntypedFormControl,
    eTimeControl: UntypedFormControl,
    timeBetweenControl: UntypedFormControl
  ) {
    if (
      !sTimeControl ||
      !eTimeControl ||
      !timeBetweenControl ||
      this.time24ValidatorIfexist(sTimeControl) ||
      this.time24ValidatorIfexist(eTimeControl) ||
      this.time24ValidatorIfexist(timeBetweenControl)
    ) {
      return;
    }
    sTimeControl.valueChanges.subscribe({
      next: () => {
        validator(sTimeControl, eTimeControl, timeBetweenControl);
      },
    });
    eTimeControl.valueChanges.subscribe({
      next: () => {
        validator(sTimeControl, eTimeControl, timeBetweenControl);
      },
    });
    timeBetweenControl.valueChanges.subscribe({
      next: () => {
        validator(sTimeControl, eTimeControl, timeBetweenControl);
      },
    });

    function validator(
      sTimeControl: UntypedFormControl,
      eTimeControl: UntypedFormControl,
      timeBetweenControl: UntypedFormControl
    ) {
      if (
        isFTimeBeforeSTime(sTimeControl.value, timeBetweenControl.value) &&
        isFTimeBeforeSTime(timeBetweenControl.value, eTimeControl.value)
      ) {
        timeBetweenControl.setErrors(null);
      } else {
        timeBetweenControl.setErrors({ invalidTimeBetween: true });
      }
    }

    function isFTimeBeforeSTime(fTime24: string, sTime24: string): boolean {
      if (!fTime24 || !sTime24) {
        return false;
      }
      const fDate: Date = new Date();
      const sDate: Date = new Date();
      fDate.setHours(+fTime24.split(':')[0], +fTime24.split(':')[1]);
      sDate.setHours(+sTime24.split(':')[0], +sTime24.split(':')[1]);
      if (fDate.getTime() < sDate.getTime()) {
        return true;
      } else {
        return false;
      }
    }
  }

  static dependentControllerRequiredValidator(
    fControl: UntypedFormControl,
    sControl: UntypedFormControl
  ) {
    if (!fControl || !sControl) {
      return;
    }
    fControl.valueChanges.subscribe({
      next: (value: any) => {
        if (!value && sControl.value) {
          fControl.setErrors({ required: true });
          fControl.markAsTouched();
        } else if (value && !sControl.value) {
          sControl.setErrors({ required: true });
          sControl.markAsTouched();
        } else {
          sControl.setErrors(null);
        }
      },
    });

    sControl.valueChanges.subscribe({
      next: (value: any) => {
        if (!value && fControl.value) {
          sControl.setErrors({ required: true });
          sControl.markAsTouched();
        } else if (value && !fControl.value) {
          fControl.setErrors({ required: true });
          fControl.markAsTouched();
        } else {
          fControl.setErrors(null);
        }
      },
    });
  }

  static checkboxHasToBeChecked(control: UntypedFormControl): any {
    const nidValue: boolean = control.value;
    if (nidValue) {
      return;
    }
    return { required: true };
  }

  static jobOrderContactsValidator(control: any) {
    // RFC 2822 compliant regex
    if (!control.value || control.value.match(/^[0-9, ]*$/)) {
      return null;
    } else {
      return { invalid_contacts_pattern: true };
    }
  }

  static numberStringValidator(control: UntypedFormControl): any {
    let cValue = '' + control.value;
    if (cValue.match(/^[0-9]+$/) && !isNaN(control.value)) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  }

  static alphabeticValidator(control: UntypedFormControl): any {
    if (
      !control.value ||
      (control.value && control.value.match(/^[A-Za-z\s]+$/))
    ) {
      return null;
    } else {
      return { invalidAlphabeticCharacters: true };
    }
  }

  static latitudeValidator(control: UntypedFormControl): any {
    if (!control || !control.value) {
      return;
    }
    const cValue: string = '' + control.value;
    if (
      cValue.match(
        /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
      )
    ) {
      return null;
    } else {
      return { invalidLatFormat: true };
    }
  }

  static longitudeValidator(control: UntypedFormControl): any {
    if (!control || !control.value) {
      return;
    }
    const cValue: string = '' + control.value;
    if (cValue.match(/^(\-?([1]?[0-7]?[0-9](\.\d+)?|180((.[0]+)?)))$/)) {
      return null;
    } else {
      return { invalidLongFormat: true };
    }
  }

  /** Accepts only English characters and integer numbers. */
  static englishValidator(control: UntypedFormControl): any {
    if (!control || !control.value) {
      return;
    }
    const englishRegex = /^[a-zA-Z0-9 ]+$/;
    if (!englishRegex.test(control.value)) {
      return { invalidEnTextNumeric: true };
    } else {
      return null;
    }
  }

  /** Accepts only Arabic characters and integer numbers. */
  static arabicValidator(control: UntypedFormControl): any {
    if (!control || !control.value) {
      return;
    }
    const arabicRegex = /^[\u0621-\u064A0-9 ]+$/;
    if (!arabicRegex.test(control.value)) {
      return { mustBeArabicLetters: true };
    } else {
      return null;
    }
  }

  /** Return invalid color if value not matching with hex color */
  static colorValidator(control: UntypedFormControl) {
    if (!control || !control.value) {
      return;
    }
    const colorRegEx3 = /^#([0-9A-F]{3}){1,2}$/i;
    const colorRegEx6 = /^#[0-9A-F]{6}$/i;
    if (!colorRegEx3.test(control.value) || !colorRegEx6.test(control.value)) {
      return { invalidColor: true };
    } else {
      return null;
    }
  }

  /** Return invalid date if format not matching with yyyy-mm-dd. */
  static dateValidator(control: UntypedFormControl) {
    if (!control || !control.value) {
      return;
    }
    const dateRegEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegEx.test(control.value)) {
      return { invalidDate: true };
    }
    const splittedDate: any[] = control.value.split('-');
    if (+splittedDate[1] > 12 || splittedDate[2] > 31) {
      return { invalidDate: true };
    } else {
      return null;
    }
  }

  /** Return `dateGreaterThanToday` is the form control date greater than today. */
  static dateSameOrBeforeToday(control: UntypedFormControl): any {
    if (!control || !control.value) {
      return;
    }
    if (moment(control.value).isAfter(AfaqyHelper.today())) {
      return { dateGreaterThanToday: true };
    }
    return null;
  }

  static customDateCompare(
    ref: string,
    compareKey:
      | 'isAfter'
      | 'isBefore'
      | 'isBetween'
      | 'isSame'
      | 'isSameOrAfter'
      | 'isSameOrBefore'
      | string,
    errorKey: string = null
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let parent = control.parent;
      if (!parent) {
        return null;
      }

      let compared = parent.get(ref);
      if (!compared) {
        return null;
      }

      if (
        compared.value == undefined ||
        compared.value == '' ||
        compared.value == null
      ) {
        return null;
      }

      if (
        control.value == undefined ||
        control.value == '' ||
        control.value == null
      ) {
        return null;
      }
      if (!moment(control.value)[compareKey](compared.value)) {
        return errorKey ? { [errorKey]: true } : { customDateCompare: true };
      }
      return null;
    };
  }

  static greaterThan(ref: string, errorKey: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let parent = control.parent;
      if (!parent) {
        return null;
      }

      let compared = parent.get(ref);
      if (!compared) {
        return null;
      }

      if (
        compared.value == undefined ||
        compared.value == '' ||
        compared.value == null
      ) {
        return null;
      }

      if (
        control.value == undefined ||
        control.value == '' ||
        control.value == null
      ) {
        return null;
      }

      if (control.value <= compared.value) {
        return errorKey ? { [errorKey]: true } : { lessThan: true };
      }
      return null;
    };
  }

  static greaterThanOrEqual(ref: string, errorKey: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let parent = control.parent;
      if (!parent) {
        return null;
      }

      let compared = parent.get(ref);
      if (!compared) {
        return null;
      }

      if (
        compared.value == undefined ||
        compared.value == '' ||
        compared.value == null
      ) {
        return null;
      }

      if (
        control.value == undefined ||
        control.value == '' ||
        control.value == null
      ) {
        return null;
      }

      if (control.value < compared.value) {
        return errorKey ? { [errorKey]: true } : { lessThan: true };
      }
      return null;
    };
  }

  static lessThan(ref: string, errorKey: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let parent = control.parent;
      if (!parent) {
        return null;
      }

      let compared = parent.get(ref);
      if (!compared) {
        return null;
      }

      if (
        compared.value == undefined ||
        compared.value == '' ||
        compared.value == null
      ) {
        return null;
      }

      if (
        control.value == undefined ||
        control.value == '' ||
        control.value == null
      ) {
        return null;
      }

      if (control.value > compared.value) {
        return errorKey ? { [errorKey]: true } : { lessThan: true };
      }
      return null;
    };
  }

  /**
   * A custom validator function that checks if a field should be required based on the values of other fields (`dependentFieldNames`).
   *
   * @param dependentFieldNames Array of field names whose values determine the validation condition.
   * @param condition Condition to determine if all or any dependent fields must be true:
   * * 'every': Requires all fields to be true for the target field to be required.
   * * 'some' (default): Requires any field to be true for the target field to be required.
   * @returns A validator function that can be used with Angular's reactive forms.
   */
  static conditionalRequiredValidator(
    dependentFieldNames: string[],
    condition: 'every' | 'some' = 'some'
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // console.log('control', control);

      const dependentFields = dependentFieldNames?.map((name) =>
        control?.parent?.get(name)
      );

      // will need to move validator in component to be able to use valuechanges and destroy subscriptions
      // this logic is memory consuming and must be wrapped with until destroyed from component using it
      // dependentFields.forEach(field => {
      //   field?.valueChanges.subscribe(() => {
      //     control.updateValueAndValidity();
      //   });
      // });

      let validationMet = false;

      switch (condition) {
        case 'every':
          validationMet = dependentFields.every((field) => field?.value);
          break;
        case 'some':
          validationMet = dependentFields.some((field) => field?.value);
          break;
      }

      // console.log(validationMet);
      if (
        validationMet &&
        !control.value &&
        !isNaN(control.value) &&
        control.value !== 0
      ) {
        // console.log('validationMet', validationMet, control.value);
        return { conditionalRequired: true };
      }
      return null;
    };
  }
}
