import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, take, takeWhile, tap } from 'rxjs/operators';
import { AfaqyHelper, AppConfig, Message } from '../../../common/classes';
import { EventData } from '../../../modules/events/models/event-data';
import { EventsService } from '../../../modules/events/services/events.service';
import { MapService } from '../../../modules/map/map.service';
import { MapModel } from '../../../modules/map/mapModel';
import { UnitService } from '../../../modules/units/services';
import {
  UnitAdditionalInfo,
  User,
  UserMap,
} from '../../../modules/users/models';
import { UserService, VerifyOTPService } from '../../../modules/users/services';
import { AfaqyAPIResponse } from '../../classes/afaqy-response';
import { ApiRequestService, AuthService, RootService } from '../../services';
// import * as configData from "./../../../../assets/configs/configs.json";
import { transition, trigger, useAnimation } from '@angular/animations';
import { BookLogService } from 'app/core/services/book-log.service';
import {
  HideAnimation,
  showAnimation,
} from 'app/shared/animations/show-hide-menu';
import { BookLogComponent } from '../book-log/book-log.component';
// import { st } from "@angular/core/src/render3";
import { LayoutService } from 'app/core/services/layout.service';
import { MonitoringStatus } from 'app/modules/units/services/monitoring-status';

import { FormControl, FormGroup } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { PusherSocketService } from 'app/core/services/pusher-socket.service';
import { AmanatService } from 'app/modules/amanat/amanat.service';
import { CustomersMapLayerService } from 'app/modules/customers/services/customers_mapLayer.service';
import { LocationMapLayerService } from 'app/modules/locations/services/locations_mapLayer.service';
import { ManualRouteMapLayerService } from 'app/modules/manual-route/services';
import { MarkerMapLayerService } from 'app/modules/markers/services';
import { TrackingMaplayerService } from 'app/modules/tracking/tracking-maplayer.service';
import { WarehousesMapLayerService } from 'app/modules/warehouses/services';
import { WastesMapLayerService } from 'app/modules/wastes/services/wastes_mapLayer.service';
import { ZonesMapLayerService } from 'app/modules/zones/services/zones_mapLayer.service';
import { ImportToFillComponent } from 'app/shared/components/import-to-fill/import-to-fill.component';
import { UnitSettingsCopyComponent } from 'app/shared/components/unit-form';
import { Overlay } from 'ol';
import { Observable } from 'rxjs';
import { CustomFieldsService } from '../../../modules/custom-fields/custom-fields.service';
import { SocketService } from '../../services/socket.service';
import { NotificationListComponent } from '../notification-list/notification-list.component';

declare var $;

enum UnitState {
  Moving = 'moving',
  Stopped = 'stopped',
}

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 1,
            hidepercent: 'calc(var(--direction-multiplier) * 100%)',
            showpercent: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 1,
            hidepercent: 'calc(var(--direction-multiplier) * 100%)',
            showpercent: '0',
          },
        }),
      ]),
    ]),
    trigger('fadeIn', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 0,
            hidepercent: '0',
            showpercent: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 0,
            hidepercent: '0',
            showpercent: '0',
          },
        }),
      ]),
    ]),
  ],
  providers: [],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('MapSectionContainer', { static: false, read: ViewContainerRef })
  MapSectionContainer: ViewContainerRef;
  @ViewChild('notificationsList', { static: false, read: ViewContainerRef })
  notificationList: NotificationListComponent;

  alive: boolean = true;
  hideNotifications = false;
  isActiveSession: boolean = false;
  systemLoading = true;
  pageloading = false;
  smallWidth = false;
  trackedResources: Number = 0;
  contentsVisibilty = {
    notifications: false,
    profile: false,
    menu: false,
    listing: true,
    map: false,
    listingIcon: '',
  };
  splitterMode = true;
  sysColor: string;
  configs = AppConfig;
  user: User;
  dragDisabled: boolean = false;
  splitter = {};
  colors = [];
  unlockPassword: string = '';
  loading = false;
  message: Message;
  isBelowMap = false;
  fullmap: boolean = false;
  userTime = AfaqyHelper.getUserCurrentTime()?.clone();
  mapConfiguration = {
    center: AppConfig.mapConfig.center,
    zoom: 12,
    maxResolutionForLabel: 1400,
    rememberMapPosition: false,
    rememberMapZoom: false,
    showTail: false,
    tailColor: '#ffffff',
    selectTileControl: true,
    selectedTile: 'osm',
    tiles: ['osm', 'gRoadMap', 'gSatellite', 'gHybrid'],
    zoomControl: true,
    zoomControlOpened: true,
    layersVisibilityControl: true,
    layersVisibilityControlOpened: false,
    layersVisibilityControlLayers: [
      'units',
      'markers',
      'zones',
      'customers',
      'warehouses',
      'tracking',
      'manualRoutes',
      'wastes',
    ],
    layersVisibilityControlShownLayers: [
      'units',
      'markers',
      'zones',
      'customers',
      'warehouses',
      'tracking',
      'manualRoutes',
      'wastes',
    ],
    mapUtilitiesControl: true,
    mapUtilitiesControlBtns: [
      'route',
      'fitBounds',
      'pinToLonLat',
      'getLonLat',
      'showCluster',
      'showZoneCluster',
    ],
  };

  loggedInAs: any;
  pageDetails = { pageIcon: '', pageTitle: '' };
  eventsList: EventData[] = [];
  unitAdditionalInfo: UnitAdditionalInfo;
  searchText: string;
  subUsersMap: Array<User> = [];
  currentYear: number;
  showLoginAs: boolean = false;
  expandedEvent: EventData; // expanded event that contains event details and displayed in overlay
  eventDetailsOverlay = null;
  hideEventDetails = true;
  MenuOpen: boolean = false;
  NotificationsOpen: boolean = false;
  UserSidebarOpen: boolean = false;
  sliderStatus = false;
  isUserVerified = false;
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true,
  };
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  @HostBinding('@showHide')
  @HostBinding('@fadeIn')
  animation = true;
  @ViewChild('navbarComponents', { static: false })
  navbarComponents: ElementRef;
  @ViewChild('navbarNotch', { static: false }) navbarNotch: ElementRef;
  @ViewChild('copyInfoStatus', { read: ViewContainerRef })
  copyInfoStatus: ViewContainerRef;
  @ViewChild('importToFill', { read: ViewContainerRef, static: false })
  importToFill: ViewContainerRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    AfaqyHelper.windowResize('onResize');
    this.setMapWidth();
  }

  direction: string = 'vertical';
  showSocketStatusIcons = AppConfig.showSocketStatusIcons;
  showCopyInfoSocketIcon: boolean = false;
  importToFillData: any;
  showImportToFill = false;
  importSocketData: any;
  toggleLogoutBtn = true;
  toggle_is_2fa = false;
  toggle_is_2fa_disabled = false;

  constructor(
    private mapService: MapService,
    private translate: TranslateService,
    public authService: AuthService,
    public eventService: EventsService,
    private router: Router,
    protected unitService: UnitService,
    private userService: UserService,
    public bookService: BookLogService,
    public rootService: RootService,
    public layoutService: LayoutService,
    public socketService: SocketService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private pusherNotificationSocket: PusherSocketService,
    private cdr: ChangeDetectorRef,
    protected zonesMapLayerService: ZonesMapLayerService,
    protected customerMapLayerService: CustomersMapLayerService,
    protected markersMapLayerService: MarkerMapLayerService,
    protected manuelRoutesMapLayerService: ManualRouteMapLayerService,
    protected wastesMapLayerService: WastesMapLayerService,
    protected trackingMapLayerService: TrackingMaplayerService,
    protected locationsMapLayerService: LocationMapLayerService,
    protected warehousesMapLayerService: WarehousesMapLayerService,
    protected amanatService: AmanatService,
    private notificationsService: NotificationsService,
    protected customFieldsService: CustomFieldsService,
    private verifyOtpService: VerifyOTPService
  ) {
    this.getImportToFillData();
  }

  /**
   * moved logic from constructor into a function that runs after checking that session is already loaded
   */
  constructorOldLogic() {
    this.userService.startAutoload();
    this.subUsersMap = this.userService.resourcesList;
    this.userService.resources.subscribe({
      next: () => {
        this.subUsersMap = this.userService.resourcesList;
      },
    });

    this.splitter = {
      map: 70,
      data: 30,
      gutterSize: AppConfig.spliterGutterSize,
    };
    this.isActiveSession = this.authService.isActiveLogin();
    this.pageloading = false;
    this.colors = this.authService.colors;

    this.message = new Message();
    this.unitAdditionalInfo =
      this.authService.user.user_settings.unit_additional_info;

    this.checkSystemLoading();
    // set the mapConfigurations from user Profile
    this.loadMapConfigurationFromUserProfile(this.authService.user.map);

    this.updateEventsList();
    this.eventService.events.subscribe({
      next: () => {
        this.updateEventsList();
      },
    });
    setInterval(() => {
      this.userTime = AfaqyHelper.getUserCurrentTime()?.clone();
    }, 1000);

    // for (const key in configData) {
    //     AppConfig[key] = configData[key];
    // }
    this.sliderStatus = this.authService.preferences('events', 'slider', false);
    this.eventService.sliderStatus.subscribe({
      next: (status) => {
        this.sliderStatus = status;
      },
    });

    this.notificationTicker();

    this.socketService.unitCopyInfoSubmitted.subscribe((resp: any) => {
      this.showCopyInfoSocketIcon = resp;
      this.showImportToFill = false;
    });
    this.socketService.importToFill.subscribe((resp: any) => {
      this.showCopyInfoSocketIcon = resp;
      this.showImportToFill = true;
    });
    this.socketService.showCopyInfoSpinner.subscribe((resp: any) => {
      this.unitService.showCopyInfoSpinner = false;
    });
    this.unitService.subscribeToPusherSocketNotifications();
    this.updateTheme();
  }

  loadComponent(component) {
    this.copyInfoStatus.clear();
    let componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    let componentRef = this.copyInfoStatus.createComponent(componentFactory);
  }

  navigateToCopyInfoModel() {
    this.loadComponent(UnitSettingsCopyComponent);
  }

  updateEventsList() {
    let unreadList = [];
    this.eventService.eventsList.forEach((event) => {
      if (unreadList.length < 5 && !event.isread) {
        unreadList.push(event);
      }
    });
    if (
      unreadList.length >= 5 ||
      (this.eventService.eventsList.length &&
        this.eventService.unreadEventCount$.value <= unreadList.length)
    ) {
      this.eventsList = unreadList;
      this.hideNotifications = false;
      return;
    }
    this.hideNotifications = false;
    // this.eventService
    //   .loadLastUnread({
    //     filters: { isread: false },
    //     limit: 5,
    //     offset: 0,
    //     simplify: false,
    //   })
    //   .subscribe({
    //     next: (data) => {
    //       const elist = Array(...data);
    //       if (elist.length > 0) {
    //         this.eventsList = elist;
    //         this.hideNotifications = false;
    //       } else {
    //         this.hideNotifications = true;
    //       }
    //     },
    //     error: (error) => {
    //       this.hideNotifications = true;
    //     },
    //   });
  }

  isShowSlider() {
    return this.sliderStatus ? this.hideNotifications : true;
  }

  /**
   * receive event details from any child component
   * @param EventDetails
   */
  receiveEventDetails(EventDetails) {
    this.expandedEvent = EventDetails;
    this.expandNotification(EventDetails, false);
  }

  /**
   * display event on map
   * @param event
   * @param {boolean} fromHeader
   */
  expandNotification(event: any, fromHeader = true) {
    this.hideEventDetails = false;
    // remove event from header list once clicked
    this.expandedEvent = event;
    if (fromHeader) {
      // index of selected event
      const index = this.eventsList.indexOf(event, 0);
      // remove event from events list
      if (index > -1) {
        this.eventsList.splice(index, 1);
      }
      this.updateEventsList();
    }

    // display on map
    this.mapService.action((map: MapModel) => {
      // Convert coordinates to map points
      const coordinates = event.pos.loc.coordinates;
      const mapPoints = map.lonLatToMapPoint(coordinates);
      const popup = document.getElementById('event-popup');
      this.eventDetailsOverlay = new Overlay({
        position: mapPoints,
        positioning: 'top-left',
        element: popup,
        stopEvent: false,
      });

      map.getMap().addOverlay(this.eventDetailsOverlay);
      map.getMap().getView().setCenter(mapPoints);
    });

    // Set as read :
    if (fromHeader) {
      this.eventService
        .updateStatus('read', [this.expandedEvent.id])
        .subscribe();
    }
    // decrement notifications count
    if (this.eventService.unreadEventCount$.value > 0 && fromHeader) {
      this.eventService.decrementUnreadEventNotification();
    }
  }

  hideNotification() {
    this.hideEventDetails = true;
    this.mapService.action((map: MapModel) => {
      // remove overlay from map
      // map.getMap().addOverlay(this.eventDetailsOverlay);
      this.eventDetailsOverlay.setPosition(undefined);
      const closer = document.getElementById('popup-closer');
      closer.blur();
      return false;
    });
  }

  loadMapConfigurationFromUserProfile(userMapOptions: UserMap) {
    this.mapConfiguration.rememberMapPosition = userMapOptions.rmp;
    if (
      userMapOptions.default_map_center.lng &&
      userMapOptions.default_map_center.lat
    ) {
      let mapCenter = [];
      mapCenter = [
        userMapOptions.default_map_center.lng,
        userMapOptions.default_map_center.lat,
      ];
      this.mapConfiguration.center = mapCenter;
    }
    if (userMapOptions.show_tiles.length < 1) {
      userMapOptions.show_tiles.push(userMapOptions.default_tile);
    }
    this.mapConfiguration.rememberMapZoom = userMapOptions.rmz;
    this.mapConfiguration.selectTileControl = userMapOptions.show_select_tile;
    this.mapConfiguration.selectedTile = userMapOptions.default_tile;
    this.mapConfiguration.tiles = userMapOptions.show_tiles;
    this.mapConfiguration.zoomControl = userMapOptions.zoom_slider;
    this.mapConfiguration.layersVisibilityControl =
      userMapOptions.show_layers_control;
    this.mapConfiguration.mapUtilitiesControl =
      userMapOptions.show_utilities_control;
    this.mapConfiguration.mapUtilitiesControlBtns =
      userMapOptions.show_utilities;
    // if (this.firstTime)
    this.mapConfiguration.layersVisibilityControlLayers =
      userMapOptions.show_layers;
    this.mapConfiguration.layersVisibilityControlShownLayers =
      userMapOptions.show_layers;
    // this.mapConfiguration = AfaqyHelper.cloneObject(this.mapConfiguration);
    this.mapConfiguration = { ...this.mapConfiguration };
  }

  updateTheme() {
    // this.systemLoading = false;
    if (this.authService.preferences('site', 'splitter')) {
      this.splitter = this.authService.preferences('site', 'splitter');
    }
    this.getUserMapStatus();
    this.updateColor();
    this.bookService.status = this.authService.preferences(
      'site',
      'booklog',
      0
    );
    this.toggleBookLog(false);
  }

  checkSystemLoading() {
    this.authService.userUnitsLoaded.subscribe({
      next: () => {
        this.systemLoading = false;
      },
    });
    if (!this.authService.checkPermissions('units-lists')) {
      this.systemLoading = false;
    }
  }

  ngAfterViewInit() {
    AfaqyHelper.windowResize('ngAfterViewInit');
    AfaqyHelper.MapSectionSplitter.pipe(takeWhile(() => this.alive)).subscribe({
      next: (data) => {
        // console.log('ngAfterViewInit AfaqyHelper.MapSectionSplitter', data);
        this.isBelowMap = data['visible'];
        this.mapDragDisabled = data['full'] === true;
        this.mapGutterSize = this.mapDragDisabled ? 1 : 7;

        if (!this.isBelowMap && AfaqyHelper?.MapSectionContainer) {
          AfaqyHelper?.MapSectionContainer?.clear();
          AppConfig.mapSplitter.map = 70;
          AppConfig.mapSplitter.data = 30;
        }

        setTimeout(function () {
          AfaqyHelper.pushResizer();
        }, 500);
      },
    });
    this.setMapWidth();

    AfaqyHelper.MapSectionContainer = this.MapSectionContainer;
    // console.log('MapSectionContainer', AfaqyHelper.MapSectionContainer);

    AfaqyHelper.MapSectionContainerRefresh.pipe(
      takeWhile(() => this.alive),
      filter((refresh) => refresh)
    ).subscribe({
      next: () => {
        // console.log('refresh MapSectionContainer')
        // console.log('MapSectionContainer', AfaqyHelper.MapSectionContainer);
        AfaqyHelper.MapSectionContainer = this.MapSectionContainer;
        // console.log('MapSectionContainer', AfaqyHelper.MapSectionContainer);
      },
    });
  }

  navbarWidth() {
    // get right sidebars width dynamically from navbar components and username length
    const navbarRightComponentsWidth =
      this.navbarComponents.nativeElement.clientWidth;
    document.body.style.setProperty(
      '--sidebar-right-width',
      navbarRightComponentsWidth + 'px'
    );
    document.body.style.setProperty(
      '--sidebar-right-width-negative',
      '-' + navbarRightComponentsWidth + 'px'
    );

    // get left sidebars width dynamically from navbar components and username length
    const navbarLeftComponentsWidth =
      this.navbarNotch.nativeElement.clientWidth;
    document.body.style.setProperty(
      '--sidebar-left-width',
      navbarLeftComponentsWidth + 'px'
    );
    document.body.style.setProperty(
      '--sidebar-left-width-negative',
      '-' + navbarLeftComponentsWidth + 'px'
    );
  }

  dragEnd($event: any) {
    AfaqyHelper.windowResize('dragEnd');
    // AfaqyHelper.hideListOptions();
    this.splitter = { data: $event.sizes[0], map: $event.sizes[1] };
    this.splitter['gutterSize'] = AppConfig.spliterGutterSize;
    this.authService.updatePreferences('site', 'splitter', this.splitter);
    this.setMapWidth();
  }

  dragProgress($event: any) {
    AfaqyHelper.windowResize('dragProgress');
    // AfaqyHelper.hideListOptions();
  }

  dragMapResizeEnd($event: any) {
    AfaqyHelper.calculateMapConenetSectionHeight();
    this.configs.mapSplitter.map = $event.sizes[0];
    this.configs.mapSplitter.data = $event.sizes[1];
    // AfaqyHelper.hideBelowMapListOptions();
    AfaqyHelper.pushResizer();
  }

  unlock() {
    this.loading = true;
    this.message.clear();
    this.authService
      .login(this.user.username, this.unlockPassword, false)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (response: AfaqyAPIResponse) => {
          if (response.status_code === 200) {
            window.history.replaceState('', '', window.location.href);
            this.loading = false;
            this.message.clear();
            this.authService.fireSessionStatus();
          } else {
            this.message.type = 'danger';
            this.message.message = response.message;
            this.loading = false;
          }
        },
        error: (error) => {
          this.message.type = 'danger';
          this.message.message = error;
          this.loading = false;
        },
      });
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  choose(newValue: any) {
    this.authService.color = newValue.target.value;
    this.updateColor();
  }

  notificationTicker() {
    setTimeout(() => {
      $('#notifications-ticker').easyTicker({
        direction: 'up',
        easing: 'swing',
        speed: 'medium',
        interval: 2500,
        height: '22px',
        visible: 1,
        mousePause: 1,
        controls: {
          up: '.ticker-controls .up',
          down: '.ticker-controls .down',
          toggle: '',
          playText: 'Play',
          stopText: 'Stop',
        },
      });
    }, 2000);
  }

  userVerificationSubscription() {
    this.verifyOtpService.triggerVerifiedUpdate
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: () => {
          this.checkUserVerification();
        },
      });
  }

  checkUserVerification() {
    const verified = {
      phone: this.authService.user.phone_verified_at,
      email: this.authService.user.email_verified_at,
    };
    this.isUserVerified = this.verifyOtpService.checkUserVerified(verified);
    // this.toggle_is_2fa = this.isUserVerified;
  }

  ngOnInit() {
    // this.mapService.mapOptions.next(this.mapConfiguration);
    AfaqyHelper.pageTitle
      .pipe(
        takeWhile(() => this.alive),
        tap({
          next: (response) => {
            this.pageDetails = {
              pageIcon: response['pageIcon'],
              pageTitle: response['pageTitle'],
            };
          },
        })
      )
      .subscribe();
    this.authService.loadedSession
      .pipe(
        filter((x) => x),
        take(1),
        takeWhile(() => this.alive)
      )
      .subscribe({
        next: (flag) => {
          this.systemLoading = false;
          this.unitService.startAutoload();
          this.constructorOldLogic();
          this.setSplitterSizes();
          this.user = this.authService.user;

          this.checkUserVerification();

          this.toggle_is_2fa = this.authService.user.is_2fa;

          this.loadMapConfigurationFromUserProfile(this.authService.user.map);
        },
      });
    !this.authService?.authToken && this.authService.loadSessionData();
    // Call getCurrentYear() --> get current year to use it in footer.
    this.getCurrentYear();
    // this.setSplitterSizes();
    this.router.events.pipe(takeWhile(() => this.alive)).subscribe({
      next: (event) => {
        const AURl = this.router.routerState.snapshot.url;
        /* event when router NavigationStart */
        if (event instanceof NavigationStart) {
          if (this.router.routerState.snapshot.url.indexOf('popup') === -1) {
            this.pageloading = true;
          }
          // Logger.info("NavigationStart", AURl);

          /* toggle menu */
          this.hideMenu();
        } else if (event instanceof RoutesRecognized) {
          // Logger.info("RoutesRecognized", AURl);
        } else if (event instanceof NavigationEnd) {
          this.pageloading = false;
          if (!this.fullmap) {
            this.setSplitterSizes();
          }
          this.setMapWidth();
          setTimeout(function () {
            AfaqyHelper.windowResize('NavigationEnd');
          }, 1000);
          // Logger.info('NavigationEnd', AURl);
        } else if (event instanceof NavigationCancel) {
          this.pageloading = false;
        }
      },
    });
    this.authService.sessionObserver
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (status) => {
          this.isActiveSession = this.authService.isActiveLogin();
          if (this.sysColor !== this.authService.color) {
            this.updateColor();
          }
        },
      });
    AfaqyHelper.windowResize('Home');
    AfaqyHelper.smallWidth.pipe(takeWhile(() => this.alive)).subscribe({
      next: (response) => {
        this.smallWidth = <boolean>response;
      },
    });
    AfaqyHelper.listingIcon.pipe(takeWhile(() => this.alive)).subscribe({
      next: (icon) => {
        this.contentsVisibilty.listingIcon = <string>icon;
      },
    });
    AfaqyHelper.updateSettingsStyle();
    this.toggleLogoutButton();
    this.userVerificationSubscription();
  }

  twoFactorToggleChange(event) {
    this.toggle_is_2fa = event;

    // A new api call will be called with this key only
    this.verifyOtpService
      .patch2faToggle(this.toggle_is_2fa)
      .subscribe((response: any) => {
        if (response.data.is_2fa) {
          this.rootService.pushNotification(
            this.translate.instant('notifications.verify-account.2fa_enabled'),
            '',
            'success'
          );
        }
      });
  }

  mapDragDisabled = false;
  mapGutterSize = 7;

  setSplitterSizes() {
    const length = this.router.routerState.snapshot.url.indexOf('?');
    const pageURL = this.router.routerState.snapshot.url.substr(
      0,
      length == -1 ? this.router.routerState.snapshot.url.length : length
    );
    let isPopup = false;
    if (
      !(
        pageURL.includes('popup') ||
        pageURL.includes('/add') ||
        pageURL.includes('/edit') ||
        pageURL.includes('/copy') ||
        pageURL.includes('/assign') ||
        pageURL.includes('/play') ||
        pageURL.includes('/zone_units')
      )
    ) {
      this.authService.loadedSession.subscribe({
        next: (flag) => {
          if (flag) {
            this.authService.updatePreferences('site', 'page', pageURL);
          }
        },
      });
    } else {
      isPopup = true;
    }
    const reportsRouter = this.router.url.substr(1, 7);
    this.direction = 'horizontal';
    this.dragDisabled = true;
    if (reportsRouter === 'reports') {
      const reportsRouterSettings = this.router.url.substr(1, 16);
      let reportsSubRoute = '';
      const reportsRoute = this.router.url.split('/');
      if (reportsRoute[1] === 'reports') {
        reportsSubRoute = reportsRoute[2];
      }
      if (reportsRouterSettings === 'reports/settings') {
        this.dragDisabled = false;
        this.splitter = this.authService.preferences('site', 'splitter', {});
        this.splitter['gutterSize'] = AppConfig.spliterGutterSize;
      } else if (reportsSubRoute === 'categories') {
        this.dragDisabled = false;
        this.splitter = this.authService.preferences('site', 'splitter', {});
        this.splitter['gutterSize'] = AppConfig.spliterGutterSize;
      } else if (
        reportsRouterSettings === 'reports' ||
        reportsRouterSettings === 'reports/template' ||
        reportsRouterSettings === 'reports/schedule' ||
        reportsRouterSettings === 'reports/add' ||
        reportsRoute[1]?.includes('reports(popup:profile)') ||
        reportsRoute[1]?.includes('reports(popup:package-usage)')
      ) {
        this.splitter = { data: 100, map: 0, gutterSize: 1 };
      } else {
        this.splitter = { map: 70, data: 30, gutterSize: 1 };
        this.dragDisabled = true;
      }
    } else {
      if (pageURL === '/monitoring-timeline') {
        this.direction = 'vertical';
        // this.dragDisabled = false;
        this.splitter = {
          map: 50,
          data: 50,
          gutterSize: AppConfig.spliterGutterSize,
        };
      } else if (
        pageURL === '/second-by-second' ||
        pageURL === '/messages' ||
        pageURL === '/tracking' ||
        pageURL.startsWith('/tracking/edit/') ||
        pageURL.startsWith('/messages/edit/') ||
        pageURL.startsWith('/second-by-second/edit/') ||
        pageURL === '/multi-unit-follow'
      ) {
        const pwidth =
          pageURL === '/messages' ||
          pageURL.startsWith('/messages/edit/') ||
          '/second-by-second'
            ? 420
            : 450;
        const left = Math.round((pwidth / window.innerWidth) * 10000) / 100;
        this.splitter = { data: left, map: 100 - left, gutterSize: 1 };
        this.dragDisabled = true;
      } else if (pageURL.includes('dashboard')) {
        this.splitter = { data: 100, map: 0, gutterSize: 1 };
        this.dragDisabled = true;
      } else if (pageURL.includes('iot_monitoring')) {
        this.splitter = { data: 100, map: 0, gutterSize: 1 };
        this.dragDisabled = true;
      } else if (pageURL.includes('/wastes/plans/statistics')) {
        this.splitter = { data: 100, map: 0, gutterSize: 1 };
        this.dragDisabled = true;
      } else {
        if (!(isPopup || this.bookService.status)) {
          AfaqyHelper.MapSectionSplitter.next({ visible: false });
        }
        if (!pageURL.includes('popup') && !pageURL.includes('play')) {
          this.dragDisabled = false;
          this.splitter = this.authService.preferences('site', 'splitter', {});
          this.splitter['gutterSize'] = AppConfig.spliterGutterSize;
        }
      }
    }
  }

  checkUserOtpBlocked(userVerification: any) {
    let userBlocked = false;
    Object.keys(userVerification?.blocked).forEach((type) => {
      if (userVerification?.blocked[type]?.blocked) {
        userBlocked = true;
      }
    });
    return userBlocked;
  }

  updateFiltersCultures(lang: any) {
    // const url = 'assets/wijimo-cultures/wijmo.culture.' + lang + '.js';
    // AfaqyHelper.loadJSFile("wijmo-culture", url);
    // setTimeout(() => {
    //     wjcCore.Control.invalidateAll();
    // }, 1000);
  }

  getCurrentYear() {
    // get year
    const dt = new Date();
    this.currentYear = dt.getFullYear();
  }

  loginAsSubUser() {
    /* temporarily using jQuery all modal() replaces */
    $('.modal').modal('hide');
    /* temporarily using jQuery all modal() replaces */
    this.showLoginAs = !this.showLoginAs;
  }

  toggleSidebars(key: any, button: any) {
    this.navbarWidth();
    $(key).modal('toggle');
    $(button).attr('disabled', 'disabled');
    $('.modal').modal('hide');
    setTimeout(() => {
      $(button).removeAttr('disabled');
    }, 500);
  }

  /* menu toggler */
  toggleMenu() {
    this.navbarWidth();
    this.MenuOpen = !this.MenuOpen;
  }

  hideMenu() {
    this.navbarWidth();
    this.MenuOpen = false;
  }

  /* helper function for setting menu state open or closed from the home  */
  toggleMenuhelper(status: any) {
    this.MenuOpen = status;
  }

  /* notifications toggler */
  toggleNotifications() {
    this.NotificationsOpen = !this.NotificationsOpen;
  }

  /* helper function for setting notifications state open or closed from the home  */
  toggleNotificationshelper(status: any) {
    this.NotificationsOpen = status;
  }

  /* UserSidebar toggler  */
  toggleUserSidebar() {
    this.UserSidebarOpen = !this.UserSidebarOpen;
  }

  /* helper function for setting UserSidebar state open or closed from the home  */
  toggleUserSidebarhelper(status: any) {
    this.UserSidebarOpen = status;
  }

  closeModal() {
    const l = document.getElementById('user-settings-sidebar');
    for (let i = 0; i < 5; i++) {
      l.click();
    }
  }

  logout() {
    this.authService.redirectTOLogin('Home Logout');
  }

  show(key) {
    this.contentsVisibilty[key] = !this.contentsVisibilty[key];
    const keys = ['notifications', 'profile', 'menu'];
    const self = this;
    keys.forEach(function (value) {
      if (key !== value) {
        self.contentsVisibilty[value] = false;
      }
    });
    if (key) {
      AfaqyHelper.hideSettings();
    }
  }

  updateColor() {
    const color = this.authService.color;
    for (const k in this.colors) {
      if (this.colors[k].name === color) {
        this.colors[k].active = true;
        this.sysColor = color;
        AfaqyHelper.updateThemeBodyClass(this.colors[k]);
      } else {
        document
          .getElementsByTagName('body')[0]
          .classList.remove('theme-' + this.colors[k].name);
        this.colors[k].active = false;
      }
    }
  }

  toggleVisibility() {
    // AfaqyHelper.hideListOptions();
    this.contentsVisibilty.map = !this.contentsVisibilty.map;
    this.contentsVisibilty.listing = !this.contentsVisibilty.listing;
    setTimeout(function () {
      AfaqyHelper.windowResize('toggleVisibility');
    }, 500);
  }

  getElmClassesListWithParents(elm: Element) {
    const classes = [];
    while (elm.parentElement) {
      const classesList = elm.classList;
      for (let index = 0; index < classesList.length; index++) {
        classes.push(classesList.item(index));
      }
      elm = elm.parentElement;
    }
    return classes;
  }

  checkOpenedLayers(event: any) {
    const srcElm = event.srcElement as Element;
    const calssesList = this.getElmClassesListWithParents(srcElm);
    if (!calssesList.includes('has-over-layer')) {
      AfaqyHelper.hideSettings();
      AfaqyHelper.hideDropdownMenus();
      this.show('');
    }
    if (!calssesList.includes('mapControl_selectTile')) {
      AfaqyHelper.hideDropdowns('mapTypesList', 'hide');
    }
  }

  toggleSettings() {
    AfaqyHelper.hideDropdownMenus();
    this.show('');
    document.getElementsByTagName('body')[0].classList.toggle('site-settings');
  }

  toggleSidebar() {
    document
      .getElementsByTagName('body')[0]
      .classList.toggle('page-sidebar-left-show');
  }

  onHChange(event: any) {}

  onVChange(event: any) {}

  setMapWidth() {
    document.body.style.setProperty(
      '--map-width',
      window.innerWidth * (this.splitter['map'] / 100) + ''
    );
    document.body.style.setProperty(
      '--data-width',
      window.innerWidth * (this.splitter['data'] / 100) + ''
    );
  }

  // This function has been deprecated and replaced with getUserMapStatus(), toggleMapStatus(), dataMapStatusHandler()
  // updateMapSize(toggle = true) {
  //     const fullmap = this.authService.preferences("site", "fullmap", false);
  //     this.fullmap = toggle ? !fullmap : fullmap;
  //     this.authService.updatePreferences("site", "fullmap", this.fullmap);
  //     if (this.fullmap) {
  //         this.splitter = {data: 0, map: 100, gutterSize: 0.3};
  //     } else {
  //         this.splitter = this.authService.preferences("site", "splitter");
  //         this.splitter['gutterSize'] = AppConfig.spliterGutterSize;
  //     }
  //     setTimeout(function () {
  //         AfaqyHelper.windowResize();
  //     }, 200);
  // }

  // Get User Map Status from his own preferences
  getUserMapStatus() {
    const userMapStatus = this.authService.preferences(
      'site',
      'fullmap',
      false
    );
    this.dataMapStatusHandler(userMapStatus);
  }

  // Toggle Map Status when click on the toggle button in footer
  toggleMapStatus() {
    this.fullmap = !this.fullmap;
    this.dataMapStatusHandler(this.fullmap);
  }

  // Change Map Status and make layout changes and save last map status in user preferences
  // Also this is called from MenuComponent which update map status to false when user navigate from menu
  dataMapStatusHandler(mapStatus: boolean) {
    mapStatus ? (this.fullmap = mapStatus) : (this.fullmap = false);
    this.authService.updatePreferences('site', 'fullmap', this.fullmap);
    this.authService.loadedSession.subscribe({
      next: (flag) => {
        if (flag) {
          if (this.fullmap) {
            this.splitter = { data: 0, map: 100, gutterSize: 0.3 };
            this.cdr.detectChanges();
          } else {
            this.setSplitterSizes();
          }
          setTimeout(function () {
            AfaqyHelper.windowResize();
          }, 200);
        }
      },
    });
  }

  loginInSameWindow(id: any) {
    this.userService
      .confirm('confirm-login-as')
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response) {
            this.authService.loginAs(id, true).subscribe({
              next: (response) => {
                if (response && response.status_code === 200) {
                  AfaqyHelper.clearBrowserHistory();
                  this.socketService.closeSocketConnection();
                  window.open('/auth/validate/' + response.data.token, '_self');
                  // this.authService.loadSessionData();
                } else {
                  this.rootService.pushNotification(
                    '',
                    this.translate.instant(`messages.${response.message}`),
                    'error'
                  );
                }
              },
            });
          }
        },
      });
  }

  navigateToPreviousLoggedUser() {
    if (this.authService.loggedAsUser) {
      this.socketService.closeSocketConnection();
      AfaqyHelper.clearBrowserHistory();
      window.open(
        '/auth/validate/' + this.authService.loggedAsUser.token,
        '_self'
      );
    }
  }

  LoginInNewWindow(id: any) {
    this.authService
      .loginAs(id, false)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response && response.status_code === 200) {
            window.open('/auth/validate/' + response.data.token);
          } else {
            this.rootService.pushNotification(
              '',
              this.translate.instant('messages.' + response.message),
              'error'
            );
          }
        },
      });
  }

  toggleBookLog(toggle: boolean = true) {
    if (this.bookService.blocked) {
      return;
    }
    if (toggle) {
      this.bookService.setStatus(!this.bookService.status);
    }
    if (this.bookService.status) {
      this.showBookLog();
    } else {
      AfaqyHelper.MapSectionSplitter.next({ visible: false });
    }
  }

  showBookLog() {
    if (AfaqyHelper?.MapSectionContainer) {
      AfaqyHelper?.MapSectionContainer?.createComponent(BookLogComponent);
    } else {
      AfaqyHelper.MapSectionContainerRefresh.next(true);
      AfaqyHelper?.MapSectionContainer?.createComponent(BookLogComponent);
    }
    AfaqyHelper.MapSectionSplitter.next({ visible: true });
  }

  filterMovingUnits(): void {
    this.filterUnitsByState(UnitState.Moving);
  }

  filterStoppedUnits(): void {
    this.filterUnitsByState(UnitState.Stopped);
  }

  private filterUnitsByState(unitState: UnitState): void {
    const filteredUnitsId: string[] = this.getFilteredUnitsId(unitState);
    this.unitService.showALLUnits(false);
    this.clearVisibleFollowList();
    this.unitService.trackedResources = filteredUnitsId;
  }

  private getFilteredUnitsId(unitState: UnitState): string[] {
    const resourceTrackingDetails: any =
      this.unitService.resourceTrackingDetails;
    const filteredUnitsIds: any[] = [];
    let unit: any;
    for (const unitId in resourceTrackingDetails) {
      unit = resourceTrackingDetails[unitId];
      if (unitState === UnitState.Moving) {
        this.pushFilteredMovingUnitsIds(unit, unitId, filteredUnitsIds);
      } else if (unitState === UnitState.Stopped) {
        this.pushFilteredStoppedUnitsIds(unit, unitId, filteredUnitsIds);
      }
    }
    return filteredUnitsIds;
  }

  private pushFilteredMovingUnitsIds(
    unit: any,
    unitId: any,
    filteredUnitsIds: any[]
  ): void {
    if (
      unit.motion_state === MonitoringStatus.MotionState.MovingON ||
      unit.motion_state === MonitoringStatus.MotionState.MovingOFF
    ) {
      filteredUnitsIds.push(unitId);
    }
  }

  private pushFilteredStoppedUnitsIds(
    unit: any,
    unitId: any,
    filteredUnitsIds: any[]
  ): void {
    if (
      unit.motion_state === MonitoringStatus.MotionState.StationaryON ||
      unit.motion_state === MonitoringStatus.MotionState.StationaryOFF
    ) {
      filteredUnitsIds.push(unitId);
    }
  }

  private clearVisibleFollowList() {
    let allIDs = this.unitService.AllUnitsIDs;
    allIDs.forEach((id) => {
      this.unitService.updateUnitTrackingDetails(id, false, 'visible');
      this.unitService.updateUnitTrackingDetails(id, false, 'follow');
    });
  }

  getImportToFillData() {
    this.pusherNotificationSocket.importToFill$
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        if (data) {
          this.importSocketData = data;
          this.importToFillData = data.data.data;
          this.importToFillData.module = 'units';
          this.showImportToFill = true;
          this.showCopyInfoSocketIcon = false;
        }
      });

    this.pusherNotificationSocket.inventoryRooms$
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        if (data) {
          this.importSocketData = data;
          this.importToFillData = data.data.data;
          this.importToFillData.module = 'inventory_rooms';
          this.showImportToFill = true;
          this.showCopyInfoSocketIcon = false;
        }
      });

    this.pusherNotificationSocket.unitGroups$
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        if (data) {
          this.importSocketData = data;
          this.importToFillData = data.data.data;
          this.importToFillData.module = 'unit_groups';
          this.showImportToFill = true;
          this.showCopyInfoSocketIcon = false;
        }
      });

    this.pusherNotificationSocket.removeSpinnerAfterImport$
      .pipe(takeWhile(() => this.alive))
      .subscribe((res) => {
        if (res) {
          this.showImportToFill = false;
          this.pusherNotificationSocket.importToFill$.next(null);
        }
      });
  }

  capitalizeAndRemoveUnderscore(word) {
    let words = word.split(/[_-]/);

    // Iterate through the array of words
    for (let i = 0; i < words.length; i++) {
      // Capitalize the first letter of each word
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Join the array of words back into a string, separated by spaces
    return words.join(' ');
  }

  showImportToFillPermissionToastr(operation) {
    this.notificationsService.error(
      this.translate.instant('common.accessDenied', {
        module: this.capitalizeAndRemoveUnderscore(operation.module),
      })
    );
  }

  loadImportToFillModal(operation?: any) {
    if (operation && operation?.operation?.includes('failure')) {
      if (
        !this.authService.checkPermissions(`${operation.module}-importErrors`)
      ) {
        this.showImportToFillPermissionToastr(operation);
      } else {
        if (this.importToFill) {
          this.importToFill.clear();
          let componentRef = this.importToFill.createComponent(
            ImportToFillComponent
          );
          componentRef.instance['operation'] = operation;
          componentRef.instance['componentName'] = operation.module;
          componentRef.instance['loadErrorsTable'] = true;
          componentRef.instance['currentStep'] = 'completed_with_failures';
        }
      }
    } else {
      // todo : check permission for success
      // if (!this.authService.checkPermissions(`${operation.module}-importErrors`)) {
      //   this.showImportToFillPermissionToastr(operation);
      // } else {
      if (this.importToFill) {
        this.importToFill.clear();
        let componentRef = this.importToFill.createComponent(
          ImportToFillComponent
        );
        componentRef.instance['componentName'] = operation.module;
        componentRef.instance['loadErrorsTable'] = true;
      }
      // }
    }
  }

  toggleLogoutButton() {
    const sessionUserName = JSON.parse(sessionStorage.getItem('loggedAsUser'));
    if (typeof sessionUserName === 'object' && sessionUserName?.id) {
      this.toggleLogoutBtn = false;
    }
  }
}
