import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AfaqyValidation } from 'app/common/afaqy-validation';
import { Unit, UnitSensor } from 'app/modules/units/models';
import { UnitSensorsService, UnitService } from 'app/modules/units/services';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { SensorType } from '../../../../common/classes/sensor-type';
import { AfaqyHelper, Message } from './../../../../common/classes';
import { AfaqyResponse } from './../../../../core/classes';
import { SensorColors } from './interfaces/sensor-colors.interfaces';
import { IntervalColorsService } from './sensor-colors/interval-colors/interval-colors.service';
import { SensorColorsFormService } from './sensor-colors/sensor-colors-form/sensor-colors-form.service';

@Component({
  selector: 'unit-sensor-form',
  templateUrl: './unit-form-sensor-form.component.html',
  styleUrls: ['./unit-form-sensor-form.component.scss'],
  animations: [
    trigger('showhide', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(0)',
          'transform-origin': '0 0',
        }),
        animate(
          '0.2s linear',
          style({
            opacity: 1,
            transform: 'scale(1)',
            'transform-origin': '0 0',
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '0.2s linear',
          style({
            opacity: 0,
            transform: 'scale(0)',
            'transform-origin': '0 0',
          })
        ),
      ]),
    ]),
  ],
})
export class UnitFormSensorFormComponent
  implements OnChanges, OnInit, OnDestroy
{
  alive: boolean = true;
  @Input() object: UnitSensor;
  @Input() unitID: string;
  @Input() unitObject: Unit;
  @Input() isView: boolean;
  @Input() isAdd?: boolean;
  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateList: EventEmitter<any> = new EventEmitter<any>();
  cid = 'units-sensors-';
  iconsList = [];
  defaultParams = [];
  title: string = '';
  form: FormGroup;
  message: Message;

  posting = false;
  loading = false;
  forceDeactivate: boolean = false;
  subs = new Subscription();

  isToolTipShow: boolean = true;
  isObjectChanged: boolean;

  rightPanelType: string = 'calibration';

  codeValueColors = {};
  sensorOptions: {};
  constructor(
    private dragulaService: DragulaService,
    public translate: TranslateService,
    protected fb: FormBuilder,
    public service: UnitSensorsService,
    public unitService: UnitService,
    private intervalColorsService: IntervalColorsService,
    private sensorColorsFormService: SensorColorsFormService
  ) {
    this.object = new UnitSensor();
    this.createForm();

    this.subs.add(
      this.dragulaService
        .drop('calibrations')
        .subscribe(({ name, el, source }) => {
          const elmXvalue = parseInt(el.querySelector('.xvalue').innerHTML);
          const calList = this.calibrations.value;
          const oldIdx = calList.findIndex((item) => item.x == elmXvalue);
          const newIdx = [].slice.call(el.parentElement.children).indexOf(el);
          let newcalList = AfaqyHelper.array_move(calList, oldIdx, newIdx);
          this.calibrations.controls = [];
          newcalList.forEach((item) => {
            let c = this.calibrationForm;
            c.reset(item);
            this.calibrations.push(c);
          });
          this.form.markAsDirty();
        })
    );

    this.subs.add(
      this.dragulaService
        .drop('codeValue')
        .subscribe(({ name, el, source }) => {
          const elmXvalue = el.querySelector('.cv_xvalue').innerHTML;
          const cvList = this.codeValues.value;
          const oldIdx = cvList.findIndex((item) => item.x == elmXvalue);
          const newIdx = [].slice.call(el.parentElement.children).indexOf(el);
          let newCvList = AfaqyHelper.array_move(cvList, oldIdx, newIdx);
          this.codeValues.controls = [];
          newCvList.forEach((item) => {
            let c = this.codeValueForm;
            c.reset(item);
            this.codeValues.push(c);
          });
          this.form.markAsDirty();
        })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillFormValues();
    this.setParams();

    //to load the value from the form to enable/disable the last update checkbox
    this.isToolTipShow = this.form.controls['tooltip_show'].value;

    if (changes.object.currentValue) {
      this.isObjectChanged = true;
      this.setInitialIntervalColors();
    }
  }

  /** If unit instance has sensorParams value,
   *  set it to form params.
   *  Else, calling API and set unitParams to form params and sensorParams of unit instance. */
  private setParams(): void {
    const unit: Unit = this.unitService.getItemFromResources(this.unitID);

    if (unit.sensorParams && unit.sensorParams.length) {
      SensorType.formOptions.params = unit.sensorParams;
    } else {
      if(!this.isAdd) {
        this.unitService
        .getUnitDefaultParameters(this.unitID)
        .subscribe((unitDefaultParameters) => {
          if (!unit.last_update.devPrms) {
            unit.last_update.devPrms = [];
          }

          this.defaultParams = this.merge_array(
            unitDefaultParameters,
            unit.last_update.devPrms
          );
          const unitParams: string[] = this.merge_array(
            this.defaultParams,
            SensorType.formOptions.params
          );
          SensorType.formOptions.params = unitParams;
          unit.sensorParams = unitParams;
        });
      }
    }
  }

  private setInitialIntervalColors(): void {
    let objColors: any[] = [...this.object.colors];

    // set default digital colors array to obj Colors.
    if (
      (!this.object.colors || this.object.colors.length <= 1) &&
      (this.object.result_type === 'logic' ||
        this.object.result_type === 'logicgte')
    ) {
      objColors = [
        { type: 0, color: '#000000' },
        { type: 1, color: '#000000' },
      ];
    }
    // set default analog colors array to obj Colors.
    else if (
      (!this.object.colors || this.object.colors.length === 0) &&
      this.object.result_type === 'value'
    ) {
      objColors = [{ from: null, to: null, color: '#000000' }];
    }
    //set CodeValues Colors Keys
    else if (
      (!this.object.colors || this.object.colors.length === 0) &&
      this.object.result_type === 'codeValue'
    ) {
      for (const row of this.codeValues.value) {
        objColors.push({
          type: row.x,
          color: '#000000',
        });
      }
    }
    const intervalColors: SensorColors = {
      resultType: this.object.result_type,
      colors: [...objColors],
    };
    this.intervalColorsService.updateIntervalColors(intervalColors);
  }

  merge_array(array1: any, array2: any) {
    if (Array.isArray(array1) && Array.isArray(array2)) {
      let result_array = [];
      let arr = array1.concat(array2);
      let len = arr.length;
      let assoc = {};

      while (len--) {
        let item = arr[len];

        if (!assoc[item]) {
          result_array.unshift(item);
          assoc[item] = true;
        }
      }

      return result_array.sort();
    }
  }

  setIcon(icon: any) {
    this.form.controls['icon'].setValue(icon);
    this.form.markAsDirty();
  }

  get form_fields(): any {
    return {
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      type: ['acc', [Validators.required]],
      param: ['', [Validators.required]],
      result_type: ['logic', [Validators.required]],
      text_0: [],
      text_1: [],
      units: [],
      low_val: [],
      high_val: [],
      formula: [],
      formula_value: [],
      calibration: this.fb.array([]),
      codeValue: this.fb.array([]),
      icon: ['', [Validators.required]],
      tooltip_show: [true],
      last_update: [false],
      fixed: [false],
      colors: this.fb.array([]),
    };
  }

  ngOnInit() {
    this.message = new Message();
    this.fillFormValues();
    this.iconsList = this.service.sensorsIcons;
    if (!this.form.controls['icon'].value) {
      this.form.controls['icon'].setValue('mdi mdi-bell');
    }
    this.sensorOptions = SensorType.formOptions;
  }

  ngOnDestroy() {
    this.alive = false;
    this.subs.unsubscribe();
  }

  get calibrations(): FormArray {
    return this.form.get('calibration') as FormArray;
  }

  get calibrationForm() {
    return this.fb.group({
      x: [
        '',
        [
          Validators.required,
          AfaqyValidation.numberValidatorIfexist,
          Validators.min(-50000),
          Validators.max(150000),
        ],
      ],
      y: [
        '',
        [
          Validators.required,
          AfaqyValidation.numberValidatorIfexist,
          Validators.min(-50000),
          Validators.max(150000),
        ],
      ],
    });
  }

  get codeValues(): FormArray {
    return this.form.get('codeValue') as FormArray;
  }

  get codeValueForm() {
    return this.fb.group({
      x: ['', [Validators.required]],
      y: ['', [Validators.required]],
    });
  }

  checkType(crs = true, updateResultType = false) {
    const type = this.form.controls['type'].value;
    if (
      type == 'acc' ||
      type == 'di' ||
      type == 'door' ||
      type == 'door2' ||
      type == 'CU' ||
      type == 'arm_movement'
    ) {
      SensorType.formOptions.result_types = ['logic'];
      if (updateResultType) this.form.controls['result_type'].setValue('logic');
    } else if (
      type == 'ai' ||
      type == 'liquid' ||
      type == 'temperature' ||
      type == 'in_battery_volt' ||
      type == 'ex_battery_volt' ||
      type == 'humidity' ||
      type == 'passengers' ||
      type == 'fuel' ||
      type == 'weight' ||
      type == 'eng_hour' ||
      type == 'milage' ||
      type == 'fc' ||
      type == 'engine_temperature' ||
      type == 'fuel_level' ||
      type == 'arm_angle'
    ) {
      SensorType.formOptions.result_types = [
        'value',
        'percentage',
        'codeValue',
      ];
      if (updateResultType) this.form.controls['result_type'].setValue('value');
    } else if (
      type == 'tailer_bind' ||
      type == 'driver_bind' ||
      type == 'rfid'
    ) {
      SensorType.formOptions.result_types = [
        'string',
        'codeValue',
        'hexString',
      ];
      if (updateResultType)
        this.form.controls['result_type'].setValue('string');
    } else {
      SensorType.formOptions.result_types = [
        'logic',
        'logicgte',
        'string',
        'value',
        'percentage',
        'codeValue',
        'hexString',
      ];
      if (updateResultType) this.form.controls['result_type'].setValue('logic');
    }
    if (crs) this.checkResultType();
    if (crs && updateResultType) this.setFormulaDefaultValues();
    this.updateIntervalColors();
  }

  checkResultType() {
    const controls = [
      'text_0',
      'text_1',
      'low_val',
      'high_val',
      'formula',
      'formula_value',
      'calibration',
      'units',
      'codeValue',
    ];
    controls.forEach((c) => {
      this.form.controls[c].enable();
      this.form.controls[c].setValidators([]);
    });

    const resultType = this.form.controls['result_type'].value;
    let disabledControls = [];
    if (resultType == 'logic' || resultType == 'logicgte') {
      this.form.controls['text_0'].setValidators([Validators.required]);
      this.form.controls['text_1'].setValidators([Validators.required]);
      this.form.controls['text_0'].updateValueAndValidity();
      this.form.controls['text_1'].updateValueAndValidity();
      disabledControls = [
        'units',
        'low_val',
        'high_val',
        'formula',
        'formula_value',
        'calibration',
        'codeValue',
      ];
    } else if (resultType == 'percentage') {
      this.form.controls['low_val'].setValidators([
        Validators.required,
        AfaqyValidation.numberValidator,
      ]);
      this.form.controls['high_val'].setValidators([
        Validators.required,
        AfaqyValidation.numberValidator,
      ]);
      this.form.controls['units'].setValue('%');
      disabledControls = [
        'text_0',
        'text_1',
        'formula',
        'formula_value',
        'calibration',
        'units',
        'codeValue',
      ];
    } else if (resultType == 'value') {
      disabledControls = [
        'text_0',
        'text_1',
        'low_val',
        'high_val',
        'codeValue',
      ];
      this.rightPanelType = 'calibration';
    } else if (resultType == 'string' || resultType == 'hexString') {
      disabledControls = [
        'text_0',
        'text_1',
        'low_val',
        'high_val',
        'units',
        'formula',
        'formula_value',
        'calibration',
        'codeValue',
      ];
    } else if (resultType == 'codeValue') {
      this.form.controls['codeValue'].setValidators([Validators.required]);
      disabledControls = [
        'text_0',
        'text_1',
        'low_val',
        'high_val',
        'units',
        'formula',
        'formula_value',
        'calibration',
      ];

      this.rightPanelType = 'codeValue';
    }
    disabledControls.forEach((c) => {
      this.form.controls[c].disable();
    });
    this.updateFormulaValidation();
    this.updateIntervalColors();
  }

  /** Send updated result type with its colors value to updateIntervalColors fn of intervalColorsService.*/
  private updateIntervalColors(): void {
    if (!this.isObjectChanged) {
      return;
    }
    const typeCtrl = this.form.controls['type'];
    const resultTypeCtrl = this.form.controls['result_type'];
    const isObjectType: boolean = typeCtrl.value === this.object.type;
    const isObjectResultType: boolean =
      resultTypeCtrl.value === this.object.result_type;

    if (!isObjectType || !isObjectResultType) {
      const defaultColors: any[] =
        resultTypeCtrl.value == 'codeValue'
          ? this.loadCodeValueColors()
          : this.intervalColorsService.getDefaultColors(resultTypeCtrl.value);
      this.intervalColorsService.updateIntervalColors({
        resultType: resultTypeCtrl.value,
        colors: defaultColors,
      });
    } else if (
      isObjectType &&
      isObjectResultType &&
      (typeCtrl.dirty || resultTypeCtrl.dirty)
    ) {
      this.setInitialIntervalColors();
    }
  }

  private loadCodeValueColors() {
    let objColors = [];
    for (const row of this.codeValues.value) {
      objColors.push({
        type: row.x,
        color: this.getCodeColor(row.x),
      });
    }

    return objColors;
  }

  private getCodeColor(x) {
    if (this.codeValueColors[x]) {
      return this.codeValueColors[x];
    }

    const color = Math.round(0xffffff * Math.random()).toString(16);
    const z = '000000'.substring(0, 6 - color.length);
    this.codeValueColors[x] = '#' + z + color;

    return this.codeValueColors[x];
  }

  updateFormulaValidation() {
    this.form.controls['formula_value'].setValidators([
      Validators.required,
      AfaqyValidation.numberValidatorIfexist,
      Validators.maxLength(10),
    ]);
  }

  updateCodeValueTable() {
    this.codeValues.push(this.codeValueForm);
  }

  deleteCalibration(index: any) {
    this.calibrations.removeAt(index);
    this.updateFormulaValidation();
    this.form.controls['formula_value'].markAsTouched();
    this.form.controls['formula_value'].markAsDirty();
  }

  addNewCalibration() {
    this.calibrations.push(this.calibrationForm);
    this.updateFormulaValidation();
  }

  applySideEffects() {
    if (this.object.calibration) {
      this.calibrations.clear();
      this.object.calibration.forEach((item: any) => {
        let c = this.calibrationForm;
        c.reset(item);
        this.calibrations.push(c);
      });
    } else {
      this.calibrations.controls = [];
    }

    if (this.object.codeValue) {
      this.codeValues.clear();
      this.object.codeValue.forEach((item: any) => {
        let c = this.codeValueForm;
        c.reset(item);
        this.codeValues.push(c);
      });
    } else {
      this.codeValues.controls = [];
    }

    this.checkType(false);
    this.checkResultType();
  }

  isEditUnit() {
    return this.unitID;
  }

  canDeactivate() {
    if (this.forceDeactivate || this.form.pristine) {
      return true;
    }
    return this.service.confirm();
  }

  fillFormValues() {
    let fobj = {};
    if (this.object) {
      for (let field in this.form_fields) {
        fobj[field] = this.object[field];
      }
    }
    this.form.reset(fobj);
    this.setFormulaDefaultValues();
    this.applySideEffects();
  }

  createForm() {
    this.form = this.fb.group(this.form_fields);
    this.applySideEffects();
    this.forceDeactivate = false;
  }

  revert() {
    this.fillFormValues();
  }

  reset() {
    this.fillFormValues();
    this.intervalColorsService.reset();
  }

  modalClose($event: any) {
    this.sensorColorsFormService.closeSensorForm();
    this.closeForm.next($event);
  }

  prepareSave(): UnitSensor {
    const formModel = this.form.value;
    const saveObj = this.service.modelInstance;

    saveObj.id = this.object.id;
    for (let field in this.form_fields) {
      saveObj[field] = formModel[field] as string;
    }
    saveObj.unit_id = this.unitID;
    return saveObj;
  }

  afterFail(err: any) {
    this.posting = false;
    let error = new AfaqyResponse();
    error.copyInto(JSON.parse(err));
    let errorsList = error.errors;
    AfaqyHelper.setFGErrors(this.form, errorsList);
    this.message.type = 'danger';
    this.message.message = error.message || 'please-try-again';
    AfaqyHelper.calcModalHeight(true);
  }

  afterSuccess(msg = {}) {
    AfaqyHelper.calcModalHeight(false);
    this.posting = false;
    this.forceDeactivate = true;
    this.modalClose({ msg: msg, type: 'success', success: true });
  }

  updateSensorsList() {
    const sensor: UnitSensor = this.object;
    const unit: Unit = this.unitService.getUnitDetails(this.object.unit_id);
    if (!unit) {
      return;
    }
    const unitSensorsList: UnitSensor[] = unit.sensors;
    const updatedSensorsList: UnitSensor[] = unitSensorsList.filter(
      (sens) => sens.id != sensor.id
    );
    updatedSensorsList.push(sensor);
    unit.sensors = updatedSensorsList;
    this.unitService.setResourceObject(unit);
  }

  saveToDB() {
    this.object = this.prepareSave();
    this.posting = true;
    this.message.clear();
    if (this.object.id) {
      this.service
        .update(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (response) => {
            this.updateList.next({ object: this.object });
            this.updateSensorsList();
            this.afterSuccess('notifications.unit_sensors.updated');
          },
          (error) => {
            this.afterFail(error);
          }
        );
    } else {
      this.service
        .create(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (response: AfaqyResponse) => {
            this.object.id = response.data.id;
            this.updateList.next({ object: this.object });
            this.updateSensorsList();
            this.afterSuccess('notifications.unit_sensors.added');
          },
          (error) => {
            this.afterFail(error);
          }
        );
    }
  }

  checkCalibrationX($event: any, i: number) {
    const val = $event.target.value;
    let status = true;
    this.calibrations.controls.forEach((item, index) => {
      if (index !== i) {
        if (val == item['controls']['x'].value) {
          status = false;
        }
      }
    });
    if (status) {
      const errors: any =
        this.calibrations['controls'][i]['controls']['x'].errors;
      if (errors && errors['sensor_point_exists'])
        this.calibrations['controls'][i]['controls']['x'].setErrors(null);
    } else {
      this.calibrations['controls'][i]['controls']['x'].setErrors({
        sensor_point_exists: true,
      });
    }
  }

  checkHighValue(event: any) {
    const lval = parseFloat(this.form.controls['low_val'].value);
    const hval = parseFloat(event.target.value);
    if (hval <= lval) {
      this.form.controls['high_val'].setErrors({
        invalid_high_value: true,
      });
    } else {
      this.form.controls['high_val'].setErrors(null);
    }
  }

  isValidSensorValues() {
    let sensors = this.unitObject.sensors;
    const sensorName = this.form.controls['name'].value;
    for (let i = 0; i < sensors.length; i++) {
      if (sensors[i].name === sensorName && this.object.id != sensors[i].id) {
        this.form.controls['name'].setErrors({ exist: true });
        return false;
      }
    }
    return true;
  }

  onSubmit() {
    if (!this.form.valid || !this.isValidSensorValues()) {
      AfaqyHelper.touchAll(this.form);
      return;
    }

    this.intervalColorsService.submit();

    if (this.isEditUnit()) {
      this.saveToDB();
    } else {
      this.object = this.prepareSave();
      this.updateList.next({ object: this.object });
    }
  }

  setCheckboxValue(e: any, field: any) {
    this.form.controls[field].setValue(e.target.checked);
    this.form.markAsDirty();

    if (field == 'tooltip_show') {
      this.isToolTipShow = this.form.controls[field].value;
      if (this.isToolTipShow == false) {
        this.form.controls['last_update'].setValue(false);
      }
    }
  }

  /** Set formula default values if change sensor type or add new sensor */
  private setFormulaDefaultValues() {
    if (this.object.id && this.form.controls['type'].pristine) return;
    this.form.controls['formula'].setValue('*');
    this.form.controls['formula_value'].setValue(1);
  }

  hasRightPanel() {
    const resType = this.form.get('result_type').value;
    return ['value', 'codeValue'].indexOf(resType) != -1;
  }

  hasColorField() {
    const resType = this.form.get('result_type').value;
    return ['logic', 'logicgte', 'value', 'codeValue'].indexOf(resType) != -1;
  }

  deleteCodeValue(index: any) {
    this.codeValues.removeAt(index);
    this.updateIntervalColors();
    this.form.controls['formula_value'].markAsTouched();
    this.form.controls['formula_value'].markAsDirty();
  }

  addNewCodeValue() {
    this.codeValues.push(this.codeValueForm);
  }

  checkCodeExist($event: any, i: number) {
    const val = $event.target.value;
    let status = true;
    this.codeValues.controls.forEach((item, index) => {
      if (index !== i) {
        if (val == item['controls']['x'].value) {
          status = false;
        }
      }
    });
    if (status) {
      const errors: any =
        this.codeValues['controls'][i]['controls']['x'].errors;
      if (errors && errors['sensor_point_exists'])
        this.codeValues['controls'][i]['controls']['x'].setErrors(null);
    } else {
      this.codeValues['controls'][i]['controls']['x'].setErrors({
        sensor_code_exists: true,
      });
    }
  }
}
