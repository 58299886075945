<div
  id="sidebar"
  class="modal left fade show"
  [class.MenuOpen]="MenuOpen"
  [@showHide]
  *ngIf="MenuOpen"
  tabindex="-1"
  role="dialog"
  #sidebar
>
  <div
    class="modal-dialog"
    role="document"
    clickOutside
    (clickOutside)="toggleMenuhelper()"
  >
    <div class="bg-primary modal-content">
      <perfect-scrollbar class="modal-body p-0">
        <div class="menu">
          <ul class="list-group">
            <ng-container *ngFor="let item of activeMenus; let i = index">
              <ng-container
                *ngTemplateOutlet="
                  parentTemplate;
                  context: { item: item, i: i }
                "
              >
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
<div class="custom-backdrop" *ngIf="MenuOpen"></div>

<ng-template #childTemplate let-item="item" let-i="i">
  <div [attr.id]="'menu' + i" class="collapse" *ngIf="item.childs">
    <ng-container *ngFor="let subitem of item.childs; let j = index">
      <ng-container *ngIf="!subitem.childs">
        <div
          [attr.testid]="item.label + '-' + subitem.label"
          (click)="setActive(item.id)"
          [routerLink]="subitem.link"
          [class.active]="subitem?.active"
          [skipLocationChange]="config['skipLocationChange']"
          class="dropdown-item"
          [secured]="subitem.permissions"
        >
          <a class="hvr-forward">
            <i
              *ngIf="subitem.iconClass"
              class="afaqy-menu-icon {{ subitem.iconClass }}"
            ></i>
            {{ subitem.id | translate }}
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="subitem.childs">
        <ul class="list-group subchild">
          <ng-container
            *ngTemplateOutlet="parentTemplate; context: { item: subitem, i: j }"
          >
          </ng-container>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #parentTemplate let-item="item" let-i="i">
  <li
    [attr.testid]="'side-menu-item-' + item.label"
    class="list-group-item p-0"
    [secured]="item.permissions"
    [class.active]="item.selected"
  >
    <a
      *ngIf="!item.link"
      [attr.href]="'#menu' + i"
      class="nav-link"
      [ngClass]="{ 'dropdown-toggle collapsed': item.childs }"
      data-toggle="collapse"
      role="button"
      aria-haspopup="true"
      aria-expanded="false"
      [attr.aria-controls]="i + 'dropdown'"
    >
      <i
        *ngIf="item.iconClass"
        class="afaqy-menu-icon {{ item.iconClass }}"
      ></i>
      <span class="afaqy-menu-text">{{ item.id | translate }}</span>
    </a>
    <ng-container *ngIf="item.childs">
      <ng-container
        *ngTemplateOutlet="childTemplate; context: { item: item, i: i }"
      >
      </ng-container>
    </ng-container>
    <a
      class="nav-link hvr-forward"
      *ngIf="item.link"
      routerLinkActive="active"
      [routerLink]="item.link"
      [skipLocationChange]="config['skipLocationChange']"
      (click)="toggleMenuOnly()"
      (contextmenu)="onRightClick($event, item)"
    >
      <i
        *ngIf="item.iconClass"
        class="afaqy-menu-icon {{ item.iconClass }}"
      ></i>
      <span class="afaqy-menu-text">{{ item.id | translate }}</span>
    </a>
  </li>
</ng-template>
