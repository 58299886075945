import { AppModel, AfaqyHelper, AppConfig } from '../../../common/classes';
import { UserContactInfo } from './user-contact-info';
import { UserMap } from './user-map';
import { UserSettings } from './user-settings';
import { DomainSettings } from './domain-settings';
import { Apps } from './apps';
export class User extends AppModel {
  private _server_id: string;
  private _parent_id: string;
  private _parent: User;
  private _username: string;
  private _email: string;
  private _timezone: string = AppConfig.DEFAULT_TIMEZONE;
  private _lng: string;
  private _password: string;
  private _current_password: string;
  private _contact_info: UserContactInfo = new UserContactInfo();
  private _active: boolean;
  private _role_id: string;
  private _role: any;
  private _role_name: any;
  private _package_id: string;
  private _custom_business_type: any;
  private _package: any;
  private _fuel_prices: any;
  private _notes: string;
  private _map: UserMap = new UserMap();
  private _speed_colors: any = [];
  private _img: string;
  private _imgUrl: string;
  private _use_shifts: string;
  private _units_count: number;
  private _units_groups_count: any;
  private _user_settings: UserSettings = new UserSettings();
  private _domain_settings: DomainSettings = new DomainSettings();
  private _apps: Apps = new Apps();

  private _permissions: string[];
  private _assignedItems: any;
  private _last_login_date: any;
  private _preferences: any;
  private _deleted_at: Date;
  private _account_apps: Array<any>;
  private _is_2fa: boolean;
  private _phone_verified_at: boolean;
  private _email_verified_at: boolean;

  titleField = 'username';

  protected copyFields = [
    'id',
    'username',
    'email',
    'phone',
    'timezone',
    'lng',
    'password',
    'current_password',
    'contact_info',
    'active',
    'role_id',
    'role',
    'role_name',
    'package_id',
    'fuel_prices',
    'notes',
    'map',
    'speed_colors',
    'img',
    'permissions',
    'server_id',
    'parent_id',
    'custom_business_type',
    'deleted_at',
    'package',
    'assignedItems',
    'subuser',
    'subusers',
    'preferences',
    'imgUrl',
    'user_settings',
    'domain_settings',
    'last_login_date',
    'apps',
    'use_shifts',
    'units_count',
    'units_groups_count',
    'is_2fa',
    'email_verified_at',
    'phone_verified_at',
  ];
  protected createFields = [
    'username',
    'email',
    'phone',
    'timezone',
    'lng',
    'password',
    'current_password',
    'contact_info',
    'active',
    'role_id',
    'role',
    'role_name',
    'package_id',
    'fuel_prices',
    'notes',
    'map',
    'speed_colors',
    'img',
    'permissions',
    'server_id',
    'parent_id',
    'custom_business_type',
    'assignedItems',
    'user_settings',
    'domain_settings',
    'apps',
    'use_shifts',
  ];
  protected updateFields = [
    'username',
    'email',
    'phone',
    'timezone',
    'lng',
    'password',
    'current_password',
    'contact_info',
    'active',
    'role_id',
    'role',
    'role_name',
    'package_id',
    'fuel_prices',
    'notes',
    'map',
    'speed_colors',
    'img',
    'permissions',
    'server_id',
    'parent_id',
    'custom_business_type',
    'assignedItems',
    'user_settings',
    'domain_settings',
    'apps',
    'use_shifts',
  ];

  get package(): any {
    return this._package;
  }

  set package(value: any) {
    this._package = value;
  }

  get parent_id(): string {
    return this._parent_id;
  }

  set parent_id(value: string) {
    this._parent_id = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get timezone(): string {
    return this._timezone;
  }

  set timezone(value: string) {
    this._timezone = value;
  }

  get lng(): string {
    return this._lng;
  }

  set lng(value: string) {
    this._lng = value;
  }

  get password(): string {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }

  get current_password(): string {
    return this._current_password;
  }

  set current_password(value: string) {
    this._current_password = value;
  }

  get contact_info(): UserContactInfo {
    return this._contact_info;
  }

  set contact_info(value: UserContactInfo) {
    let cinfo = new UserContactInfo();
    cinfo.copyInto(value);
    this._contact_info = cinfo;
  }

  get active(): boolean {
    return this._active == null || this._active == undefined
      ? true
      : this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  get role_id(): string {
    return this._role_id;
  }

  set role_id(value: string) {
    this._role_id = value;
  }

  get role(): any {
    return this._role;
  }

  set role(value: any) {
    this._role = value;
  }

  get package_id(): string {
    return this._package_id;
  }

  set package_id(value: string) {
    this._package_id = value;
  }

  get fuel_prices(): any {
    return this._fuel_prices;
  }

  set fuel_prices(value: any) {
    this._fuel_prices = value;
  }

  get notes(): string {
    return this._notes;
  }

  set notes(value: string) {
    this._notes = value;
  }

  get map(): UserMap {
    return this._map;
  }

  set map(value: UserMap) {
    this._map = value;
  }

  get speed_colors(): any {
    return this._speed_colors;
  }

  set speed_colors(value: any) {
    this._speed_colors = value;
  }

  get img(): string {
    return this._img;
  }

  set img(value: string) {
    this._img = value;
  }

  get permissions(): string[] {
    return this._permissions ? this._permissions : [];
  }

  set permissions(value: string[]) {
    if (value instanceof Array) {
      this._permissions = value;
    } else {
      this._permissions = [];
      for (const v of value as any) {
        this._permissions.push(v);
      }
    }
  }

  get server_id(): string {
    return this._server_id;
  }

  set server_id(value: string) {
    this._server_id = value;
  }

  get custom_business_type(): any {
    return this._custom_business_type;
  }

  set custom_business_type(value: any) {
    this._custom_business_type = value;
  }

  get imageURL() {
    return this._img ? this._img : 'assets/images/default.png';
  }

  get assignedItems(): any {
    return this._assignedItems;
  }

  set assignedItems(value: any) {
    this._assignedItems = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get last_login_date(): any {
    return this._last_login_date;
  }

  set last_login_date(value: any) {
    this._last_login_date = value;
  }

  get parent(): User {
    return this._parent;
  }

  set parent(value: User) {
    this._parent = value;
  }

  get preferences(): any {
    return this._preferences;
  }

  set preferences(value: any) {
    this._preferences = value;
  }

  get imgUrl(): string {
    if (this._img) {
      return this._imgUrl;
    } else {
      return AfaqyHelper.getDefaultImagePath('p');
    }
  }

  set imgUrl(value: string) {
    this._imgUrl = value;
  }

  get user_settings(): UserSettings {
    return this._user_settings;
  }

  set user_settings(value: UserSettings) {
    this._user_settings = value;
  }

  get domain_settings(): DomainSettings {
    return this._domain_settings;
  }

  set domain_settings(value: DomainSettings) {
    this._domain_settings = value;
  }

  get apps(): any {
    return this._apps;
  }

  set apps(value: Apps) {
    this._apps = value;
  }

  get role_name(): any {
    return this._role_name;
  }

  set role_name(value: any) {
    this._role_name = value;
  }

  get use_shifts(): any {
    return this._use_shifts;
  }

  set use_shifts(value: any) {
    this._use_shifts = value;
  }

  get units_count(): number {
    return this._units_count;
  }

  set units_count(value: number) {
    this._units_count = value;
  }

  get units_groups_count(): any {
    return this._units_groups_count;
  }

  set units_groups_count(value: any) {
    this._units_groups_count = value;
  }
  set account_apps(value) {
    this._account_apps = value ? value : [];
  }
  get account_apps() {
    return this._account_apps;
  }
  set is_2fa(value: any) {
    this._is_2fa = value;
  }
  get is_2fa() {
    return this._is_2fa;
  }
  set email_verified_at(value: any) {
    this._email_verified_at = value ? true : false;
  }
  get email_verified_at() {
    return this._email_verified_at;
  }
  set phone_verified_at(value: any) {
    this._phone_verified_at = value ? true : false;
  }
  get phone_verified_at() {
    return this._phone_verified_at;
  }
}
