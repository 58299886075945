import { AfaqyObject } from '../../../common/classes';
import { UnitFuelConsumptionRate } from './unit-fuel-consumption-rate';
import { UnitFuelConsumptionMath } from './unit-fuel-consumption-math';

export class UnitFuelConsumption extends AfaqyObject {
  private _rate: UnitFuelConsumptionRate = new UnitFuelConsumptionRate();
  private _math: UnitFuelConsumptionMath = new UnitFuelConsumptionMath();

  private time_of_ignoring_signals_after_stationary_on: number;
  private target_fuel_per_month: number;

  protected copyFields = [
    'rate',
    'math',
    'time_of_ignoring_signals_after_stationary_on',
    'target_fuel_per_month',
  ];

  get rate(): UnitFuelConsumptionRate {
    return this._rate;
  }

  set rate(value: UnitFuelConsumptionRate) {
    this._rate = value;
  }

  get math(): UnitFuelConsumptionMath {
    return this._math;
  }

  set math(value: UnitFuelConsumptionMath) {
    this._math = value;
  }
}
