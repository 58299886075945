import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AfaqyHelper } from 'app/common';

import { AuthService } from '../../services';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    // TODO remove logout component and handle logout path
    AfaqyHelper.clearBrowserHistory();
    if (this.authService.isActiveLogin()) {
      this.authService.redirectToSession();
    } else {
      this.authService.logout();
    }
    return false;
  }
}
