import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MarkersService } from '../../modules/markers/services';

@Injectable()
export class MarkerIconsResolver implements Resolve<any> {
  constructor(public service: MarkersService) {}

  resolve() {
    return this.service.icons();
  }
}
