import { Injectable } from '@angular/core';
import { UnitSensorColor } from 'app/modules/units/models';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class ColorRangesFormService {
  isOpen: boolean;
  formData: BehaviorSubject<UnitSensorColor> =
    new BehaviorSubject<UnitSensorColor>(null);
  isFormSubmitted: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  openSensorForm(data?: any): void {
    this.setFormData(data);
    this.isOpen = true;
  }

  closeSensorForm(): void {
    this.setFormData(null);
    this.isOpen = false;
  }

  private setFormData(data: any): void {
    this.formData.next(data);
  }
}
