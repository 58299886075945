<ng-container *ngIf="intervals && intervals.length">
  <section
    *ngFor="let interval of intervals; last as isLast; index as i"
    [ngClass]="{ 'hr-small-width': hasValidSameColorRange(interval) }"
    class="hr-container"
  >
    <hr
      class="hr clickOutsideExcept"
      [style.borderColor]="interval?.color"
      (click)="editInterval(interval)"
    />
    <section class="interval-section">
      <span
        #intervalFrom
        *ngIf="
          (interval?.from || interval?.from === 0) &&
          !hasValidSameColorRange(interval)
        "
        >{{ interval.from }}</span
      >
      <span *ngIf="interval?.from !== 0 && !interval?.from && i === 0">
        <span *ngIf="!isNumber(min)"> -&#8734; </span>
        <span *ngIf="isNumber(min)">{{ min }}</span>
      </span>
      <span *ngIf="isLast">
        <span *ngIf="!isNumber(max)"> &#8734; </span>
        <span *ngIf="isNumber(max)">{{ max }}</span>
      </span>
    </section>
  </section>
</ng-container>
