import { animation, style, animate } from '@angular/animations';

export const showAnimation = animation([
  style({
    opacity: '{{ opacity }}',
    transform: '{{ transformRulesHidden }}',
    'transform-origin': '{{ transformOrigin }}',
  }),
  animate(
    '{{ timings }}',
    style({
      opacity: 1,
      transform: '{{ transformRulesShown }}',
      'transform-origin': '{{ transformOrigin }}',
    })
  ),
]);

export const HideAnimation = animation([
  animate(
    '{{ timings }}',
    style({
      opacity: '{{ opacity }}',
      transform: '{{ transformRulesHidden }}',
      'transform-origin': '{{ transformOrigin }}',
    })
  ),
]);
