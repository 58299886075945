import { AppModel, AfaqyObject } from '../../../common/classes';

export class Protocols extends AppModel {
  private _name: string;
  private _code: string;
  private _port: number;
  private _enabled: boolean;
  private _param: string[];
  private _accparam: string = 'di1';
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;
  private _sensors: SensorArray[];

  protected copyFields = [
    'id',
    'name',
    'code',
    'port',
    'enabled',
    'param',
    'accparam',
    'deleted_at',
    'sensors',
  ];
  protected createFields = [
    'name',
    'code',
    'port',
    'enabled',
    'param',
    'accparam',
    'sensors',
  ];
  protected updateFields = [
    'name',
    'code',
    'port',
    'enabled',
    'param',
    'accparam',
    'sensors',
  ];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get port(): number {
    return this._port;
  }

  set port(value: number) {
    this._port = value;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(value: boolean) {
    this._enabled = value;
  }

  get param(): string[] {
    return this._param;
  }

  set param(value: string[]) {
    let param = [];
    if (value) {
      for (let k in value) {
        if (typeof value[k] === 'object') {
          param.push(value[k]['value']);
        } else {
          param.push(value[k]);
        }
      }
    }
    this._param = param;
  }

  get accparam(): string {
    return this._accparam;
  }

  set accparam(value: string) {
    this._accparam = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }
  get sensors(): SensorArray[] {
    return this._sensors;
  }

  set sensors(value: SensorArray[]) {
    this._sensors = value;
  }
}
class SensorArray extends AfaqyObject {
  private _name: string;
  private _type: string;
  private _default_parameter: string;
  private _active: number;

  protected copyFields = ['name', 'type', 'default_parameter', 'active'];
  protected createFields = ['name', 'type', 'default_parameter', 'active'];
  protected updateFields = ['name', 'type', 'default_parameter', 'active'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get default_parameter(): string {
    return this._default_parameter;
  }

  set default_parameter(value: string) {
    this._default_parameter = value;
  }
  get active(): number {
    return this._active;
  }

  set active(value: number) {
    this._active = value;
  }
}
