import { AppModel } from 'app/common';
import { Unit } from 'app/modules/units/models';
import { Warehouses } from 'app/modules/warehouses/models/warehouses';
import { PlanDetails } from './plan-details';

export class ManualRoute extends AppModel {
  private _user_id: string;
  private _extra_route_id: string;
  private _deleted_at: Date;
  private _name: string;
  private _start_warehouse_id: string;
  private _unit_id: string;
  private _unit_name: string;
  private _driver_name: string;
  private _points: string[] = [];
  private _end_warehouse_flag: number;
  private _end_warehouse_id: string;
  private _estimated_duration_time: string;
  private _estimated_distance: number;
  private _optimized: boolean = true;
  private _unit: Unit;
  private _start_warehouse: Warehouses;
  private _end_warehouse: Warehouses;
  private _polyline: string;
  private _stops_details: PlanDetails[] = [];
  private _selected: boolean;
  private _same_warehouse: boolean;
  private _color: string;

  protected copyFields = [
    'id',
    'extra_route_id',
    'user_id',
    'name',
    'start_warehouse_id',
    'unit_id',
    'unit_name',
    'driver_name',
    'points',
    'end_warehouse_flag',
    'end_warehouse_id',
    'estimated_duration_time',
    'estimated_distance',
    'optimized',
    'stops_details',
    'polyline',
    'selected',
    'color',
  ];
  protected createFields = [
    'user_id',
    'extra_route_id',
    'name',
    'start_warehouse_id',
    'unit_id',
    'unit_name',
    'driver_name',
    'points',
    'end_warehouse_flag',
    'end_warehouse_id',
    'estimated_duration_time',
    'estimated_distance',
    'optimized',
    'stops_details',
    'polyline',
    'selected',
  ];
  protected updateFields = [
    'user_id',
    'extra_route_id',
    'name',
    'start_warehouse_id',
    'unit_id',
    'unit_name',
    'driver_name',
    'points',
    'end_warehouse_flag',
    'end_warehouse_id',
    'estimated_duration_time',
    'estimated_distance',
    'optimized',
    'stops_details',
    'polyline',
    'selected',
  ];

  static colors: string[] = [
    '#FF0400',
    '#7D7DFF',
    '#00BE00',
    '#FF7300',
    '#B4A700',
    '#00FFFF',
    '#7D006C',
    '#C4782C',
    '#7D00CE',
    '#0028DC',
    '#FF42F8',
    '#A0005D',
    '#375426',
    '#8C0400',
    '#0FC8BE',
    '#FFB4EA',
    '#FF4678',
    '#4B321C',
    '#011D54',
    '#C1EA15',
    '#0F0F0F',
    '#470249',
  ];

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get extra_route_id(): string {
    return this._extra_route_id;
  }

  set extra_route_id(value: string) {
    this._extra_route_id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get start_warehouse_id(): string {
    return this._start_warehouse_id;
  }

  set start_warehouse_id(value: string) {
    this._start_warehouse_id = value;
  }

  get start_warehouse(): Warehouses {
    return this._start_warehouse;
  }

  set start_warehouse(value: Warehouses) {
    this._start_warehouse = value;
  }

  get start_warehouse_name(): string {
    if (this.start_warehouse) return this.start_warehouse.name;
    return '';
  }

  get end_warehouse(): Warehouses {
    return this._end_warehouse;
  }

  set end_warehouse(value: Warehouses) {
    this._end_warehouse = value;
  }

  get end_warehouse_name(): string {
    if (this._end_warehouse) return this._end_warehouse.name;
    return '';
  }

  get unit_id(): string {
    return this._unit_id;
  }

  set unit_id(value: string) {
    this._unit_id = value;
  }

  get unit_name(): string {
    // return this._unit_name;
    if (this.unit) return this.unit.name;
    return '';
  }

  set unit_name(value: string) {
    this._unit_name = value;
  }

  get driver_name(): string {
    return this._driver_name;
  }

  set driver_name(value: string) {
    this._driver_name = value;
  }

  get unit(): Unit {
    return this._unit;
  }

  set unit(value: Unit) {
    this._unit = value;
  }

  get points(): string[] {
    return this._points;
  }

  set points(value: string[]) {
    this._points = value;
  }

  get points_count(): number {
    return this.points.length;
  }

  get end_warehouse_flag(): number {
    return this._end_warehouse_flag;
  }

  set end_warehouse_flag(value: number) {
    this._end_warehouse_flag = value;
  }

  get end_warehouse_id(): string {
    return this._end_warehouse_id;
  }

  set end_warehouse_id(value: string) {
    this._end_warehouse_id = value;
  }

  get estimated_duration_time(): string {
    return this._estimated_duration_time;
  }

  set estimated_duration_time(value: string) {
    this._estimated_duration_time = value;
  }

  get estimated_distance(): number {
    return this._estimated_distance;
  }

  set estimated_distance(value: number) {
    this._estimated_distance = value;
  }

  get optimized(): boolean {
    return this._optimized;
  }

  set optimized(value: boolean) {
    this._optimized = value;
  }

  get stops_details(): PlanDetails[] {
    return this._stops_details;
  }

  set stops_details(value: PlanDetails[]) {
    this._stops_details = value;
  }

  get polyline(): string {
    return this._polyline;
  }

  set polyline(value: string) {
    this._polyline = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  get same_warehouse(): boolean {
    return this._same_warehouse;
  }

  set same_warehouse(value: boolean) {
    this._same_warehouse = value;
  }

  preferenceStore() {
    return {
      user_id: this.user_id,
      extra_route_id: this.extra_route_id,
      name: this.name,
      start_warehouse_id: this.start_warehouse_id,
      unit_id: this.unit_id,
      unit_name: this.unit_name,
      driver_name: this.driver_name,
      points: this.points,
      end_warehouse_flag: this.end_warehouse_flag,
      end_warehouse_id: this.end_warehouse_id,
      estimated_duration_time: this.estimated_duration_time,
      estimated_distance: this.estimated_distance,
      optimized: this.optimized,
      unit: this.unit,
      start_warehouse: this.start_warehouse,
      end_warehouse: this.end_warehouse,
      polyline: this.polyline,
      stops_details: this.stops_details,
      selected: this.selected,
      same_warehouse: this.same_warehouse,
      color: this.color,
    };
  }
}
