import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CustomConfig } from './interfaces';
import { ButtonClass } from 'app/shared/enums';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
})
export class FormActionsComponent implements OnInit {
  @Input() posting: boolean;
  @Input() form: UntypedFormGroup;
  @Input() cancelbutton: boolean = false;
  @Input() isView: boolean;
  @Input() customConfig: CustomConfig = {
    save: {
      name: 'Save',
      class: ButtonClass.success,
      iconClass: 'mdi mdi-check',
    },
    cancel: {
      name: 'Cancel',
      class: ButtonClass.outlineDanger,
      iconClass: 'mdi mdi-close',
    },
    reset: {
      name: 'Revert',
      class: ButtonClass.warning,
      iconClass: 'mdi mdi-format-clear',
    },
  };

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() reset: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
