import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiRequestService } from 'app/core/services';
import Pusher from 'pusher-js';
import { AppConfig } from '../../common';

@Injectable({
  providedIn: 'root',
})
export class PusherSocketService {
  currentMessage = new BehaviorSubject(null);
  messageCount = new BehaviorSubject(0);
  count = 0;
  public notification: Subject<any> = new Subject<any>();
  importToFill$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  inventoryRooms$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  unitGroups$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  notificationCenter$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  removeSpinnerAfterImport$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  pusherClient: Pusher;
  constructor(
    private api: ApiRequestService // private notificationsService: NotificationsService,
  ) {}

  openConnection(userId: string) {
    const token =
      sessionStorage.getItem('token') || localStorage.getItem('token');
    if (userId) {
      const pusherOptions = AppConfig.pusher.options;
      pusherOptions.authEndpoint =
        AppConfig.baseURL + 'broadcasting/auth?token=' + token;

      this.pusherClient = new Pusher(AppConfig.pusher.key, pusherOptions);
      const channel = this.pusherClient.subscribe(
        `private-avl-portal-${userId}`
      );

      channel.bind(`copy-unit-info`, (data: any) => {
        this.notification.next({
          data,
          eventName: `copy-unit-info-${userId}`,
        });

        // this.notificationsService.resourcesList.additionalData.totalUnread++;
        // this.notificationsService.resourcesList.data.unshift(data.data); //add the incoming notifications to the history
      });

      channel.bind(`import-to-fill`, (data: any) => {
        this.importToFill$.next({
          data,
          eventName: `import-to-fill-${userId}`,
        });
      });

      channel.bind(`import-to-add`, (data: any) => {
        this.importToFill$.next({
          data,
          eventName: `import-to-add-${userId}`,
        });
      });

      channel.bind(`import-inventory-rooms`, (data: any) => {
        this.inventoryRooms$.next({
          data,
          eventName: `import-inventory-rooms-${userId}`,
        });
      });

      channel.bind(`import-to-add-unit-group`, (data: any) => {
        this.unitGroups$.next({
          data,
          eventName: `import-to-add-unit-group-${userId}`,
        });
      });

      channel.bind(`notification_center`, (data: any) => {
        this.notificationCenter$.next({
          data,
          eventName: `notification_center-${userId}`,
        });
      });
    }
  }

  closeConnection(userId: string) {
    if (userId) {
      return this.pusherClient.unsubscribe(`private-avl-portal-${userId}`);
    }
  }
}
