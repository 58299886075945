import { Component, OnDestroy, OnInit } from '@angular/core';
import { AfaqyAlertService } from '../../../core/services/alert.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'afaqy-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  private alive: boolean = true;
  show = false;
  type: string;
  title: string;
  message: string;
  messagesList: any[] = [];
  ok: string;
  cancel: string;
  params;
  params2;

  constructor(private alert: AfaqyAlertService) {}

  ngOnInit() {
    this.alert.message
      .pipe(takeWhile(() => this.alive))
      .subscribe((response) => {
        this.show = true;
        this.title = response['title'];
        this.message = response['message'];
        this.messagesList = response['messagesList'];
        this.type = response['type'];
        this.ok = response['ok'];
        this.cancel = response['cancel'] || '';
        this.params = response['params'] || '';
        this.params2 = response['params2'] || '';
      });
  }

  isOk() {
    this.show = false;
    this.alert.Ok();
  }

  isCancel() {
    this.show = false;
    this.alert.Cancel();
  }

  toggleDiv(id) {
    if (id) {
      const elmHeader = document.getElementById('afaqy-alert-header-' + id);
      const elm = document.getElementById('afaqy-alert-container-' + id);
      const elmClass = elm.getAttribute('class');
      if (elmClass.indexOf('hidden') === -1) {
        elm.setAttribute('class', elmClass + ' hidden');
        elmHeader.className = 'mdi mdi-plus';
      } else {
        elm.setAttribute('class', elmClass.replace('hidden', ''));
        elmHeader.className = 'mdi mdi-minus';
      }
    }
  }

  changeClass(elmId, clss) {
    const elm = document.getElementById(elmId);
    const elmClass = elm.getAttribute('class');
    if (elmClass.indexOf('hidden') === -1) {
      elm.setAttribute('class', elmClass + ' hidden');
    } else {
      elm.setAttribute('class', elmClass.replace('hidden', ''));
    }
  }

  styleHeaderClass(msg) {
    var cls = '';
    switch (msg.message) {
      case 'success_count':
        cls = 'afaqy-alert-success';
        break;
      case 'error_count':
        cls = 'afaqy-alert-error';
        break;
    }

    if (msg.childs && msg.childs.length > 0) {
      cls += ' afaqy-alert-hasChilds';
    }

    return cls;
  }

  public ngOnDestroy() {
    this.alive = false;
  }
}
