import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';

import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';

import { AfaqyHelper } from './../../../common/classes';
import { RootService, AuthService } from '../../../core/services';
import { Message } from '../../../common/classes/message';
import { takeWhile } from 'rxjs/operators';
import { AppConfig } from '../../../common/classes/app-config';
import { UserService } from '../../../modules/users/services/user.service';

@Component({
  selector: 'gridlist-for-select',
  templateUrl: './gridlist-for-select.component.html',
  styleUrls: ['./gridlist-for-select.component.css'],
})
export class GridlistForSelectComponent implements OnInit, OnDestroy {
  alive: boolean = true;
  @Input() modalSizeClass: string;
  @Input() service: RootService;
  @Input() selected;
  @Input() title: string = 'bind.select';
  @Input() is_assignation: boolean = false;
  @Input() op: string;
  @Input() multiple_ids: string[];
  @Input() columns: any[] = [];
  @Input() draggable = false;
  @Input() hasbackground = true;
  @Input() message: Message;
  @Input() EmitSelectedAsID = true;

  selected_ids = {};
  _usersList = [];
  _assignedUsers = [];
  isAllChecked: boolean;

  @Input() set assignedUsers(list) {
    this._assignedUsers = list;
    this.updateUserResources();
  }

  @Output() Select: EventEmitter<any> = new EventEmitter<any>();
  @Output() Cancel: EventEmitter<any> = new EventEmitter<any>();

  @Output() toggleCheck: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateIds: EventEmitter<any> = new EventEmitter<any>();
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() assignation: EventEmitter<any> = new EventEmitter<any>();
  @Output() assign: EventEmitter<any> = new EventEmitter<any>();

  filtersCols: any[] = [];
  cvData: wjcCore.CollectionView;

  @ViewChild('sgrid') grid: wjcGrid.FlexGrid;
  loading = false;
  @Input() posting = false;
  @Input() is_checked: boolean;

  config = AppConfig;

  constructor(
    private authService: AuthService,
    protected userService: UserService
  ) {
    this.cvData = new wjcCore.CollectionView([]);
  }

  doToggleCheck(checked: boolean) {
    if (checked) {
      this.toggleCheck.next({
        ids: this.cvData.items.map((item) => {
          item.checked = true;
          return item.id;
        }),
      });
    } else {
      this.cvData.items.map((item) => (item.checked = false));
      this.toggleCheck.next({ ids: [] });
    }
  }

  doRefresh() {
    this.refresh.next();
  }

  doAssignation(op) {
    this.assignation.next({ op: op });
  }

  doAssign($event, op, id) {
    $event.preventDefault;
    this.assign.next({ id: id, op: op });
  }

  doUpdateIds($event, id, index: number) {
    this.cvData.items[index].checked = $event.target.checked;
    this.isAllChecked = this.cvData.items.every((item) => item.checked);
    this.updateIds.next({ id: id, checked: $event.target.checked });
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    for (let colmn of this.columns) {
      if (colmn.colValue != 'actions' && colmn.filters !== false) {
        this.filtersCols.push(colmn.colValue);
      }
    }

    if (this.service.pages && this.service.pages.hasNext()) {
      this.loading = true;
    } else {
      this.loading = false;
    }

    if (this.grid) {
      this.grid.rowHeaders.columns.defaultSize = 25;
    }
    AfaqyHelper.resizer.pipe(takeWhile(() => this.alive)).subscribe({
      next: (res) => {
        if (this.grid) {
          this.grid.refresh(true);
          this.isAllChecked = false;
        }
      },
    });

    if (this.is_assignation && this.config.isCMS) {
      this.loadUserResources();
    } else if (this.config.isCMS) {
      this.loadResources();
    } else {
      this.updateGridResources();
      this.service.resources.pipe(takeWhile(() => this.alive)).subscribe({
        next: () => {
          this.updateGridResources();
        },
      });
      this.service.resources.pipe(takeWhile(() => this.alive)).subscribe({
        next: () => {
          if (this.service.pages && this.service.pages.hasNext()) {
            this.loading = true;
          } else {
            this.loading = false;
          }
          AfaqyHelper.windowResize();
        },
      });
    }
    AfaqyHelper.windowResize();
  }

  updateUserResources() {
    let ulists = [];
    for (let user of this._usersList) {
      if (this.op == 'assign' && !this._assignedUsers.includes(user.id)) {
        ulists.push(user);
      } else if (
        this.op == 'unassign' &&
        this._assignedUsers.includes(user.id)
      ) {
        ulists.push(user);
      }
    }
    const listCountString = ' ' + ulists.length;
    this.cvData.sourceCollection = ulists;
    this.cvData.refresh();
    if (this.grid) {
      this.grid.rowHeaders.columns.defaultSize =
        listCountString.length * 10 > 25 ? listCountString.length * 10 : 25;
    }
    this.cvData.sourceCollection.forEach((item) => {
      if (item.id == this.selected) {
        this.cvData.moveCurrentTo(item);
      }
    });
    if (!this.selected) {
      this.cvData.currentItem = {};
    }
  }

  loadUserResources() {
    this.userService.subUsersList(this.authService.userID, false).subscribe({
      next: (data) => {
        this._usersList = data;
        this.updateUserResources();
      },
    });
  }

  loadResources() {
    this.service.getOptionsList(this.service.routerPrefix()).subscribe({
      next: (data) => {
        const listCountString = ' ' + data.length;
        this.cvData.sourceCollection = data;
        if (this.grid) {
          this.grid.rowHeaders.columns.defaultSize =
            listCountString.length * 10 > 25 ? listCountString.length * 10 : 25;
        }
        this.cvData.sourceCollection.forEach((item) => {
          if (item.id == this.selected) {
            this.cvData.moveCurrentTo(item);
          }
        });
        if (!this.selected) {
          this.cvData.currentItem = {};
        }
      },
    });
  }

  updateGridResources() {
    let users = this.service.resourcesList;
    let listCountString = '';
    if (this.is_assignation) {
      let ulists = [];
      // const assignedIds = this._assignedUsers.map((user) => user.uid);
      for (let user of this.service.resourcesList) {
        if (
          (this.op == 'assign' && !this._assignedUsers.includes(user.id)) ||
          (this.op == 'unassign' && this._assignedUsers.includes(user.id))
        ) {
          let obj = { id: user.id };
          this.columns.forEach((colmn) => {
            if (colmn.colValue != 'actions') {
              obj[colmn.colValue] = user[colmn.colValue];
            }
          });
          ulists.push(obj);
        }
      }
      users = ulists;
      listCountString = ' ' + users.length;
      this.cvData.sourceCollection = users;
    } else {
      listCountString = ' ' + users.length;
      this.cvData.sourceCollection = users;
    }
    if (this.grid) {
      this.grid.rowHeaders.columns.defaultSize =
        listCountString.length * 10 > 25 ? listCountString.length * 10 : 25;
    }
    this.cvData.sourceCollection.forEach((item) => {
      if (item.id == this.selected) {
        this.cvData.moveCurrentTo(item);
      }
    });
    if (!this.selected) {
      this.cvData.currentItem = {};
    }
  }

  modalClose(event) {
    this.Cancel.next(event);
  }

  selectObject(item) {
    if (this.EmitSelectedAsID) {
      this.Select.next(item.id);
    } else {
      this.Select.next({ id: item.id, item: item });
    }
  }
}
