import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UnitSensorColor } from './../../../../../../modules/units/models/unit-sensor';

@Injectable()
export class SensorColorsFormService {
  isOpen: boolean;
  formData: BehaviorSubject<UnitSensorColor> =
    new BehaviorSubject<UnitSensorColor>(null);
  isFormSubmitted: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  openSensorForm(data?: UnitSensorColor): void {
    this.setFormData(data);
    this.isOpen = true;
  }

  closeSensorForm(): void {
    this.setFormData(null);
    this.isOpen = false;
  }

  private setFormData(data: UnitSensorColor): void {
    this.formData.next(data);
  }
}
