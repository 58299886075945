import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'afaqy-default-value',
  templateUrl: './afaqy-default-value.component.html',
  styleUrls: ['./afaqy-default-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AfaqyDefaultValueComponent),
      multi: true,
    },
  ],
})
export class AfaqyDefaultValueComponent implements ControlValueAccessor {
  @Input() type;
  @Input() showControlError? = false;
  @Input() currentControl: any;
  private innerValue: any;
  /**
   *
   */
  constructor(private translate: TranslateService) {}

  writeValue(value: any): void {
    // this.el.nativeElement.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  onInputChange(event: any) {
    this.innerValue = event.target.value;
    this.onChange(this.innerValue); // Notify form control about the change
  }

  onBlur() {
    this.onTouched();
  }

  private onChange = (_: any) => {};

  private onTouched = () => {};
}
