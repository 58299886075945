import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { AfaqyHelper } from '../../../common/classes';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
  showAnimation,
  HideAnimation,
} from 'app/shared/animations/transform-rules-advanced';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/core/services';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'listing-options',
  templateUrl: './listing-options.component.html',
  styleUrls: ['./listing-options.component.scss'],
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '200ms linear',
            opacity: 0,
            transformRulesShown: 'none',
            transformRulesHidden: 'scale(.5)',
            transformOrigin: 'var(--transformOrigin)',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '200ms linear',
            opacity: 0,
            transformRulesShown: 'none',
            transformRulesHidden: 'scale(.5)',
            transformOrigin: 'var(--transformOrigin)',
          },
        }),
      ]),
    ]),
  ],
})
export class ListingOptionsComponent implements OnInit {
  @Output() updateCols: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  allColumns: any[];

  @Input()
  gridOptimizer;

  @HostBinding('@showHide')
  animation = true;

  alive: boolean = true;
  isRTL: boolean = false;

  constructor(
    private translate: TranslateService,
    protected authService: AuthService
  ) {
    this.isRTL = authService.isRTLLang();
    this.translate.onLangChange.pipe(takeWhile(() => this.alive)).subscribe({
      next: () => {
        this.isRTL = authService.isRTLLang();
        // console.log('lang is : ' + lng['lang']);
        // console.log('RTL is : ' + this.isRTL);
      },
    });
  }

  ngOnInit() {
    AfaqyHelper.resizer.subscribe({
      next: () => {
        this.gridOptimizer.status = false;
      },
    });
  }

  update(colIndex, status) {
    this.updateCols.next({ index: colIndex, status: status });
  }
}
