<ng-container
  *ngIf="
    intervals.resultType === 'value' &&
    intervals &&
    intervals.colors &&
    intervals.colors.length
  "
>
  <section
    *ngFor="let interval of intervals.colors; last as isLast; index as i"
    [ngClass]="{ 'hr-small-width': hasSameValues(interval, i) }"
    class="hr-container"
  >
    <hr
      class="hr clickOutsideExcept"
      [style.borderColor]="interval?.color"
      (click)="editInterval(interval)"
    />
    <section class="interval-section">
      <span
        #intervalFrom
        *ngIf="
          (interval?.from || interval?.from === 0) && !hasSameValues(interval)
        "
        >{{ interval.from }}</span
      >
      <span *ngIf="interval?.from !== 0 && !interval?.from && i === 0"
        >-&#8734;</span
      >
      <span *ngIf="isLast">&#8734;</span>
    </section>
  </section>
</ng-container>

<section
  *ngIf="
    (intervals.resultType === 'logic' ||
      intervals.resultType === 'logicgte' ||
      intervals.resultType === 'codeValue') &&
    intervals &&
    intervals.colors
  "
  id="logical-container"
>
  <section *ngFor="let interval of intervals.colors" class="hr-container">
    <hr
      class="hr clickOutsideExcept"
      [style.borderColor]="interval?.color"
      (click)="editInterval(interval)"
    />
    <span class="interval-text">{{ interval?.type }}</span>
  </section>
</section>
