import { AppModel } from '../../../common/classes';
import { AfaqyHelper } from '../../../common/classes/afaqy-helper';

export class Tailer extends AppModel {
  private _name: string;
  private _ibtn: string = '';
  private _rfid: string = '';
  private _desc: string = '';
  private _img: string = '';
  private _imgUrl: string = '';
  private _user_id: string;
  private _created: Date;
  private _deleted_at: Date;

  protected copyFields = [
    'id',
    'name',
    'ibtn',
    'rfid',
    'desc',
    'img',
    'created',
    'user_id',
    'created',
    'deleted_at',
    'users_list',
    'imgUrl',
  ];
  protected createFields = [
    'name',
    'ibtn',
    'rfid',
    'desc',
    'img',
    'users_list',
  ];
  protected updateFields = [
    'name',
    'ibtn',
    'rfid',
    'desc',
    'img',
    'users_list',
  ];

  get name(): string {
    return this._name;
  }

  get ibtn(): string {
    return this._ibtn;
  }

  get rfid(): string {
    return this._rfid;
  }

  get desc(): string {
    return this._desc;
  }

  get img(): string {
    return this._img;
  }

  get user_id(): string {
    return this._user_id;
  }

  get created(): Date {
    return this._created;
  }

  set name(value: string) {
    this._name = value;
  }

  set ibtn(value: string) {
    this._ibtn = value;
  }

  set rfid(value: string) {
    this._rfid = value;
  }

  set desc(value: string) {
    this._desc = value;
  }

  set img(value: string) {
    this._img = value;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  set created(value: Date) {
    this._created = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get imgUrl(): string {
    if (this._img) {
      return this._imgUrl;
    } else {
      return AfaqyHelper.getDefaultImagePath('t');
    }
  }

  set imgUrl(value: string) {
    this._imgUrl = value;
  }
}
