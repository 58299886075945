import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from 'app/core/services';
import { BehaviorSubject } from 'rxjs';
import { InventoryRoomsModel } from '../models/inventory-rooms.model';

@Injectable({ providedIn: 'root' })
export class InventoryRoomsService extends RootService {
  public groupedResources: BehaviorSubject<any[]> = new BehaviorSubject(null);
  customPermissionKey = 'inventory_rooms';
  constructor(
    public authService: AuthService,
    public apiRequest: ApiRequestService,
    public alertService: AfaqyAlertService,
    public notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alertService, notificationsService);
    this.disableListItemAction['copy'] = true;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'rooms';
  }
  gridColumns(trashed = false) {
    const allcols: any[] = [
      {
        header: this.cid + '.name',
        minWidth: 150,
        width: '*',
        colValue: 'name',
        active: true,
        immutable: false,
        default: true,
      },
      {
        header: this.cid + '.units_no',
        minWidth: 150,
        width: '*',
        colValue: 'units_no',
        active: true,
        immutable: false,
        default: true,
      },
      // {
      //   header: this.cid + ".room_status", "minWidth": 150, "width": '*',
      //   colValue: "room_status", active: true, immutable: false, default: true
      // },
      {
        header: 'actions',
        minWidth: 60,
        width: 100,
        colValue: 'actions',
        ctive: true,
        immutable: false,
        default: true,
        // extra: [
        //   {
        //     header: 'assign_user',
        //     'permissions': 'job_orders_categories-assign',
        //     'code': 'assign',
        //     'faclass': "fa-link"
        //   },
        //   {
        //     header: 'unassign_user',
        //     'permissions': 'job_orders_categories-unassign',
        //     'code': 'unassign',
        //     'faclass': "fa-unlink"
        //   }
        // ]
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
    }
    return allcols;
  }

  updateObjectsRelations(updates: any) {
    if (updates['action'] === 'list' || updates['action'] === 'addMulti') {
      for (const data of updates['data']) {
        if (data['units']) {
          data['units_no'] = data['units'].length;
        }
        this.setResourceObject(data);
      }
    } else if (updates['action'] === 'update' || updates['action'] === 'add') {
      const data = updates['data'];
      if (data['units']) {
        data['units_no'] = data['units'].length;
      }
      this.setResourceObject(data);
    }
    this.pushChanges();
  }

  get modelInstance() {
    return new InventoryRoomsModel();
  }
}
