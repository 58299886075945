import { AppModel, AfaqyObject } from '../../../common/classes';

export class ServiceType extends AfaqyObject {
  private _status: boolean;
  private _interval: any;
  private _last: any;
  private _date: any;
  private _left: boolean;
  private _left_num: number;

  protected copyFields = [
    'status',
    'interval',
    'last',
    'left',
    'left_num',
    'date',
  ];

  get status(): boolean {
    return this._status ? this._status : false;
  }

  set status(value: boolean) {
    this._status = value;
  }

  get interval(): any {
    return this._interval;
  }

  set interval(value: any) {
    this._interval = value;
  }

  get last(): any {
    return this._last;
  }

  set last(value: any) {
    this._last = value;
  }

  get date(): any {
    return this._date;
  }

  set date(value: any) {
    this._date = value;
  }

  get left(): boolean {
    return this._left;
  }

  set left(value: boolean) {
    this._left = value;
  }

  get left_num(): number {
    return this._left_num;
  }

  set left_num(value: number) {
    this._left_num = value;
  }
}

export class UnitServiceItem extends AppModel {
  private _name: string;
  private _unit_id: string;
  private _update_last: boolean;

  private _status: any;

  private _odometer: ServiceType = new ServiceType();
  private _engine_hours: ServiceType = new ServiceType();
  private _days: ServiceType = new ServiceType();
  private _date: ServiceType = new ServiceType();

  protected copyFields = [
    'id',
    'name',
    'unit_id',
    'update_last',
    'status',
    'odometer',
    'engine_hours',
    'days',
    'date',
  ];
  protected createFields = [
    'name',
    'unit_id',
    'user_id',
    'update_last',
    'odometer',
    'engine_hours',
    'days',
    'date',
  ];
  protected updateFields = [
    'name',
    'unit_id',
    'user_id',
    'update_last',
    'odometer',
    'engine_hours',
    'days',
    'date',
  ];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get unit_id(): string {
    return this._unit_id;
  }

  set unit_id(value: string) {
    this._unit_id = value;
  }

  get update_last(): boolean {
    return this._update_last;
  }

  set update_last(value: boolean) {
    this._update_last = value;
  }

  get odometer(): ServiceType {
    return this._odometer;
  }

  set odometer(value: ServiceType) {
    this._odometer = value;
  }

  get engine_hours(): ServiceType {
    return this._engine_hours;
  }

  set engine_hours(value: ServiceType) {
    this._engine_hours = value;
  }

  get days(): ServiceType {
    return this._days;
  }

  set days(value: ServiceType) {
    this._days = value;
  }

  get date(): ServiceType {
    return this._date;
  }

  set date(value: ServiceType) {
    this._date = value;
  }

  get status() {
    return this._status;
  }

  set status(value) {
    this._status = value;
  }

  setServiceType(key, obj) {
    let o = new ServiceType();
    o.copyInto(obj);
    this[key] = o;
  }

  setServiceDateType(key, obj) {
    let o = new ServiceType();
    o.copyInto(obj);
    this[key] = o;
  }
}
