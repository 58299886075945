import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { UnitSensor } from '../models';

@Injectable()
export class UnitSensorsService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  get limitForPageList() {
    return 0;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'unit_sensors';
  }

  get modelInstance() {
    return new UnitSensor();
  }

  get sensorsIcons() {
    return [
      'mdi mdi-bell',
      'mdi mdi-circle',
      'mdi mdi-check',
      'mdi mdi-lock',
      'mdi mdi-signal',
      'mdi mdi-microphone',
      'mdi mdi-power',
      'mdi mdi-square',
      'mdi mdi-toggle-switch',
      'mdi mdi-flash',
      'mdi mdi-camera',
    ];
  }
}
