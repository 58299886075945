import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { UserService } from '../../modules/users/services';

@Injectable()
export class SystemPermissionsResolver implements Resolve<any> {
  constructor(public service: UserService) {}

  resolve() {
    return this.service.getSystemPermissionsList();
  }
}
