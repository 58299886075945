import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AfaqyHelper } from '../../../common/classes/afaqy-helper';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { Markers } from '../models';

@Injectable({ providedIn: 'root' })
export class MarkersService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    private translate: TranslateService
  ) {
    super(authService, apiRequest, alert, notificationsService);
  }

  routerPrefix(val: string = '') {
    return val ? val : 'markers';
  }

  get modelInstance() {
    return new Markers(this.translate);
  }

  get markerStatus() {
    return Markers.statusVal;
  }

  setResourceObject(obj) {
    let vids = this.authService.preferences('markers', 'visible');
    if (vids?.includes(obj?.id)) {
      obj['visible'] = 1;
    } else {
      obj['visible'] = 0;
    }
    super.setResourceObject(obj);
  }
  doGetById(url: string, id: string, simplify = 0) {
    return super.doGetById(url, id, simplify);
  }
  getNearestMarker(lat, lng) {
    let name = '';
    let icon_url = '';
    let distance = 0;
    for (let marker of this.resourcesList) {
      let temp = AfaqyHelper.getLengthBetweenCoordinates(
        lat,
        lng,
        marker.location.coordinates[1],
        marker.location.coordinates[0]
      );
      if (distance > temp || distance == 0) {
        distance = temp;
        name = marker.name;
        icon_url = marker.iconUrl;
      }
    }
    distance = AfaqyHelper.convDistanceUnits(
      distance,
      'km',
      this.authService.user.user_settings.distance_unit
    );
    let d =
      distance.toFixed(2) +
      ' ' +
      this.translate.instant(
        'measurements.' + this.authService.user.user_settings.distance_unit
      );
    let nearest = { n: name, d: d, icon_url: icon_url };
    return nearest;
  }
}
