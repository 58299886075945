import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';

import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';

import { Router } from '@angular/router';
import { AfaqyHelper, AppConfig, Pagination } from '../../../common/classes';
import { AfaqyResponse } from '../../../core/classes/afaqy-response';
import { AuthService, RootService } from '../../../core/services';

@Component({
  selector: 'afaqy-cms-grid',
  exportAs: 'AfaqyCMSGrid',
  templateUrl: './afaqy-cms-grid.component.html',
  styleUrls: ['./afaqy-cms-grid.component.scss'],
})
export class AfaqyCMSGridComponent implements AfterViewInit, OnInit, OnDestroy {
  private alive: boolean = true;
  private _formFilters: any = {};
  @Output() loaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() applyAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeActions: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleCheck: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateIds: EventEmitter<any> = new EventEmitter<any>();

  @Input() service: RootService;

  @Input() allColumns: any[] = [];
  @Input() controller: string = '';
  @Input() is_trashed: boolean = false;
  @Input() is_checked: boolean = false;

  @Input() set filtersValues(filters) {
    this._formFilters = filters;
    this.loadPageData(1);
  }

  @Input() set randomRefresh(value) {
    if (value <= 0 && this.pagination.current > 0) {
      this.loadPageData(this.pagination.current);
    } else if (this.cvData) {
      const cmp = this;
      setTimeout(function () {
        cmp.cvData.refresh();
      }, 50);
    }
  }

  @ViewChild('grid') grid: wjcGrid.FlexGrid;
  @ViewChild('extraActionsContainer') extraActionsContainer;

  currentRequest;
  pagination: Pagination;
  activeColumns: any[] = [];
  showExtra = false;
  extraActions = [];
  isRTL = false;
  hasActions = false;
  extraParams = {
    permissions: '',
    hideExtra: true,
    elmTop: 0,
    elmLeft: 0,
    elmRight: 0,
    item: {},
  };
  actions = { edit: 'edit', copy: 'copy', delete: 'delete' };
  cvData: wjcCore.CollectionView;
  config = AppConfig;
  loading = false;
  moduleName: string;
  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private router: Router
  ) {
    this.isRTL = authService.isRTLLang();
  }
  ngAfterViewInit(): void {
    this.grid.rowHeaders.columns.defaultSize = 25;
  }
  ngOnInit() {
    this.pagination = new Pagination();
    this.pagination.limit = 25;
    ['add', 'edit', 'delete'].forEach((action) => {
      if (
        this.authService.checkPermissions(this.controller + '-' + action, '')
      ) {
        this.hasActions = true;
      }
    });
    if (
      this.is_trashed &&
      this.authService.checkPermissions(this.controller + '-restore', '')
    ) {
      this.hasActions = true;
    }
    this.updateActiveColumns(true);
    AfaqyHelper.resizer.pipe(takeWhile(() => this.alive)).subscribe({
      next: () => {
        this.grid.refresh(true);
      },
    });
    this.cvData = new wjcCore.CollectionView([]);
    this.loadPageData(1);
    this.moduleName =
      this.router.url.split('/')[this.router.url.split('/').length - 1];
    if (this.moduleName.includes('?'))
      this.moduleName = this.moduleName.split('?')[0];
  }

  loadPageData(page = 1) {
    this.loading = true;
    const params = {
      offset: Pagination.getOffset(page, this.pagination.limit),
      limit: this.pagination.limit,
      filters: this._formFilters,
    };
    if (this.currentRequest) {
      this.currentRequest.unsubscribe();
    }
    if (!this.is_trashed) {
      this.service
        .getLists(params)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (response: AfaqyResponse) => {
            this.updateGridResources(response);
            // this.currentRequest.unsubscribe();
          },
        });
    } else {
      this.service
        .getTrashedList(params)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (response: AfaqyResponse) => {
            this.updateGridResources(response);
          },
        });
    }
  }

  updateGridResources(response: AfaqyResponse) {
    const listCountString = ' ' + response.list.length;
    this.pagination = response.pagination;
    this.grid.rowHeaders.columns.defaultSize =
      listCountString.length * 10 > 25 ? listCountString.length * 10 : 25;
    this.cvData.sourceCollection = response.list;
    this.cvData.refresh();
    this.loaded.next();
    this.loading = false;
  }

  updateActiveColumns(updates = false) {
    const gridColsKEY = 'gridCols';
    let activeColumns = [];
    // let savedDef = this.service.getUserPreferences(this.controller, gridColsKEY);
    // for (let key in this.allColumns) {
    //     if (updates) {
    //         if (savedDef.length) {
    //             if (savedDef.findIndex((value) => {
    //                     return this.allColumns[key].colValue == value
    //                 }) != -1) {
    //                 this.allColumns[key].active = true;
    //             }
    //         } else if (this.allColumns[key].default) {
    //             this.allColumns[key].active = true;
    //         }
    //     }
    //     if (this.allColumns[key].active) {
    //         activeColumns.push(this.allColumns[key]);
    //     }
    // }
    // this.activeColumns = activeColumns;
    const isRTL = this.authService.isRTLLang();
    for (let key in this.allColumns) {
      if (!this.allColumns[key]['align']) {
        this.allColumns[key]['align'] = isRTL ? 'right' : 'left';
      } else if (isRTL && this.allColumns[key]['align'] != 'center') {
        this.allColumns[key]['align'] =
          this.allColumns[key]['align'] == 'left' ? 'right' : 'left';
      }
    }
    this.activeColumns = this.allColumns;
    this.showExtra = false;
    this.activeColumns.forEach((item) => {
      if (item.colValue == 'actions') {
        if (item.actions) {
          if (item.actions && item.actions['edit']) {
            this.actions['edit'] = item.actions['edit'];
          }
          if (item.actions && item.actions['copy']) {
            this.actions['copy'] = item.actions['copy'];
          }
        }
        if (item.extra) {
          this.showExtra = true;
          this.extraActions = item.extra;
          let permissions = [];
          item.extra.forEach((a) => {
            if (a.permissions.trim()! + '') {
              permissions.push(a.permissions);
              if (this.authService.checkPermissions(a.permissions, '')) {
                this.hasActions = true;
              }
            }
          });
          this.extraParams.permissions = permissions.join(',');
        }
      }
    });
    if (!this.hasActions) {
      this.activeColumns = this.activeColumns.filter(
        (item) => item.colValue != 'actions'
      );
      this.removeActions.next({});
    }
    if (this.showExtra == false) {
      this.extraActions = [];
    }
    // let options = this.activeColumns.map(function (item) {
    //     return item.colValue;
    // });
    // this.service.updateUserPreferences(this.controller, gridColsKEY, options);
  }

  showExtraActions(event, cell) {
    const extraHeight = this.extraActions.length * 25;
    let posTop =
      cell.row.pos +
      cell.row.grid.scrollPosition.y +
      this.grid.columnHeaders.height;
    if (cell.row.grid.clientSize.height - posTop > extraHeight) {
      this.extraParams.elmTop = posTop + 7;
    } else {
      this.extraParams.elmTop = posTop - extraHeight + 10;
    }
    if (this.authService.isRTLLang()) {
      this.extraParams.elmRight =
        cell.col.pos + this.grid.rowHeaders.columns.defaultSize;
      this.extraParams.elmLeft = 0;
    } else {
      this.extraParams.elmLeft =
        cell.col.pos + this.grid.rowHeaders.columns.defaultSize;
      this.extraParams.elmRight = 0;
    }

    this.extraParams.hideExtra = false;
    this.extraParams.item = cell.item;
  }

  doDelete($event, id) {
    $event.preventDefault;
    this.applyAction.next({ action: 'delete', item: id });
    return false;
  }

  doUpdateIds($event, id) {
    this.updateIds.next({ id: id, checked: $event.target.checked });
  }

  dotoggleCheck() {
    this.toggleCheck.next({ ids: this.cvData.items.map((item) => item.id) });
  }

  doRestore($event, ids = []) {
    $event.preventDefault;
    this.applyAction.next({ action: 'restore', ids: ids });
    return false;
  }

  hideExtra() {
    this.extraParams.hideExtra = true;
    this.extraParams.item = {};
  }

  checkMove(event) {
    let onextra = false;
    const eid = this.controller + '-extra-action';
    let path = event.path || (event.composedPath && event.composedPath());
    path.forEach((item) => {
      if (item.id == eid) {
        onextra = true;
      }
    });
    if (!onextra) {
      this.hideExtra();
    }
  }

  executeAction(code, item) {
    this.applyAction.next({ action: code, item: item });
  }

  applyExtraAction(code) {
    this.applyAction.next({ action: code, item: this.extraParams.item });
    this.hideExtra();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  changePagerLimit(perpage) {
    this.pagination.limit = perpage;
    this.loadPageData(1);
  }

  updateFlag(prms) {
    const itemID = prms.item.id;
    const value = !prms.item[prms.key];
    this.service
      .updateFlag({ id: itemID, key: prms.key, value: value })
      .subscribe({
        next: () => {
          this.cvData.sourceCollection.map((item) => {
            if (item.id == itemID) {
              item[prms.key] = value;
            }
            return item;
          });
          this.cvData.refresh();
        },
      });
  }
}
