import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RootService } from 'app/core/services';
import { MatStepper } from '@angular/material/stepper';
import { PusherSocketService } from 'app/core/services/pusher-socket.service';
import { takeWhile } from 'rxjs/operators';
import { AfaqyResponse } from 'app/core/classes';

@Component({
  selector: 'afaqy-import-to-fill',
  templateUrl: './import-to-fill.component.html',
  styleUrls: ['./import-to-fill.component.scss'],
})
export class ImportToFillComponent implements OnInit, OnDestroy {
  alive = true;
  optionsData = [
    {
      fileOption: 'import_to_add',
      image: 'assets/images/add-plus-circle.png',
      title: 'importToFill.importToAdd',
      subTitle: 'importToFill.importToAddSubTitle',
    },
    {
      fileOption: 'import_to_fill',
      image: 'assets/images/combine-union.png',
      title: 'importToFill.importToFill',
      subTitle: 'importToFill.importToFillSubTitle',
    },
  ];
  selectedOption: any;
  firstStepDisabled = true;
  importToFillForm = this.fb.group({
    action_type: [''],
    file: [''],
  });
  file: any;
  params: any;
  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('stepper') private myStepper: MatStepper;

  dataSource = [];
  displayedColumns: string[] = ['row', 'errors_count', 'errors'];
  @Input() importToFillDataSocketData: any;
  currentStep = 'choose-action';
  beforeSubmit = true;
  hideModal = false;
  operationId: string;
  enableSubmitBtn = true;
  numOfErrors;
  @Input() componentName: string;
  downloadSampleURl: any;
  submitUrl: any;
  errorsUrl: any;
  disableFirstStep = false;
  @Input() loadErrorsTable = false;
  @Input() operation: any;

  constructor(
    private fb: FormBuilder,
    private rootService: RootService,
    private pusherSocketService: PusherSocketService
  ) {}

  ngOnInit(): void {
    if (this.operation) {
      this.getErrorsList(this.operation.logId);
    } else {
      this.prepareSelectedComponentData(this.componentName);
    }
    this.beforeSubmit = !this.loadErrorsTable;
  }

  prepareSelectedComponentData(componentName) {
    if (componentName) {
      switch (componentName) {
        case 'units':
          this.getUnitsSocketData();
          this.disableFirstStep = false;
          this.downloadSampleURl = 'units/import/sample/import_to_fill';
          this.submitUrl = 'units/import';
          this.beforeSubmit = true;
          this.errorsUrl = `units/import/${this.operationId}/failure-download`;
          break;

        case 'inventory_rooms':
          this.getInventoryRoomSocketData();
          this.disableFirstStep = true;
          this.downloadSampleURl = 'rooms/import/sample';
          this.submitUrl = 'rooms/import';
          this.beforeSubmit = true;
          this.errorsUrl = `rooms/import/${this.operationId}/failure-download`;
          break;

        case 'unit_groups':
          this.getUnitGroupsSocketData();
          this.disableFirstStep = true;
          this.downloadSampleURl = 'unit_groups/import/sample/import_to_fill';
          this.submitUrl = 'unit_groups/import';
          this.beforeSubmit = true;
          this.errorsUrl = `unit_groups/import/${this.operationId}/failure-download`;
          break;
      }
    } else {
      this.disableFirstStep = true;
      this.beforeSubmit = false;
      this.currentStep = 'completed_with_failures';
    }
  }

  /**
   * determine which step to load with component
   */
  getStepToLoad(stepName: string) {
    this.currentStep = stepName;
  }

  modalClose() {
    this.hideModal = true;
  }

  /**
   * set option to add or fill before submiting (importing)
   */
  chooseOption(option: any) {
    this.selectedOption = option;
    this.firstStepDisabled = false;
    this.importToFillForm.controls['action_type'].setValue(option.fileOption);
  }

  downloadTemplate() {
    const payload = {
      action_type: this.importToFillForm.value['action_type'],
      type: 'xlsx',
    };

    this.rootService
      .downloadFile(this.downloadSampleURl, payload)
      .pipe(takeWhile(() => this.alive))
      .subscribe();
  }

  /**
   * fetch file from input file upload and add it into params to form submit
   * @param event
   */
  onChange(event) {
    this.file = event.target.files[0];
    if (this.file) this.enableSubmitBtn = false;
    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    this.params = {
      updloadfiles: {
        file: { file: file, name: file.name },
        action_type: {
          action_type: this.importToFillForm.value['action_type'],
        },
      },
    };
  }

  /**
   * submit excel file into api after pressing Done
   */
  submit() {
    this.rootService
      .doImport(this.submitUrl, this.params)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        () => {},
        (error) => {
          let err = new AfaqyResponse();
          err.copyInto(JSON.parse(error));
          let errorsList = err.errors;
          let messagesList = [];
          for (let key in errorsList) {
            messagesList.push({
              // message: errorsList[key].join('\n'),
              message: 'serverValidations.' + errorsList[key],
              params: { field: key },
            });
          }
          /* If root service sends error of 504 */
          if (err.status_code == '504') {
            messagesList.push({
              message: 'serverValidations.failure_response',
              params: { field: '' },
            });
          }
          /* If root service sends error of 504 */
          this.rootService.popup(messagesList);
        }
      );
  }

  /**
   * load socket updates after file submit
   */
  getUnitsSocketData() {
    this.pusherSocketService.importToFill$
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res: any) => {
          if (res) {
            const operation_status = res['data']['data']['operation_status'];
            this.operationId = res['data']['data']['logId'];
            this.beforeSubmit = false;
            this.getStepToLoad(operation_status);
            if (
              operation_status === 'completed_with_failures' &&
              this.loadErrorsTable
            ) {
              this.getErrorsList(this.operationId);
            } else if (operation_status === 'in_progress') {
              this.modalClose();
            }
          }
        },
        () =>
          this.rootService.pushNotification(
            'Fetching socket data failed please try again',
            '',
            'error'
          )
      );
  }

  getInventoryRoomSocketData() {
    this.pusherSocketService.inventoryRooms$
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res: any) => {
          if (res) {
            const operation_status = res['data']['data']['operation_status'];
            this.operationId = res['data']['data']['logId'];
            this.beforeSubmit = false;
            this.getStepToLoad(operation_status);
            if (
              operation_status === 'completed_with_failures' &&
              this.loadErrorsTable
            ) {
              this.getErrorsList(this.operationId);
            } else if (operation_status === 'in_progress') {
              this.modalClose();
            }
          }
        },
        () =>
          this.rootService.pushNotification(
            'Fetching socket data failed please try again',
            '',
            'error'
          )
      );
  }

  getUnitGroupsSocketData() {
    this.pusherSocketService.unitGroups$
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res: any) => {
          if (res) {
            const operation_status = res['data']['data']['operation_status'];
            this.operationId = res['data']['data']['logId'];
            this.beforeSubmit = false;
            this.getStepToLoad(operation_status);
            if (
              operation_status === 'completed_with_failures' &&
              this.loadErrorsTable
            ) {
              this.getErrorsList(this.operationId);
            } else if (operation_status === 'in_progress') {
              this.modalClose();
            }
          }
        },
        () =>
          this.rootService.pushNotification(
            'Fetching socket data failed please try again',
            '',
            'error'
          )
      );
  }

  /**
   * get logId from socket update and fetch errors list if process completed with failure
   * @param id
   * @source any
   */
  getErrorsList(id: any) {
    this.operationId = null;
    this.operationId = id;
    let url;
    switch (this.componentName) {
      case 'units':
        url = `units/import/${id}/failure`;
        break;

      case 'inventory_rooms':
        url = `rooms/import/${id}/failure`;
        break;

      case 'unit_groups':
        url = `unit_groups/import/${id}/failure`;
        break;
    }

    this.rootService
      .doGet(url, {})
      // .pipe(takeWhile(() => this.alive))
      .subscribe(
        (errorsList: any) => {
          if (errorsList) {
            this.dataSource = errorsList.data;
            this.numOfErrors = errorsList?.['extra'][0]?.fails;
          }
        },
        () => {
          this.rootService.pushNotification(
            'Error fetching failure data please try again',
            '',
            'error'
          );
        }
      );
  }

  /**
   * Download failed data via file
   */
  downloadFailedDataSheet() {
    const payload = {
      action_type: this.importToFillForm.value['action_type'],
      type: 'xlsx',
    };

    this.rootService
      .downloadFile(this.errorsUrl, payload)
      .pipe(takeWhile(() => this.alive))
      .subscribe();
  }

  /**
   * Navigate to ulpoad screen to re-upload failed data
   */
  reUploadFile() {
    this.beforeSubmit = true;
    this.myStepper.selectedIndex = 0;
  }

  closeModalAndSpinner() {
    this.modalClose();
    this.pusherSocketService.removeSpinnerAfterImport$.next(true);
    this.beforeSubmit = true;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
