import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { UnitSensor } from 'app/modules/units/models';
import { takeWhile } from 'rxjs/operators';
import { SensorColors } from '../interfaces/sensor-colors.interfaces';
import { UnitSensorColor } from './../../../../../modules/units/models/unit-sensor';
import { IntervalColorsService } from './interval-colors/interval-colors.service';
import { SensorColorsFormService } from './sensor-colors-form/sensor-colors-form.service';

@Component({
  selector: 'sensor-colors',
  templateUrl: './sensor-colors.component.html',
  styleUrls: ['./sensor-colors.component.scss'],
})
export class SensorColorsComponent implements OnInit, OnChanges {
  @ViewChild('intervalColors', { read: ElementRef }) intervalColors: ElementRef;
  @ViewChild('addInterval', { read: ElementRef }) addButton: ElementRef;
  @ViewChild('sensorColorsForm', { read: ElementRef })
  sensorColorsForm: ElementRef;
  alive: boolean = true;
  @Input() unitSensorObject: UnitSensor;
  @Input() unitSensorForm: UntypedFormGroup;
  defaultSensorColors: any[] = [];

  constructor(
    public sensorColorsFormService: SensorColorsFormService,
    public intervalColorsService: IntervalColorsService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.onSubmitSensorColorsForm();
    this.onSubmit();
    this.onReset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.unitSensorObject.currentValue) {
      this.setDefaultSensorColors();
    }
  }

  setFormVisibility(): void {
    this.sensorColorsFormService.openSensorForm();
  }

  resetSensorColors(): void {
    let sensorColors: SensorColors;
    let defaultColors: any[] = [];

    if (
      this.unitSensorForm.controls['type'].value ===
        this.unitSensorObject.type &&
      this.unitSensorForm.controls['result_type'].value ===
        this.unitSensorObject.result_type
    ) {
      // clone defaultSensorColors objects to defaultColors array.
      this.defaultSensorColors.map((obj: any) => {
        defaultColors.push({ ...obj });
      });

      // Set default colors to defaultColors array if it has no value.
      if (
        !defaultColors ||
        !defaultColors.length ||
        defaultColors.length === 0
      ) {
        defaultColors =
          this.unitSensorForm.controls['result_type'].value == 'codeValue'
            ? this.loadCodeValueColors()
            : this.intervalColorsService.getDefaultColors(
                this.unitSensorObject.result_type
              );
      }
      // Set default result type and defaultColors to sensorColors object.
      sensorColors = {
        resultType: this.unitSensorObject.result_type,
        colors: defaultColors,
      };
    } else {
      // Set current result type and default colors to sensorColors object.
      defaultColors =
        this.intervalColorsService.resultType == 'codeValue'
          ? this.loadCodeValueColors()
          : this.intervalColorsService.getDefaultColors(
              this.intervalColorsService.resultType
            );
      sensorColors = {
        resultType: this.intervalColorsService.resultType,
        colors: defaultColors,
      };
    }

    this.intervalColorsService.updateIntervalColors(sensorColors);
  }

  private loadCodeValueColors() {
    let objColors = [];
    for (const row of this.unitSensorForm.controls['codeValue'].value) {
      objColors.push({
        type: row.x,
        color: '#000000',
      });
    }

    return objColors;
  }

  /** Clone colors of the retrieve API unit object to defaultSensorColors array. */
  private setDefaultSensorColors(): void {
    this.defaultSensorColors = [];
    if (
      !this.unitSensorObject ||
      !this.unitSensorObject.colors ||
      !this.unitSensorObject.colors.length
    ) {
      return;
    }

    let keyPrefix: string = '';
    if (this.unitSensorObject.colors[0] instanceof UnitSensorColor) {
      keyPrefix = '_';
    }

    this.unitSensorObject.colors.map((obj: any) => {
      const colorObj: any = { ...obj };
      const color: any = {
        from: colorObj[`${keyPrefix}from`],
        to: colorObj[`${keyPrefix}to`],
        type: colorObj[`${keyPrefix}type`],
        color: colorObj[`${keyPrefix}color`],
      };
      this.defaultSensorColors.push(color);
    });
  }

  /** When isFormSubmitted emits a value, mark colors form control as dirty. */
  private onSubmitSensorColorsForm(): void {
    this.sensorColorsFormService.isFormSubmitted.subscribe({
      next: () => {
        this.colorsCtl.markAsDirty();
      },
    });
  }

  /** Subscribe to doSubmit and when it emit a value, calling submitSensorColors fn. */
  private onSubmit(): void {
    this.intervalColorsService.doSubmit
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: () => {
          this.submitSensorColors();
        },
      });
  }

  /** Set colors form array value depends on unitSensorColors. */
  private submitSensorColors(): void {
    const isValueResultType: boolean =
      this.intervalColorsService.resultType === 'value' ? true : false;
    let colorFormGroup: UntypedFormGroup;
    this.colorsCtl.setValue([]);

    if (
      !this.intervalColorsService.unitSensorColors ||
      !this.intervalColorsService.unitSensorColors.length
    ) {
      return;
    }

    this.intervalColorsService.unitSensorColors.map((obj: any) => {
      colorFormGroup = isValueResultType
        ? this.fb.group({ from: [], to: [], color: [] })
        : this.fb.group({ type: [], color: [] });
      this.colorsCtl.push(colorFormGroup);
      for (let key in colorFormGroup.controls) {
        colorFormGroup.controls[key].setValue(obj[key]);
      }
    });

    this.unitSensorForm.updateValueAndValidity();
  }

  /** Subscribe to doReset and reset sensor colors when the observable emits a value. */
  private onReset(): void {
    this.intervalColorsService.doReset
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: () => {
          this.resetSensorColors();
        },
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  get colorsCtl(): UntypedFormArray {
    return this.unitSensorForm.controls['colors'] as UntypedFormArray;
  }
}
