<afaqy-modal
  [title]="'units.commands'"
  (modalClose)="modalClose($event)"
  #modal="afaqyModal"
  sizeClass="modal-lg"
  [staticHeight]="false"
>
  <div class="modalbody">
    <div class="p-relative" [formGroup]="form">
      <div class="afaqy-mask" *ngIf="isView"></div>
      <div class="col-md-9">
        <div class="element-form">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['name'].dirty || form.controls['name'].touched) &&
              form.controls['name'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'name' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <input
                class="form-control form-control-sm"
                formControlName="name"
                [attr.testId]="cid + 'name'"
              />
              <control-errors-hint
                [ctl]="form.controls['name']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="element-form">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['protocol_command_id'].dirty ||
                form.controls['protocol_command_id'].touched) &&
              form.controls['protocol_command_id'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'units.commands_type' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="protocol_command_id"
                [attr.testId]="cid + 'protocol_command_id'"
              >
                <option
                  *ngFor="let command of formOptions.protocolCommandsList"
                  [value]="command.id"
                >
                  {{ command.name }}
                </option>
              </select>
              <control-errors-hint
                [ctl]="form.controls['protocol_command_id']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="element-form">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['channel'].dirty ||
                form.controls['channel'].touched) &&
              form.controls['channel'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'protocol_commands.channel' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="channel"
                [attr.testId]="cid + 'channel'"
              >
                <option
                  *ngFor="let channel of service.channelsValue"
                  [value]="channel.k"
                >
                  {{ channel.v }}
                </option>
              </select>
              <control-errors-hint
                [ctl]="form.controls['channel']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9" *ngIf="!hideParam">
        <div class="element-form">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['param'].dirty ||
                form.controls['param'].touched) &&
              form.controls['param'].errors
            "
          >
            <div class="col-12">
              <label
                *ngIf="form.controls['protocol_command_id'].value == 'custom'"
                class="form-control-label"
                >{{ 'commands.message' | translate }}:</label
              >
              <label
                *ngIf="form.controls['protocol_command_id'].value != 'custom'"
                class="form-control-label"
                >{{ 'param' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <input
                class="form-control form-control-sm"
                formControlName="param"
                [attr.testId]="cid + 'param'"
              />
              <control-errors-hint
                [ctl]="form.controls['param']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modalfooter">
    <form-actions
      [form]="form"
      [isView]="isView"
      [posting]="posting"
      (submit)="onSubmit()"
      (cancel)="modalClose($event)"
      (reset)="reset()"
    ></form-actions>
  </div>
</afaqy-modal>
