import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'unit-form-trip-detection',
  templateUrl: './unit-form-trip-detection.component.html',
})
export class UnitFormTripDetectionComponent implements OnInit {
  cid = 'units-accuracy-';
  @Input() group: UntypedFormGroup;

  ngOnInit() {}

  constructor() {}
}
