import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AfaqyHelper } from '../../common/classes/afaqy-helper';
import { AuthService } from '../../core/services/auth.service';

@Pipe({
  name: 'afaqyDate',
})
export class AfaqyDatePipe implements PipeTransform {
  timeZone: string;

  constructor(private authService: AuthService) {}

  transform(
    value: any,
    timezone: boolean = true,
    format?: string,
    cms: boolean = false
  ): string {
    this.timeZone = this.authService.timeZone
      ? this.authService.timeZone
      : this.authService.user.timezone;

    if (!value) {
      return;
    }
    if (!format) {
      format = 'DD-MM-YYYY HH:mm:ss';
    }
    if (timezone) {
      return AfaqyHelper.timeStampToTimeZoneDateString(
        value,
        this.timeZone,
        format,
        cms
      );
    } else {
      return moment(value).format(format);
    }
  }
}
