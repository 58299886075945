import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { RolesService } from '../../modules/roles/services';

@Injectable()
export class PermissionsResolver implements Resolve<any> {
  constructor(public service: RolesService) {}

  resolve() {
    return this.service.permissionsList();
  }
}
