import { AppModel } from '../../../common/classes';

export class Roles extends AppModel {
  private _name: string;
  private _permissions: string[];
  private _user_id: string;
  private _created_at: Date;
  private _deleted_at: Date;
  private _operation: boolean;
  private _level: string;

  protected copyFields = [
    'id',
    'name',
    'permissions',
    'user_id',
    'created_at',
    'deleted_at',
    'operation',
    'level',
  ];
  protected createFields = ['name', 'permissions', 'operation', 'level'];
  protected updateFields = ['name', 'permissions', 'operation', 'level'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get permissions(): string[] {
    return this._permissions;
  }

  set permissions(value: string[]) {
    this._permissions = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }
  get operation(): boolean {
    return this._operation;
  }

  set operation(value: boolean) {
    this._operation = value;
  }
  get level(): string {
    return this._level;
  }

  set level(value: string) {
    this._level = value;
  }
}
