import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AfaqyHelper } from '../../../common/classes/afaqy-helper';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { Zones } from '../models';
import { ZoneGroupService } from './zone_group.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ZonesService extends RootService {
  constructor(
    public zoneGroupService: ZoneGroupService,
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    private translate: TranslateService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.zoneGroupService.resources.subscribe({
      next: (updates) => {
        this.updateGroupRelations(updates);
      },
    });
  }

  applyAfterLoadResources() {
    this.zoneGroupService.autoLoadResources();
    super.applyAfterLoadResources();
  }
  setResourceObject(obj) {
    let vids = this.authService.preferences('zones', 'visible');
    if (vids?.includes(obj?.id)) {
      obj['visible'] = 1;
    } else {
      obj['visible'] = 0;
    }
    super.setResourceObject(obj);
  }

  updateObjectsRelations(updates) {
    if (updates['action'] == 'list' || updates['action'] == 'addMulti') {
      for (const zone of updates['data']) {
        zone.zone_group = this.zoneGroupService.getItemFromResources(
          zone.zone_group_id
        );
        this.setResourceObject(zone);
      }
    } else if (updates['action'] == 'update' || updates['action'] == 'add') {
      updates['data'].zone_group = this.zoneGroupService.getItemFromResources(
        updates['data'].zone_group_id
      );
      this.setResourcesList([updates['data']]);
    }
  }
  getZoneDetails(id): Zone {
    return this._resourcesList[id];
  }

  updateGroupRelations(updates) {
    if (updates['action'] == 'list') {
      for (const zg of updates['data']) {
        for (const key in this._resourcesList) {
          if (zg.id == this._resourcesList[key].zone_group_id) {
            this._resourcesList[key].zone_group = zg;
          }
        }
      }
    } else if (updates['action'] == 'remove') {
      for (const key in this._resourcesList) {
        if (this._resourcesList[key].zone_group_id == updates['data']) {
          this._resourcesList[key].zone_group =
            this.zoneGroupService.modelInstance;
        }
      }
    } else if (updates['action'] == 'update') {
      for (const key in this._resourcesList) {
        if (this._resourcesList[key].zone_group_id == updates['data']['id']) {
          this._resourcesList[key].zone_group = updates['data'];
        }
      }
    }
    this.pushChanges();
  }

  zonesList() {
    return this.getOptionsList('zones');
    // return this.apiRequest.authPost("zones/lists", {limit: 0, projection: ['id', 'name', 'zone_group_id']}).map((result: AfaqyAPIResponse) => {
    //     if (result.status_code == 200) {
    //         let list = [];
    //         for (let obj of result.data) {
    //             let object = new Zones();
    //             object.copyInto(obj);
    //             list.push(object);
    //         }
    //         return list;
    //     }
    //     return [];
    // });
  }

  get zoneTypes() {
    return Zones.zoneTypes;
  }

  get zoneStatus() {
    return Zones.statusVal;
  }

  get isPersonal() {
    return Zones.isPersonal;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'zones';
  }

  get modelInstance() {
    return new Zones(this.translate);
  }

  getNearestZone(lat, lng) {
    let in_zone_vertices = [];
    let name = '';
    let distance = 0;
    for (let zone of this.resourcesList) {
      if (!zone.vertices) {
        continue;
      }
      for (let j = 0; j < zone.vertices.length; j++) {
        if (!zone.vertices[j]) {
          continue;
        }
        let temp = AfaqyHelper.getLengthBetweenCoordinates(
          lat,
          lng,
          zone.vertices[j][1],
          zone.vertices[j][0]
        );
        if (distance > temp || distance == 0) {
          distance = temp;
          name = zone.name;
          in_zone_vertices = zone.vertices;
        }
      }
    }
    let polygon = [];
    for (let j = 0; j < in_zone_vertices.length; j++) {
      let zoneLat = parseFloat(in_zone_vertices[j][1]);
      let zoneLng = parseFloat(in_zone_vertices[j][0]);
      polygon.push({ x: zoneLat, y: zoneLng });
    }
    if (AfaqyHelper.isPointInPolygon(polygon, { x: lat, y: lng }) == true) {
      distance = 0;
    }
    distance = AfaqyHelper.convDistanceUnits(
      distance,
      'km',
      this.authService.user.user_settings.distance_unit
    );
    let d =
      distance.toFixed(2) +
      ' ' +
      this.translate.instant(
        'measurements.' + this.authService.user.user_settings.distance_unit
      );
    let nearest = { n: name, d: d };
    return nearest;
  }

  getExportFileName(prms: any) {
    return (
      this.authService.user.username +
      '_' +
      'Geofences' +
      '_' +
      moment().format('YYYY-MM-DD')
    );
  }
}
