import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AfaqyAPIResponse, AfaqyResponse } from '../../../core/classes';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { Driver } from '../models';
import { DriverGroupService } from './driver_group.service';

@Injectable({ providedIn: 'root' })
export class DriverService extends RootService {
  public groupedResources: BehaviorSubject<any[]> = new BehaviorSubject(null);

  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    private translate: TranslateService,
    private driverGroupService: DriverGroupService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.customActions = ['forceLogout'];

    driverGroupService.resources.subscribe({
      next: (updates) => {
        if (this.resourcesList.length) this.updateGroupedResources(updates);
      },
    });

    this.resources.subscribe({
      next: (updates) => {
        this.updateGroupedResources(updates);
      },
    });
  }

  routerPrefix(val: string = '') {
    return val ? val : 'drivers';
  }

  get modelInstance() {
    return new Driver();
  }

  bindToUnit(driver_id, unit_id) {
    let params: {} = { driver_id: driver_id, unit_id: unit_id };
    return this.apiRequest
      .authPost(this.getFunctionURL('set_unit_driver'), params)
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          reqres.status(result.status_code);
          return this.doResponse(reqres);
        }),
        catchError((err) => this.serverError(err))
      );
  }

  getItems() {
    let items = [];
    for (const driver of this.resourcesList) {
      let i = {};
      for (let key of ['id', 'name', 'phone']) {
        i[key] = driver[key];
      }
      i['selected'] = false;
      items.push(i);
    }
    return items;
  }

  gridColumns(trashed = false) {
    let allcols = [
      {
        header: 'multiple_select',
        minWidth: 28,
        width: 30,
        colValue: 'checkbox',
        active: true,
        immutable: false,
        default: true,
        type: 'checkbox',
        sorting: false,
        filters: false,
      },
      {
        header: 'name',
        minWidth: 100,
        width: '*',
        colValue: 'name',
        active: true,
        immutable: true,
        default: true,
      },
      // {header: "description", "minWidth": 75, "width": '*', colValue: "desc", active: false, default: true},
      {
        header: 'phone',
        minWidth: 110,
        width: '*',
        colValue: 'phone',
        active: false,
        default: true,
      },
      {
        header: 'ibtn',
        minWidth: 100,
        width: '*',
        colValue: 'ibtn',
        active: false,
        default: false,
      },
      {
        header: 'rfid',
        minWidth: 100,
        width: '*',
        colValue: 'rfid',
        active: false,
        default: false,
      },
      {
        header: 'vehicle',
        minWidth: 100,
        width: '*',
        colValue: 'unit.n',
        active: true,
        default: true,
      },
      {
        header: 'address',
        minWidth: 100,
        width: '*',
        colValue: 'address',
        active: false,
        default: false,
      },
      {
        header: 'email',
        minWidth: 100,
        width: '*',
        colValue: 'email',
        active: false,
        default: false,
      },
      {
        header: 'username',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'username',
        active: false,
        default: false,
      },
      {
        header: 'name_ar',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'name_ar',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.driver_nationality',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'driver_nationality',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.driver_id_number',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'driver_id_number',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.driver_id_expiration',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'driver_id_expiration',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.driver_insurance_company',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'driver_insurance_company',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.driver_insurance_policy_no',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'driver_insurance_policy_no',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.driver_insurance_expiration_date',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'driver_insurance_expiration_date',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.driver_type',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'driver_type',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.contract_no',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'contract_no',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.supplier',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'supplier',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.contract_start_date',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'contract_start_date',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.contract_end_date',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'contract_end_date',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.tamm_authorization_no',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'tamm_authorization_no',
        active: false,
        default: false,
      },
      {
        header: 'driver-info.tamm_authorization_expiration_date',
        sorting: false,
        filters: false,
        minWidth: 100,
        width: '*',
        colValue: 'tamm_authorization_expiration_date',
        active: false,
        default: false,
      },
      {
        header: 'actions',
        minWidth: 60,
        width: 100,
        colValue: 'actions',
        active: false,
        default: true,
        extra: [
          {
            header: 'assign_user',
            permissions: this.cid + '-assign',
            code: 'assign',
            faclass: 'fa-link',
          },
          {
            header: 'unassign_user',
            permissions: this.cid + '-unassign',
            code: 'unassign',
            faclass: 'fa-unlink',
          },
          {
            header: 'drivers.bind_unit',
            permissions: this.cid + '-setUnitDriver',
            code: 'bindUnit',
            faclass: 'fa-car',
          },
          {
            header: 'drivers.force_logout',
            permissions: this.cid + '-forceLogout',
            code: 'forceLogout',
            faclass: 'fa-sign-out-alt',
          },
        ],
      },
    ];
    if (trashed) {
      const lastInx = allcols.length - 1;
      allcols[lastInx]['extra'] = [];
      allcols.shift();
    }
    if (!this.authService.checkPermissions(this.cid + '-bulkDelete')) {
      allcols.shift();
    }
    return allcols;
  }

  get listingIcon() {
    return 'afaqy-icon-drivers';
  }

  applyCustomAction(action: string, itemId: string) {
    if (action === 'forceLogout') {
      this.onForceLogout(itemId).subscribe();
    }
  }

  private onForceLogout(driverId: string) {
    const params: Object = { id: driverId };
    return this.apiRequest
      .authPost(this.getFunctionURL('force_logout'), params)
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          reqres.status(result.status_code);
          this.pushNotification(
            this.translate.instant('notifications.driver.force_logout'),
            this.translate.instant('headings.drivers')
          );
          return this.doResponse(reqres);
        }),
        catchError((err) => this.serverError(err))
      );
  }

  /**
   * on update of units or unit groups this method updates the
   * groupedUnits Reply Subject with the new values
   * @param updates resources of units or unit groups
   */
  updateGroupedResources(updates: any) {
    // if(updates.action == "list"){
    let rList = [];
    this.resourcesList.map((item) => {
      if (item.groupsList.length) {
        item.groupsList.forEach((groupid) => {
          let newitem = item.clone();
          newitem['group_id'] = groupid;
          newitem['group_name'] =
            this.driverGroupService.getItemFromResources(groupid).name;
          rList.push(newitem);
        });
      } else {
        let newitem = item.clone();
        newitem['group_id'] = 0;
        newitem['group_name'] = 'Un-grouped';
        rList.push(newitem);
      }
    });
    this.groupedResources.next(rList);

    // }
  }
}
