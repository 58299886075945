import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services';
import { AppConfig } from 'app/common';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent implements OnInit {
  config = AppConfig;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    //this.updateColor();
  }

  updateColor() {
    const colorName = this.authService.color;
    const cssRoot = 'assets/layouts/layout-icon-menu/css/color/';
    document.getElementsByTagName('body')[0].setAttribute('theme', colorName);
    document
      .getElementsByTagName('body')[0]
      .classList.add('theme-' + colorName);
    document
      .getElementById('site-color')
      .setAttribute('href', cssRoot + 'light/color-' + colorName + '.min.css');
  }
}
