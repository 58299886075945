<div class="servicesCustomPadding">
  <driver-behavior-form
    *ngIf="showForm"
    [isView]="isView"
    [unitID]="unitObject.id"
    [unitObject]="unitObject"
    [object]="selectedDriverBehavior"
    (closeForm)="closeForm($event)"
    (updateList)="updateDriverBehavior($event)"
  ></driver-behavior-form>
  <unit-driver-behavior-clone-form
    *ngIf="showClone"
    [behaviorId]="behaviorId"
    (closeForm)="closeClone($event)"
  ></unit-driver-behavior-clone-form>

  <div class="table-responsive height100">
    <div class="clearfix">
      <div class="float-right filter no-padding form-inline">
        <afaqy-spinner [loading]="loading"></afaqy-spinner>
        <!--                <a *ngIf="unitObject.id" class="btn btn-sm mb-3 btn-outline-primary mx-2" afaqy-tooltip [tooltipText]="'tooltips.import' | translate"-->
        <!--                    secured="{{controller +'-import'}}" (click)="importfile.value = ''; importfile.click();">-->
        <!--                    <input class="hidden" type="file" (change)="import($event)" #importfile />-->
        <!--                    &lt;!&ndash; <i class="mdi mdi-upload mdi-18px"></i> &ndash;&gt;-->
        <!--                    {{"units.unit_driver_behavior.importfile" | translate}}-->
        <!--                </a>-->
        <!--                <div *ngIf="unitObject.id" class="p-relative btn-group" dropdown container="body">-->
        <!--                    <button id="dropdown-exportAs-trigger" dropdownToggle type="button" class="btn btn-sm mb-3 btn-outline-primary mx-2 dropdown-toggle"-->
        <!--                        aria-controls="dropdown-exportAs" afaqy-tooltip [tooltipText]="'tooltips.export' | translate"-->
        <!--                        secured="{{controller +'-export'}}">-->
        <!--                        &lt;!&ndash; <i class="mdi mdi-download mdi-18px"></i> <span class="caret"></span> &ndash;&gt;-->
        <!--                        {{"units.unit_driver_behavior.exportas" | translate}}-->
        <!--                    </button>-->

        <!--                    <ul id="dropdown-exportAs" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="dropdown-exportAs-trigger">-->
        <!--                        <li role="menuitem">-->
        <!--                            <a class="dropdown-item" href="javascript:void(0);" (click)="doExport('xlsx')">XLSX-->
        <!--                            </a>-->
        <!--                        </li>-->
        <!--                        <li role="menuitem">-->
        <!--                            <a class="dropdown-item" href="javascript:void(0);" (click)="doExport('xls')">XLS-->
        <!--                            </a>-->
        <!--                        </li>-->
        <!--                        <li role="menuitem">-->
        <!--                            <a class="dropdown-item" href="javascript:void(0);" (click)="doExport('csv')">CSV-->
        <!--                            </a>-->
        <!--                        </li>-->
        <!--                    </ul>-->
        <!--                </div>-->
        <button
          class="btn btn-sm mb-3 btn-outline-primary mx-2"
          href="javascript:void(0)"
          container="body"
          secured="{{ controller + '-add' }}"
          (click)="addForm()"
          [attr.testId]="cid + 'add_new_driver_behavior'"
          type="button"
        >
          <i class="mdi mdi-plus"></i>
          {{ 'units.unit_driver_behavior.add_new_driver_behavior' | translate }}
        </button>
      </div>
    </div>
    <div>
      <div class="grid-container">
        <flash-message langKey="" [message]="message"></flash-message>
        <wj-flex-grid
          [itemsSource]="itemsList"
          [selectionMode]="'None'"
          [isReadOnly]="true"
          [allowSorting]="true"
        >
          <wj-flex-grid-filter
            [filterColumns]="filtersCols"
          ></wj-flex-grid-filter>
          <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'"
            >#</ng-template
          >
          <ng-template
            wjFlexGridCellTemplate
            [cellType]="'RowHeader'"
            let-cell="cell"
            ><span class="row-header">{{
              cell.row.index + 1
            }}</span></ng-template
          >
          <wj-flex-grid-column
            [allowSorting]="true"
            [header]="'name' | translate"
            binding="name"
            [isReadOnly]="true"
            [width]="'*'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [allowSorting]="true"
            [header]="'units.unit_driver_behavior.sensor' | translate"
            binding="type"
            [isReadOnly]="true"
            [width]="'*'"
          >
            <ng-template
              wjFlexGridCellTemplate
              [cellType]="'Cell'"
              let-item="item"
            >
              <div [innerHTML]="item.sensor_name"></div>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'actions' | translate"
            binding=""
            [isReadOnly]="true"
            [width]="100"
          >
            <ng-template
              wjFlexGridCellTemplate
              [cellType]="'Cell'"
              cellClass="class"
              let-item="item"
              let-cell="cell"
            >
              <div class="float-right list-actions">
                <a
                  secured="{{ controller + '-cloneBehavior' }}"
                  (click)="clone(cell.row.index, item)"
                  href="javascript:void(0)"
                  afaqy-tooltip
                  [tooltipText]="'driver_behavior.clone' | translate"
                >
                  <i
                    class="mdi mdi-arrange-send-backward"
                    [attr.testid]="'clone'"
                  ></i>
                </a>

                <a
                  secured="{{ controller + '-edit' }}"
                  afaqy-tooltip
                  [tooltipText]="'driver_behavior.edit' | translate"
                  (click)="edit(cell.row.index, item)"
                  href="javascript:void(0)"
                  ><i class="mdi mdi-pencil" [attr.testid]="'edit'"></i
                ></a>

                <!--Start View Option-->
                <a
                  *ngIf="editPermission === false"
                  secured="{{ controller + '-view' }}"
                  afaqy-tooltip
                  [tooltipText]="'driver_behavior.view' | translate"
                  (click)="view(cell.row.index, item)"
                  href="javascript:void(0)"
                  ><i class="mdi mdi-eye" [attr.testid]="'view'"></i
                ></a>
                <!--End View Option-->

                <a
                  secured="{{ controller + '-add,' + controller + '-edit' }}"
                  afaqy-tooltip
                  [tooltipText]="'driver_behavior.duplicate' | translate"
                  (click)="copy(item)"
                  href="javascript:void(0)"
                  ><i class="mdi mdi-content-copy" [attr.testid]="'copy'"></i
                ></a>
                <a
                  secured="{{ controller + '-delete' }}"
                  afaqy-tooltip
                  [tooltipText]="'driver_behavior.trash' | translate"
                  (click)="delete(item)"
                  href="javascript:void(0)"
                  ><i class="mdi mdi-delete" [attr.testid]="'delete'"></i
                ></a>
              </div>
            </ng-template>
          </wj-flex-grid-column>
        </wj-flex-grid>
      </div>
    </div>
  </div>
</div>
