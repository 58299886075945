import { Component, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'afaqy-control',
  template: '',
})
export class AfaqyControl implements OnChanges {
  writing = false;
  propagateChange: any = () => {};
  validateFn: any = () => {};

  ngOnChanges(inputs) {
    this.pushValue();
  }

  pushValue() {
    this.propagateChange();
  }

  writeValue(value: any) {}

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
}
