import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'afaqyRound',
})
export class RoundPipe implements PipeTransform {
  transform(value: any): number {
    return Math.round(parseFloat(value) * 100) / 100;
  }
}
