<div class="modal" *ngIf="show" [attr.testid]="'afaqy-alert'">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="title">{{ title | translate }}</h5>
      </div>
      <div class="modal-body">
        <p *ngIf="message">
          {{ message | translate : { params: params, params2: params2 } }}
        </p>

        <div class="errors" *ngFor="let msg of messagesList">
          <p
            [ngClass]="styleHeaderClass(msg)"
            (click)="toggleDiv(msg.params.index ? msg.params.index : '')"
          >
            <i
              id="{{ 'afaqy-alert-header-' + msg.params.index }}"
              class="mdi mdi-plus"
              *ngIf="msg.childs"
            ></i>
            {{ msg.message | translate : msg.params }}
            <span *ngIf="msg.childs">({{ msg.childs.length }})</span>
          </p>
          <ul
            class="hidden"
            *ngIf="msg.childs"
            id="{{ 'afaqy-alert-container-' + msg.params.index }}"
          >
            <li class="afaqy-alert-subitem" *ngFor="let submsg of msg.childs">
              {{ submsg.message | translate : submsg.params }}
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer clearfix">
        <button
          class="d-block btn btn-success btn-custom float-right"
          type="button"
          (click)="isOk()"
          [attr.testId]="'ok'"
        >
          {{ ok | translate }}
        </button>
        <button
          class="d-block btn btn-outline-danger btn-custom float-left"
          *ngIf="type == 'confirm'"
          type="button"
          (click)="isCancel()"
          [attr.testId]="'cancel'"
        >
          {{ cancel | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
