import { AfaqyHelper } from './afaqy-helper';

export class AfaqyObject {
  protected copyFields = [];

  public copyInto(obj: any, simplify = true) {
    for (let key of this.copyFields) {
      const mappedKey = simplify ? AfaqyHelper.getMappedKey(key) : key;
      // console.log(key, mappedKey);
      if (this[key] instanceof AfaqyObject) {
        this[key].copyInto(
          obj[mappedKey] ? obj[mappedKey] : obj[key] ? obj[key] : {},
          simplify
        );
      } else if (key == 'id') {
        this[key] = obj['_id'] ? obj['_id'] : obj['id'];
      } else {
        this[key] =
          obj[mappedKey] || obj[mappedKey] == false
            ? obj[mappedKey]
            : obj[key]
            ? obj[key]
            : '';
      }
    }
    this.doObjectChecks();
  }

  public doObjectChecks() {}

  public preparePostData(field) {
    let data = {};
    for (let key of this.copyFields) {
      data[key] = this[key];
    }
    return data;
  }

  public getCreatePostData() {
    return this.preparePostData('createFields');
  }

  public getUpdatePostData() {
    return this.preparePostData('updateFields');
  }
}
