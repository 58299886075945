<div class="import-to-fill-wrapper">
  <afaqy-modal
    [title]="'importToFill.import'"
    (modalClose)="modalClose()"
    #modal="afaqyModal"
    sizeClass="modal-lg"
    [hidden]="hideModal"
  >
    <div class="import-modal-wrapper-body modalbody">
      <form [formGroup]="importToFillForm">
        <mat-horizontal-stepper #stepper>
          <!-- Steps before submit -->
          <ng-container *ngIf="beforeSubmit">
            <!-- Choose action step -->
            <mat-step class="step-1-choose-action" *ngIf="!disableFirstStep">
              <div class="import-modal-header">
                <p class="title">
                  {{ 'importToFill.selectImportType' | translate }}
                </p>
                <p class="subtitle">
                  {{ 'importToFill.selectOneOption' | translate }}
                </p>
              </div>

              <div class="import-modal-body">
                <div
                  *ngFor="let option of optionsData"
                  class="options-wrapper"
                  (click)="chooseOption(option)"
                  [class.selected-option]="option === selectedOption"
                >
                  <div class="options-image">
                    <img src="{{ option.image }}" alt="" class="image" />
                  </div>
                  <div class="options-text">
                    <p class="title">{{ option.title | translate }}</p>
                    <p class="subtitle">{{ option.subTitle | translate }}</p>
                  </div>
                </div>
              </div>

              <div class="import-modal-footer">
                <button
                  [disabled]="firstStepDisabled"
                  matStepperNext
                  class="next-button"
                  mat-button
                >
                  {{ 'importToFill.next' | translate }}
                </button>
              </div>
            </mat-step>

            <!-- Upload file step -->
            <mat-step class="step-2-upload-doc">
              <div class="step-two-header">
                <span>{{
                  'importToFill.downloadTemplateToFillIn' | translate
                }}</span>
                <button
                  mat-button
                  class="download-template"
                  (click)="downloadTemplate()"
                  secured="{{ componentName }}-downloadImportSample"
                >
                  {{ 'importToFill.downloadTemplateButton' | translate }}
                </button>
              </div>

              <div class="step-two-body">
                <label for="images" class="drop-container">
                  <span class="drop-title">{{
                    'importToFill.browseFiles' | translate
                  }}</span>
                  <span class="font-grey-12">{{
                    'importToFill.supportedFiles' | translate
                  }}</span>
                  <input
                    (change)="onChange($event)"
                    accept=".xls,.xlsx"
                    formControlName="file"
                    id="images"
                    required
                    type="file"
                  />
                </label>
                <span class="font-grey-12">{{
                  'importToFill.recommendedSize' | translate
                }}</span>
              </div>

              <div class="step-two-footer">
                <!-- <button matStepperPrevious>Previous</button> -->
                <button
                  class="next-button"
                  matStepperNext
                  mat-button
                  [disabled]="enableSubmitBtn"
                >
                  {{ 'importToFill.proceedToValidate' | translate }}
                </button>
              </div>
            </mat-step>

            <!-- Upload success screen -->
            <mat-step class="upload-success">
              <div class="response-message-wrapper">
                <img
                  src="assets/images/checkmark-done-circle.png"
                  alt=""
                  width="160px"
                  height="160px"
                />
                <p class="title">
                  {{ 'importToFill.submitSuccess' | translate }}
                </p>
                <p class="subtitle">
                  {{ 'importToFill.verified' | translate }}
                </p>
              </div>
              <button
                class="next-button"
                mat-button
                (click)="modalClose(); submit()"
              >
                {{ 'importToFill.close' | translate }}
              </button>
            </mat-step>

            <!-- Upload failed screen -->
            <mat-step
              class="upload-failed"
              *ngIf="currentStep === 'upload-failed'"
            >
              <div class="response-message-wrapper">
                <img
                  src="assets/images/checkmark-error-circle.png"
                  alt=""
                  width="160px"
                  height="160px"
                />
                <p class="title">
                  {{ 'importToFill.somethingWentWrong' | translate }}
                </p>
                <!-- <p class="subtitle">Please try again</p> -->
              </div>
              <!-- <button class="next-button" mat-button>{{ 'importToFill.retry' | translate}}</button> -->
            </mat-step>
          </ng-container>

          <!-- Steps after submit (coming from bottom right progress indicator) -->
          <ng-container *ngIf="!beforeSubmit">
            <!-- Errors table -->
            <mat-step *ngIf="currentStep === 'completed_with_failures'">
              <div class="errors-table">
                <table mat-table [dataSource]="dataSource" class="">
                  <!-- Row Column -->
                  <ng-container matColumnDef="row">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'importToFill.row' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.row }}
                    </td>
                  </ng-container>

                  <!-- Num of errors Column -->
                  <ng-container matColumnDef="errors_count">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'importToFill.numOfErrors' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.errors_count }}
                    </td>
                  </ng-container>

                  <!-- Errors Column -->
                  <ng-container matColumnDef="errors">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'importToFill.errors' | translate }} ({{
                        numOfErrors
                      }})
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <p
                        *ngFor="let error of element.errors"
                        class="error-text"
                      >
                        {{ error }}
                      </p>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>

              <div class="import-modal-footer">
                <button
                  class="cancel"
                  mat-button
                  (click)="closeModalAndSpinner()"
                >
                  {{ 'importToFill.cancel' | translate }}
                </button>

                <button class="next-button" mat-button (click)="reUploadFile()">
                  {{ 'importToFill.reUpload' | translate }}
                </button>

                <button
                  class="download-failed-data"
                  mat-button
                  (click)="downloadFailedDataSheet()"
                  [secured]="componentName + '-downloadImportFailedData'"
                >
                  {{ 'importToFill.downloadFailedData' | translate }}
                </button>
              </div>
            </mat-step>

            <!-- Progress and operation success screen -->
            <mat-step
              class="upload-success"
              *ngIf="currentStep === 'completed'"
            >
              <div class="response-message-wrapper">
                <img
                  src="assets/images/checkmark-done-circle.png"
                  alt=""
                  width="160px"
                  height="160px"
                />
                <p class="title">
                  {{ 'importToFill.dataUploadSuccess' | translate }}
                </p>
                <p class="subtitle">
                  {{ 'importToFill.verified' | translate }}
                </p>
              </div>
              <button
                matStepperNext
                class="next-button"
                mat-button
                (click)="closeModalAndSpinner()"
              >
                {{ 'importToFill.done' | translate }}
              </button>
            </mat-step>

            <!-- Progress and operation failed screen -->
            <mat-step
              class="upload-failed"
              *ngIf="currentStep === 'upload-failed'"
            >
              <div class="response-message-wrapper">
                <img
                  src="assets/images/checkmark-error-circle.png"
                  alt=""
                  width="160px"
                  height="160px"
                />
                <p class="title">
                  {{ 'importToFill.browseFiles' | translate }}API Error Message
                </p>
                <p class="subtitle">
                  {{ 'importToFill.browseFiles' | translate }}Description
                </p>
              </div>
              <button matStepperNext class="next-button" mat-button>
                {{ 'importToFill.retry' | translate }}
              </button>
            </mat-step>
          </ng-container>
        </mat-horizontal-stepper>
      </form>
    </div>
  </afaqy-modal>
</div>
