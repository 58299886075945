<afaqy-modal
  [title]="'units.unit_driver_behavior.title'"
  (modalClose)="modalClose($event)"
  #modal="afaqyModal"
  [draggable]="false"
  sizeClass="modal-lg modal-dialog-centered"
  [staticHeight]="false"
>
  <div class="modalbody">
    <div class="p-relative" [formGroup]="form">
      <div class="afaqy-mask" *ngIf="isView"></div>
      <div class="row">
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['name'].dirty || form.controls['name'].touched) &&
              form.controls['name'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'name' | translate }}:</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <input
                class="form-control form-control-sm"
                formControlName="name"
                [attr.testId]="cid + 'name'"
              />
              <control-errors-hint
                [ctl]="form.controls['name']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['sensor_id'].dirty ||
                form.controls['sensor_id'].touched) &&
              form.controls['sensor_id'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'units.unit_driver_behavior.sensor' | translate }} :</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <select
                class="form-control form-control-sm"
                formControlName="sensor_id"
                [attr.testId]="cid + 'sensor_id'"
              >
                <option *ngFor="let sensor of sensorList" [value]="sensor.id">
                  {{ sensor.title }}
                </option>
              </select>
              <control-errors-hint
                [ctl]="form.controls['sensor_id']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['penalty'].dirty ||
                form.controls['penalty'].touched) &&
              form.controls['penalty'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label"
                >{{ 'units.unit_driver_behavior.penalty' | translate }} :</label
              >
            </div>
            <div class="col-12 input-group form-group-hint">
              <input
                class="form-control form-control-sm"
                formControlName="penalty"
                (change)="reformatValue($event, 'penalty')"
                [attr.testId]="cid + 'penalty'"
              />
              <control-errors-hint
                [ctl]="form.controls['penalty']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['minValue'].dirty ||
                form.controls['minValue'].touched) &&
              form.controls['minValue'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label lable-form">{{
                'units.unit_driver_behavior.min' | translate
              }}</label>
            </div>
            <div class="col-12 input-group form-group-hint">
              <input
                class="form-control form-control-sm"
                formControlName="minValue"
                (change)="checkHigh('value', $event, 'minValue')"
                [attr.testId]="cid + 'minValue'"
              />
              <control-errors-hint
                [ctl]="form.controls['minValue']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['maxValue'].dirty ||
                form.controls['maxValue'].touched) &&
              form.controls['maxValue'].errors
            "
          >
            <div class="col-12">
              <label class="form-control-label lable-form">{{
                'units.unit_driver_behavior.max' | translate
              }}</label>
            </div>
            <div class="col-12 input-group form-group-hint">
              <input
                class="form-control form-control-sm"
                formControlName="maxValue"
                (change)="checkHigh('value', $event, 'maxValue')"
                [attr.testId]="cid + 'maxValue'"
              />
              <control-errors-hint
                [ctl]="form.controls['maxValue']"
              ></control-errors-hint>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-12">
              <label class="form-control-label lable-form">{{
                'units.unit_driver_behavior.min_speed' | translate
              }}</label>
            </div>
          </div>
          <div
            class="row form-group button-form-group"
            [class.has-danger]="
              (form.controls['minSpeed'].dirty ||
                form.controls['minSpeed'].touched) &&
              form.controls['minSpeed'].errors
            "
          >
            <div class="col-12 form-group-hint">
              <div class="input-group input-group-sm">
                <input
                  class="form-control form-control-sm"
                  formControlName="minSpeed"
                  (change)="checkHigh('spd', $event, 'minSpeed')"
                  [attr.testId]="cid + 'minSpeed'"
                />
                <div class="input-group-append">
                  <label class="input-group-text">{{
                    'units.unit_driver_behavior.km/h' | translate
                  }}</label>
                </div>
                <control-errors-hint
                  [ctl]="form.controls['minSpeed']"
                ></control-errors-hint>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-12">
              <label class="form-control-label lable-form">{{
                'units.unit_driver_behavior.max_speed' | translate
              }}</label>
            </div>
          </div>
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['maxSpeed'].dirty ||
                form.controls['maxSpeed'].touched) &&
              form.controls['maxSpeed'].errors
            "
          >
            <div class="col-12 form-group-hint">
              <div class="input-group input-group-sm">
                <input
                  class="form-control form-control-sm"
                  formControlName="maxSpeed"
                  (change)="checkHigh('spd', $event, 'maxSpeed')"
                  [attr.testId]="cid + 'maxSpeed'"
                />
                <div class="input-group-append">
                  <label class="input-group-text">{{
                    'units.unit_driver_behavior.km/h' | translate
                  }}</label>
                </div>
                <control-errors-hint
                  [ctl]="form.controls['maxSpeed']"
                ></control-errors-hint>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-12">
              <label class="form-control-label lable-form">{{
                'units.unit_driver_behavior.min_duration' | translate
              }}</label>
            </div>
          </div>
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['minDuration'].dirty ||
                form.controls['minDuration'].touched) &&
              form.controls['minDuration'].errors
            "
          >
            <div class="col-12 form-group-hint">
              <div class="input-group input-group-sm">
                <input
                  class="form-control form-control-sm"
                  formControlName="minDuration"
                  (change)="checkHigh('du', $event, 'minDuration')"
                  [attr.testId]="cid + 'minDuration'"
                />
                <div class="input-group-append">
                  <label class="input-group-text">{{
                    'units.unit_driver_behavior.sec' | translate
                  }}</label>
                </div>
                <control-errors-hint
                  [ctl]="form.controls['minDuration']"
                ></control-errors-hint>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-12">
              <label class="form-control-label lable-form">{{
                'units.unit_driver_behavior.max_duration' | translate
              }}</label>
            </div>
          </div>
          <div
            class="row form-group"
            [class.has-danger]="
              (form.controls['maxDuration'].dirty ||
                form.controls['maxDuration'].touched) &&
              form.controls['maxDuration'].errors
            "
          >
            <div class="col-12 form-group-hint">
              <div class="input-group input-group-sm">
                <input
                  class="form-control form-control-sm"
                  formControlName="maxDuration"
                  [attr.testId]="cid + 'maxDuration'"
                  (change)="checkHigh('du', $event, 'maxDuration')"
                />
                <div class="input-group-append">
                  <label class="input-group-text">{{
                    'units.unit_driver_behavior.sec' | translate
                  }}</label>
                </div>
                <control-errors-hint
                  [ctl]="form.controls['maxDuration']"
                ></control-errors-hint>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modalfooter">
    <form-actions
      [form]="form"
      [isView]="isView"
      [posting]="posting"
      (submit)="onSubmit()"
      (cancel)="modalClose($event)"
      (reset)="reset()"
    ></form-actions>
  </div>
</afaqy-modal>
