import { AppModel } from '../../../common/classes';
import { AfaqyObject } from '../../../common/classes/afaqy-object';
import { TranslateService } from '@ngx-translate/core';

class Region extends AfaqyObject {
  private _name: string = '';

  protected copyFields = ['id', 'name'];
  protected createFields = ['name'];
  protected updateFields = ['name'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}

export class Regions extends AppModel {
  constructor(protected translate: TranslateService) {
    super();
  }

  private _name: string;
  private _user_id: string;

  protected copyFields = ['id', 'name'];
  protected createFields = ['name'];
  protected updateFields = ['id', 'name'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}
