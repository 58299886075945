<div
  class="table-bordered options-list listOptions"
  [ngStyle]="
    !isRTL
      ? {
          'top.px': gridOptimizer.gidOptimTop,
          'left.px': gridOptimizer.gidOptimLeft,
          'maxHeight.%': gridOptimizer.maxHeight
        }
      : {
          'top.px': gridOptimizer.gidOptimTop,
          'right.px': gridOptimizer.gidOptimLeft,
          'maxHeight.%': gridOptimizer.maxHeight
        }
  "
  *ngIf="gridOptimizer.status"
  [@showHide]
  (clickOutside)="gridOptimizer.status = !gridOptimizer.status"
>
  <table class="table">
    <tr *ngFor="let col of allColumns; let i = index">
      <td>
        <!--
                <div class="checkbox-squared d-inline-block">
                    <label class="switch">
                        <input type="checkbox" [checked]="col.active" [disabled]="col.immutable"
                               (change)="update(i,$event.target.checked)">
                        <span class="slider round"></span>
                    </label>
                </div>
                <span class="switchLabel">
                    <ng-container *ngIf="col.headerHtml; else normal">
                        <span [innerHTML]="col.header"></span>
                    </ng-container>
                    <ng-template #normal>
                        <ng-container *ngIf="col.translated; else transed">
                            <span>{{col.header}} </span>
                        </ng-container>
                        <ng-template #transed>
                          <span>{{col.header | translate}}</span>
                        </ng-template>
                    </ng-template>
                </span>
                -->
        <div class="row">
          <div class="col-auto d-flex align-items-center">
            <label
              class="switch"
              [class.disabled]="col.immutable"
              for="{{ col.header }}-{{ i }}"
              [attr.testid]="col.header + '-' + i"
            >
              <input
                id="{{ col.header }}-{{ i }}"
                type="checkbox"
                [checked]="col?.active"
                [disabled]="col?.immutable"
                (change)="update(i, $event?.target?.checked)"
              />

              <span class="slider round"></span>
            </label>
          </div>
          <div class="col pl-0">
            <label
              class="title"
              [class.disabled]="col.immutable"
              for="{{ col?.header }}-{{ i }}"
            >
              <ng-container *ngIf="col.headerHtml; else normal">
                <span [innerHTML]="col?.header"></span>
              </ng-container>
              <ng-template #normal>
                <ng-container *ngIf="col?.translated; else transed">
                  <span>{{ col?.header }} </span>
                </ng-container>
                <ng-template #transed>
                  <span>{{ col?.header | translate }}</span>
                </ng-template>
              </ng-template>
            </label>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>
