import { AfaqyObject, AppConfig, AfaqyHelper } from '../../../common/classes';

export class FuelPrices extends AfaqyObject {
  private _f95: number = 2.330;
  private _f91: number = 2.180;
  private _diesel: number = 1.150;
  protected copyFields = ['f95', 'f91', 'diesel'];

  get f95(): number {
    return this._f95;
  }

  set f95(value: number) {
    this._f95 = value;
  }

  get f91(): number {
    return this._f91;
  }

  set f91(value: number) {
    this._f91 = value;
  }

  get diesel(): number {
    return this._diesel;
  }

  set diesel(value: number) {
    this._diesel = value;
  }
}
