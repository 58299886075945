import { AfaqyObject, AppConfig, AfaqyHelper } from '../../../common/classes';

export class UnitAdditionalInfo extends AfaqyObject {
  private _imei: boolean[];
  private _device: boolean[];
  private _sim: boolean[];
  private _sensors: boolean[];
  private _services: boolean[];
  private _custom_fields: boolean[];
  private _dtt: boolean[];
  private _dts: boolean[];
  private _driver: boolean[];
  private _trailer: boolean[];
  private _signal_prams: boolean[];
  private _sensors_time: boolean[];
  private _params_time: boolean[];
  public copyFields = [
    'dts',
    'dtt',
    'imei',
    'device',
    'sim',
    'driver',
    'trailer',
    'custom_fields',
    'sensors',
    'sensors_time',
    'services',
    'signal_prams',
    'prams_time',
  ];

  getFieldValue(key) {
    let value = [false, false, false];
    for (let i = 0; i < 3; i++) {
      if (this[key][i]) {
        value[i] = true;
      }
    }
    return value;
  }

  get custom_fields(): boolean[] {
    if (this._custom_fields === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_custom_fields');
  }

  set custom_fields(value: boolean[]) {
    if (value) this._custom_fields = value;
  }

  get dtt(): boolean[] {
    if (this._dtt === undefined) {
      return [false, false, false];
    }

    return this.getFieldValue('_dtt');
  }

  set dtt(value: boolean[]) {
    if (value) this._dtt = value;
  }

  get dts(): boolean[] {
    if (this._dts === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_dts');
  }

  set dts(value: boolean[]) {
    if (value) this._dts = value;
  }

  get imei(): boolean[] {
    if (this._imei === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_imei');
  }

  set imei(value: boolean[]) {
    if (value) this._imei = value;
  }

  get device(): boolean[] {
    if (this._device === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_device');
  }

  set device(value: boolean[]) {
    if (value) this._device = value;
  }

  get sim(): boolean[] {
    if (this._sim === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_sim');
  }

  set sim(value: boolean[]) {
    if (value) this._sim = value;
  }

  get sensors(): boolean[] {
    if (this._sensors === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_sensors');
  }

  set sensors(value: boolean[]) {
    if (value) this._sensors = value;
  }

  get services(): boolean[] {
    if (this._services === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_services');
  }

  set services(value: boolean[]) {
    if (value) this._services = value;
  }

  get driver(): boolean[] {
    if (this._driver === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_driver');
  }

  set driver(value: boolean[]) {
    if (value) this._driver = value;
  }

  get trailer(): boolean[] {
    if (this._trailer === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_trailer');
  }

  set trailer(value: boolean[]) {
    if (value) this._trailer = value;
  }

  get signal_prams(): boolean[] {
    if (this._signal_prams === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_signal_prams');
  }

  set signal_prams(value: boolean[]) {
    if (value) this._signal_prams = value;
  }

  get sensors_time(): boolean[] {
    if (this._sensors_time === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_sensors_time');
  }

  set sensors_time(value: boolean[]) {
    if (value) this._sensors_time = value;
  }

  get prams_time(): boolean[] {
    if (this._params_time === undefined) {
      return [false, false, false];
    }
    return this.getFieldValue('_params_time');
  }

  set prams_time(value: boolean[]) {
    if (value) this._params_time = value;
  }
}
