import { AppModel } from '../../../common/classes';
import { AfaqyObject } from '../../../common/classes/afaqy-object';
import { TranslateService } from '@ngx-translate/core';

class Government extends AfaqyObject {
  private _name: string = '';
  private _region: string = '';

  protected copyFields = ['id', 'name', 'region'];
  protected createFields = ['name', 'region_id'];
  protected updateFields = ['name', 'region_id'];

  get name(): string {
    return this._name;
  }
  get region(): string {
    return this._region;
  }

  set region(value: string) {
    this._region = value;
  }
}

export class Governments extends AppModel {
  constructor(protected translate: TranslateService) {
    super();
  }

  private _name: string;
  private _user_id: string;
  private _region: string = '';

  protected copyFields = ['id', 'name', 'region'];
  protected createFields = ['name', 'region_id'];
  protected updateFields = ['id', 'name', 'region_id'];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get region(): string {
    return this._region;
  }

  set region(value: string) {
    this._region = value;
  }
}
