import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VerifyOTPService {
  userVerification: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  triggerResendTimer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  triggerVerifyTimer: BehaviorSubject<any> = new BehaviorSubject<any>({
    type: null,
    blocked: false,
  });

  triggerBlockedStatus: Subject<{
    type?: string;
    blocked?: boolean;
  }> = new Subject();

  triggerVerifiedUpdate: Subject<{
    type?: string;
    verified: boolean;
  }> = new Subject();

  constructor(
    authService: AuthService,
    private apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    protected translate: TranslateService
  ) {}

  getOtp(url, methodType, token?: string): any {
    return !token
      ? this.apiRequest.postWithToken(`${url}`, {
          method: methodType,
        })
      : this.apiRequest.post(`${url}`, {
          method: methodType,
          token: token,
        });
  }

  checkUserVerified(userVerification: any) {
    const verified = Object.values(userVerification).some((x) => x);
    return verified;
  }
  verifyOtp(otpModel) {
    return this.apiRequest.postWithToken(`user/verify_otp`, {
      otpcode: parseInt(otpModel.otpcode),
      method: otpModel.method,
    });
  }
  verifyLoginOtp(otpModel) {
    return this.apiRequest.post(`auth/verify_login_otp`, {
      otpcode: parseInt(otpModel.otpCode),
      method: otpModel.method,
      token: otpModel.token,
    });
  }
  patch2faToggle(is_2fa: boolean) {
    return this.apiRequest.postWithToken('user/edit_profile', {
      is_2fa: is_2fa,
    });
  }
}
