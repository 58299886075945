<div
  class="d-flex flex-row align-items-center justify-content-start float-right"
>
  <!--<span class="float-right mt-3 ml-5 white-space-nowrap">-->
  <!--<strong>{{'total' | translate}}: {{pagination.filtersCount}}</strong>-->
  <!--</span>-->
  <div class="etwal">
    <nav aria-label="Page navigation" *ngIf="pagination.filtersCount">
      <ul class="pagination m-0 justify-content-center">
        <li class="page-item" [class.disabled]="pagination.current == 1">
          <a class="page-link" href="javascript:void(0)" (click)="getPage(1)">
            <i
              afaqy-tooltip
              [tooltipText]="'pagination.first' | translate"
              class="mdi mdi-chevron-double-left"
            ></i>
          </a>
        </li>
        <li class="page-item" [class.disabled]="pagination.current == 1">
          <a
            class="page-link"
            href="javascript:void(0)"
            (click)="getPage(pagination.current - 1)"
          >
            <i
              afaqy-tooltip
              [tooltipText]="'pagination.previous' | translate"
              class="mdi mdi-chevron-left"
            ></i>
          </a>
        </li>
        <li class="page-item" *ngIf="prevDots">.....</li>
        <li
          class="page-item"
          [class.active]="p == pagination.current"
          *ngFor="let p of pages"
        >
          <a class="page-link" href="javascript:void(0)" (click)="getPage(p)">{{
            p
          }}</a>
        </li>
        <li class="page-item" *ngIf="afterDots">.....</li>
        <li
          class="page-item"
          [class.disabled]="pagination.current == pagination.pages"
        >
          <a
            class="page-link"
            href="javascript:void(0)"
            (click)="getPage(pagination.current + 1)"
          >
            <i
              afaqy-tooltip
              [tooltipText]="'pagination.next' | translate"
              class="mdi mdi-chevron-right"
            ></i>
          </a>
        </li>
        <li
          class="page-item"
          [class.disabled]="pagination.current == pagination.pages"
        >
          <a
            class="page-link"
            href="javascript:void(0)"
            (click)="getPage(pagination.pages)"
          >
            <i
              afaqy-tooltip
              [tooltipText]="'pagination.last' | translate"
              class="mdi mdi-chevron-double-right"
            ></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <span class="mt-3 ml-5 white-space-nowrap">
    <strong>{{ 'total' | translate }}: {{ pagination.filtersCount }}</strong>
  </span>
</div>
